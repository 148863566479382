import ContainerWithHeader from '../../../components/container/containerWithHeader';
import { Icon } from '../../../components/icon/icon';
import { ROUTE_SETTINGS } from '../../../constants/routes';
import { InterestsForm } from '../../../forms/settings/interests';
import { useSupabase } from '../../../providers/supabase';
import { ReactComponent as BackSVG } from '../../../svg/arrow-left.svg';
import { ReactComponent as BackPlusSVG } from '../../../svg/arrowLeftCenePlus.svg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const InterestsPage = () => {
  const { session } = useSupabase();
  const navigate = useNavigate();

  const isCene = session?.type === 'cene';

  return (
    <ContainerWithHeader>
      <div
        className={classNames(
          'max-lg:w-full max-lg:mt-[1rem]',
          'w-[456px] mt-[2.5rem]'
        )}
      >
        <div className={classNames('w-full flex items-center text-center')}>
          <Icon onClick={() => navigate(ROUTE_SETTINGS)}>
            {isCene ? <BackSVG /> : <BackPlusSVG />}
          </Icon>
          <h1
            className={classNames(
              'text-[1.3125rem]/[1.3125] font-light ml-[calc(100%/3)]',
              'max-lg:ml-[calc(100%/4)]',
              isCene ? 'text-content' : 'text-generic'
            )}
          >
            Interests
          </h1>
        </div>
        <InterestsForm />
      </div>
    </ContainerWithHeader>
  );
};

export default InterestsPage;
