import { TicketList, TicketListProps } from './ticketItem';

interface AccessibleProps extends TicketListProps {
  isCombined: boolean;
}

const AccessibleSection = ({
  session,
  ticketTypes,
  isPurchaseDisabled,
  quantity,
  handleDecrement,
  handleIncrement,
  maxTotalQuantity,
  isCombined,
}: AccessibleProps) => {
  return isCombined ? (
    <div className="mt-[1rem] flex flex-col gap-[1rem]">
      <TicketList
        session={session}
        ticketTypes={ticketTypes}
        isPurchaseDisabled={isPurchaseDisabled}
        quantity={quantity}
        handleIncrement={handleIncrement}
        handleDecrement={handleDecrement}
        maxTotalQuantity={maxTotalQuantity}
      />
      <p className="text-generic font-light text-[0.75rem]/[1.125rem]">
        Accessible tickets are reserved for individuals with disabilities or
        specific physical needs. Our team may contact you to process this
        request
      </p>
    </div>
  ) : (
    <></>
  );
};

export default AccessibleSection;
