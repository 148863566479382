import { Button } from '../../components/button/button';
import { Checkbox } from '../../components/checkbox/checkbox';
import { ErrorBox } from '../../components/errorBox/errorBox';
import { Input } from '../../components/input/cene/input';
import { Label } from '../../components/label/cene/label';
import { InputSelect } from '../../components/select/select';
import Toast from '../../components/toast/toast';
import translations from '../../constants/translations.json';
import {
  ContactWithUsSchema,
  contactWithUsSchema,
} from '../../schemas/ContactWithUsSchema';
import {
  IOption,
  cenePartnersOptions,
  cenePlusOptions,
  ceneUsersOptions,
} from '../../types/options';
import { YourCenePlan } from '../../types/yourCenePlan';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';

export const ContactWithUsForm = () => {
  const [supabaseError, setSupabaseError] = useState<string | null>(null);
  const [isSuccess, setSuccess] = useState(false);
  const [isVisibleToast, setVisibleToast] = useState(false);
  const [secondDropdownOptions, setSecondDropdownOptions] =
    useState<IOption[]>(ceneUsersOptions);

  const planOptionsMap: Record<YourCenePlan, IOption[]> = {
    [YourCenePlan.CENE_USER]: ceneUsersOptions,
    [YourCenePlan.CENE_PLUS]: cenePlusOptions,
    [YourCenePlan.CENE_PARTNERS]: cenePartnersOptions,
  };

  const formik = useFormik<ContactWithUsSchema>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      yourCenePlan: YourCenePlan.CENE_USER,
      reasonForReachingOut: ceneUsersOptions[0].value,
      message: '',
      newsletterAgreement: false,
    },
    validationSchema: contactWithUsSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSupabaseError(null);
      try {
        const formData = new FormData();
        formData.append(
          'access_key',
          process.env.REACT_APP_WEBFORMS_ACCESS_KEY || ''
        );
        formData.append('Full Name', values.firstName + ' ' + values.lastName);
        formData.append('E-mail', values.email);
        formData.append('Additional Information', values.message || '');
        formData.append(
          'Newsletter',
          values.newsletterAgreement ? 'Yes' : 'No'
        );
        formData.append('Reason', values.reasonForReachingOut);
        formData.append('Cene Plan', values.yourCenePlan);

        const response = await fetch('https://api.web3forms.com/submit', {
          method: 'POST',
          body: formData,
        });

        await response.json();
        setSuccess(true);
        setVisibleToast(true);

        setSubmitting(false);
        resetForm();
      } catch (error) {
        setSuccess(false);
        setSupabaseError('There was an issue with sending the message');
      }
    },
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleFirstDropdownChange = (value: string) => {
    formik.setFieldValue('yourCenePlan', value);
    const options = planOptionsMap[value as YourCenePlan];

    if (options) {
      setSecondDropdownOptions(options);
      formik.setFieldValue('reasonForReachingOut', options[0].value);
    } else {
      setSecondDropdownOptions([]);
      formik.setFieldValue('reasonForReachingOut', '');
    }
  };

  return (
    <>
      <div
        className={classNames([
          'w-[644px] flex flex-col gap-[40px] lg:mb-[39px] relative',
          ' max-md:w-full',
        ])}
      >
        {isVisibleToast && (
          <Toast
            message={
              isSuccess ? 'Email sent successfully!' : 'Error sending email.'
            }
            bgColor={isSuccess ? 'bg-event' : 'bg-error'}
            onClose={() => setVisibleToast(false)}
          />
        )}
        <div className="flex items-baseline w-[100%] lg:gap-[24px] gap-[40px] max-md:flex-col">
          <div className="flex flex-col gap-[8px] w-full">
            <Label
              isRequired={true}
              id="firstName"
              classLabel="text-ticket"
              classText="text-white"
            >
              {translations.contactUs.firstName}
            </Label>
            <Input
              id="firstName"
              placeholder="Abayomi"
              contactUs={true}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.firstName}
              touched={Boolean(formik.touched.firstName)}
            />
          </div>
          <div className="flex flex-col gap-[8px] w-full">
            <Label
              isRequired={true}
              id="lastName"
              classLabel="text-ticket"
              classText="text-white"
            >
              {translations.contactUs.lastName}
            </Label>
            <Input
              id="lastName"
              contactUs={true}
              placeholder="Samuel"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.lastName}
              touched={Boolean(formik.touched.lastName)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <Label
            isRequired={true}
            id="email"
            classLabel="text-ticket"
            classText="text-white"
          >
            {translations.contactUs.email}
          </Label>
          <Input
            id="email"
            contactUs={true}
            placeholder="Your e-mail..."
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.email}
            touched={Boolean(formik.touched.email)}
          />
        </div>

        <div className="flex flex-col lg:flex-row lg:gap-[24px] gap-[40px] w-full">
          <div className="flex flex-col gap-[8px] w-full">
            <Label
              id="yourCenePlan"
              isRequired={true}
              classLabel="text-ticket"
              classText="text-white"
            >
              {translations.contactUs.cenePlan}
            </Label>
            <InputSelect
              type="text"
              value={formik.values.yourCenePlan}
              options={Object.values(YourCenePlan).map((plan) => ({
                label: plan,
                value: plan,
              }))}
              onChange={handleFirstDropdownChange}
              className="w-full"
            />
          </div>
          <div className="flex flex-col gap-[8px] w-full">
            <Label
              id="subscribtionPlan"
              isRequired={true}
              classLabel="text-ticket"
              classText="text-white"
            >
              {translations.contactUs.reason}
            </Label>
            <InputSelect
              type="text"
              value={formik.values.reasonForReachingOut}
              options={[
                ...secondDropdownOptions,
                { label: 'Other', value: 'Other' },
              ]}
              onChange={(value) => {
                formik.setFieldValue('reasonForReachingOut', value);
              }}
              className="w-full"
            />
          </div>
        </div>

        <div className="flex flex-col gap-[8px]">
          <Label id="message" classText="text-white">
            {translations.contactUs.message}
          </Label>
          <textarea
            value={formik.values.message}
            onChange={formik.handleChange}
            onInput={handleInput}
            ref={textareaRef}
            id="message"
            placeholder="Describe your issue or request..."
            rows={3}
            className={classNames([
              'focus:border-content',
              'placeholder:text-gray-light text-[1rem] font-light resize-none overflow-hidden w-full m-h-[96px] h-auto p-[16px] pl-[24px] outline-none text-white bg-black rounded-tl-[20px] rounded-br-[20px] border border-gray-medium',
            ])}
          />
        </div>
        <div className="w-full">
          <Checkbox
            checked={formik.values.newsletterAgreement}
            classText="text-white"
            onChange={(checked) =>
              formik.setFieldValue('newsletterAgreement', checked)
            }
            label="Sign up to our newsletter"
          />

          {supabaseError && (
            <div className="my-[20px]">
              <ErrorBox message={supabaseError} />
            </div>
          )}

          <Button
            className="w-full lg:mt-[24px] mt-[24px] mb-[72px] lg:mb-0 bg-ticket"
            type="submit"
            onClick={() => formik.handleSubmit()}
          >
            {translations.partnerWithUs.textButton}
          </Button>
        </div>
      </div>
    </>
  );
};