import { PaymentProviders } from '@ceneteam/namespace';
import { ReactNode, createContext, useContext, useState } from 'react';

interface CurrencyContextType {
  selectedCurrency: keyof PaymentProviders;
  setSelectedCurrency: (currency: keyof PaymentProviders) => void;
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined
);

interface CurrencyProviderProps {
  children: ReactNode;
}

export const CurrencyProvider = ({ children }: CurrencyProviderProps) => {
  const [selectedCurrency, setSelectedCurrency] =
    useState<keyof PaymentProviders>('NGN');

  return (
    <CurrencyContext.Provider value={{ selectedCurrency, setSelectedCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = (): CurrencyContextType => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};
