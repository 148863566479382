import translations from '../../constants/translations.json';
import classNames from 'classnames';

const CeneStory = 'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/ceneStory.gif';

export const CeneStorySection = () => {
  return (
    <div
      className={classNames([
        'lg:my-[120px] lg:mx-[64px] py-[72px] text-center',
      ])}
    >
      <div className="flex flex-col gap-[24px]">
        <h2 className="lg:text-[4rem] lg:leading-[72px] text-[2rem] leading-[40px] font-light text-village">
          {translations.ceneStory.title}
        </h2>

        <p className="lg:text-[1.3125rem] lg:leading-[21px] text-[1.1875rem] leading-[19px] lg:mb-[40px] mb-[48px] font-light max-w-[800px] mx-auto text-white whitespace-pre-wrap">
          {translations.ceneStory.desc}
        </p>
      </div>
      <div
        className="mx-auto w-full lg:w-[866px] lg:h-[500px] rounded-tl-[30px] rounded-br-[30px] bg-cover bg-no-repeat h-[193.42px]"
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%), url(${CeneStory})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
        aria-label="Cene Story"
      />
    </div>
  );
};
