import CarouselSection from '../../components/carouselSection/carouselSection';
import { Loader } from '../../components/loader/cene/loader';
import { Loader as LoaderCenePlus } from '../../components/loader/cenePlus/loader';
import translations from '../../constants/translations.json';
import { useTicketActions } from '../../hooks/useTicketActions';
import { useSupabase } from '../../providers/supabase';
import EmptyEventsSection, { EventType } from './notFound.tsx/notFound';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UpcomingEventsSection = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { getAllTickets } = useTicketActions();
  const navigate = useNavigate();
  const { session } = useSupabase();

  const sessionType = session?.type === 'cene';

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const tickets = await getAllTickets();
        setEvents(tickets || []);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
      setLoading(false);
    };

    fetchTickets();
  }, []);

  const handleCardClick = (id: string, eventData: Event) => {
    navigate(`/ticket-details/${id}`, { state: { eventData } });
  };

  return (
    <div className={classNames('mt-[2.75rem]', 'max-lg:mt-[1.75rem]')}>
      {isLoading ? (
        <div className="flex justify-center items-center h-[558px]">
          {sessionType ? <Loader /> : <LoaderCenePlus />}
        </div>
      ) : events.length === 0 ? (
        <EmptyEventsSection
          events={events}
          title={translations.myTickets.upcomingEvents}
          sessionType={sessionType}
          type={EventType.UPCOMING}
        />
      ) : (
        <CarouselSection
          data={events}
          title={translations.myTickets.upcomingEvents}
          sessionType={sessionType}
          color={sessionType ? 'ticket' : 'generic'}
          handleCardClick={(id) =>
            handleCardClick(id, events.find((event) => event.id === id)!)
          }
          colorCard={sessionType ? 'ticket' : 'black'}
        />
      )}
    </div>
  );
};

export default UpcomingEventsSection;
