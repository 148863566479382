import { ReactComponent as CalendarBlankSvg } from '../../../src/svg/CalendarBlank.svg';
import { ReactComponent as MapGenericSvg } from '../../../src/svg/MapPinLineGeneric.svg';
import { ReactComponent as MapSvg } from '../../../src/svg/MapPinLineSmall.svg';
import { ReactComponent as ArrowDownSvg } from '../../../src/svg/arrow-down-event.svg';
import { ReactComponent as ArrowDownGenericSvg } from '../../../src/svg/arrowPlus.svg';
import { ReactComponent as ArrowUpSvg } from '../../../src/svg/arrowUp.svg';
import { ReactComponent as CalendarBlankGenericSvg } from '../../../src/svg/calendarPlus.svg';
import { ReactComponent as InfoSvg } from '../../../src/svg/infoWhite.svg';
import { Button } from '../../components/button/button';
// import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import { formatEventDate } from '../../helpers/formatDate';
import { InfoSection } from './InfoSection';
import classNames from 'classnames';
import React, { useState } from 'react';

interface StepTwoProps {
  nextStep: () => void;
  session?: string | undefined;
  title: string | undefined;
  place?: string;
  currentDate?: string;
  startsAt: string | undefined;
  selectedTickets: { title: string; quantity: number }[];
}

// const infoSections = [
//   {
//     title: translations.paymentSuccess.date,
//     subtitle: translations.paymentSuccess.dateTime,
//   },
//   // {
//   //   title: translations.paymentSuccess.order,
//   //   subtitle: translations.paymentSuccess.orderNumber,
//   // },
//   {
//     title: translations.paymentSuccess.ticket,
//     subtitle: translations.paymentSuccess.ticketType,
//   },
//   // {
//   //   title: translations.paymentSuccess.host,
//   //   subtitle: translations.paymentSuccess.collective,
//   // },
//   // {
//   //   title: translations.paymentSuccess.entry,
//   //   subtitle: translations.paymentSuccess.entryInfo,
//   // },
// ];

export const StepTwo: React.FC<StepTwoProps> = ({
  nextStep,
  session,
  startsAt,
  currentDate,
  place,
  title,
  selectedTickets,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sessionType = session === 'cene';
  const formattedDate = formatEventDate(startsAt || '');

  const infoSections = [
    {
      title: translations.paymentSuccess.date,
      subtitle: currentDate,
    },
    // {
    //   title: translations.paymentSuccess.order,
    //   subtitle: translations.paymentSuccess.orderNumber,
    // },
    {
      title: translations.paymentSuccess.ticket,
      subtitle: selectedTickets
        .map((ticket) => `${ticket.title} x ${ticket.quantity}`)
        .join('\n'),
    },
    // {
    //   title: translations.paymentSuccess.host,
    //   subtitle: translations.paymentSuccess.collective,
    // },
    // {
    //   title: translations.paymentSuccess.entry,
    //   subtitle: translations.paymentSuccess.entryInfo,
    // },
  ];

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="text-start">
      <p className="leading-[18px] text-[0.75rem] text-white font-medium mb-[27px]">
        {translations.paymentSuccess.copy}
      </p>
      <p
        className={classNames([
          'leading-[12px] text-[0.75rem] font-normal mb-[12px]',
          sessionType ? 'text-event ' : 'text-generic',
        ])}
      >
        {title} {translations.paymentSuccess.world}
      </p>
      <div className="mb-[27px]">
        <div className=" mb-[16px] flex items-start">
          <div>
            {sessionType ? (
              <MapSvg className="mr-[12px]" />
            ) : (
              <MapGenericSvg className="mr-[12px]" />
            )}
          </div>
          <div>
            <p className="text-[1.0625rem] leading-[22px] text-white font-normal">
              {place}
            </p>
          </div>
        </div>
        <div className="flex items-start">
          {sessionType ? (
            <CalendarBlankSvg className="mr-[12px] w-[24px] h-[24px]" />
          ) : (
            <CalendarBlankGenericSvg className="mr-[12px] w-[24px] h-[24px]" />
          )}

          <div>
            <p className="text-[1.0625rem] leading-[22px] text-white font-normal">
              {formattedDate}
            </p>
          </div>
        </div>
      </div>

      {infoSections.map((section, index) => (
        <InfoSection
          key={index}
          title={section.title}
          subtitle={section.subtitle}
        />
      ))}

      <div className="border-b border-gray-medium mb-[16px]">
        <p className="text-white font-normal text-[0.75rem] leading-[12px] mb-[8px]">
          {translations.paymentSuccess.ticketPrice}
        </p>
        <div className="text-gray-light font-medium text-[1rem] mb-[8px] leading-[24px] flex flex-col">
          <span className="mb-[8px]">
            {translations.paymentSuccess.ticketFace}
          </span>
          <span className="mb-[8px]">{translations.paymentSuccess.fee}</span>
          <span>{translations.paymentSuccess.total}</span>
        </div>
      </div>
      <div className="border-b border-gray-medium mb-[16px]">
        <div className="flex items-center mb-[16px]">
          <p
            className={classNames([
              'font-medium text-[1rem] leading-[24px] mr-[10.25px]',
              sessionType ? 'text-event' : 'text-generic',
            ])}
          >
            {translations.paymentSuccess.terms}
          </p>
          <button
            onClick={toggleContent}
            className="flex items-center justify-center"
          >
            {isOpen ? (
              sessionType ? (
                <ArrowUpSvg />
              ) : (
                <ArrowDownGenericSvg className="transform rotate-180" />
              )
            ) : sessionType ? (
              <ArrowDownSvg />
            ) : (
              <ArrowDownGenericSvg />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="mt-[16px] text-white text-[0.75rem] leading-[18px] lg:mb-[40px] mb-[24px] flex items-start">
          <InfoSvg className="flex-shrink-0 self-start " />
          <div className="ml-[10px] whitespace-pre-line">
            <p className="mb-[10px]">{translations.paymentSuccess.getCrew}</p>
            <p>{translations.paymentSuccess.openApp}</p>
          </div>
        </div>
      )}
      <Button
        className={classNames([
          'w-full h-[48px]',
          sessionType ? 'bg-event ' : 'bg-generic',
        ])}
        onClick={nextStep}
      >
        <p className="font-medium text-[1rem] leading-[24px]">
          {translations.paymentSuccess.next}
        </p>
      </Button>
    </div>
  );
};
