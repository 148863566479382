import { ReactComponent as LoaderSVG } from '../svg/Loader.svg';
import { useEffect, useState } from 'react';

interface ISeatsioPreview {
  containerId?: string;
  eventId: string;
}

export const SeatsioPreview = ({
  containerId = 'seatsio',
  eventId = 'a592c5b4-0869-4ff9-81d9-3e9932087834',
}: ISeatsioPreview) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.seatsio) {
      new window.seatsio.SeatingChart({
        divId: containerId,
        workspaceKey: `${process.env.REACT_APP_SEATSIO_WORKSPACE}`,
        event: eventId,
        colorScheme: 'dark',
        mode: 'print',
        onChartRendered: () => {
          setLoading(false);
        },
      }).render();
    }
  }, []);

  const renderLoader = (
    <div className="w-full absolute bg-black flex justify-center items-center h-[220px]">
      <LoaderSVG className="animate-spin" />
    </div>
  );

  return (
    <div className="relative">
      {loading && renderLoader}
      <div
        style={{
          width: '100%',
          height: '220px',
        }}
        id={containerId}
      />
    </div>
  );
};
