import { Button } from '../../../components/button/button';
import { ROUTE_EVENTS } from '../../../constants/routes';
import translations from '../../../constants/translations.json';
import { ReactComponent as TicketSVG } from '../../../svg/ticket-menu.svg';
import { ReactComponent as TimerSVG } from '../../../svg/timer-icon.svg';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export enum EventType {
  ATTENDED = 'attended',
  UPCOMING = 'upcoming',
}

interface EmptyEventsProps {
  title: string;
  events: Event[];
  sessionType: boolean;
  type: EventType;
}

const EmptyEventsSection = ({
  title,
  events,
  sessionType,
  type,
}: EmptyEventsProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-[2.5rem] max-lg:gap-[1.5rem] items-center w-full lg:pb-[96px]">
      <div className="flex items-center justify-start w-full">
        <h2
          className={classNames(
            'text-[2rem]/[2.5rem] font-light',
            sessionType ? 'text-white' : 'text-generic'
          )}
        >
          {title}
        </h2>
        <p className="text-[0.9375rem] leading-[20px] text-event-light ml-[8px] self-start">
          {events.length}
        </p>
      </div>
      <div
        className={classNames(
          'max-w-[544px] text-center flex flex-col items-center',
          'max-lg:py-[2.5rem] max-lg:max-w-[280px]'
        )}
      >
        {(type === EventType.UPCOMING && (
          <TicketSVG
            style={{
              stroke: sessionType ? '#FFF' : '#B8CAD9',
              width: '72px',
              height: '48px',
              strokeWidth: '1px',
            }}
          />
        )) ||
          (type === EventType.ATTENDED && (
            <TimerSVG
              style={{
                stroke: sessionType ? '#FFF' : '#B8CAD9',
                width: '60px',
                height: '60px',
                strokeWidth: '1px',
              }}
            />
          ))}
        <h3
          className={classNames(
            'text-[3rem]/[3.5rem] !font-light mt-[1rem]',
            'max-lg:text-[1.3125rem]/[150%] max-lg:mt-[0.5rem]',
            sessionType ? 'text-ticket' : 'text-generic'
          )}
        >
          {(type === EventType.UPCOMING &&
            translations.myTickets.emptyUpcomingEventsTitle) ||
            (type === EventType.ATTENDED &&
              translations.myTickets.emptyAttendedEventsTitle)}
        </h3>
        <p
          className={classNames(
            'text-[1.0625rem]/[1.375rem] font-light mt-[1rem]',
            'max-lg:text-[0.875rem]/[1.3125rem] max-lg:mt-[0.5rem]',
            sessionType ? 'text-white' : 'text-gray-light'
          )}
        >
          {(type === EventType.UPCOMING &&
            translations.myTickets.emptyUpcomingEventsSubtitle) ||
            (type === EventType.ATTENDED &&
              translations.myTickets.emptyAttendedEventsSubtitle)}
        </p>
        <Button
          onClick={() => navigate(ROUTE_EVENTS)}
          color={sessionType ? 'ticket' : 'generic'}
          className={classNames(
            'w-[332px] mt-[2.5rem]',
            'max-lg:w-[260px] max-lg:mt-[1.5rem]'
          )}
        >
          {(type === EventType.UPCOMING &&
            translations.myTickets.emptyUpcomingEventsButtonText) ||
            (type === EventType.ATTENDED &&
              translations.myTickets.emptyAttendedEventsButtonText)}
        </Button>
      </div>
    </div>
  );
};

export default EmptyEventsSection;
