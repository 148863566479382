import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
import { CeneStorySection } from '../../sections/diversity/ceneStorySection';
import { CommitmentSection } from '../../sections/diversity/commitmentSection';
import { DiversityBanner } from '../../sections/diversity/diversityBanner';
import { InclusivitySection } from '../../sections/diversity/inclusivitySection';
import { Road14Section } from '../../sections/diversity/road14Section';
import classNames from 'classnames';

export const DiversityPage = () => {
  usePreventHorizontalScroll();

  return (
    <>
      <div className="">
        <div
          className={classNames([
            'block lg:h-[811px] w-full top-0 left-1/2 -translate-x-1/2 z-10',
            'relative max-lg:-mx-[20px] max-lg:-mt-[88px] max-lg:w-auto max-lg:left-0 max-lg:translate-x-0 ',
            'h-[578px]',
          ])}
        >
          <DiversityBanner />

          <div className="lg:absolute lg:w-screen lg:top-[-89px] lg:left-[50%] lg:right-[50%] lg:mx-[-50vw] relative">
            
            <div className="absolute  inset-0 lg:top-0 lg:h-[298px] h-[78px] bg-diversity-t"/>

            <img
              src="/pictures/diversityBanner.png"
              className={classNames([
                'h-full w-full max-sm:object-cover object-cover object-[50%] max-lg:w-full lg:block hidden',
              ])}
            />

            <img
              src="/pictures/bannerDiversitySmall.png"
              className={classNames([
                'h-full w-full max-sm:object-cover mt-[72px] object-cover object-[70%] max-lg:w-full block lg:hidden',
              ])}
            />

            <div className="absolute  inset-x-0 bottom-0 lg:h-[290px] h-[56.51px] bg-diversity-b" />
          </div>
        </div>
      </div>

      
        <CommitmentSection />
        <InclusivitySection />
        <Road14Section />
        <CeneStorySection />
      
    </>
  );
};
