import App from './App';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
  document.getElementById('cene') as HTMLElement
);

root.render(<App />);
