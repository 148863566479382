import { ReactComponent as LeftQuoteSvg } from '../../../src/svg/leftQuote.svg';
import { ReactComponent as RightQuoteSvg } from '../../../src/svg/rightQuote.svg';
import { ReactComponent as YellowLogoSvg } from '../../../src/svg/yellowLogo.svg';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

const Person = '/pictures/person.png';

export const TestimonialSection = () => {
  return (
    <div
      className={classNames([
        'lg:my-[120px] lg:mx-[171px] mx-[20px] my-[72px]',
      ])}
    >
      <div className={classNames(['text-white flex flex-col  items-center'])}>
        <YellowLogoSvg className="lg:mb-[40px] mb-[80px]" />

        <div
          className={classNames([
            'relative mx-auto ',
            'max-w-full sm:max-w-[1098px] lg:max-w-[1098px] ',
          ])}
        >
          <LeftQuoteSvg
            className={classNames([
              'sm:h-[56px] sm:w-[56px]',
              'absolute lg:-top-4 lg:-left-[48px] lg:-ml-5',
              '   left-1/2 transform -translate-x-1/2 -translate-y-full lg:transform-none',
            ])}
          />
          <h1
            className={classNames([
              'text-[1.25rem] leading-[28px] font-light text-center',
              'lg:text-[2.5rem] lg:leading-[3rem] pt-[8px] pb-[8px] lg:pt-[22px] lg:pb-[22px]',
            ])}
          >
            {translations.testimonialTicketing.title}
          </h1>
          <RightQuoteSvg
            className={classNames([
              'sm:h-[56px] sm:w-[56px]',
              'absolute lg:bottom-10 lg:right-0 lg:-mr-[48px]',
              '  bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full lg:left-auto lg:translate-x-0',
            ])}
          />
        </div>
        <div className="lg:mt-[40px] flex items-center mt-[80px]">
          <img
            src={Person}
            alt="Person"
            className={classNames(['w-[48px]', 'rounded-big'])}
          />
          <div className="ml-[20px] flex flex-col">
            <h3
              className={classNames([
                'lg:text-[1.0625rem] lg:leading-[22px] text-[1rem] leading-[21px]',
              ])}
            >
              {translations.testimonialTicketing.name}
            </h3>
            <h6 className={classNames(['text-gray-light text-[0.875rem]'])}>
              {translations.testimonialTicketing.position}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
