import * as routes from '../constants/routes';
import { seo } from '../constants/seo';
import { RootLayout } from '../layout/rootLayout';
import { Seo } from '../layout/seo';
import { AboutPage } from '../pages/about/aboutPage';
import { CreateNewPasswordPage } from '../pages/auth/createPassword/createPassword';
import { TermSplashPage } from '../pages/auth/termSplash/termSplash';
import { BurstOntoPage } from '../pages/burstOnto/burstOnto';
import { CareersPage } from '../pages/careers/careersPage';
import { ContactUs } from '../pages/contactUs/contactUs';
import { DeleteAccount } from '../pages/deleteAccount/deleteAccount';
import { DiversityPage } from '../pages/diversity/diversity';
import { DownloadPage } from '../pages/download/download';
import EventsCenePage from '../pages/events/cene';
import { EventsDetailsPage } from '../pages/eventsDetails/eventsDetailsPage';
import { FaqPage } from '../pages/faq/faqPage';
import { HomePage } from '../pages/home/homePage';
import { MembershipPage } from '../pages/membership/membership';
import { NotFoundPage } from '../pages/notFound/notFoundPage';
import { OrganizerPage } from '../pages/organizer/organizerPage';
import { PartnerWithUs } from '../pages/partnerWithUs/partnerWithUs';
import { TermsPage } from '../pages/terms/terms';
import { TicketingPage } from '../pages/ticketing/ticketing';
import { WelcomePage } from '../pages/welcome/welcome';
import { RouteObject } from 'react-router-dom';

/**
 * Router for authorized and unauthorized users
 */

export const generalRouter: RouteObject = {
  children: [
    {
      element: <RootLayout />,
      children: [
        {
          index: true,
          element: (
            <Seo title={seo.home.title} description={seo.home.description}>
              <HomePage />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_BURST,
          element: (
            <Seo
              title={seo.partners.title}
              description={seo.partners.description}
            >
              <BurstOntoPage />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_TICKETING,
          element: (
            <Seo
              title={seo.plansAndPricing.title}
              description={seo.plansAndPricing.description}
            >
              <TicketingPage />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_DIVERSITY,
          element: <DiversityPage />,
        },
        {
          path: routes.ROUTE_CAREERS,
          element: <CareersPage />,
        },
        {
          path: routes.ROUTE_MEMBERSHIP,
          element: <MembershipPage />,
        },
        {
          path: routes.ROUTE_PARTNER_WITH_US,
          element: (
            <Seo
              title={seo.becomePartner.title}
              description={seo.becomePartner.description}
            >
              <PartnerWithUs />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_CONTACT_WITH_US,
          element: <ContactUs />,
        },
        {
          path: routes.ROUTE_DELETE_ACCOUNT,
          element: <DeleteAccount />,
        },
        {
          path: routes.ROUTER_ABOUT,
          element: (
            <Seo title={seo.about.title} description={seo.about.description}>
              <AboutPage />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_FAQ,
          element: (
            <Seo title={seo.faq.title} description={seo.faq.description}>
              <FaqPage />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_TERMS,
          element: (
            <Seo title={seo.terms.title} description={seo.terms.description}>
              <TermsPage />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_DOWNLOAD,
          element: (
            <Seo
              title={seo.download.title}
              description={seo.download.description}
            >
              <DownloadPage />
            </Seo>
          ),
        },
        {
          path: routes.ROUTE_EVENTS,
          element: <EventsCenePage />,
        },
        {
          path: routes.ROUTE_EVENTS_DETAILS,
          element: <EventsDetailsPage />,
        },
        {
          path: routes.ROUTE_ORGANIZER,
          element: <OrganizerPage />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE_PLUS_TERM,
          element: <TermSplashPage />,
        },
        {
          path: routes.ROUTE_WELCOME,
          element: (
            <Seo title={seo.home.title} description={seo.home.description}>
              <WelcomePage />
            </Seo>
          ),
        },
        {
          path: '*',
          element: (
            <Seo
              title={seo.notFound.title}
              description={seo.notFound.description}
            >
              <NotFoundPage />
            </Seo>
          ),
        },
      ],
    },
    {
      element: <RootLayout shownHeader={false} shownFooter={false} />,
      children: [
        {
          path: routes.ROUTER_FORGOT_PASSWORD_CREATE_NEW_PASSWORD,
          element: <CreateNewPasswordPage />,
        },
      ],
    },
  ],
};