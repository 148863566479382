import { Button } from '../../../components/button/button';
import { ErrorBox } from '../../../components/errorBox/errorBox';
import { Input } from '../../../components/input/cene/input';
import { Label } from '../../../components/label/cene/label';
import translation from '../../../constants/translations.json';
import { RegisterSchema } from '../../../schemas/registerSchema';
// import { supabaseCeneClient } from '../../../supabase/supabase';
import { AuthError } from '@supabase/supabase-js';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';


interface EmailFormProps {
  onNext: () => void;
}

export const EmailForm = ({ onNext = () => null }: EmailFormProps) => {
  const formik = useFormikContext<RegisterSchema>();
  const [supabaseError, setSupabaseError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);

  const isEmailValid = formik.values.email ? !formik.errors.email : false;

  const isFormValid = isEmailValid;

  const onSubmit = async () => {
    try {
      setSupabaseError(null);
      setLoading(true);

      // const updateUser = await supabaseCeneClient.auth.updateUser(
      //   {
      //     email: formik.values.email,
      //   },
      //   {
      //     emailRedirectTo: `${window.location.origin}/auth/register/cene?verify_email=verified`,
      //   }
      // );

      // if (updateUser.error) throw updateUser.error;

      // const { error } = await supabaseCeneClient
      //   .from('customers')
      //   .update([
      //     {
      //       first_name: formik.values.firstName,
      //       last_name: formik.values.lastName,
      //       gender: formik.values.gender,
      //       birthdate: formik.values.dateOfBirth,
      //       phone_number: `+${formik.values.countryCode}${formik.values.phone}`,
      //     },
      //   ])
      //   .eq('user_id', updateUser.data?.user?.id);

      // if (error) throw error;

      //sign user up with email

      onNext();
    } catch (error) {
      if (error instanceof AuthError) {
        setSupabaseError(error.message);
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pt-[24px]">
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.emailStep.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.emailStep.description}
      </p>
      <div className="flex flex-col">
        <div>
          <Label id="email" isRequired={true}>
            {translation.steps.emailStep.email}
          </Label>
          <Input
            id="email"
            type="email"
            placeholder={translation.steps.emailStep.enterEmail}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.email}
            touched={Boolean(formik.touched.email)}
          />
        </div>
        {supabaseError && <ErrorBox message={supabaseError} />}
        <Button
          color="content"
          type="button"
          className={classNames(['w-full mt-[40px]'])}
          onClick={onSubmit}
          disabled={!isFormValid || isLoading}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};