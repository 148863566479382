import { ReactComponent as DoorCloseSvg } from '../../../../../src/svg/DoorClose.svg';
import { ReactComponent as DoorOpenSvg } from '../../../../../src/svg/DoorOpen.svg';
import { ReactComponent as ArrowDownSvg } from '../../../../../src/svg/arrow-down.svg';
import translations from '../../../../constants/translations.json';
import { Event, LineUpItem } from '@ceneteam/namespace';
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';

interface EventWithTimezone extends Event {
  timezone: string;
}

interface LineupSectionProps {
  event: Event | null;
}

const LineupSection = ({ event }: LineupSectionProps) => {
  const [showMore, setShowMore] = useState(false);
  const handleToggle = () => setShowMore(!showMore);

  const artists = event?.line_up as LineUpItem[] | undefined;
  const eventWithTimezone = event as EventWithTimezone | null;

  if (!artists || artists.length <= 2) {
    return (
      <div className="w-full">
        <p className="text-[15px] leading-[20px] text-white">
          {translations.eventDetailsSection.lineUp}
        </p>
        <div className="mt-[16px] text-generic text-[16px] leading-[24px]">
          No line-up available
        </div>
      </div>
    );
  }

  const filteredArtists = artists.slice(1, artists.length - 1);

  const renderArtists = () => {
    return (
      <div className="flex items-center">
        <div className="relative flex items-center">
          {filteredArtists.slice(0, 3).map((artist, index) => (
            <div
              key={index}
              className={classNames(
                'relative w-[40px] h-[40px] bg-gray-light rounded-tl-[8px]',
                index > 0 ? '-ml-[8px]' : ''
              )}
              style={{ zIndex: index }}
            >
              <div
                className="absolute -top-[1px] -left-[2px] w-[42px] h-[41px] rounded-tl-[8px] bg-black"
                style={{ zIndex: 0 }}
              ></div>

              {artist.thumbnail_url ? (
                <img
                  src={artist.thumbnail_url}
                  alt={artist.title || `Artist ${index + 1}`}
                  className="absolute w-[40px] h-[40px] object-cover rounded-tl-[8px] filter grayscale"
                  style={{ zIndex: 1 }}
                />
              ) : (
                <div
                  className="absolute w-[40px] h-[40px] rounded-tl-[8px] bg-gray-medium"
                  style={{ zIndex: 1 }}
                ></div>
              )}

              <div
                className="absolute inset-0 bg-card-gradient"
                style={{ zIndex: 2 }}
              ></div>
            </div>
          ))}
          {filteredArtists.length > 3 && (
            <div
              className={classNames(
                'relative w-[40px] h-[40px] rounded-tl-[8px] flex items-center justify-center',
                '-ml-[8px]'
              )}
              style={{ zIndex: 3 }}
            >
              <div
                className="absolute -top-[1px] -left-[2px] w-[42px] h-[41px] rounded-tl-[8px] bg-black"
                style={{ zIndex: 0 }}
              ></div>

              <div
                className="absolute w-[40px] h-[40px] rounded-tl-[8px] bg-gray-medium"
                style={{ zIndex: 1 }}
              ></div>

              <div
                className="absolute inset-0 bg-card-gradient"
                style={{ zIndex: 2 }}
              ></div>
              <p className="text-generic text-[1rem]" style={{ zIndex: 3 }}>
                +{filteredArtists.length - 3}
              </p>
            </div>
          )}
        </div>
        <p className="ml-[16px] text-generic text-[1.0625rem] font-normal">
          {filteredArtists
            .slice(0, 3)
            .map((artist) => artist.title)
            .join(', ')}
          {filteredArtists.length > 3 &&
            ` and ${filteredArtists.length - 3} more`}
        </p>
      </div>
    );
  };

  const renderFullLineup = () => (
    <div className="mt-[16px] flex flex-col">
      {artists.map((artist, index) => {
        const isFirst = index === 0;
        const isLast = index === artists.length - 1;
        const hasImage = artist.thumbnail_url;

        const artistTitle = isFirst
          ? 'Doors open'
          : isLast
            ? 'Event ends'
            : artist.title;

        return (
          <div key={index} className="flex items-center mb-[24px]">
            {isFirst ? (
              <DoorOpenSvg className="w-[40px] h-[40px] mr-[16px]" />
            ) : isLast ? (
              <DoorCloseSvg className="w-[40px] h-[40px] mr-[16px]" />
            ) : hasImage ? (
              <img
                src={artist.thumbnail_url || ''}
                alt={artist.title || `Artist ${index + 1}`}
                className="w-[40px] h-[40px] object-cover rounded-tl-[8px] mr-[16px] filter grayscale"
              />
            ) : (
              <div className="w-[40px] h-[40px] flex justify-center items-center mr-[16px]">
                <div className="h-[30px] w-[1px] bg-gray-medium"></div>
              </div>
            )}
            <div className="flex flex-col">
              <p className="text-generic font-medium">{artistTitle}</p>
              {artist.starts_at ? (
                <p className="text-white">
                  {moment(artist.starts_at).format('h:mm A')}{' '}
                  {eventWithTimezone?.timezone}
                </p>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="bg-generic-06 border border-generic-light max-md:p-[1.25rem] p-[2.5rem] rounded-tl-[30px] rounded-br-[30px] flex flex-col max-md:gap-[1rem]">
      <div className="max-lg:block hidden">
        <h3
          className={classNames(
            'text-[1.0625rem]/[1.375rem] font-light',
            'text-gray-light'
          )}
        >
          {event?.category}
        </h3>
        <h1
          className={classNames(
            'text-[2.25rem]/[2.75rem] font-light mt-[0.5rem]',
            'text-generic'
          )}
        >
          {event?.name}
        </h1>
      </div>

      <p className="text-[15px] leading-[20px] text-white mb-[1rem] max-md:hidden">
        {translations.eventDetailsSection.lineUp}
      </p>
      {showMore ? renderFullLineup() : renderArtists()}
      <div
        className="mt-[16px] text-generic text-[1rem] leading-[24px] flex items-center cursor-pointer"
        onClick={handleToggle}
      >
        <p className="mr-[12px]">
          {showMore
            ? translations.eventDetailsSection.hide
            : translations.eventDetailsSection.more}
        </p>
        <ArrowDownSvg
          className={`transition-transform ${showMore ? 'rotate-180' : ''}`}
        />
      </div>
    </div>
  );
};

export default LineupSection;
