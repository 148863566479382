import { ReactComponent as WarningSVG } from '../../svg/circleWavyWarning.svg';

interface ErrorBoxProps {
  message: string;
}

export const ErrorBox = ({ message }: ErrorBoxProps) => (
  <div className="flex items-center gap-[16px] text-[0.875rem] text-red font-light mt-[10px]">
    <WarningSVG className="min-w-[32px] min-h-[32px]" />
    <div>{message}</div>
  </div>
);
