import { ReactComponent as CopySvg } from '../../../src/svg/Copy.svg';
import { ReactComponent as CloseSvg } from '../../../src/svg/close.svg';
import { ReactComponent as ExternalSvg } from '../../../src/svg/externalLink.svg';
import translations from '../../constants/translations.json';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { Header } from '../../layout/header/header';
import Toast from '../toast/toast';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';

interface ModalProps {
  event?: Event | null;
  isOpen: boolean;
  onClose: () => void;
  session?: string | undefined;
}

export const ModalLocation: React.FC<ModalProps> = ({
  event,
  isOpen,
  onClose,
  session,
}) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

  useDisableBodyScroll(isOpen);

  useEffect(() => {
    if (isOpen && mapContainerRef.current && event?.location) {
      try {
        const [lat, lng] = event.location
          .replace(/[()]/g, '')
          .split(',')
          .map((coord) => parseFloat(coord.trim()));

        if (isNaN(lat) || isNaN(lng)) {
          throw new Error('Invalid coordinates');
        }

        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/dark-v11',
          center: [lng, lat],
          zoom: 10,
        });

        new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);

        const navControl = new mapboxgl.NavigationControl();
        map.addControl(navControl, 'top-right');

        return () => {
          map.remove();
        };
      } catch (error) {
        console.error('Invalid location format: ', error);
      }
    }
  }, [isOpen, event]);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleCopyClick = () => {
    const address = event?.address || '';
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setCopySuccess(true);
      })
      .catch((err) => console.error('Failed to copy text: ', err));
  };

  const handleAppleMapsClick = () => {
    if (!event?.location) return;

    const [lat, lng] = event.location
      .replace(/[()]/g, '')
      .split(',')
      .map((coord) => parseFloat(coord.trim()));

    if (isNaN(lat) || isNaN(lng)) return;

    const url = `https://maps.apple.com/?ll=${lat},${lng}`;
    window.open(url, '_blank');
  };

  const handleGoogleMapsClick = () => {
    if (!event?.location) return;

    const [lat, lng] = event.location
      .replace(/[()]/g, '')
      .split(',')
      .map((coord) => parseFloat(coord.trim()));

    if (isNaN(lat) || isNaN(lng)) return;

    const url = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(url, '_blank');
  };
  const sessionType = session === 'cene+';

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-[16px] flex justify-center items-center z-[100]"
        onClick={handleOverlayClick}
      >
        <div
          className="bg-black lg:w-[365px] w-full relative lg:rounded-tl-[30px] lg:rounded-br-[30px] border border-gray-medium lg:h-[682px] h-full overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="lg:hidden flex px-[20px]">
            <Header />
          </div>

          <div className="flex justify-between lg:justify-center items-center lg:pt-[32px] pb-[24px] px-[20px] lg:px-0">
            <h1
              className={classNames([
                ' text-[2rem] leading-[40px]',
                sessionType ? 'text-generic' : 'text-white ',
              ])}
            >
              {sessionType
                ? translations.modalLocation.titlePlus
                : translations.modalLocation.title}
            </h1>
            <button
              onClick={onClose}
              aria-label="Close"
              className="p-[8px] border border-gray-medium rounded-tl-[16px] rounded-br-[16px] lg:hidden"
            >
              <CloseSvg />
            </button>
          </div>
          <div className="lg:px-8 px-[20px]">
            <div
              ref={mapContainerRef}
              id="map"
              className="lg:w-[300px] lg:h-[300px] w-full h-[336px] rounded-tl-[30px] rounded-br-[30px]"
              style={{ overflow: 'hidden' }}
            ></div>
            <div className="mt-[24px]">
              <p className="text-gray-light mb-[8px] text-[0.875rem] leading-[14px]">
                {translations.modalLocation.titleAddress}
              </p>
              <div className="flex items-center mb-[24px]">
                <p
                  title={event?.address}
                  className="text-white flex-1 text-[1rem] leading-[24px] truncate"
                >
                  {event?.address}
                </p>
                <button onClick={handleCopyClick} aria-label="Copy address">
                  <CopySvg />
                </button>
              </div>
            </div>
            <div className="h-[1px] bg-gray-medium mb-[24px]" />
            <div className="flex items-center mb-[24px]">
              <p className="text-white flex-1 text-[1rem] leading-[24px]">
                {translations.modalLocation.appleMaps}
              </p>
              <button
                onClick={handleAppleMapsClick}
                aria-label="Open Apple Maps"
              >
                <ExternalSvg />
              </button>
            </div>
            <div className="h-[1px] bg-gray-medium mb-[24px]" />
            <div className="flex items-center mb-[32px]">
              <p className="text-white flex-1 text-[1rem] leading-[24px]">
                {translations.modalLocation.googleMaps}
              </p>
              <button
                onClick={handleGoogleMapsClick}
                aria-label="Open Google Maps"
              >
                <ExternalSvg />
              </button>
            </div>
          </div>
        </div>
      </div>
      {copySuccess && (
        <Toast
          message="Address successfully copied!"
          onClose={() => setCopySuccess(false)}
        />
      )}
    </>
  );
};
