import translations from '../../constants/translations.json';
import classNames from 'classnames';

export const CareersBannerSection = () => {
  return (
    <div>
      <div
        className={classNames([
          'relative flex flex-col items-center justify-center bg-cover bg-no-repeat h-[506px] w-screen bg-bgCareersSmall',
          'lg:flex-row lg:items-end lg:justify-between lg:w-screen lg:h-[596px] lg:bg-bgCareers',
          'max-w-[1200px] mx-auto',
        ])}
      >
        <div className="lg:absolute lg:flex hidden inset-0 bg-gradient-to-b from-black/90 to-transparent h-[100px] z-0" />

        <div
          className={classNames([
            'relative flex flex-col gap-[16px] mx-[20px] mb-0 text-center',
            'lg:w-[867px] lg:mx-[64px] lg:text-start lg:gap-[26px] lg:mb-[56px]',
          ])}
        >
          <h2
            className={classNames([
              'text-[2.25rem] leading-[44px] font-light text-white',
              'lg:text-[4.5rem] lg:leading-[80px]',
            ])}
          >
            {translations.careers.titleBanner}
          </h2>

          <p
            className={classNames([
              'text-[1rem] leading-[21px] font-light text-white whitespace-pre-wrap',
              'lg:text-[1.0625rem] lg:w-[686px] lg:leading-[22px]',
            ])}
          >
            {translations.careers.descBanner}
          </p>
        </div>
      </div>
    </div>
  );
};
