import { ReactComponent as PlusIcon } from '../../../src/svg/plusBlack.svg';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { Header } from '../../layout/header/header';
import { FriendList } from './shareModal/ friendList';
import { FooterShare } from './shareModal/footerShare';
import { InviteForm } from './shareModal/inviteForm';
import { ModalHeader } from './shareModal/modalHeader';
import { SearchFriends } from './shareModal/searchFriends';
import { TicketInfo } from './shareModal/ticketInfo';
import classNames from 'classnames';
import { useState } from 'react';

interface TicketModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSendClick: () => void;
  sessionType: boolean;
}

interface Friend {
  id: number;
  name: string;
  image: string;
}

const friendsList: Friend[] = [
  { id: 1, name: 'John Doe', image: '/pictures/successImg.png' },
  { id: 2, name: 'Jane Smith', image: '/pictures/successImg.png' },
  { id: 3, name: 'Doe', image: '/pictures/successImg.png' },
  { id: 4, name: 'Doe', image: '/pictures/successImg.png' },
];

export const ShareTicketModal: React.FC<TicketModalProps> = ({
  isOpen,
  onClose,
  onSendClick,
  sessionType,
}) => {
  useDisableBodyScroll(isOpen);

  const [isSearching, setIsSearching] = useState(false);
  const [isInvaiting, setIsInvaiting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredFriends, setFilteredFriends] = useState<Friend[]>(friendsList);
  const [selectedFriends, setSelectedFriends] = useState<Set<number>>(
    new Set()
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setFilteredFriends(
      friendsList.filter((friend) =>
        friend.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleFriendSelect = (id: number) => {
    setSelectedFriends((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleShare = () => {
    console.log('Share');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black lg:bg-opacity-50 lg:backdrop-blur-[16px] z-50 overflow-y-auto">
      <div className="lg:hidden px-[20px]">
        <Header />
      </div>
      <ModalHeader
        onClose={onClose}
        sessionType={sessionType}
        setIsInvaiting={setIsInvaiting}
        isInvaiting={isInvaiting}
      />
      <h1
        className={classNames([
          'text-[1.0625rem] leading-[1.375rem text-center lg:hidden font-normal mb-[14px]',
          sessionType ? 'text-ticket' : 'text-generic',
        ])}
      >
        Shares Ticket(s)
      </h1>
      <div className="flex justify-center max-h-[90vh] xl:max-h-[90vh] lg:max-h-[90vh] 2xl:max-h-[86vh] 3xl:max-h-[80vh]  relative ">
        <div className="bg-black text-white rounded-lg lg:p-[40px] p-[20px] lg:w-[532px] w-full relative lg:border border-t border-gray-medium lg:rounded-tr-0 rounded-tl-[30px] rounded-tr-[30px] lg:rounded-br-[30px] flex flex-col gap-[40px]">
          <h1
            className={classNames([
              ' text-[2rem] leading-[2.5rem] text-center lg:flex hidden justify-center ',
              sessionType ? 'text-ticket' : 'text-generic',
            ])}
          >
            SHARE TICKETS
          </h1>
          <TicketInfo
            event={{
              image: '/pictures/successImg.png',
              title: 'Lauryn Hill and The Fugees',
              zone: 'Zone A, Table 13',
              ticketsAvailable: 7,
            }}
            sessionType={sessionType}
          />
          {isInvaiting ? (
            <InviteForm
              setIsInvaiting={setIsInvaiting}
              onSendClick={onSendClick}
              sessionType={sessionType}
            />
          ) : (
            <>
              <SearchFriends
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                onSearchFocus={() => setIsSearching(true)}
                onSearchBlur={() => setIsSearching(false)}
                isSearching={isSearching}
                setIsSearching={setIsSearching}
                sessionType={sessionType}
              />
              <FriendList
                filteredFriends={filteredFriends}
                selectedFriends={selectedFriends}
                onFriendSelect={handleFriendSelect}
                sessionType={sessionType}
              />
              <FooterShare
                filteredFriends={filteredFriends}
                selectedFriends={selectedFriends}
                isInvaiting={isInvaiting}
                sessionType={sessionType}
                setIsInvaiting={setIsInvaiting}
                onShare={handleShare}
              />
            </>
          )}
          {!isInvaiting && (
            <button
              onClick={() => setIsInvaiting((prev) => !prev)}
              className={classNames([
                'py-3 px-6 rounded-lg flex items-center justify-center gap-2 rounded-tl-[20px] rounded-br-[20px] border bg-transparent',
                sessionType
                  ? ' border-ticket text-ticket'
                  : ' border-generic text-generic',
              ])}
            >
              Invite Someone New
              <PlusIcon stroke={sessionType ? '#ec67f7' : '#b8cad9'} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
