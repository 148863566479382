import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import { StepsCard } from './stepsCard';
import classNames from 'classnames';
import React from 'react';

interface SubscriptionData {
  title: string;
  titleSmall: string;
  description: string;
}

interface SubscriptionJSON {
  nexSteps: {
    full: SubscriptionData[];
  };
}

const subscriptionDataTyped: SubscriptionJSON =
  translations as SubscriptionJSON;

export const StepsInfo: React.FC = () => {
  const { full } = subscriptionDataTyped.nexSteps;

  return (
    <>
      <p className="text-white lg:leading-[22px] lg:text-[1.0625rem] text-[1rem] leading-[21px]">
        {translations.stepsInfo.smallTitle}
      </p>
      <h2 className="text-event lg:leading-[72px] lg:text-[4rem] font-light mt-[24px] text-[2rem] leading-[40px] text-center">
        {translations.stepsInfo.title}
      </h2>
      <p className="text-white text-center lg:w-[712px] my-[24px] lg:leading-[22px] lg:text-[1.0625rem] text-[1rem] leading-[21px]">
        {translations.stepsInfo.desc}
      </p>
      <Button
        className="text-black bg-event w-[199px] h-[48px] font-medium"
        onClick={() => console.log('click')}
      >
        {translations.soundsLike.buttonText}
      </Button>
      <div
        className={classNames([
          'grid grid-cols-1 lg:grid-cols-3 lg:gap-8 gap-[16px] lg:mt-[50px] mt-[40px] container mx-auto',
        ])}
      >
        {full.map((card, index) => {
          const stepLabel = `STEP ${index + 1}`;

          return (
            <StepsCard
              key={index}
              title={card.title}
              titleSmall={card.titleSmall}
              description={card.description}
              stepLabel={stepLabel}
            />
          );
        })}
      </div>
    </>
  );
};
