import CarouselSection from '../../components/carouselSection/carouselSection';
import { Loader } from '../../components/loader/cene/loader';
import { Loader as LoaderCenePlus } from '../../components/loader/cene/loader';
import { WaitlistNotification } from '../../components/notification/waitlistNotification';
import { ROUTE_DOWNLOAD } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useGetLikedEvents } from '../../hooks/useGetUserLikedEvents';
import { useWaitlistNotification } from '../../hooks/useWaitlistNotification';
import { useSupabase } from '../../providers/supabase';
import { CarouselArtists } from '../../sections/home/carouselArtists';
import { LiveEventsSection } from '../../sections/home/liveEvents';
import { PersonalizedSection } from '../../sections/home/personalized';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import { SupabaseClient } from '@supabase/supabase-js';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const HomePage = () => {
  const { session } = useSupabase();
  const { loadEvents, eventsLiked } = useGetLikedEvents();

  const {
    showWaitlistNotification,
    handleHeartClick,
    notificationMessage,
    hideWaitlistNotification,
  } = useWaitlistNotification();

  const navigate = useNavigate();
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setLoading] = useState(false);

  const sessionType = session?.type === 'cene+';

  useEffect(() => {
    const supabaseClient =
      session?.type === 'cene+' ? supabaseCenePlusClient : supabaseCeneClient;

    fetchEvents(supabaseClient);
    loadEvents();
  }, [sessionType]);

  const fetchEvents = async (supabaseClient: SupabaseClient) => {
    setLoading(true);

    try {
      const { data, error } = await supabaseClient.from('events').select('*');

      if (error) {
        throw error;
      }

      setEvents(data || []);
    } catch (error) {
      console.error('Error fetching Cene events:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (id: string) => {
    navigate(`/event-details/${id}`);
  };

  return (
    <div className="flex flex-col  items-center justify-center w-full ">
      <div className="w-full mt-[56px]">
        {isLoading ? (
          <div className="flex justify-center items-center h-[558px]">
            {sessionType ? <LoaderCenePlus /> : <Loader />}
          </div>
        ) : (
          <CarouselArtists
            cards={events}
            handleHeartClick={handleHeartClick}
            eventsLiked={eventsLiked}
          />
        )}

        <div className="w-full h-[160px] mt-[40px] lg:mb-[56px]">
          <h1
            className={classNames([
              'text-white ',
              'w-full lg:text-[4.5rem] lg:w-[867px] lg:leading-[80px] text-[2.25rem] leading-[40px] lg:pt-[40px] pt-[24px] font-light',
            ])}
          >
            {translations.homePage.title}
          </h1>
        </div>
      </div>
      <PersonalizedSection />
      <LiveEventsSection />

      <div className="container px-0 mb-[120px]">
        <div
          className={classNames([
            'lg:w-full  flex flex-col lg:mb-[64px] mb-[40px] ',
            ' lg:flex-row lg:h-[128px] lg:items-center lg:justify-center',
          ])}
        >
          <h2
            className={classNames([
              'text-content h-[120px] text-[1.75rem] leading-[36px]',
              'lg:w-[1008px] lg:h-[128px] lg:text-[3.5rem] lg:leading-[64px] font-light',
            ])}
          >
            {translations.homePage.titleTwo}
          </h2>
          <Link
            className={classNames([
              'lg:w-[177px] lg:h-[48px] mt-[24px] lg:ml-[47px] lg:mt-[60px] bg-content flex justify-center items-center rounded-tl-[20px] rounded-br-[20px]',
              'w-[177px] h-[48px]',
            ])}
            to={ROUTE_DOWNLOAD}
          >
            {translations.homePage.textButton}
          </Link>
        </div>
        <div className={classNames('mt-[2.75rem]', 'max-lg:mt-[1.75rem]')}>
          {isLoading ? (
            <div className="flex justify-center items-center h-[558px]">
              {sessionType ? <LoaderCenePlus /> : <Loader />}
            </div>
          ) : (
            <CarouselSection
              eventsLiked={eventsLiked}
              data={events}
              sessionType={!sessionType}
              color={sessionType ? 'generic' : 'event'}
              handleCardClick={handleCardClick}
              colorCard={sessionType ? 'black' : 'event'}
            />
          )}
        </div>
      </div>
      <WaitlistNotification
        visible={showWaitlistNotification}
        message={notificationMessage}
        onClose={hideWaitlistNotification}
      />
    </div>
  );
};
