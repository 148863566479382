import classNames from 'classnames';
import { useEffect } from 'react';

interface ToastProps {
  message: string;
  onClose: () => void;
  bgColor?: string;
}

const Toast = ({ message, onClose, bgColor = 'bg-event' }: ToastProps) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div
      className={classNames(
        bgColor,
        'fixed top-[0.5rem] left-1/2 -translate-x-1/2 text-black px-[1.5rem] py-[1rem] rounded-tl-[20px] rounded-br-[20px] shadow-lg z-[1000]'
      )}
    >
      {message}
    </div>
  );
};

export default Toast;
