import { ReactComponent as LoupeSvg } from '../../../src/svg/MagnifyingGlass.svg';
import { ReactComponent as DownSVG } from '../../svg/arrow-down.svg';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';

interface IOption {
  value: string;
  label?: ReactNode;
  title?: string;
}

interface ISelectProps {
  options: IOption[];
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  type?: 'country' | 'phone' | 'text';
  error?: string | boolean | undefined;
  id?: string;
  onBlur?: () => void;
  touched?: boolean;
  isPlus?: boolean;
  inputSearch?: {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
  };
  andorment?: ReactNode;
  isCloseAfterSelection?: boolean;
}

export const InputSelect = ({
  options,
  value,
  onChange = () => null,
  placeholder = '',
  className,
  touched,
  onBlur = () => null,
  type = 'country',
  error,
  id,
  isPlus = false,
  inputSearch,
  andorment,
  isCloseAfterSelection = true,
}: ISelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpward, setIsOpenUpward] = useState<boolean | null>(null);

  const selectRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (selectedValue: string) => {
    onChange(selectedValue);

    if (isCloseAfterSelection) {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
      onBlur();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      setIsOpenUpward(rect.bottom + 272 > viewportHeight && rect.top > 272);
    }
  }, [isOpen]);

  return (
    <div className={classNames('relative w-full', {})} ref={selectRef}>
      <div
        id={id}
        className={classNames(
          'flex items-center justify-between cursor-pointer w-full h-[56px] p-[16px] pl-[24px] outline-none color-white bg-black rounded-tl-[20px] rounded-br-[20px] border border-gray-medium',
          value ? 'text-white ' : 'text-gray-light',
          {
            '!border-generic': isOpen && isPlus,
            '!border-content': isOpen && !isPlus,
          },
          className
        )}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {andorment} {value || placeholder}
        <DownSVG />
      </div>
      {isOpen && (
        <div
          className="p-2 absolute top-[110%] left-0 z-[1] bg-black border border-[#222] rounded-tl-[20px] rounded-br-[20px] w-[270px] max-h-[272px] overflow-auto custom-scrollbar"
          style={{
            top: isOpenUpward ? 'auto' : '110%',
            bottom: isOpenUpward ? '110%' : 'auto',
          }}
        >
          {inputSearch && (
            <div
              className={classNames([
                'mb-2 flex items-center text-gewneric rounded-tl-[16px] rounded-br-[16px] bg-black border border-gray-medium h-[48px] w-full',
              ])}
            >
              <LoupeSvg className="ml-[16px] mr-[10px]" />
              <input
                type="text"
                placeholder={inputSearch.placeholder}
                value={inputSearch.value}
                onChange={(e) => inputSearch.onChange(e.target.value)}
                className="flex-1 text-white outline-none bg-black"
              />
            </div>
          )}

          {options.map(({ value: optionValue, title, label }) => (
            <div
              key={value}
              className={classNames(
                'flex items-center gap-[8px] p-[16px] text-white cursor-pointer h-[40px]',
                'hover:bg-gray-dark',
                {
                  'bg-gray-medium': optionValue === value,
                }
              )}
              onClick={() => {
                handleOptionClick(optionValue);
              }}
            >
              {type === 'text' ? (
                optionValue
              ) : (
                <>
                  {label} {title}
                </>
              )}
            </div>
          ))}
        </div>
      )}
      {touched && error && (
        <div className="text-[0.875rem] text-red font-light mt-[8px]">
          {error}
        </div>
      )}
    </div>
  );
};
