import { ReactComponent as ArrowBackSvg } from '../../../src/svg/arrowEvent.svg';
import { ReactComponent as ArrowBackGenericSvg } from '../../../src/svg/arrowLeftGeneric.svg';
// import { ReactComponent as DownloadSvg } from '../../../src/svg/downloadEvent.svg';
// import { ReactComponent as DownloadGenericSvg } from '../../../src/svg/downloadGeneric.svg';
// import { ReactComponent as FacebookSvg } from '../../../src/svg/facebookEvent.svg';
// import { ReactComponent as FacebookGenericSvg } from '../../../src/svg/facebookGeneric.svg';
// import { ReactComponent as InstagramSvg } from '../../../src/svg/instagramEvent.svg';
// import { ReactComponent as InstagramGenericSvg } from '../../../src/svg/instagramGeneric.svg';
// import { ReactComponent as TikTokSvg } from '../../../src/svg/tikTokEvent.svg';
// import { ReactComponent as TikTokGenericSvg } from '../../../src/svg/tikTokGeneric.svg';
// import { ReactComponent as TwitterSvg } from '../../../src/svg/twitterEvent.svg';
// import { ReactComponent as TwitterGenericSvg } from '../../../src/svg/twitterGeneric.svg';
import translations from '../../constants/translations.json';
import { StepOne } from '../stepsPayment/stepOne';
import { StepThree } from '../stepsPayment/stepThree';
import { StepTwo } from '../stepsPayment/stepTwo';
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';

interface SuccessProps {
  session?: string | undefined;
  title?: string;
  place?: string;
  starts_at?: string;
  background?: string | null;
  selectedTickets: { title: string; quantity: number }[];
}

// const socialIcons = (sessionType: boolean) => [
//   {
//     Icon: sessionType ? TikTokSvg : TikTokGenericSvg,
//     url: 'https://www.tiktok.com/@getcene?_t=8qQQ1NNlHtN&_r=1',
//     title: translations.shareOptions.tiktok,
//   },
//   {
//     Icon: sessionType ? InstagramSvg : InstagramGenericSvg,
//     url: 'https://www.instagram.com/getcene?igsh=MWFrdzZ0eXZ3bng3Yg%3D%3D&utm_source=qr',
//     title: translations.shareOptions.instagram,
//   },
//   {
//     Icon: sessionType ? TwitterSvg : TwitterGenericSvg,
//     url: 'https://x.com/getcene?s=21',
//     title: translations.shareOptions.twitter,
//   },
//   {
//     Icon: sessionType ? FacebookSvg : FacebookGenericSvg,
//     url: 'https://www.facebook.com',
//     title: translations.shareOptions.facebook,
//   },
//   {
//     Icon: sessionType ? DownloadSvg : DownloadGenericSvg,
//     onClick: (session: string | undefined) => {
//       const link = document.createElement('a');
//       link.href =
//         session === 'cene'
//           ? '/pictures/successImg.png'
//           : '/pictures/successPlusImg.png';
//       link.download = 'banner.png';
//       link.click();
//     },
//     title: translations.shareOptions.download,
//   },
// ];

export const PaymentSuccessMessage: React.FC<SuccessProps> = ({
  session,
  background,
  place,
  starts_at,
  title,
  selectedTickets,
}) => {
  const [step, setStep] = useState(1);
  const sessionType = session === 'cene';

  const currentDate = moment().format('ddd, MMM D YYYY - h.mmA');

  const nextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="text-center justify-center bg-black pb-[16px] lg:w-[422px] w-full ">
      <div className="flex items-center justify-between w-full mt-[16px]">
        {step > 1 && (
          <button
            onClick={prevStep}
            className={classNames([
              'flex items-center justify-center w-[40px] h-[40px] bg-gray-dark',
              sessionType ? 'rounded-tl-[8px] rounded-br-[8px]' : 'rounded-big',
            ])}
          >
            {sessionType ? <ArrowBackSvg /> : <ArrowBackGenericSvg />}
          </button>
        )}
        <div className="flex-grow text-center ">
          <p className="text-white text-[1.0625rem] leading-[22px] font-normal">
            {translations.paymentSuccess.title}
          </p>
        </div>
      </div>
      <div className=" w-full flex justify-center items-center lg:mb-[24px] mb-[32px]">
        <div className="flex space-x-[4px]">
          {[1, 2, 3].map((index) => (
            <div
              key={index}
              className={`h-[2px] w-[98px] lg:w-[127px] rounded-lg ${step >= index ? (sessionType ? 'bg-event' : 'bg-generic') : 'bg-gray-medium'}`}
            />
          ))}
        </div>
        <div
          className={classNames([
            'text-lg ml-[16px] font-medium',
            sessionType ? 'text-event' : 'text-generic',
          ])}
        >
          {step}/3
        </div>
      </div>

      {step === 1 && (
        <StepOne
          nextStep={nextStep}
          session={session}
          firstImage={background as string}
          place={place}
          startsAt={starts_at}
          title={title}
        />
      )}
      {step === 2 && (
        <StepTwo
          nextStep={nextStep}
          session={session}
          place={place}
          selectedTickets={selectedTickets}
          currentDate={currentDate}
          startsAt={starts_at}
          title={title}
        />
      )}
      {step === 3 && (
        <StepThree
          // socialIcons={socialIcons(sessionType).map((icon) => ({
          //   ...icon,
          //   onClick: icon.onClick ? () => icon.onClick(session) : undefined,
          // }))}
          session={session}
        />
      )}
    </div>
  );
};
