import { ReactComponent as DividerSvg } from '../../../src/svg/divider.svg';
import { Button } from '../../components/button/button';
import { TermsCheckbox } from '../../components/termsCheckbox/termsCheckbox';
import translations from '../../constants/translations.json';
import { formatPriceString } from '../../utils/pricing';
import { FeeItem } from './feeItem';
import { OrderDetails } from '@ceneteam/namespace';
import { useState } from 'react';

interface ICartSummary {
  orderSummary: OrderDetails;
  onSendPayment: () => void;
  isLoading: boolean;
}

export const CartSummary = ({ isLoading, orderSummary, onSendPayment }: ICartSummary) => {
  const [terms, setTerms] = useState(false);

  const fees = [
    {
      label: translations.ticketSummary.service,
      amount: orderSummary?.service_fee.value,
    },
    {
      label: translations.ticketSummary.order,
      amount: orderSummary?.processing_fee.value,
    },
    {
      label: translations.ticketSummary.tax,
      amount: orderSummary?.tax.value,
    },
  ];

  return (
    <div>
      <p className="text-white text-[21px] leading-[20px] font-normal mb-[16px]">
        Fees
      </p>
      {fees.map((fee, index) => (
        <FeeItem key={index} label={fee.label} amount={`${fee.amount}`} />
      ))}
      <DividerSvg className="my-[15px] w-full" />
      <div className="text-[32px] text-white font-light">
        {translations.ticketSummary.summary}
      </div>
      <div className="flex justify-between mt-[14.5px]">
        <p className="text-gray-light text-[0.875rem] leading-[14px]">
          {translations.ticketSummary.amount}
        </p>
        <p className="text-white lg:text-[1.3125rem] lg:leading-[21px] text-[1.1875rem] leading-[19px]">{`₦ ${formatPriceString(orderSummary?.total_net_amount)}`}</p>
      </div>
      <div className="flex items-center justify-between lg:mt-[12px] mt-[16px]">
        <p className="text-white text-[1rem] leading-[24px] font-medium">
          {translations.ticketSummary.total}
        </p>
        <p className="text-white text-[2rem] leading-[40px]">{`₦ ${formatPriceString(orderSummary.total_gross_amount)}`}</p>
      </div>
      <TermsCheckbox
        isChecked={terms}
        onChange={() => setTerms((prev) => !prev)}
        label={translations.ticketSummary.terms}
      />
      <Button
        type="button"
        disabled={!terms || isLoading}
        className="mt-[30px] bg-generic min-w-[172px] w-full flex justify-center items-center lg:mb-[40px] mb-[16px]"
        color=""
        onClick={onSendPayment}
      >
        Pay Now
      </Button>
    </div>
  );
};
