import { ReactComponent as FilledHeartSvg } from '../../../src/svg/filledHeart.svg';
import { ReactComponent as HeartSvg } from '../../../src/svg/heart.svg';
import { ReactComponent as LockSvg } from '../../../src/svg/lock.svg';
import { ReactComponent as ShareSvg } from '../../../src/svg/share.svg';
import { ROUTE_EVENTS_DETAILS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useCardImgContainer } from '../../hooks/useCardImgContainer';
import { useClickOutside } from '../../hooks/useClickOutside';
import { ShareOptions } from '../shareOptions/shareOptions';
import { LineUpItem, Ticket } from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Image {
  url: string;
}

interface CardProps {
  title: string;
  event_id: string;
  description: string;
  status: string;
  state: string;
  place: string;
  country: string;
  starts_at: string;
  background: Image[];
  line_up?: LineUpItem[];
  quantity?: number;
  trending?: boolean;
  isWaitlisted?: boolean;
  liked?: boolean;
  textStatusEvent?: string;
  waitlist?: boolean;
  tickets: Ticket[] | null;
  attended?: boolean;
  setIsPaused?: (paused: boolean) => void;
  handleHeartClick: (title: string) => void;
  setShowLogInModal?: () => void | undefined;
}

export const CardPlus: React.FC<CardProps> = ({
  title,
  place,
  country,
  background,
  status,
  starts_at,
  quantity,
  setIsPaused,
  handleHeartClick,
  event_id,
  attended,
  textStatusEvent,
  trending,
  liked,
}) => {
  const cardImgContainer = useCardImgContainer(trending || false);
  const navigate = useNavigate();
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isHeartFilled, setIsHeartFilled] = useState(liked || false);

  const shareOptionsRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(shareOptionsRef, () => handleCloseShareOptions());

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const newShowShareOptions = !showShareOptions;
    setShowShareOptions(newShowShareOptions);
    setIsPaused?.(newShowShareOptions);
  };

  const handleCloseShareOptions = (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setShowShareOptions(false);
    setIsPaused?.(false);
  };
  
  const handleCopyLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    const textToCopy = `https://cene.xyz/event-details/${event_id}`;
    navigator.clipboard
      .writeText(textToCopy)
      .catch((err) => console.error('Failed to copy link:', err));
  };

  const handleCardClick = () => {
    if (!showShareOptions) {
      navigate(ROUTE_EVENTS_DETAILS.replace(':id', event_id), {
        state: { status, quantity },
      });
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return text?.length > maxLength
      ? `${text?.substring(0, maxLength)}...`
      : text;
  };

  const getDaySuffix = (day: number) => {
    if (day >= 11 && day <= 13) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const firstImage = background?.[0]?.url || '/pictures/person-three.png';

  const formatTime = (dateString: string) => {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-En', { month: 'long' });

    const suffix = getDaySuffix(day);
    return `${day}${suffix} ${month}`;
  };

  const toggleHeart = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsHeartFilled(!isHeartFilled);
    if (!isHeartFilled) {
      handleHeartClick(title);
    }
  };

  const buttonClasses: Record<string, string> = {
    CONCERT: 'text-event',
    'EVENT CLOSED': 'bg-gray-medium text-white px-[15px] py-[16px]',
    'COMING SOON': 'bg-content text-white px-[15px] py-[16px]',
  };

  const buttonClass =
    buttonClasses[textStatusEvent || ''] || 'bg-transparent text-event';
  const icon =
    textStatusEvent === translations.organizer.close ? (
      <LockSvg className="w-[12px] h-[12px] mr-[8px]" />
    ) : null;

  const cardHeightClass = trending
    ? 'h-[458px]'
    : '2xl:h-[458px] h-[335px] lg:h-[458px]';

  const cardTextClass = trending
    ? 'text-[2rem] leading-[40px]'
    : 'lg:text-[2rem] lg:leading-[40px] text-[1.3125rem] leading-[21px]';
  const cardTextCityClass = trending
    ? 'text-[1.0625rem] leading-[22px]'
    : 'lg:text-[1.0625rem] lg:leading-[22px] text-[0.875rem] leading-[14px]';

  return (
    <div
      className={`relative ${cardHeightClass}  transition-opacity duration-300 hover:opacity-70  cursor-pointer flex-shrink-0`}
      onClick={handleCardClick}
    >
      <div className={`${cardImgContainer} relative`}>
        {textStatusEvent && (
          <div
            className={classNames(
              'absolute top-0 left-1/2 transform -translate-x-1/2 text-[0.75rem] leading-[12px] rounded-big flex items-center justify-center w-full lg:text-[0.875rem] lg:leading-[14px]',
              buttonClass
            )}
          >
            {icon}
            {textStatusEvent}
          </div>
        )}
        <>
          <img
            src={firstImage}
            alt={title}
            className="object-cover w-full h-full rounded-tl-[30px] rounded-br-[30px]"
          />
          <div className="absolute inset-0 bg-black bg-opacity-25"></div>
        </>
        <div className="absolute bottom-[16px] left-1/2 transform -translate-x-1/2 flex items-center space-x-4">
          <button
            onClick={handleShareClick}
            className="transition-transform duration-200 ease-in-out hover:scale-110 rounded-big bg-generic-bg w-[48px] h-[48px] flex items-center justify-center"
          >
            <ShareSvg />
          </button>
          {isHeartFilled ? (
            <button
              className="rounded-big bg-generic-bg w-[48px] h-[48px] flex items-center justify-center transition-transform duration-200 ease-in-out hover:scale-110 "
              onClick={toggleHeart}
            >
              <FilledHeartSvg style={{ fill: '#91DBBF' }} />
            </button>
          ) : (
            <button
              className="rounded-big bg-generic-bg w-[48px] h-[48px] flex items-center justify-center transition-transform duration-200 ease-in-out hover:scale-110"
              onClick={toggleHeart}
            >
              <HeartSvg />
            </button>
          )}
        </div>
      </div>
      <div className="absolute top-[16px] left-[43%] transform -translate-x-1/2 text-center">
        <p className="text-black text-[0.625rem] leading-[10px]">
          {translations.homePage.concertName}
        </p>
      </div>

      <div
        className="absolute bottom-0 flex flex-col items-start text-start mt-4 overflow-hidden"
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          lineClamp: 3,
          textOverflow: 'ellipsis',
        }}
      >
        <p
          className={`${cardTextClass} font-normal text-generic whitespace-nowrap`}
        >
          {truncateText(title, 20)}
        </p>
        <p
          className={classNames(
            `${cardTextCityClass} font-normal mt-4 whitespace-pre-line`,
            {
              'text-generic': !attended,
              'text-gray-light': attended,
            }
          )}
        >
          {place}, {country}
        </p>
        <p
          className={classNames(
            `${cardTextCityClass} font-normal mt-[9px]`,
            'lg:mt-[9px]',
            {
              'text-generic': !attended,
              'text-gray-light': attended,
            }
          )}
        >
          {formatDate(starts_at)} at {formatTime(starts_at)}
        </p>
      </div>
      {showShareOptions && (
        <div
          ref={shareOptionsRef}
          className="absolute top-0 right-0 z-10"
          onClick={(event) => event.stopPropagation()}
        >
          <ShareOptions
            onCopyLink={handleCopyLink}
            onClose={(e) => handleCloseShareOptions(e)}
          />
        </div>
      )}
    </div>
  );
};
