import { useSupabase } from '../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../supabase/supabase';
import { useCallback } from 'react';

interface UseEventActionsProps {
  userId: string | null;
}

export const useEventActions = ({ userId }: UseEventActionsProps) => {
  const { session } = useSupabase();

  const sessionType = session?.type === 'cene';
  const client = sessionType ? supabaseCeneClient : supabaseCenePlusClient;
  const setLike = useCallback(
    async (event_id: string, isLiked: boolean) => {
      if (!userId) {
        console.error('User ID is not available.');
        return;
      }
      try {
        if (isLiked) {
          const { error } = await client
            .from('liked_events')
            .delete()
            .match({ event_id, customer_id: userId });

          if (error) throw error;
        } else {
          const { error } = await client
            .from('liked_events')
            .insert({ event_id, customer_id: userId });

          if (error) throw error;
        }
      } catch (err) {
        console.error('Error in setLike:', err);
      }
    },
    [userId]
  );

  const setWaitlist = useCallback(
    async (event_id: string, isWaitlisted: boolean) => {
      if (!userId) {
        console.error('User ID is not available.');
        return;
      }

      try {
        if (isWaitlisted) {
          const { error } = await client
            .from('waitlist')
            .delete()
            .match({ event_id, customer_id: userId });

          if (error) throw error;
        } else {
          const { error } = await client
            .from('waitlist')
            .upsert({ event_id, customer_id: userId });

          if (error) throw error;
        }
      } catch (err) {
        console.error('Error in setWaitlist:', err);
      }
    },
    [userId]
  );

  return { setLike, setWaitlist };
};
