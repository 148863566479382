import { Loader } from '../../../components/loader/cene/loader';
import { ROUTE_AUTH } from '../../../constants/routes';
import { ReactComponent as CloseSVG } from '../../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../../svg/logo.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export const LoadingPage = () => {
  const navigate = useNavigate();
  const params = useLocation();

  const [progress, setProgress] = useState(params.state?.skipLoading ? 100 : 0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 3;
        if (nextProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return nextProgress;
      });
    }, 75);

    return () => clearInterval(interval);
  }, [progress]);

  return progress === 100 ? (
    <Navigate to="/" />
  ) : (
    <div
      className={classNames([
        'bg-loading-bg relative bg-no-repeat bg-cover',
        'max-sm:bg-loading-bg-mobile ',
      ])}
    >
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between mb-[40px] px-[20px]',
          'max-lg:h-[72px] max-lg:px-[20px] max-lg:border-b border-[#ffffff28] max-lg:mb-0',
        ])}
      >
        <LogoSVG
          className={classNames([
            'w-[164px] h-[48px]',
            'max-lg:w-[137px] max-lg:h-[40px]',
          ])}
        />
        <div
          onClick={() => navigate(ROUTE_AUTH)}
          className={classNames([
            'cursor-pointer border border-white w-[48px] h-[48px] flex items-center justify-center rounded-big',
            'max-lg:w-[40px] max-lg:h-[40px] max-lg:border-generic',
          ])}
        >
          <CloseSVG stroke="#fff" />
        </div>
      </div>

      <div
        className={classNames([
          'h-[calc(100vh-87px)] w-full flex flex-col items-center justify-center',
          'max-lg:h-[calc(100vh-72px)]',
        ])}
      >
        <div className="absolute text-center left-[50%] translate-x-[-50%]">
          <h2 className="text-h1 text-white font-light">
            We are loading your experience
          </h2>
          <div className="mt-[48px] flex flex-col items-center">
            <Loader />
          </div>
          <p className="text-h2 font-light text-generic mt-[48px]">
            {progress}%
          </p>
        </div>
      </div>
    </div>
  );
};
