import { useState } from 'react';

export const useWaitlistNotification = () => {
  const [showWaitlistNotification, setShowWaitlistNotification] =
    useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const handleHeartClick = (title: string) => {
      const message = `Yay! You’ve added ${title} to your favourite events`;
      setNotificationMessage(message);
      setShowWaitlistNotification(true);
      setTimeout(() => setShowWaitlistNotification(false), 5000);
    };

  const handleFollowClick = () => {
    setShowWaitlistNotification(true);
    setTimeout(() => setShowWaitlistNotification(false), 15000);
  };

  return {
    showWaitlistNotification,
    handleHeartClick,
    notificationMessage,
    setShowWaitlistNotification,
    handleFollowClick,
    hideWaitlistNotification: () => setShowWaitlistNotification(false),
  };
};
