import CarouselSection from '../../../components/carouselSection/carouselSection';
import { Loader } from '../../../components/loader/cenePlus/loader';
import translations from '../../../constants/translations.json';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface Props {
  events: Event[];
  sessionType: boolean;
  eventsLiked:Event[]
  isLoading: boolean;
}

const CuratedForYouSection = ({ events, sessionType, isLoading,eventsLiked }: Props) => {
  const navigate = useNavigate();

  const handleCardClick = (id: string) => {
    navigate(`/event-details/${id}`);
  };

  return (
    <div className={classNames('mt-[2.75rem]', 'max-lg:mt-[1.75rem]')}>
      {isLoading ? (
        <div className="flex justify-center items-center h-[558px]">
          <Loader />
        </div>
      ) : (
        <CarouselSection
          data={events}
          eventsLiked={eventsLiked}
          title={translations.events.eventsCenePlus.curatedForYou}
          sessionType={sessionType}
          color={sessionType ? 'event' : 'generic'}
          handleCardClick={handleCardClick}
          colorCard={sessionType ? 'event' : 'black'}
        />
      )}
    </div>
  );
};
export default CuratedForYouSection;
