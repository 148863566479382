import { Button } from '../../../components/button/button';
import { Input } from '../../../components/input/cenePlus/input';
import { Label } from '../../../components/label/cenePlus/label';
import translation from '../../../constants/translations.json';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

interface FormProps {
  onNext: () => void;
}

interface FormValues {
  jobTitle: string;
  company: string;
  firstName: string;
}

export const JobForm = ({ onNext = () => null }: FormProps) => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<FormValues>();

  const isValidJobTitle = values.jobTitle && !errors.jobTitle;
  const isValidCompany = values.company && !errors.company;

  return (
    <div className={classNames(['pt-[24px] pb-[20px] px-5', 'sm:px-0'])}>
      <h1 className="text-[2rem] text-generic font-light mb-[12px]">
        {translation.steps.jobTitleStep.title} {values.firstName}
      </h1>
      <p className="text-[0.875rem] text-generic font-light">
        {translation.steps.jobTitleStep.description}
      </p>

      <div className="flex flex-col">
        <div className="mt-[40px] mb-[40px]">
          <Label id="jobTitle" isRequired={true}>
            {translation.steps.jobTitleStep.jobTitle}
          </Label>
          <Input
            id="jobTitle"
            placeholder={translation.steps.jobTitleStep.jobTitlePlaceholder}
            value={values.jobTitle}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.jobTitle}
            touched={Boolean(touched.jobTitle)}
          />
        </div>

        <div className="mb-[40px]">
          <Label id="company" isRequired={true}>
            {translation.steps.jobTitleStep.company}
          </Label>
          <Input
            id="company"
            placeholder={translation.steps.jobTitleStep.companyPlaceholder}
            value={values.company}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.company}
            touched={Boolean(touched.company)}
          />
        </div>

        <Button
          color="generic"
          type="button"
          onClick={onNext}
          disabled={!(isValidJobTitle && isValidCompany)}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};
