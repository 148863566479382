import { useSupabase } from '../providers/supabase';
import { useEffect, useState } from 'react';

export const useCardImgContainer = (trending: boolean) => {
  const { session } = useSupabase();
  const [cardImgContainer, setCardImgContainer] = useState('');
  const sessionType = session?.type === 'cene' || session?.type === undefined;

  useEffect(() => {
    const updateCardImgContainer = () => {
      const screenWidth = window.innerWidth;
      const baseClasses = 'rounded-tl-[30px] rounded-br-[30px]';

      const breakpoints = [
        {
          width: 375,
          trending: sessionType ? 'w-[310px] h-[438px]' : 'w-[355px] h-[355px]',
          nonTrending: 'w-[155px] h-[200px]',
        },
        {
          width: 390,
          trending: sessionType ? 'w-[310px] h-[438px]' : 'w-[355px] h-[355px]',
          nonTrending: 'w-[163px] h-[210px]',
        },
        {
          width: 393,
          trending: sessionType ? 'w-[310px] h-[438px]' : 'w-[355px] h-[355px]',
          nonTrending: 'w-[164px] h-[210px]',
        },
        {
          width: 414,
          trending: sessionType ? 'w-[310px] h-[438px]' : 'w-[355px] h-[355px]',
          nonTrending: 'w-[174px] h-[226px]',
        },
        {
          width: 428,
          trending: sessionType ? 'w-[310px] h-[438px]' : 'w-[355px] h-[355px]',
          nonTrending: 'w-[182px] h-[226px]',
        },
        {
          width: 430,
          trending: sessionType ? 'w-[310px] h-[438px]' : 'w-[355px] h-[355px]',
          nonTrending: 'w-[183px] h-[226px]',
        },
        {
          width: Infinity,
          trending: sessionType?'w-[310px] h-[438px]':"w-[335px] h-[335px]",
          nonTrending: sessionType
            ? 'w-[170px] h-[226px] lg:w-[350px] lg:h-[438px] xl:w-[310px] xl:h-[438px] 2xl:w-[334px] 2xl:h-[438px] 3xl:w-[350px] 3xl:h-[438px]'
            : 'w-[170px] h-[226px] lg:w-[335px] lg:h-[335px] xl:w-[310px] xl:h-[335px] 2xl:w-[334px] 2xl:h-[335px] 3xl:w-[350px] 3xl:h-[335px]',
        },
      ];

      const selectedBreakpoint = breakpoints.find(
        (b) => screenWidth <= b.width
      );
      setCardImgContainer(
        trending
          ? `${baseClasses} ${selectedBreakpoint?.trending}`
          : `${baseClasses} ${selectedBreakpoint?.nonTrending}`
      );
    };

    updateCardImgContainer();
    window.addEventListener('resize', updateCardImgContainer);

    return () => {
      window.removeEventListener('resize', updateCardImgContainer);
    };
  }, [trending]);

  return cardImgContainer;
};
