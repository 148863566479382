import { ReactComponent as CheckLogo } from '../../../src/svg/checkPink.svg';
import { Button } from '../../components/button/button';
import { ROUTER_MY_TICKETS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean;
  session?: string | undefined;
  onClose: () => void;
  sessionType: boolean;
}

export const InviteUserModal = ({
  isOpen,
  onClose,
  sessionType,
}: ModalProps) => {
  const navigate = useNavigate();

  useDisableBodyScroll(isOpen);

  useEffect(() => {
    if (!isOpen) return;
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const buttonClick = () => {
    navigate(ROUTER_MY_TICKETS);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-[16px] flex justify-center items-center z-[100]"
      onClick={handleOverlayClick}
    >
      <div
        className="lg:w-[640px] w-full relative max-lg:p-[1.25rem] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-center text-center max-lg:gap-[1rem]">
          <CheckLogo stroke={sessionType ? '#EC67F7' : '#b8cad9'} />
          <h1
            className={classNames([
              'text-[3rem]/[3.5rem] font-light lg:mt-[1.5rem]',
              'max-lg:text-[3rem]/[3.5rem]',
              sessionType ? 'text-white' : 'text-generic ',
            ])}
          >
            name has been invited to your Cene
          </h1>

          <p
            className={classNames(
              'text-[1.0625rem]/[1.375rem]  font-light flex flex-col gap-[0.75rem] lg:mt-[2.5rem]',
              'max-lg:text-[1.0625rem]/[1.375rem] ',
              sessionType ? 'text-gray-light' : 'text-generic'
            )}
          >
            {translations.inviteUser.desc}
          </p>
          <Button
            color={sessionType ? 'ticket' : 'generic'}
            className={classNames('w-[336px] lg:mt-[4rem] max-lg:w-full')}
            onClick={buttonClick}
          >
            {translations.inviteUser.buttonText}
          </Button>
        </div>

        <div className="h-full lg:h-[calc(100%-96px)] overflow-y-auto lg:px-8 px-[20px]"></div>
      </div>
    </div>
  );
};
