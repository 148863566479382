import CarouselSection from '../../components/carouselSection/carouselSection';
import { Loader } from '../../components/loader/cene/loader';
import translations from '../../constants/translations.json';
import { NoWaitlistMessage } from '../events/cene/messages/noWaitlistMessage';
import { Event, LineUpItem, Ticket } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Image {
  url: string;
}

interface WaitlistSectionProps {
  events: Event[];
  session: string | undefined;
  handleHeartClick: (title: string) => void;
  isLoading: boolean;
}

interface CarouselCard {
  event_id: string;
  title: string;
  tickets: Ticket[] | null;
  place: string;
  starts_at: string;
  state: string;
  country: string;
  status: string;
  line_up: LineUpItem[];
  description: string;
  saved: boolean;
  background: Image[];
  type?: 'trending';
}

const TRENDING_TYPE = 'trending' as const;

export const WaitlistSection: React.FC<WaitlistSectionProps> = ({
  // handleHeartClick,
  events,
  isLoading,
  session,
}) => {
  const navigate = useNavigate();
  const [mappedEvents, setMappedEvents] = useState<CarouselCard[]>([]);
  const sessionType = session == 'cene';
  const sessionText = sessionType ? 'text-white' : 'text-generic';
  // const attendedCards = cardData.filter((card) => card.waitlist === true);
  // const cardCount = attendedCards.length;

  useEffect(() => {
    if (events && events.length > 0) {
      const mapped = events.map((event) => ({
        event_id: event.id,
        title: event.name,
        place: event.address,
        state: event.state,
        country: event.country,
        starts_at: event.starts_at ?? '',
        saved: true,
        status: event.status,
        description: event.description,
        line_up: event.line_up ?? [],
        background: event.images,
        type: TRENDING_TYPE,
        tickets: event.tickets,
      }));
      setMappedEvents(mapped);
    } else {
      setMappedEvents([]);
    }
  }, [events]);

  const handleCardClick = (id: string, isWaitlist: boolean) => {
    navigate(`/event-details/${id}`, { state: { isWaitlist } });
  };

  return (
    <div
      className={classNames([
        'flex flex-col justify-center w-full items-center',
        'lg:flex-row my-[40px] lg:my-10',
      ])}
    >
      <div className="w-full">
        {mappedEvents.length === 0 && (
          <div className="mb-[40px] flex flex-row items-start justify-between">
            <div className="flex flex-row items-start">
              <p
                className={classNames(
                  'text-[2rem] leading-[40px] capitalize font-light',
                  sessionText
                )}
              >
                {translations.favourite.waitlist}
              </p>
              <p className="text-[0.9375rem] leading-[20px] text-event-light ml-[8px] self-start">
                {mappedEvents.length}
              </p>
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="flex justify-center items-center h-[558px]">
            <Loader />
          </div>
        ) : mappedEvents.length === 0 ? (
          <NoWaitlistMessage session={session} />
        ) : (
          <CarouselSection
            isWaitlist={true}
            data={events}
            title={translations.favourite.waitlist}
            sessionType={sessionType}
            color={sessionType ? 'event' : 'generic'}
            handleCardClick={handleCardClick}
            colorCard={sessionType ? 'event' : 'black'}
          />
        )}
      </div>
    </div>
  );
};
