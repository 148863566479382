import { useState } from 'react';
import { Event } from '@ceneteam/namespace';

import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../supabase/supabase';

import { useSupabase } from '../providers/supabase';

export const useGetLikedEvents = () => {
  const { session } = useSupabase();
  const [eventsLiked, setEventsLiked] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const sessionType = session?.type === 'cene';

  const fetchEvents = async () => {
    // Don't fetch if there's no user ID
    if (!session?.user?.id) {
      return;
    } 

    setLoading(true);
    setError(null);

    try {
      const client = sessionType
        ? supabaseCeneClient
        : supabaseCenePlusClient;

        const { data: userLikedEvents, error: likedEventsError } = await client
          .from('liked_events')
          .select('event_id')
          .eq('customer_id', session.user.id);

      if (likedEventsError) {
        throw likedEventsError;
      }

      if (userLikedEvents.length) {
        const { data } = await client
          .from('events')
          .select()
          .in(
            'id',
            userLikedEvents.map((event) => event.event_id),
          );

        return setEventsLiked(data!);
      }

      setEventsLiked([]);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An unknown error occurred'));
      // Set events to empty array if there's an error
      setEventsLiked([]);
    } finally {
      setLoading(false);

      if (initialLoad) {
        setInitialLoad(false);
      } 
    }
  };

  return { eventsLiked, loading: initialLoad || loading, error, loadEvents: fetchEvents };
};
