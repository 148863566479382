import { ROUTE_ORGANIZER } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { Ticket } from '../../sections/ticketDetails/aboutEvent';
import { ReactComponent as RightArrowSVG } from '../../svg/caretRight.svg';
import { Image } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export type Event = {
  id: string;
  name: string;
  description: string;
  floorplan_id?: string;
  featured_image: Image;
  artists?: string[];
  location: string;
  starts_at: string | null;
  ends_at: string | null;
  category: 'Concert';
  tickets: Ticket[] | null;
  country: string;
  state: string;
  address: string;
  tags?: string[];
  stock: Record<string, number> | null;
  partner_info: {
    id: string;
    name: string;
    description: string | null;
    social_handles: {
      tiktok: string | null;
      twitter: string | null;
      website: string | null;
      facebook: string | null;
      instagram: string | null;
    } | null;
    profile_picture_url: string | null;
  };
};

interface Props {
  sessionType?: boolean;
  event: Event | null;
}

export const OrganizerSection = ({ sessionType, event }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(ROUTE_ORGANIZER, {
      state: { partnerInfo: event?.partner_info || {} },
    });
  };

  return (
    <div className="w-full mt-0 cursor-pointer" onClick={handleNavigate}>
      <div className="flex justify-between items-center">
        <p
          className={classNames(
            'text-[1.0625rem] leading-[22px] uppercase',
            sessionType ? 'text-ticket' : 'text-white'
          )}
        >
          {translations.ticketDetails.organizer}
        </p>
        <button className="text-event w-[32px] h-[32px] bg-gray-dark rounded-tl-[8px] rounded-br-[8px] flex justify-center items-center">
          <RightArrowSVG
            className={classNames(
              'w-[12px] h-[12px]',
              sessionType ? 'stroke-ticket' : 'stroke-white'
            )}
          />
        </button>
      </div>
      <div className="mt-[16px] flex flex-row items-center justify-between w-full">
        <div className="flex items-center z-50">
          {event?.partner_info?.profile_picture_url ? (
            <img
              src={event?.partner_info?.profile_picture_url}
              alt="Organizer Logo"
              className="w-[56px] h-[56px]"
            />
          ) : (
            <div
              className="w-[56px] h-[56px] rounded-big bg-gray-medium transition-all"
              aria-label="No Organizer Image"
            />
          )}
          <p className="ml-[16px] text-white text-[1rem] leading-[22px] font-medium">
            {event?.partner_info?.name ||
              translations.eventDetailsSection.nameOrganizer}
          </p>
        </div>
      </div>
    </div>
  );
};
