import { ReactComponent as TicketGenericSvg } from '../../../src/svg/ticketGenericPlus.svg';
import { ReactComponent as TicketSvg } from '../../../src/svg/ticketModal.svg';
import { Ticket as OriginalTicket } from '@ceneteam/namespace';
// import translations from '../../constants/translations.json';
import classNames from 'classnames';
import React from 'react';

// interface Ticket {
//   name: string;
//   // price: number;
// }
interface Ticket extends OriginalTicket {
  title: OriginalTicket['name'];
}

interface BulkProps {
  ticketTypes: Ticket[] | null;
  handleQuantityChange: (ticketId: string, value: number) => void;
  session: string | undefined;
  quantity: { [key: string]: number };
}

export const Bulk: React.FC<BulkProps> = ({
  ticketTypes,
  handleQuantityChange,
  session,
  quantity,
}) => {
  const sessionType = session === 'cene';

  const totalSelected = Object.values(quantity).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const formatPriceString = (price: number) => {
    return new Intl.NumberFormat('en-US').format(price);
  };

  const handleInputChange = (
    ticketId: string,
    value: string,
    maxQuantity: number
  ) => {
    const newValue = Math.min(
      Math.max(Number(value) || 0, 0),
      Math.min(maxQuantity, 100 - Number(totalSelected) + quantity[ticketId])
    );

    handleQuantityChange(ticketId, newValue);
  };

  console.log(handleInputChange, totalSelected);

  return (
    <div
      className={classNames('flex flex-col space-y-[24px] flex-grow', {
        'bg-generic-06 border border-gray-medium rounded-tl-[30px] rounded-br-[30px] p-[20px] lg:bg-black lg:border-none lg:rounded-none lg:p-0':
          !sessionType,
      })}
    >
      <div
        className={classNames(
          'w-fit border border-generic-light flex justify-center items-center px-[1rem] py-[0.5rem] rounded-tl-[1rem] rounded-br-[1rem]'
        )}
      >
        <span
          className={classNames(
            'text-generic text-[1rem]/[1.3125rem] font-light'
          )}
        >
          Selected {totalSelected}/100
        </span>
      </div>
      <div className="flex items-start">
        {sessionType ? (
          <TicketSvg className="mr-[29px] lg:flex hidden" />
        ) : (
          <TicketGenericSvg className="mr-[29px] lg:flex hidden" />
        )}

        <div className="flex flex-col space-y-[32px] flex-grow">
          {ticketTypes?.map((ticket) => (
            <div
              key={ticket.name}
              className="flex items-start justify-between mt-[17px] lg:mt-0"
            >
              <div className="flex items-center w-full">
                <div className="flex flex-col flex-grow">
                  <div className="flex items-center mb-[5px]">
                    <p className="text-generic text-[0.875rem] leading-[14px] font-light">
                      {ticket.name}
                    </p>
                  </div>
                  <p
                    className={classNames([
                      'font-light',
                      sessionType
                        ? 'text-white text-[2rem] leading-[40px]'
                        : 'text-generic leading-[25px] text-[1.5625rem]',
                    ])}
                  >
                  {`₦ ${formatPriceString(ticket.NGN || 0)}`}
                  </p>
                </div>
                <input
                  type="number"
                  min="0"
                  max={Number(ticket.quantity || 100)}
                  className={classNames(
                    'border border-gray-medium rounded-tr-[20px] rounded-bl-[20px] text-center lg:w-[165px] lg:h-[48px] w-[88px] h-[49px] appearance-none',
                    'bg-generic-06 text-gray-light',
                    '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none placeholder-gray-light'
                  )}
                  placeholder="0"
                  disabled={totalSelected === 100 && quantity[ticket.id] === 0}
                  onChange={(e) => {
                    console.log(Number(e.target.value) > 100);

                    if (Number(e.target.value) > 100) {
                      e.target.value = '100';
                    }

                    handleQuantityChange(
                      ticket.id,
                      Number(e.target.value) > 100
                        ? 100
                        : Number(e.target.value)
                    );
                  }}
                  onKeyDown={(e) => {
                    const isNumberKey = e.key >= '0' && e.key <= '9';
                    const isControlKey = [
                      'Backspace',
                      'Tab',
                      'ArrowLeft',
                      'ArrowRight',
                    ].includes(e.key);
                    if (!isNumberKey && !isControlKey) e.preventDefault();
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
