import { WaitlistNotification } from '../../components/notification/waitlistNotification';
import translations from '../../constants/translations.json';
// import { useGetAttendedEvents } from '../../hooks/useGetUserAttendedEvents';
import { useGetLikedEvents } from '../../hooks/useGetUserLikedEvents';
import { useGetWaitlistEvents } from '../../hooks/useGetUserWaitlistEvents';
import { useWaitlistNotification } from '../../hooks/useWaitlistNotification';
import { useSupabase } from '../../providers/supabase';
import { LikedSection } from '../../sections/favourite/liked';
import { WaitlistSection } from '../../sections/favourite/waitlist';
import classNames from 'classnames';
import { useEffect } from 'react';

const MyEventsPage = () => {
  const { session } = useSupabase();
  //   const { eventsAttended, loading: loadingAttended } = useGetAttendedEvents();
  const {
    eventsLiked,
    loading: loadingLiked,
    loadEvents,
  } = useGetLikedEvents();

  const { eventsWaitlist, loading: loadingWaitlist } = useGetWaitlistEvents();

  useEffect(() => {
    loadEvents();
  }, []);

  const {
    showWaitlistNotification,
    handleHeartClick,
    hideWaitlistNotification,
    notificationMessage,
  } = useWaitlistNotification();

  const sessionType = session?.type == 'cene';
  const sessionText = sessionType ? 'text-event ' : ' text-generic';

  return (
    <>
      <h1
        className={classNames(
          'text-[2.25rem] leading-[44px] mt-[16px] font-light mb-[24px]',
          'lg:text-[4.5rem] lg:leading-[80px] lg:mt-[80px] lg:mb-[48px]',
          sessionText
        )}
      >
        {translations.favourite.myEvents}
      </h1>

      <LikedSection
        handleHeartClick={handleHeartClick}
        events={eventsLiked}
        isLoading={loadingLiked}
        session={session?.type}
      />
      <WaitlistSection
        handleHeartClick={handleHeartClick}
        events={eventsWaitlist}
        isLoading={loadingWaitlist}
        session={session?.type}
      />

      {/* {sessionType ? (
        <div className="w-full flex justify-center">
          <DiscoverAboutSection />
        </div>
      ) : (
        <DiscoverSection
          sessionType={session?.type}
          title={translations.eventDiscover.titleTwoPlus}
          desc={translations.eventDiscover.descTwoPlus}
          buttonText={translations.eventDiscover.textButton}
          favourite={false}
        />
      )} */}

      <WaitlistNotification
        visible={showWaitlistNotification}
        message={notificationMessage}
        onClose={hideWaitlistNotification}
      />
    </>
  );
};

export default MyEventsPage;
