import { JustSection } from '../../components/collapsibleSection/justSection';
import translations from '../../constants/translations.json';
import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
import { CareersBannerSection } from '../../sections/careers/careersBanner';
import { CareersInfoSection } from '../../sections/careers/careersInfo';
import { DifferentVoices } from '../../sections/careers/differentVoices';
import { SoundsLikeSection } from '../../sections/careers/soundsLike';
import { StepsInfo } from '../../sections/careers/stepsInfo';
import classNames from 'classnames';

interface SectionData {
  title: string;
  description: string;
}
const sections: SectionData[] = translations.sectionsInfo;

export const CareersPage = () => {
  usePreventHorizontalScroll();

  return (
    <div className="flex flex-col items-center justify-center w-full ">
      <CareersBannerSection />
      <CareersInfoSection />
      <StepsInfo />
      <DifferentVoices />
      <SoundsLikeSection />

      <div
        className={classNames([
          'flex flex-col w-full items-center justify-center ',
          'lg:my-[120px] mx-0 my-[72px]',
        ])}
      >
        <h1 className="lg:text-[4rem] font-light text-event text-center lg:leading-[72px] whitespace-pre-line text-[2rem] leading-[40px]">
          {translations.infoSection.title}
        </h1>
        <h3 className="lg:text-[1.3125rem] font-light text-white text-center mb-[64px] lg:leading-[21px] mt-[24px] leading-[19px] text-[1.1875rem] lg:w-[866px] whitespace-pre-line lg:whitespace-normal">
          {translations.infoSection.desc}
        </h3>
        {sections.map((section, index) => (
          <JustSection
            key={index}
            typeCareers={true}
            title={section.title}
            description={section.description}
            isLast={index === sections.length - 1}
          />
        ))}
      </div>
    </div>
  );
};
