import translations from '../../../constants/translations.json';
import { ICategory } from '../../../types/category';

const accessibility: ICategory = {
  label: 'Accessibility',
  children: [
    {
      question: 'What is Accessible Seating?',
      answer: <p>{translations.accessibility.answerFirst}</p>,
    },
    {
      question: 'How Do I Request Accessible Seating for Events?',
      answer: <p>{translations.accessibility.answerTwo}</p>,
    },
    {
      question:
        'What Do I Need to Do After Selecting the Accessible Seating Option?',
      answer: <p>{translations.accessibility.answerThree}</p>,
    },
    {
      question:
        'What Kind of Proof Do I Need to Provide for Accessible Seating?',
      answer: <p>{translations.accessibility.answerFour}</p>,
    },
    {
      question:
        'Can I Request an Extra Seat for a Companion With My Accessible Seating?',
      answer: <p>{translations.accessibility.answerFive}</p>,
    },
    {
      question:
        'How Long Does It Take To Process My Request for Accessible Seating?',
      answer: (
        <p>
          {translations.accessibility.answerSix.split('24 hours')[0]}
          <span className="font-[700]">24 hours</span>
          {translations.accessibility.answerSix.split('24 hours')[1]}
        </p>
      ),
    },
    {
      question:
        'How Soon Should I Pay for Tickets After My Accessible Seating Request is Approved?',
      answer: (
        <p>
          {translations.accessibility.answerSeven.split('2 hours')[0]}
          <span className="font-[700]">2 hours</span>
          {translations.accessibility.answerSeven.split('2 hours')[1]}
        </p>
      ),
    },
    {
      question: 'What Happens if My Request for Accessible Seating Is Denied?',
      answer: <p>{translations.accessibility.answerEight}</p>,
    },
    {
      question:
        'What’s the Best Time to Submit My Request for Accessible Seating?',
      answer: <p>{translations.accessibility.answerNine}</p>,
    },
    {
      question: 'How Can I Change or Cancel My Accessible Seating Request?',
      answer: (
        <p>
          {translations.accessibility.answerTen.split('[email address]')[0]}
          <span className="font-[700]">[email address]</span>
          {translations.accessibility.answerTen.split('[email address]')[1]}
        </p>
      ),
    },
    {
      question:
        'Who Can I Contact if I Have More Questions About Accessible Seating?',
      answer: (
        <p>
          {translations.accessibility.answerEleven.split('email')[0]}
          <span className="underline">email</span>
          {translations.accessibility.answerEleven.split('email')[1]}
        </p>
      ),
    },
  ],
};

export default accessibility;
