import translations from '../../../constants/translations.json';

const waitlist = {
  label: 'Waitlist',
  children: [
    {
      question: 'What’s the Deal with the Waitlist?',
      answer: (
        <p className=" whitespace-pre-line">
          {translations.waitlist.answerFirst}
        </p>
      ),
    },
    {
      question: 'How Will I Know When Tickets Are Available?',
      answer: (
        <p className=" whitespace-pre-line">
          {translations.waitlist.answerTwo}
        </p>
      ),
    },
    {
      question: 'Can I Join Multiple Waitlists?',
      answer: <p>{translations.waitlist.answerThree}</p>,
    },
    {
      question: 'Can I Leave the Waitlist if I Change My Mind?',
      answer: <p>{translations.waitlist.answerFour}</p>,
    },
    {
      question: 'Can I Share the Waitlist With Friends?',
      answer: <p>{translations.waitlist.answerFive}</p>,
    },
    {
      question: 'What if the Event Gets Rescheduled or Canceled?',
      answer: <p>{translations.waitlist.answerSix}</p>,
    },
    {
      question: 'What Happens if Tickets Sell Out Before I Get One?',
      answer: <p>{translations.waitlist.answerSeven}</p>,
    },
    {
      question: 'Can I Change How I Get Waitlist Updates?',
      answer: <p>{translations.waitlist.answerEight}</p>,
    },
  ],
};

export default waitlist;
