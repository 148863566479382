import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

interface IQuestionItemProps {
  title: string;
  description: string | ReactNode;
  activeTab: number;
}

export const QuestionItem = ({
  title,
  description,
  activeTab,
}: IQuestionItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [activeTab]);

  const toggleItem = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      onClick={toggleItem}
      className={classNames([
        'border-t rounded-lg py-[32px] w-full',
        isOpen ? 'border-generic' : 'border-gray-light',
      ])}
    >
      <div className="flex justify-between items-center gap-[16px]">
        <h2
          className={classNames(
            'text-h2/[1.3125rem] max-lg:text-[1.1875rem]/[1.1875rem] font-light text-white'
          )}
        >
          {title}
        </h2>
        <div>
          <button
            onClick={toggleItem}
            className={classNames([
              `w-10 h-10 flex justify-center items-center text-[1rem] rounded-none rounded-tl-[20px] rounded-br-[20px]
              ${isOpen ? 'bg-generic text-black' : 'bg-gray-dark text-generic'}`,
              'sm:text-[1.125rem] sm:w-11 sm:h-11',
            ])}
          >
            {isOpen ? '-' : '+'}
          </button>
        </div>
      </div>
      {isOpen && (
        <p className="mt-4 text-[1.0625rem]/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light text-generic">
          {description}
        </p>
      )}
    </div>
  );
};
