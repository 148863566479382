import { ReactComponent as ArrowDownSvg } from '../../../src/svg/arrowPlus.svg';
// import { ReactComponent as CheckSvg } from '../../../src/svg/checkOne.svg';
import { ReactComponent as FacebookSvg } from '../../../src/svg/facebookGeneric.svg';
import { ReactComponent as GlobeSvg } from '../../../src/svg/globeGeneric.svg';
import { ReactComponent as InstagramSvg } from '../../../src/svg/instagram.svg';
import { ReactComponent as ShareEventSvg } from '../../../src/svg/share-event.svg';
import { ReactComponent as ShareSvg } from '../../../src/svg/share.svg';
import { ReactComponent as TikTokSvg } from '../../../src/svg/tikTok.svg';
import { ReactComponent as TwitterSvg } from '../../../src/svg/twitterGeneric.svg';
// import { Button } from '../../components/button/button';
import { ShareOptions } from '../../components/shareOptions/shareOptionsOrganization';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import { useState } from 'react';

const socialLinks = [
  {
    Icon: TikTokSvg,
    label: translations.organizer.tikTok,
    url: 'https://www.tiktok.com/@getcene?_t=8qQQ1NNlHtN&_r=1',
    handleKey: 'tiktok',
  },
  {
    Icon: InstagramSvg,
    label: translations.organizer.inst,
    url: 'https://www.instagram.com/getcene?igsh=MWFrdzZ0eXZ3bng3Yg%3D%3D&utm_source=qr',
    handleKey: 'instagram',
  },
  {
    Icon: TwitterSvg,
    label: translations.organizer.twit,
    url: 'https://x.com/getcene?s=21',
    handleKey: 'twitter',
  },
  {
    Icon: FacebookSvg,
    label: translations.organizer.facebook,
    url: 'https://www.facebook.com',
    handleKey: 'facebook',
  },
  {
    Icon: GlobeSvg,
    label: translations.organizer.site,
    url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley',
    handleKey: 'website',
  },
];

interface ImageTextSectionProps {
  imageSrc: string;
  session: string | undefined;
  handleFollowClick: () => void;
  organizerDetails: {
    id: string;
    name: string;
    description: string | null;
    social_handles: {
      tiktok: string | null;
      twitter: string | null;
      website: string | null;
      facebook: string | null;
      instagram: string | null;
    } | null;
    profile_picture_url: string | null;
  };
}

export const DetailsOrganizerSection: React.FC<ImageTextSectionProps> = ({
  session,
  // handleFollowClick,
  organizerDetails,
}) => {
  // const [isFollowing, setIsFollowing] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const sessionType = session === 'cene' || session === undefined;

  // const handleFollow = () => {
  //   setIsFollowing((prevIsFollowing) => {
  //     if (!prevIsFollowing) {
  //       handleFollowClick();
  //     }
  //     return !prevIsFollowing;
  //   });
  // };

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const toggleShareOptions = () => {
    setShowShareOptions((prev) => !prev);
  };

  return (
    <div className="flex flex-col lg:flex-row items-start mt-[24px]">
      <div className="flex flex-col lg:flex-row w-full lg:px-[111px] lg:mb-[80px]">
        <div className="relative w-full lg:w-[422px] lg:h-[545px] rounded-tl-[32px] overflow-hidden">
          {organizerDetails?.profile_picture_url ? (
            <img
              src={organizerDetails?.profile_picture_url}
              alt={organizerDetails?.name || 'Organizer'}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gray-dark flex items-center justify-center">
              <p
                className={classNames(
                  'text-lg',
                  sessionType ? 'text-white' : 'text-generic'
                )}
              >
                {translations.organizer.titleInfo}
              </p>
            </div>
          )}
          {sessionType && (
            <>
              <div className="absolute top-0 left-0 right-0 h-[156px] bg-gradient-event to-transparent" />
              <div className="absolute bottom-0 left-0 right-0 h-[224px] bg-gradient-event-bottom to-transparent" />
            </>
          )}
        </div>
        <div
          className={`lg:ml-[40px] flex-1 ${showMore ? 'lg:max-h-[200px]' : 'lg:max-h-[50px]'} transition-all duration-300`}
        >
          <div
            className={classNames({
              'bg-generic-06 border p-[20px] border-gray-medium rounded-tl-[30px] rounded-br-[30px]':
                !sessionType,
            })}
          >
            <h1
              className={classNames([
                'flex  leading-[12px] text-[0.75rem] mb-[4px]',
                sessionType ? 'text-white' : 'text-generic',
              ])}
            >
              {translations.eventDetailsSection.organizerTitle}
            </h1>
            <h1
              className={classNames(
                'lg:text-[4.5rem] lg:leading-[80px] text-[2rem] leading-[40px] font-light',
                {
                  'text-white': sessionType,
                  'text-generic': !sessionType,
                }
              )}
            >
              {organizerDetails?.name}
            </h1>
            <p
              className={classNames(
                'mt-[16px] lg:mt-[16px] lg:text-[1.0625rem] lg:leading-[22px] text-[1.0625rem] leading-[22px] whitespace-pre-line font-normal',
                {
                  'text-white': sessionType,
                  'text-generic': !sessionType,
                }
              )}
            >
              {organizerDetails?.description
                ? showMore
                  ? organizerDetails.description
                  : `${organizerDetails.description.substring(0, 106)}`
                : showMore
                  ? translations.organizer.desc
                  : `${translations.organizer.desc.substring(0, 106)}`}
            </p>

            <button
              className={classNames([
                'flex items-center  mt-[16px]',
                sessionType ? 'text-event' : ' text-generic',
              ])}
              onClick={toggleShowMore}
            >
              {showMore
                ? translations.organizer.less
                : translations.organizer.more}
              <ArrowDownSvg
                className={classNames('ml-[8px] transition-transform', {
                  'rotate-180': showMore,
                })}
              />
            </button>
          </div>

          {sessionType && (
            <div className="lg:flex hidden h-[1px] bg-gray-medium lg:my-[32px]" />
          )}

          {organizerDetails?.social_handles && (
            <div
              className={classNames({
                'bg-generic-06 border px-[20px] pt-[20px] border-gray-medium rounded-tl-[30px] rounded-br-[30px] mt-[8px] lg:h-[126px] ':
                  !sessionType,
              })}
            >
              <div className="w-full">
                <p
                  className={classNames(
                    'text-[0.75rem] leading-[12px] font-normal',
                    {
                      'mt-[24px] text-white': sessionType,
                      'mt-0 text-generic': !sessionType,
                    }
                  )}
                >
                  {translations.organizer.handles}
                </p>
                <div className="lg:py-[24px] flex flex-row items-center justify-between">
                  <div className="flex items-center lg:space-x-[40px] space-x-[14.33px] mt-[24px] mb-[24px] lg:mb-0 lg:mt-0">
                    {socialLinks.map(({ Icon, label, url, handleKey }) => {
                      if (
                        organizerDetails?.social_handles &&
                        organizerDetails?.social_handles[
                          handleKey as keyof typeof organizerDetails.social_handles
                        ] !== null
                      ) {
                        return (
                          <div
                            key={label}
                            className="flex flex-col items-center lg:px-0 px-[6px] cursor-pointer"
                            onClick={() => window.open(url, '_blank')}
                          >
                            <Icon className="mb-[8px]" />
                            <p
                              className={classNames(
                                'text-[0.875rem] leading-[14px] font-light',
                                {
                                  'text-white': sessionType,
                                  'text-generic': !sessionType,
                                }
                              )}
                            >
                              {label}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
              {sessionType && (
                <div className="h-[1px] lg:flex hidden bg-gray-medium my-[16px] lg:my-[32px]" />
              )}
            </div>
          )}

          <div className="lg:mt-[24px] lg:flex items-center space-x-4 hidden mt-[32px]">
            <div>
              <button
                className={classNames([
                  ' text-generic flex items-center justify-center rounded-big w-[48px] h-[48px]',
                  sessionType ? 'bg-gray-dark' : 'bg-generic-light',
                ])}
                type="button"
                onClick={toggleShareOptions}
              >
                {sessionType ? <ShareEventSvg stroke='#91DBBF' /> : <ShareSvg />}
              </button>
            </div>

            {/* <Button
              className={`${
                isFollowing
                  ? 'bg-village text-black border border-transparent py-[12px] px-[22px]'
                  : 'bg-generic-06 border border-gray-medium text-generic py-[12px] px-[32px]'
              } flex items-center justify-center`}
              type="button"
              onClick={handleFollow}
            >
              <p className="font-medium">
                {translations.eventDetailsSection.follow}
              </p>
              {isFollowing && <CheckSvg className="ml-[8px]" />}
            </Button> */}
          </div>
          {showShareOptions && (
            <ShareOptions
              onCopyLink={() => {
                navigator.clipboard.writeText(window.location.href);
              }}
              onClose={toggleShareOptions}
            />
          )}
        </div>
      </div>
    </div>
  );
};
