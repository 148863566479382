import { countryCodes } from '../../forms/signup/phoneStep/countryCodes';
import { InputSelect } from '../select/select';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import CountryList from 'country-list-with-dial-code-and-flag';
import { useEffect, useState } from 'react';

interface CountryCodeSelectProps {
  onChange: (value: string) => void;
  value: string;
  isCenePlus?: boolean;
}

export const CountryCodeSelect = ({
  onChange,
  value,
  isCenePlus,
}: CountryCodeSelectProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setLoader] = useState(true);
  const [filteredCountries, setFilteredCountries] = useState(countryCodes);

  useEffect(() => {
    axios
      .get('https://ipinfo.io')
      .then((response) => {
        const data = response.data;

        const country = CountryList.findOneByCountryCode(data.country);

        onChange(country?.dialCode.replace('+', '') || '');
      })
      .catch((error) => {
        onChange('93');
        console.log(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const handleSearch = (value: string) => {
    setSearchValue(value);

    const toLowerCase = value.toLowerCase();

    setFilteredCountries([
      ...countryCodes.filter(
        (item) =>
          item.code.toLowerCase().includes(toLowerCase) ||
          item.title.toLowerCase().includes(toLowerCase)
      ),
    ]);
  };

  if (isLoader)
    return <CircularProgress size="20px" style={{ marginTop: '16px' }} />;

  return (
    <InputSelect
      inputSearch={{
        placeholder: 'Search country...',
        value: searchValue,
        onChange: handleSearch,
      }}
      andorment="+"
      onChange={onChange}
      placeholder="+380"
      value={value}
      options={filteredCountries}
      isPlus={isCenePlus}
    />
  );
};
