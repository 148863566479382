import { ROUTE_ERROR, ROUTE_SUCCESS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { PartnerWithUsForm } from '../../forms/partnerWithUs/partnerWithUs';
import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Logo = '/pictures/bannerWelcome.jpg';

export const PartnerWithUs: React.FC = () => {
  usePreventHorizontalScroll();
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [beforeColon, afterColon] =
    translations.partnerWithUs.title.split(': ');
  const [formStatus, setFormStatus] = useState<'success' | 'error' | null>(
    null
  );

  const handleSuccess = () => {
    setIsFormSubmitted(true);
    setFormStatus('success');
  };

  const handleError = () => {
    setIsFormSubmitted(true);
    setFormStatus('error');
  };

  useEffect(() => {
    if (isFormSubmitted && formStatus) {
      const route = formStatus === 'success' ? ROUTE_SUCCESS : ROUTE_ERROR;
      navigate(route);
    }
  }, [isFormSubmitted, formStatus]);

  return (
    <div className="w-full flex flex-col gap-[48px] items-center mt-[120px] max-lg:w-full max-lg:mt-[32px]">
      {!isFormSubmitted && (
        <>
          <div className="text-center flex flex-col items-center">
            <h1
              className={classNames([
                'text-[4.5rem]/[5rem] text-white font-light',
                'max-lg:text-[2.25rem]/[2.75rem] whitespace-pre-line leading-[64px]',
              ])}
            >
              {beforeColon + ': '}
              <span className="text-village">{afterColon}</span>
            </h1>
            <h2 className="text-white mt-[24px] text-[1.0625rem]/[1.375rem] max-lg:text-[1rem]/[1.3125rem] lg:w-[644px] font-light">
              {translations.partnerWithUs.titleTwo}
            </h2>
          </div>
          <PartnerWithUsForm onSuccess={handleSuccess} onError={handleError} />
          <div className="relative w-screen h-screen max-md:h-[400px] overflow-hidden lg:mt-[120px]">
            <img
              src={Logo}
              alt="Banner"
              className="object-cover w-full h-full max-md:h-[400px]"
            />
          </div>
        </>
      )}
    </div>
  );
};
