import { Button } from '../../../../components/button/button';
import { Input } from '../../../../components/input/cenePlus/input';
import translation from '../../../../constants/translations.json';
import { supabaseCenePlusClient } from '../../../../supabase/supabase';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import {
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

interface FormProps {
  onNext: () => void;
}
interface Otp {
  otp: string;
  countryCode: string;
  phone: string;
}

export const VerifyForm = ({ onNext = () => null }: FormProps) => {
  const formik = useFormikContext<Otp>();

  const [isResent, setIsResent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const initialLength = 6;

  const initialArray = Array.from({ length: initialLength }, () => '');
  const [verifyCode, setVerifyCode] = useState<string[]>(initialArray);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    inputRefs?.current[0]?.focus();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isResent) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResent]);

  const handleResend = async () => {
    setIsResent(true);
    setResendTimer(22);

    setTimeout(() => setIsResent(false), 22000);

    const phone = `+${formik.values.countryCode}${formik.values.phone}`;

    await supabaseCenePlusClient.auth.signInWithOtp({
      phone,
    });

    formik.setFieldError('otp', undefined);

    setVerifyCode(initialArray);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && verifyCode[index] === '') {
      e.preventDefault();
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    } else if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleOTPChange = (
    e: SyntheticEvent<HTMLInputElement>,
    index: number
  ) => {
    const text = e.currentTarget.value;
    const newOtp = [...verifyCode];
    newOtp[index] = text;

    setVerifyCode(newOtp);

    if (text === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (text !== '' && index < verifyCode.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const onVerifyOtp = async () => {
    const phone = `+${formik.values.countryCode}${formik.values.phone}`;

    const { error } = await supabaseCenePlusClient.auth.verifyOtp({
      type: 'sms',
      phone,
      token: verifyCode.join(''),
    });

    if (error) {
      return formik.setFieldError('otp', 'OTP is invalid or has expired.');
    }

    onNext();
  };

  return (
    <div className={classNames(['pt-[24px] pb-[20px] px-5', 'sm:px-0'])}>
      <h1 className="text-[2rem] text-generic font-light mb-[12px]">
        {translation.steps.verifyStep.titleCenePlus}
      </h1>

      <p className="text-[0.875rem] text-generic font-light mb-[40px]">
        {translation.steps.verifyStep.descriptionCenePlus}
      </p>

      <div className="flex flex-col">
        <div>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: {
                xs: '8px',
                lg: '16px',
              },
            }}
          >
            {verifyCode.map((value, index) => (
              <Input
                key={index}
                type="otp"
                id="otp"
                value={value}
                inputRef={(input: HTMLInputElement) =>
                  (inputRefs.current[index] = input as HTMLInputElement)
                }
                onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                  handleOTPChange(e, index)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(e, index)
                }
                touched={Boolean(formik.errors.otp)}
                error={formik.errors.otp}
              />
            ))}
          </Stack>
        </div>

        {formik.errors.otp && (
          <div className="text-[0.875rem] text-red font-light mt-[8px]">
            {formik.errors.otp}
          </div>
        )}

        <h5 className="text-generic font-light text-[0.875rem] mt-[40px] text-center sm:text-left">
          If you do not receive an OTP code, please contact our{' '}
          <a
            href="https://cene.xyz/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            support team
          </a>
          .
          <div className="flex justify-center sm:justify-start mt-[16px]">
            <button
              onClick={handleResend}
              disabled={isResent}
              className={classNames(
                'flex gap-[8px] rounded-tl-[20px] rounded-br-[20px] text-[1rem] font-medium text-black px-[24px] py-[8px] mb-[40px]',
                isResent ? 'bg-gray-dark text-gray-light' : 'bg-generic'
              )}
            >
              {isResent ? (
                <>
                  <span>{translation.steps.verifyStep.resendCode}</span>
                  <p className="text-white">{formatTime(resendTimer)}</p>
                </>
              ) : (
                translation.steps.verifyStep.resendCode
              )}
            </button>
          </div>
        </h5>
        <Button
          color="generic"
          type="button"
          onClick={onVerifyOtp}
          disabled={Boolean(!verifyCode.join('').length)}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};
