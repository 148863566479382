import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import React from 'react';

interface AboutUsProps {
  refProp: React.RefObject<HTMLDivElement>;
}

const Deejay = '/pictures/aboutImg.png';
const caseStudyFile = '/testDownload.pdf'; // TEMP

export const AboutUs: React.FC<AboutUsProps> = ({ refProp }) => {
  return (
    <div
      className={classNames(['lg:my-[120px] lg:mx-[64px] mx-[20px] my-[72px]'])}
    >
      <div
        ref={refProp}
        className={classNames([
          'flex flex-col container mx-auto',
          'lg:flex-row lg:gap-[135px] gap-0',
        ])}
      >
        <div
          className={classNames([
            'w-full flex justify-center',
            'lg:mb-0 lg:justify-end lg:w-1/2',
          ])}
        >
          <img
            src={Deejay}
            alt="Img"
            className={classNames([
              'lg:w-[644px] lg:h-[644px] rounded-tl-[30px] object-cover w-full',
              'lg:max-w-none',
            ])}
          />
        </div>
        <div className="w-full lg:w-[55%] flex flex-col justify-center">
          
          <div className="lg:mt-0 mt-[56px]">
            <h1 className="text-[0.9375rem] text-gray-light leading-[20px] font-normal">
              {translations.aboutUs.title}
            </h1>
            <h2
              className={classNames([
                'text-[2rem] leading-[40px] text-event mt-[4px]',
                'lg:leading-[72px] lg:text-[4rem] lg:mt-0 font-light',
              ])}
            >
              {translations.aboutUs.titleTwo}
            </h2>
            <p className="lg:text-[1.0625rem] text-generic lg:mt-[24px] mt-[16px] lg:leading-[22px] font-normal text-[1rem] leading-[21px]">
              {translations.aboutUs.description}
            </p>
          </div>
          <a href={caseStudyFile} download className="inline-block">
            <Button className="text-black bg-generic w-[199px] h-[48px] lg:mt-[48px] mt-[16px] font-medium">
              {translations.aboutUs.testButton}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
