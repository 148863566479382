import Steps from '../../../../components/steps/steps';
import { ROUTE_AUTH } from '../../../../constants/routes';
import { usePreventUnload } from '../../../../hooks/usePreventUpload';
import { GuestSchema, guestSchema } from '../../../../schemas/guestSchema';
import { GenderAndBirthForm } from '../../genderAndBirthStep/guest/genderAndBirthForm';
import { GenrePreferencesForm } from '../../genrePreferencesStep/cenePlus/genrePreferences';
import { InterestPreferencesForm } from '../../interestPreferencesStep/cenePlus/interestPreferences';
import { InviteVerifyForm } from '../../inviteVerifyStep/guest/inviteVerifyForm';
import { JobForm } from '../../jobStep/jobForm';
import { NameForm } from '../../nameStep/guest/nameForm';
import { Notifications } from '../../notificationStep/notificationForm';
import { VerifyForm } from '../../otpStep/guest/verifyForm';
import { PasswordForm } from '../../passwordStep/guest/passwordForm';
import { PhoneForm } from '../../phoneStep/guest/phoneForm';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RegisterFormProps {
  onLoading: () => void;
}

export const RegisterForm = ({ onLoading }: RegisterFormProps) => {
  const [activeStep, setStep] = useState(1);
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePreventUnload(isFormDirty);

  const navigate = useNavigate();

  const onBackStep = useCallback(() => {
    if (activeStep === 1) return navigate(ROUTE_AUTH);

    setStep(activeStep - 1);
  }, [activeStep]);

  const nextStep = useCallback(() => setStep(activeStep + 1), [activeStep]);

  const onSubmit = async (values: GuestSchema) => {
    console.log(values);
    onLoading();

    // await supabaseCeneClient.auth.updateUser({
    //   password: values.password,
    // });

    // const { error } = await supabaseCeneClient
    //   .from('customers')
    //   .update([
    //     {
    //       interests: values.interests,
    //       genres: values.genres,
    //       notifications: values?.notifications,
    //       onboarding_finished: true,
    //     },
    //   ])
    //   .eq('user_id', session?.user.id);

    // if (!error) onLoading();
  };

  const isSkip = activeStep === 8 || activeStep === 9 || activeStep === 10;

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        gender: 'Male',
        dateOfBirth: null,
        jobTitle: '',
        company: '',
        countryCode: '',
        phone: '',
        email: '',
        reference: '',
        password: '',
        confirmPassword: '',
        agreement: false,
        musicGenres: [],
        interestGenres: [],
        notifications: [],
      }}
      validationSchema={guestSchema}
      onSubmit={() => {}}
    >
      {({ values, dirty, setFieldValue }) => {
        useEffect(() => {
          setIsFormDirty(dirty);
        }, [dirty]);
        return (
          <Steps
            hideBackButton={activeStep === 1 || activeStep === 7}
            activeStep={activeStep}
            onBack={onBackStep}
            onSkip={() => {
              if (activeStep === 8) setFieldValue('genres', []);
              if (activeStep === 9) setFieldValue('interests', []);
              if (activeStep === 10) {
                return onSubmit({ ...values, notifications: [] });
              }
              nextStep();
            }}
            canSkip={isSkip}
            isPremium={true}
            width={168}
            size="px"
          >
            <NameForm onNext={nextStep} />
            <GenderAndBirthForm onNext={nextStep} />
            <JobForm onNext={nextStep} />
            <PhoneForm onNext={nextStep} />
            <VerifyForm onNext={nextStep} />
            <InviteVerifyForm onNext={nextStep} />
            <PasswordForm onNext={nextStep} />
            <GenrePreferencesForm onNext={nextStep} />
            <InterestPreferencesForm onNext={nextStep} />
            <Notifications onNext={() => onSubmit(values)} isPremium={true} />
          </Steps>
        );
      }}
    </Formik>
  );
};
