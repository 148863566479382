const cookiePolicy = {
  label: 'Cookie Policy',
  content: (
    <>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem]/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light">
        Please read these Purchase Terms carefully as they set out the terms on
        which you purchase a ticket on the Cene/Cene+ mobile app (the “App”) or
        via the services, features, content, applications, websites, or widgets
        offered by Cene (the “Services”). All purchases for tickets and other
        goods and services are subject to these Purchase Terms. If you have any
        questions about them, please contact us at support@cene.xyz . We may
        change these Purchase Terms from time to time so please check our
        website at www.cene.xyz from time to time for any updates to ensure that
        you are aware of the current terms. Your continued use of the App or the
        Services following any update to these Purchase Terms will constitute
        your acceptance of those changes. These Purchase Terms incorporate our
        Terms and Conditions by this reference.
      </p>
      <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white mt-[4rem] max-lg:mt-[2.5rem]">
        What are cookies?
      </h2>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        Cookies are small text files that are placed on your device when you
        visit our Website. They are widely used to improve user experience by
        remembering your actions and preferences over time. This data may be
        shared with our third party partners where necessary.
      </p>
      <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white mt-[4rem] max-lg:mt-[2.5rem]">
        Use of cookies
      </h2>
      <h3 className="text-[1.75rem]/[2.25rem] max-lg:text-[1.3125rem]/[1.3125rem] text-white font-light mt-[4rem] max-lg:mt-[2.5rem]">
        Essential cookies
      </h3>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        These cookies are necessary for the proper functioning of the Website
        and enable you to navigate and use its features. They include, for
        example, cookies that enable you to log into secure areas of our website
        and app, use a shopping cart or make use of e-billing services. They do
        not require your consent.
      </p>
      <h3 className="text-[1.75rem]/[2.25rem] max-lg:text-[1.3125rem]/[1.3125rem] text-white font-light mt-[4rem] max-lg:mt-[2.5rem]">
        Functional cookies
      </h3>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        These cookies allow our Website to remember choices you make (such as
        your language preferences) and provide enhanced, personalized features.
        This enables us to personalise our content for you, greet you by name
        and remember your preferences (for example, your choice of language or
        region).
      </p>
      <h3 className="text-[1.75rem]/[2.25rem] max-lg:text-[1.3125rem]/[1.3125rem] text-white font-light mt-[4rem] max-lg:mt-[2.5rem]">
        Analytical/Performance cookies
      </h3>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        We use these cookies to collect information about how visitors use the
        Website, such as which pages are visited most often and if any error
        messages occur. This helps us improve the performance and design of the
        Website. These cookies are usually provided by third-party analytics
        providers.
      </p>
      <h3 className="text-[1.75rem]/[2.25rem] max-lg:text-[1.3125rem]/[1.3125rem] text-white font-light mt-[4rem] max-lg:mt-[2.5rem]">
        Advertising/Targeting cookies
      </h3>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        These cookies are used to deliver advertisements that are relevant to
        you and your interests. They are also used to limit the number of times
        you see an advertisement, as well as to measure the effectiveness of
        advertising campaigns. These cookies are typically set by advertising
        networks with our permission.
      </p>
      <h3 className="text-[1.75rem]/[2.25rem] max-lg:text-[1.3125rem]/[1.3125rem] text-white font-light mt-[4rem] max-lg:mt-[2.5rem]">
        How to Control Cookies
      </h3>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        You can control and manage cookies in various ways. Most browsers allow
        you to refuse or accept cookies, delete cookies, or notify you when a
        cookie is being sent. Please note that if you choose to block cookies,
        some features of the Website may not function properly.
      </p>
      <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white mt-[4rem] max-lg:mt-[2.5rem]">
        Cookie consent
      </h2>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        When you use our website for the first time you are given the
        opportunity to accept or decline cookies which are not strictly
        necessary. You can also change your cookie consents at any time by
        accessing the cookie banner or cookie settings. By continuing to use the
        Website and not adjusting your browser settings to block cookies, you
        consent to our use of cookies as described in this policy.
      </p>
      <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white mt-[4rem] max-lg:mt-[2.5rem]">
        Updates to this policy
      </h2>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
        We may update this Cookie Policy from time to time to reflect changes to
        our use of cookies or for other operational, legal, or regulatory
        reasons.
      </p>
    </>
  ),
};

export default cookiePolicy;
