import { ReactComponent as CalendarBlankSvg } from '../../svg/calendarPlus.svg';
import { ReactComponent as MapSvg } from '../../svg/mapTrifold.svg';
import { Event } from '@ceneteam/namespace';
import moment from 'moment';

interface IEventInformation {
  event: Event;
}

export const EventInformation = ({ event }: IEventInformation) => {
  return (
    <>
      <div className="mt-[20px] flex ">
        <div className="w-[119px] h-[154px] relative">
          <div className="bg-event-gradient absolute w-full h-full" />
          <img
            className="min-w-[119px] min-h-[154px] object-cover"
            src={event?.featured_image.url}
            alt={event?.name}
          />
        </div>
        <div className="ml-[32px] max-w-[346px]">
          <h2 className="text-generic text-[1.32rem] font-light">
            {event?.name}
          </h2>
          <div className="mt-[17.5px] flex gap-[12px]">
            <MapSvg className="min-w-[24px] min-h-[24px]" />
            <label
              title={event?.address}
              className="line-clamp-3 text-generic text-[1rem] font-light"
            >
              {event?.address}
            </label>
          </div>
          <div className="mt-[17.5px] flex gap-[12px]">
            <CalendarBlankSvg className="min-w-[24px] min-h-[24px]" />
            <label className="text-generic text-[1rem] font-light">
              {moment(event?.starts_at, 'YYYY-MM-DD HH:mm').format(
                'dddd, MMMM D · HH:mm'
              )}
            </label>
          </div>
        </div>
        <div className="ml-[24px] max-w-[532px]">
          <h2 className="text-white text-[1.32rem] font-light mb-[24px]">
            Using the Floorplan
          </h2>
          <p className="text-gray-light text-[1.1rem] font-light">
            This interactive floorplan allows you to zoom in and click on a
            table for details or to add it to your basket. Greyed-out tables are
            not available
          </p>
        </div>
      </div>
    </>
  );
};
