import * as Yup from 'yup';

export const uppercaseRule = Yup.string()
  .required('Password is required')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter');

export const lowercaseRule = Yup.string()
  .required('Password is required')
  .matches(/[a-z]/, 'Password must contain at least one lowercase letter');

export const numberRule = Yup.string()
  .required('Password is required')
  .matches(/\d/, 'Password must contain at least one number');

export const specialCharacterRule = Yup.string()
  .required('Password is required')
  .matches(
    /[!@#$%^&*()_+]/,
    'Password must contain at least one special character'
  );

export const onlyLatinCharactersRule = Yup.string()
  .required('Password is required')
  .matches(
    /^[a-zA-Z0-9!@#$%^&*()_+]*$/,
    'Password can only contain latin letters, numbers, and special characters'
  );

export const emailRule = Yup.string()
  .email('Your e-mail is not valid')
  .required('E-mail is required')
  .matches(
    /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Invalid e-mail format. Please enter a valid e-mail address.'
  );
