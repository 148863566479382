import CarouselSection from '../../../components/carouselSection/carouselSection';
import { Loader } from '../../../components/loader/cene/loader';
import translations from '../../../constants/translations.json';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface Props {
  events: Event[];
  eventsLiked:Event[]
  sessionType: boolean;
  isLoading: boolean;
}

const HotRightNowSection = ({ events, sessionType, isLoading,eventsLiked }: Props) => {
  const navigate = useNavigate();

  const handleCardClick = (id: string, isWaitlist: boolean) => {
    navigate(`/event-details/${id}`, { state: { isWaitlist } });
  };
  return (
    <div className={classNames('mt-[2.75rem]', 'max-lg:mt-[1.75rem]')}>
      {isLoading ? (
        <div className="flex justify-center items-center h-[558px]">
          <Loader />
        </div>
      ) : (
        <CarouselSection
          data={events}
          eventsLiked={eventsLiked}
          title={translations.events.hotRightNow}
          sessionType={!sessionType}
          color={sessionType ? 'generic' : 'event'}
          handleCardClick={handleCardClick}
          colorCard={sessionType ? 'black' : 'event'}
        />
      )}
    </div>
  );
};

export default HotRightNowSection;
