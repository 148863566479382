import { Button } from '../../../components/button/button';
import { Icon } from '../../../components/icon/icon';
import { Input } from '../../../components/input/cene/input';
import { Label } from '../../../components/label/cene/label';
import PasswordRequirementsList from '../../../components/passwordRequirementsList/passwordRequirementsList';
import { ROUTE_LOGIN } from '../../../constants/routes';
import translation from '../../../constants/translations.json';
import {
  CreateNewPasswordSchema,
  createNewPasswordSchema,
} from '../../../schemas/createNewPasswordSchema';
import {
  lowercaseRule,
  numberRule,
  specialCharacterRule,
  uppercaseRule,
} from '../../../schemas/validationRules';
import { supabaseCeneClient } from '../../../supabase/supabase';
import { ReactComponent as BackSVG } from '../../../svg/arrow-left.svg';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IFormProps {
  onSubmit: () => void;
}

export const CreateNewPasswordForm = ({
  onSubmit = () => null,
}: IFormProps) => {
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      setAccessToken(hashParams.get('access_token') || '');
      setRefreshToken(hashParams.get('refresh_token') || '');
    }
  }, []);

  useEffect(() => {
    const getSessionWithTokens = async () => {
      if (accessToken && refreshToken) {
        const { error } = await supabaseCeneClient.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken,
        });

        if (error) {
          alert(`Error signing in: ${error.message}`);
        }
      }
    };

    if (accessToken && refreshToken) {
      getSessionWithTokens();
    }
  }, [accessToken, refreshToken]);

  const [passwordValid, setPasswordValid] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const navigate = useNavigate();

  const handleChangePassword = (e: SyntheticEvent<HTMLInputElement>) => {
    setError('');
    const value = e.currentTarget.value;
    formik.setFieldValue('password', value);
    validatePassword(value);
  };

  const validatePassword = (newPassword: string) => {
    const isUppercase = uppercaseRule?.isValidSync(newPassword);
    const isLowercase = lowercaseRule?.isValidSync(newPassword);
    const isNumber = numberRule?.isValidSync(newPassword);
    const isSpecialCharacter = specialCharacterRule?.isValidSync(newPassword);

    setPasswordValid({
      uppercase: isUppercase,
      lowercase: isLowercase,
      number: isNumber,
      specialChar: isSpecialCharacter,
    });
  };

  const onBackStep = useCallback(() => {
    navigate(ROUTE_LOGIN);
  }, []);

  const formik = useFormik<CreateNewPasswordSchema>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: createNewPasswordSchema,
    onSubmit: async (values) => {
      const { data, error } = await supabaseCeneClient.auth.updateUser({
        password: values.password,
      });

      if (error) {
        setError(error.message);
        return;
      }

      if (data) {
        onSubmit();
      }
    },
  });

  return (
    <div
      className={classNames(['flex flex-col', 'px-5 sm:px-0 w-full h-full'])}
    >
      <div>
        <Icon onClick={onBackStep}>
          <BackSVG />
        </Icon>
      </div>
      <h2 className="text-[2rem] font-light text-white mt-[24px]">
        {translation.createNewPasswordPage.titleCene}
      </h2>

      <form
        className="flex flex-col gap-[40px] max-md:gap-[24px] mt-[40px] max-md:mt-[24px]"
        onSubmit={formik.handleSubmit}
      >
        <div className="w-full flex flex-col gap-[8px]">
          <Label id="password" isRequired={true}>
            {translation.createNewPasswordPage.password}
          </Label>
          <Input
            id="password"
            type="password"
            placeholder={translation.createNewPasswordPage.enterPassword}
            value={formik.values.password}
            onChange={handleChangePassword}
            onBlur={formik.handleBlur}
            error={formik.errors.password}
            touched={Boolean(formik.touched.password)}
          />
          {formik.errors.password && formik.touched.password && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {formik.errors.password}
            </div>
          )}
        </div>
        <div className="w-full flex flex-col gap-[8px]">
          <h2 className="text-h6 font-light text-generic">
            {translation.createNewPasswordPage.passwordMustHave}
          </h2>
          <PasswordRequirementsList passwordValid={passwordValid} />
        </div>
        <div className="w-full flex flex-col gap-[8px]">
          <Label id="confirmPassword" isRequired={true}>
            {translation.createNewPasswordPage.confirmPassword}
          </Label>
          <Input
            id="confirmPassword"
            type="password"
            placeholder={translation.createNewPasswordPage.enterConfirmPassword}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.confirmPassword}
            touched={Boolean(formik.touched.confirmPassword)}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <div className="text-[0.875rem] text-red font-light">
              {formik.errors.confirmPassword}
            </div>
          )}
          {error && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {error}
            </div>
          )}
        </div>
        <Button
          className="mb-[24px]"
          color="content"
          type="submit"
          disabled={!(formik.isValid && formik.dirty)}
        >
          {translation.createNewPasswordPage.continue}
        </Button>
      </form>
    </div>
  );
};
