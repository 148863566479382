import { ReactComponent as AccessibilitySvg } from '../../../src/svg/accessibility.svg';
import { ReactComponent as BoltSvg } from '../../../src/svg/bolt.svg';
import { ReactComponent as EarSvg } from '../../../src/svg/ear.svg';
import { ReactComponent as EyeClosedSvg } from '../../../src/svg/eyeClosed.svg';
import { ReactComponent as SupportSvg } from '../../../src/svg/support.svg';
import { ReactComponent as WheelchairSvg } from '../../../src/svg/wheelchair.svg';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

const icons = [
  WheelchairSvg,
  AccessibilitySvg,
  EyeClosedSvg,
  SupportSvg,
  BoltSvg,
  EarSvg,
];

export const InclusivitySection = () => {
  const { linclusivityItems } = translations.inclusivity;

  return (
    <div
      className={
        'w-full h-auto flex flex-col justify-center items-center lg:px-[64px] lg:pt-[0px] pt-[120px] lg:mt-[120px] mt-0'
      }
    >
      <p className="text-white leading-[14px] text-[0.875rem] font-light mb-[24px]">
        {' '}
        {translations.inclusivity.titleSmall}
      </p>
      <h1
        className={classNames([
          'text-[2rem] leading-[40px] font-light text-ticket text-center lg:w-[736px] whitespace-pre-line',
          'lg:text-[4rem] lg:leading-[72px] ',
        ])}
      >
        {translations.inclusivity.title}
      </h1>
      <h3 className="lg:text-[1.0625rem] text-white text-center mt-[24px] w-full max-w-[537.92px] font-light lg:leading-[22px] text-[1rem] leading-[21px]">
        {translations.inclusivity.description}
      </h3>

      <div
        className={classNames([
          'w-full max-w-[1106px] grid grid-cols-1 gap-x-6 lg:mt-[64px] mt-[40px]',
          'sm:grid-cols-2 md:grid-cols-3 mx-[167px] my-[120px]',
        ])}
      >
        {linclusivityItems.map((item, index) => {
          const IconComponent = icons[index % icons.length];
          return (
            <div
              key={index}
              className={classNames([
                'flex flex-col items-center mb-8 text-center',
                'lg::mb-[64px] ',
              ])}
            >
              <div className="w-12 h-12 mb-4">
                <IconComponent />
              </div>
              <h4 className="lg:text-[2rem] font-light lg:leading-[40px] mb-2 text-ticket whitespace-nowrap text-[1.125rem] leading-[26px]">
                {item.title}
              </h4>
              <p className="text-[1rem] font-light leading-[24px] text-white-light mt-2">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
