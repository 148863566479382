import { ReactComponent as DeviceSvg } from '../../../src/svg/deviceMobileCameraGeneric.svg';
import { ReactComponent as DimondSvg } from '../../../src/svg/diamond.svg';
import { ReactComponent as HeadphonesSvg } from '../../../src/svg/headphones.svg';
import { ReactComponent as ListViewSvg } from '../../../src/svg/listView.svg';
import { ReactComponent as PeopleSvg } from '../../../src/svg/peopleCenePlus.svg';
import { ReactComponent as WineSvg } from '../../../src/svg/wine.svg';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

const icons = [
  DimondSvg,
  ListViewSvg,
  DeviceSvg,
  WineSvg,
  PeopleSvg,
  HeadphonesSvg,
];

export const LifeMember = () => {
  const { lifeMemberItems } = translations.lifeMember;

  return (
    <div
      className={
        'w-full h-auto flex flex-col justify-center items-center lg:px-[64px] lg:pt-[120px] pt-[72px] '
      }
    >
      <h1
        className={classNames([
          'text-[2rem] leading-[40px] font-light text-generic text-center lg:w-[736px] whitespace-pre-line',
          'lg:text-[4rem] lg:leading-[72px] ',
        ])}
      >
        {translations.lifeMember.title}
      </h1>
      <h3 className="lg:text-[1.0625rem] text-generic-d text-center mt-[24px] w-full max-w-[537.92px]  font-normal lg:leading-[22px] text-[1rem] leading-[21px]">
        {translations.lifeMember.description}
      </h3>

      <div
        className={classNames([
          'w-full max-w-[1106px] grid grid-cols-1 gap-x-6 lg:mt-[64px]',
          'sm:grid-cols-2 md:grid-cols-3 mx-[167px] lg:my-[120px] my-[40px]',
        ])}
      >
        {lifeMemberItems.map((item, index) => {
          const IconComponent = icons[index % icons.length];
          return (
            <div
              key={index}
              className={classNames([
                'flex flex-col items-center mb-8 text-center',
                'lg:mb-[64px] ',
              ])}
            >
              <div className="w-12 h-12 mb-4">
                <IconComponent />
              </div>
              <h4 className="lg:text-[2rem] font-light lg:leading-[40px] mb-2 text-generic text-[1.125rem] leading-[26px]">
                {item.title}
              </h4>
              <p className="text-[1rem] font-light leading-[24px] text-white-light mt-2">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
