import translations from '../../../constants/translations.json';
import { ICategory } from '../../../types/category';

const cenePlus: ICategory = {
  label: 'Cene+',
  children: [
    {
      question: 'What is Cene+?',
      answer: <p>{translations.cenePlus.answerFirst}</p>,
    },
    {
      question: 'What Kind of Tickets Are Available to Cene+ Members?',
      answer: <p>{translations.cenePlus.answerTwo}</p>,
    },
    {
      question: 'What are Bulk Tickets?',
      answer: <p>{translations.cenePlus.answerThree}</p>,
    },
    {
      question: 'Can I Share My Tickets to a Non-Cene+ Member?',
      answer: <p>{translations.cenePlus.answerFour}</p>,
    },
    {
      question: 'What is Cene+ Guests?',
      answer: <p>{translations.cenePlus.answerFive}</p>,
    },
    {
      question: 'I Bought a Table. How Can I Share the Tickets With My Guests?',
      answer: <p>{translations.cenePlus.answerSix}</p>,
    },
    {
      question: 'Can I Transfer My Cene+ Membership to Someone Else?',
      answer: <p>{translations.cenePlus.answerSeven}</p>,
    },
  ],
};

export default cenePlus;
