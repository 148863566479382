import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../supabase/supabase';
import { Session } from '@supabase/supabase-js';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface SupabaseContextProps {
  session:
    | (Session & {
        type?: 'cene' | 'cene+' | 'undefined';
        onboardingStatus: boolean;
      })
    | null;
  logout: () => Promise<void>;
}

const SupabaseContext = createContext<SupabaseContextProps>({
  session: null,
  logout: async () => {},
});

export const SupabaseProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<SupabaseContextProps['session']>(null);
  const [isLoading, setLoading] = useState(true);

  const ceneCookieKey = `sb-${process.env.REACT_APP_SUPABASE_CENE_REF || 'hhgggeiqmvlprlwiccqa'}-auth-token`;
  const cenePlusCookieKey = `sb-${process.env.REACT_APP_SUPABASE_CENE_PLUS_REF || 'ahaoimgwthfulhssvwfo'}-auth-token`;

  useEffect(() => {
    getSession();
    onAuthStateChange();
  }, []);

  useEffect(() => {
    getOnboardingStatus();
  }, [session?.access_token]);

  const getSession = async () => {
    try {
      const isCene = localStorage.getItem(ceneCookieKey);
      const isCenePlus = localStorage.getItem(cenePlusCookieKey);

      if (isCene) {
        console.log('CENE Session');
        const sessionCene = await supabaseCeneClient.auth.getSession();

        setSession({
          ...session,
          ...sessionCene.data.session,
          type: 'cene',
        } as SupabaseContextProps['session']);
      }

      if (isCenePlus) {
        console.log('CENE+ Session');
        const sessionCenePlus = await supabaseCenePlusClient.auth.getSession();

        if (sessionCenePlus.data)
          setSession({
            ...session,
            ...sessionCenePlus.data.session,
            type: 'cene+',
          } as SupabaseContextProps['session']);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onAuthStateChange = async () => {
    supabaseCeneClient.auth.onAuthStateChange(async (_event, session) => {
      const isCene = localStorage.getItem(ceneCookieKey);

      if (isCene) {
        setSession(
          (prevSession) =>
            ({
              ...session,
              onboardingStatus: prevSession?.onboardingStatus,
              type: 'cene',
            }) as SupabaseContextProps['session']
        );
        console.log('CENE SESSION');
      }

      if (_event === 'SIGNED_OUT') {
        console.log('SIGNED_OUT');
        setSession(null);
      }
    });

    supabaseCenePlusClient.auth.onAuthStateChange(async (_event, session) => {
      const isCenePlus = localStorage.getItem(cenePlusCookieKey);

      if (isCenePlus) {
        setSession(
          (prevSession) =>
            ({
              onboardingStatus: prevSession?.onboardingStatus,
              ...session,
              type: 'cene+',
            }) as SupabaseContextProps['session']
        );
        console.log('CENE+ SESSION');
      }

      if (_event === 'SIGNED_OUT') {
        console.log('SIGNED_OUT');

        setSession(null);
      }
    });
  };

  const logout = async () => {
    try {
      if (session?.type === 'cene') {
        await supabaseCeneClient.auth.signOut();
      } else {
        await supabaseCenePlusClient.auth.signOut();
      }

      setSession(null);
    } catch (error) {
      console.log(error);
    }
  };

  const getOnboardingStatus = async () => {
    const isCene = localStorage.getItem(ceneCookieKey);
    const isCenePlus = localStorage.getItem(cenePlusCookieKey);

    if (!session) return false;

    if (isCene) {
      const { data } = await supabaseCeneClient
        .from('customers')
        .select('*')
        .eq('user_id', session?.user?.id)
        .single();

      setSession({
        ...session,
        onboardingStatus: data?.onboarding_finished,
      });
    } else if (isCenePlus) {
      const { data } = await supabaseCenePlusClient
        .from('customers')
        .select('*')
        .eq('user_id', session?.user?.id)
        .single();

      setSession({
        ...session,
        onboardingStatus: data?.onboarding_finished,
      });
    } else {
      setSession({
        ...session,
        onboardingStatus: false,
      });
    }
  };

  return (
    <SupabaseContext.Provider
      value={{
        session,
        logout,
      }}
    >
      {isLoading ? null : children}
    </SupabaseContext.Provider>
  );
};

export const useSupabase = () => {
  const context = useContext(SupabaseContext);

  if (context === undefined) {
    throw new Error(
      'useSupabaseSession must be used within a SupabaseSessionProvider'
    );
  }
  return context;
};
