import { ReactComponent as FilledHeartSVG } from '../../../src/svg/filledHeart.svg';
import { ReactComponent as HeartSVG } from '../../../src/svg/heart.svg';
import { ReactComponent as ShareSVG } from '../../../src/svg/share.svg';
import { useEventActions } from '../../hooks/useEventActions';
// import { useGetLikedEvents } from '../../hooks/useGetUserLikedEvents';
import { useSupabase } from '../../providers/supabase';
import { ShareOptions } from '../shareOptions/shareOptions';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

interface CardProps {
  event: Event;
  eventsLiked: Event | undefined;
  handleHeartClick: (title: string) => void;
  sessionType?: boolean;
  colorTitle?: string;
  liked?: boolean;
  color?: string;
  onShareOpen?: (isOpen: boolean) => void;
}

const Card = ({
  event,
  handleHeartClick,
  sessionType,
  onShareOpen,
  eventsLiked,
  liked,
  color = '#B8CAD9',
  colorTitle = '#000000',
}: CardProps) => {
  const { session } = useSupabase();
  const id = String(session?.user?.id || '');
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isHeartFilled, setIsHeartFilled] = useState(liked || false);
  const { setLike } = useEventActions({ userId: id || null });
  const shareOptionsRef = useRef<HTMLDivElement | null>(null);

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const newShowShareOptions = !showShareOptions;
    setShowShareOptions(newShowShareOptions);
    onShareOpen?.(newShowShareOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        shareOptionsRef.current &&
        !shareOptionsRef.current.contains(event.target as Node)
      ) {
        handleCloseShareOptions();
      }
    };

    if (showShareOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showShareOptions]);

  useEffect(() => {
    if (Array.isArray(eventsLiked)) {
      const isLiked = eventsLiked.some(
        (likedEvent: Event) => likedEvent?.id === event?.id
      );
      if (isLiked !== isHeartFilled) {
        setIsHeartFilled(isLiked);
      }
    }
  }, [eventsLiked, event?.id]);

  const toggleHeart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!session) {
      return;
    }
    setIsHeartFilled((prev) => {
      const newIsHeartFilled = !prev;
      setLike(event?.id, !newIsHeartFilled);

      if (newIsHeartFilled) {
        // The heart is full (true), the event is added
        handleHeartClick(event.name);
        console.log(`Event ${event.name} added to the list`);
      } else {
        // The heart is empty (false), the event is deleted
        console.log(`Event ${event.name} removed from the list`);
      }

      return newIsHeartFilled;
    });
  };

  // const toggleHeart = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation();
  //   setIsHeartFilled(!isHeartFilled);
  //   if (!isHeartFilled) {
  //     handleHeartClick(event.name);
  //   }
  // };

  const handleCloseShareOptions = (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setShowShareOptions(false);
    onShareOpen?.(false);
  };

  const handleCopyLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    const textToCopy = `https://cene.xyz/event-details/${event.id}`;
    navigator.clipboard
      .writeText(textToCopy)
      .catch((err) => console.error('Failed to copy link:', err));
  };

  return (
    <div
      className={classNames(
        'max-w-full w-full relative h-[458px] hover:opacity-70 hover:cursor-pointer transition-opacity duration-300'
      )}
    >
      <div className="max-w-full relative h-[336px] overflow-hidden">
        {showShareOptions && (
          <div
            ref={shareOptionsRef}
            onClick={(event) => event.stopPropagation()}
          >
            <ShareOptions
              onCopyLink={handleCopyLink}
              onClose={(e) => handleCloseShareOptions(e)}
              event={{
                id: event?.id,
                title: event.name,
                description: event.description,
                link: `https://cene.xyz/event-details/${event?.id}`,
              }}
            />
          </div>
        )}
        <img
          src={event.featured_image.url}
          alt={event.name}
          className="max-w-full rounded-tl-[30px] rounded-br-[30px]"
        />
        <div className="auto bg-card-gradient absolute w-full h-[110%] top-0 -bottom-3" />

        <div
          className={classNames(
            sessionType
              ? 'absolute top-4 left-4'
              : 'absolute top-4 left-1/2 -translate-x-1/2'
          )}
        >
          <span
            className={classNames(
              sessionType
                ? 'uppercase text-[0.875rem]/[0.875rem] font-light'
                : 'uppercase text-[0.625rem]/[0.625rem]'
            )}
            style={{ color: colorTitle }}
          >
            {event.category}
          </span>
        </div>
        <div
          className={classNames(
            'flex gap-[0.5rem] absolute z-10',
            sessionType
              ? 'right-[1.5rem] flex-col justify-center bottom-[1.5rem] mb-[1.5rem]'
              : 'left-1/2 -translate-x-1/2  items-center bottom-[16px] mb-[2rem]'
          )}
        >
          <button
            onClick={handleShareClick}
            className={classNames(
              'w-[48px] h-[48px] flex items-center justify-center',
              sessionType ? '' : 'bg-generic-06 rounded-[24px]'
            )}
          >
            <ShareSVG />
          </button>
          {isHeartFilled ? (
            <button
              onClick={toggleHeart}
              className={classNames(
                'w-[48px] h-[48px] flex items-center justify-center',
                sessionType ? '' : 'bg-generic-06 rounded-[24px]'
              )}
            >
              <FilledHeartSVG style={{ fill: color }} />
            </button>
          ) : (
            <button
              onClick={toggleHeart}
              className="w-[48px] h-[48px] flex items-center justify-center bg-generic-06 rounded-[24px]"
            >
              <HeartSVG />
            </button>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'w-full flex flex-col mt-[1rem] absolute left-1/2 -translate-x-1/2 bottom-1',
          sessionType ? 'items-start text-start' : 'items-center text-center'
        )}
      >
        <h3
          title={event.name}
          style={{ color: color }}
          className={classNames(
            'text-[2rem]/[2.5rem] font-light truncate max-w-full'
          )}
        >
          {event.name}
        </h3>
        <div
          className={classNames(
            'mt-[1rem] w-full',
            'text-[0.875rem]/[0.875] font-light',
            sessionType ? 'text-white' : 'text-my-ticket'
          )}
        >
          <p title={event.address} className="truncate h-[14px] max-w-full">
            {event.address}
          </p>
          <p className={classNames('mt-[0.25rem]')}></p>
          <p className={classNames(' mt-[0.5rem]')}>
            {moment(event.starts_at, 'YYYY-MM-DD HH:mm').format(
              'dddd, MMMM D · HH:mm'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
