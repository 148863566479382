import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import { formatEventDateSucess } from '../../helpers/formatDate';
import classNames from 'classnames';
import React from 'react';

interface StepOneProps {
  nextStep: () => void;
  session?: string | undefined;
  firstImage: string;
  title: string | undefined;
  place?: string;
  startsAt: string | undefined;
}

export const StepOne: React.FC<StepOneProps> = ({
  nextStep,
  session,
  firstImage,
  place,
  startsAt,
  title,
}) => {
  const sessionType = session === 'cene';
  const formattedDate = formatEventDateSucess(startsAt || '');

  return (
    <div>
      <h1 className="text-[2rem] leading-[40px] text-white font-light">
        {translations.paymentSuccess.info}
      </h1>
      <h2
        className={classNames(
          ['text-[1.3125rem] leading-[21px] font-light'],
          sessionType ? 'text-event ' : 'text-generic'
        )}
      >
        {translations.paymentSuccess.infoTwo}
      </h2>
      <div className="relative my-[40px] lg:w-[422px] lg:h-[547px] w-full h-auto mx-auto overflow-hidden rounded-tl-[30px] rounded-br-[30px]">
        <img
          src={firstImage}
          alt="Profile"
          className="w-full lg:w-[422px] h-auto rounded-tl-[30px] rounded-br-[30px] max-w-[422px]"
        />
        <div className="auto bg-faq-tab-gradient absolute w-full h-[110%] top-0 -bottom-3" />
        <div className="absolute left-[20px] top-[20px] text-black">
          <p className=" text-start text-[0.875rem] leading-[14px] text-white font-light whitespace-pre-line mb-[16px] lg:w-[100px]">
            {place}
          </p>
          <p className=" text-start text-[0.875rem] leading-[14px] text-white font-light whitespace-pre-line lg:w-[80px]">
            {formattedDate}
          </p>
        </div>
        <div className="absolute left-[20px] bottom-[20px] text-white text-start">
          <p className="text-[0.75rem] leading-[12px] font-normal mb-1">
            {translations.paymentSuccess.type}
          </p>
          <p className="text-[2rem] leading-[40px] font-light">{title}</p>
        </div>
      </div>
      <Button
        className={classNames([
          'w-full h-[48px] !mb-[16px]',
          sessionType ? 'bg-event' : 'bg-generic',
        ])}
        onClick={nextStep}
      >
        <p className="font-medium text-[1rem] leading-[24px]">
          {translations.paymentSuccess.next}
        </p>
      </Button>
    </div>
  );
};
