import React from 'react';

interface MediaDisplayProps {
  isVideo: boolean;
  imageSrc: { type: string; src: string }[];
  currentIndex: number;
}

export const MediaDisplay = ({
  isVideo,
  imageSrc,
  currentIndex,
}: MediaDisplayProps) => {
  const currentMedia = imageSrc[currentIndex];

  return (
    <div className="relative w-full h-full">
      {isVideo ? (
        <video
          src={currentMedia.src}
          className="w-full object-cover rounded-tl-[32px]"
          controls
          autoPlay
        />
      ) : (
        <img
          src={currentMedia.src}
          alt={`Media ${currentIndex}`}
          className="w-full object-cover rounded-tl-[32px]"
        />
      )}
    </div>
  );
};
