import { Button } from '../../components/button/button';
import { ROUTER_REGISTER_CENE_PLUS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Phone = '/pictures/makeCountBanner.png';

export const MakeTicketSection = () => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames([
        'relative bg-generic h-[520px] rounded-tl-[30px] rounded-br-[30px]  w-full flex flex-col ',
        'lg:w-[1086px] lg:justify-between lg:flex-row',
      ])}
    >
      <div
        className={classNames([
          ' flex flex-col items-center mt-[32px]',
          ' lg:ml-[91px] lg:mr-[90px] lg:mt-[135px] lg:items-start  lg:flex-row lg:w-full  lg:h-auto ',
        ])}
      >
        <div
          className={classNames([
            'text-left w-auto px-[24px] lg:px-0 h-[114px]',
            'lg:text-left lg:w-[588px] lg:mr-[90px]',
          ])}
        >
          <h2
            className={classNames([
              'text-black text-[1.5rem]  leading-[36px] font-light',
              'lg:leading-[56px] lg:text-[3rem]',
            ])}
          >
            {translations.personalized.title}
          </h2>
          <p className="lg:mt-[24px] mt-[16px] font-light lg:leading-[21px] lg:text-[1.3125rem] leading-[19px] text-[1.1875rem]">
            {translations.personalized.desc}
          </p>
          <div className="flex lg:mt-[24px] mt-[16px] z-10 relative">
            <Button
              type="button"
              className="lg:w-[182px] lg:h-[48px] w-[182px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px] bg-gray-medium text-white"
              onClick={() => navigate(ROUTER_REGISTER_CENE_PLUS)}
            >
              {translations.personalized.buttonText}
            </Button>
          </div>
        </div>
        <img
          src={Phone}
          alt="Phone"
          className={classNames([
            'w-[330px] h-[260px] absolute bottom-0',
            'lg:top-[20px] lg:mt-[0] mx-auto lg:w-auto  lg:right-[-30px] lg:h-[500px] ',
          ])}
        />
      </div>
    </div>
  );
};
