import { Button } from '../../../components/button/button';
import FaviconManager from '../../../components/faviconManager/faviconManager';
import { ROUTE_AUTH, ROUTE_LOGIN } from '../../../constants/routes';
import translation from '../../../constants/translations.json';
import { CreateNewPasswordForm } from '../../../forms/createNewPassword/cene/createNewPasswordForm';
import { CreateNewPasswordForm as CreateNewPasswordCenePlusForm } from '../../../forms/createNewPassword/cenePlus/createNewPasswordForm';
import { useSupabase } from '../../../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../../supabase/supabase';
import { ReactComponent as CloseSVG } from '../../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../../svg/logotype.svg';
import { ReactComponent as SuccessSVG } from '../../../svg/successful.svg';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateNewPasswordPage = () => {
  const navigate = useNavigate();
  const { session } = useSupabase();
  const [isSubmit, setSubmit] = useState(false);
  const isCenePlus = session?.user.user_metadata.mobileApp === 'Cene+';

  console.log('session metadata', session?.user.user_metadata.mobileApp);

  const handleSubmit = () => {
    setSubmit(!isSubmit);
  };

  const gotoBack = useCallback(() => {
    isCenePlus
      ? supabaseCenePlusClient.auth.signOut()
      : supabaseCeneClient.auth.signOut();

    navigate(ROUTE_AUTH);
  }, [isCenePlus]);

  const backToLogin = useCallback(() => {
    isCenePlus
      ? supabaseCenePlusClient.auth.signOut()
      : supabaseCeneClient.auth.signOut();

    navigate(ROUTE_LOGIN, {
      state: { type: isCenePlus ? 'cenePlus' : 'cene' },
    });
  }, [isCenePlus]);

  return (
    <>
      <FaviconManager isCenePlus={isCenePlus} />
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between px-[20px]',
        ])}
      >
        {isCenePlus ? (
          <LogoPlusSVG className="w-[164px] h-[48px]" />
        ) : (
          <LogoSVG className="w-[164px] h-[48px]" />
        )}
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-big"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center max-w-[422px]">
        {isSubmit ? (
          <div
            className={classNames([
              'h-[calc(100vh-88px)] flex items-center justify-center text-center flex-col',
              'w-full px-[39px] sm:px-0 lg:w-[292px]',
            ])}
          >
            <SuccessSVG
              className={classNames([
                'w-[72px] h-[72px]',
                isCenePlus ? 'fill-generic' : 'fill-content',
              ])}
            />

            <h1
              className={classNames([
                'text-h1 text-white font-light my-[12px]',
                isCenePlus ? 'text-generic' : 'text-white',
              ])}
            >
              {translation.createNewPasswordPage.resetSuccessTitle}
            </h1>
            <p className="text-h6 text-generic font-light">
              {translation.createNewPasswordPage.resetSuccessDescription}
            </p>
            <Button
              color={isCenePlus ? 'generic' : 'content'}
              className="mt-[40px] w-full"
              onClick={backToLogin}
            >
              {translation.createNewPasswordPage.continue}
            </Button>
          </div>
        ) : isCenePlus ? (
          <CreateNewPasswordCenePlusForm onSubmit={handleSubmit} />
        ) : (
          <CreateNewPasswordForm onSubmit={handleSubmit} />
        )}
      </div>
    </>
  );
};
