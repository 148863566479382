import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import { useState } from 'react';

export const useFetchTicketsAttended = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchAttendedTickets = async (isCene: boolean) => {
    setLoading(true);
    try {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;
      const { data, error } = await client.from('tickets').select('*');
      const now = new Date();
      const finishedEvents =
        data?.filter((event: Event) => new Date(event?.ends_at || '') < now) ||
        [];
      if (error) throw error;
      setEvents(finishedEvents);
    } catch (error) {
      console.error(
        `Error fetching ${isCene ? 'Cene' : 'CenePlus'} events:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  return { events, isLoading, fetchAttendedTickets };
};
