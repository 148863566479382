import translations from '../../constants/translations.json';
import classNames from 'classnames';

const City = '/pictures/aboutPageImg.png';
const Map = '/pictures/map.png';

export const StorySection = () => {
  return (
    <div
      className={classNames(['w-full h-auto flex flex-col', 'lg:bg-ourStory '])}
    >
      <div className={classNames([' w-full mt-[56px]', 'lg:mt-[112px]'])}>
        <div className="flex flex-col lg:flex-row justify-start ">
          <h1
            className={classNames([
              'text-event text-[2.25rem] leading-[44px] font-light ',
              'lg:text-[4.5rem] lg:leading-[80px] whitespace-nowrap',
            ])}
          >
            {translations.aboutPage.titleStory}
          </h1>
          <h2
            className={classNames([
              'text-white font-light w-full h-auto lg:text-[1.3125rem] lg:leading-[21px] whitespace-pre-line mt-[24px]  text-[1.1875rem] leading-[19px]',
              ' lg:ml-[360px] lg:w-[534px] lg:h-[210px] lg:mt-0',
            ])}
          >
            {translations.aboutPage.titleAbout}
          </h2>
          <img src={Map} alt="Map" className="w-full lg:hidden " />
        </div>
      </div>
      <div
        className={classNames([
          'flex flex-col  justify-between  w-full',
          'lg:flex-row lg:mb-[200px]',
        ])}
      >
        <div>
          <p
            className={classNames([
              'text-ticket w-full h-auto font-light lg:text-[2rem] lg:leading-[40px] lg:mb-0 mb-[56px] text-[1.125rem] leading-[26px]',
              'lg:w-[501px] lg:h-[200px] lg:mt-[550px]',
            ])}
          >
            {translations.aboutPage.textPartnerships}
          </p>
        </div>
        <div>
          <img
            src={City}
            alt="City"
            className={classNames([
              'w-auto  h-auto  mt-[40px] mb-[30.63px]',
              'lg:max-w-[429px] lg:mt-[280px] lg:ml-[170px]  lg:h-[429px]  ',
            ])}
          />
        </div>
      </div>
    </div>
  );
};
