import translations from '../../constants/translations.json';
import { FeatureTitle } from './featureTitle';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const PhoneImages = [
  '/pictures/phoneBannerOne.png',
  '/pictures/phoneBannerTwo.png',
  '/pictures/phoneBannerThree.png',
];

type Feature = {
  title: string;
  titleTwo: string;
  titleThree: string;
  desc: string;
};

export const FeatureList: React.FC = () => {
  const [progressPercent, setProgressPercent] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const infoFeatures: Feature[] = translations.infoFeaturesMembership.info;
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (!isSmallScreen) {
      const interval = setInterval(() => {
        setProgressPercent((prev) => (prev + 1) % 101);
      }, 200);

      return () => clearInterval(interval);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (!isSmallScreen && progressPercent === 100) {
      const nextIndex = (activeIndex + 1) % infoFeatures.length;
      setActiveIndex(nextIndex);
      setProgressPercent(0);
    }
  }, [progressPercent, activeIndex, infoFeatures.length, isSmallScreen]);

  const resetProgress = () => {
    setProgressPercent(0);
  };

  return (
    <div className="flex flex-col w-full justify-center xl:px-0 xl:flex-row lg::w-[1098px] lg:gap-[136px] gap-0">
      <div className="w-full">
        <div className="flex-wrap mb-6 lg:mb-14 hidden xl:flex">
          <div
            className={classNames(
              'rounded-full cursor-pointer relative',
              {
                'bg-gray-medium': progressPercent !== 100,
                'bg-white': progressPercent === 100,
              },
              'w-full h-[1px]'
            )}
          >
            <div
              className="absolute bg-white h-full"
              style={{ width: `${progressPercent}%` }}
            ></div>
          </div>
        </div>
        <div>
          {infoFeatures.map((feature, index) => {
            const titles = [
              feature.title,
              feature.titleTwo,
              feature.titleThree,
            ];
            return (
              <FeatureTitle
                key={index}
                index={index}
                title={titles[index]}
                description={feature.desc}
                activeTitle={isSmallScreen ? index : activeIndex}
                setActiveTitle={setActiveIndex}
                isLast={index === infoFeatures.length - 1}
                resetProgress={resetProgress}
              />
            );
          })}
        </div>
      </div>
      <div className="w-full justify-center lg:mb-8 xl:mb-0 xl:justify-end lg:mt-[22px]">
        <img
          src={PhoneImages[activeIndex]}
          alt="Phone"
          className={classNames(
            'w-[533px] h-[533px] max-w-xs xl:mt-0 hidden xl:flex xl:max-w-none mt-[40px]'
          )}
        />
      </div>
    </div>
  );
};
