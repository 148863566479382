import React from 'react';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import { ReactComponent as CloseModalSvg } from '../../../src/svg/closeModalGeneric.svg';

interface HeaderSectionProps {
  sessionType: boolean;
  handleCloseClick: () => void;
}

export const HeaderPayment: React.FC<HeaderSectionProps> = ({ sessionType, handleCloseClick }) => {
  return (
    <div className="flex w-full justify-between py-[16px] lg:py-[20px] lg:px-[64px] lg:border-none border border-b-gray-medium">
      {sessionType ? (
        <LogoSVG className="w-[137px] lg:w-[164px]" />
      ) : (
        <LogoPlusSVG className="w-[137px] lg:w-[164px]" />
      )}
      <button
        className="flex justify-center items-center bg-gray-dark rounded-tl-[8px] rounded-br-[8px] w-[48px] h-[48px]"
        onClick={handleCloseClick}
      >
        <CloseModalSvg className="w-[24px] h-[24px]" />
      </button>
    </div>
  );
};
