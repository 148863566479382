import { Button } from '../../../../components/button/button';
import {
  DateOfBirthSelect,
  months,
} from '../../../../components/dateOfBirthSelect/dateOfBirthSelect';
import { Label } from '../../../../components/label/cene/label';
import translation from '../../../../constants/translations.json';
import { RegisterSchema } from '../../../../schemas/registerSchema';
import { ReactComponent as RadioUncheckedSVG } from '../../../../svg/radio-unchecked.svg';
import { ReactComponent as RadioCheckedSVG } from '../../../../svg/radiobuttonGeneric.svg';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

interface FormProps {
  onNext: () => void;
}

interface IDate {
  day: string;
  month: string;
  year: string;
}

export const GenderAndBirthForm = ({ onNext }: FormProps) => {
  const formik = useFormikContext<RegisterSchema>();

  const getMonthLabel = (monthName: string) => {
    const month = months.find(
      (m) => m.value.toLowerCase() === monthName.toLowerCase()
    );
    return month ? month.label : '';
  };

  const formatDate = (date: IDate) => {
    const { day, month, year } = date;

    const formattedDay = day.padStart(2, '0');
    const formattedMonth = month.padStart(2, '0');

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const handleDateChange = (date: IDate) => {
    const monthLabel = getMonthLabel(date.month);

    const formattedDate: IDate = {
      day: date.day,
      month: monthLabel,
      year: date.year,
    };

    const dateString = formatDate(formattedDate);
    formik.setFieldValue('dateOfBirth', dateString);
  };

  const handleGenderSelect = (gender: string) => {
    formik.setFieldValue('gender', gender);
  };

  const isGenderValid = formik.values.gender ? !formik.errors.gender : false;

  // const isDateOfBirthValid = formik.values.dateOfBirth
  //   ? !formik.errors.dateOfBirth
  //   : false;

  const isFormValid = isGenderValid;
  return (
    <div
      className={classNames(['pt-[24px]', 'transition-all', 'duration-500'])}
    >
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.genderAndBirthStep.titleCene}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.genderAndBirthStep.descriptionCene}
      </p>
      <div
        className={classNames([
          'flex flex-col gap-[40px]',
          'max-lg:gap-[24px]',
        ])}
      >
        <div className="flex flex-col">
          <Label id="gender" isRequired={true}>
            {translation.steps.genderAndBirthStep.gender}
          </Label>
          <div className="flex gap-[8px]">
            <div
              className={classNames([
                'flex cursor-pointer p-4 text-h4 font-light text-white border border-gray-medium rounded-tl-[30px] rounded-br-[30px] mb-[10px] justify-between items-center w-1/2',
                formik.values.gender === 'Male'
                  ? 'border-blue-500'
                  : 'border-gray-medium',
              ])}
              onClick={() => handleGenderSelect('Male')}
            >
              <div className="flex text-h4">Male</div>
              <span>
                {formik.values.gender === 'Male' ? (
                  <RadioCheckedSVG />
                ) : (
                  <RadioUncheckedSVG />
                )}
              </span>
            </div>
            <div
              className={classNames([
                'flex cursor-pointer p-4 text-h4 font-light text-white border border-gray-medium rounded-tl-[30px] rounded-br-[30px] mb-[10px] justify-between items-center w-1/2',
                formik.values.gender === 'Female'
                  ? 'border-blue-500'
                  : 'border-gray-medium',
              ])}
              onClick={() => handleGenderSelect('Female')}
            >
              <div className="flex text-h4">Female</div>
              <span>
                {formik.values.gender === 'Female' ? (
                  <RadioCheckedSVG />
                ) : (
                  <RadioUncheckedSVG />
                )}
              </span>
            </div>
          </div>
          <div
            className={classNames([
              'flex cursor-pointer p-4 text-h4 font-light text-white border border-gray-medium rounded-tl-[30px] rounded-br-[30px] mb-[10px] justify-between items-center w-1/2',
              formik.values.gender === 'Prefer not to say'
                ? 'border-blue-500'
                : 'border-gray-medium',
            ])}
            onClick={() => handleGenderSelect('Prefer not to say')}
          >
            <div className="flex text-h4">Prefer not to say</div>
            <span>
              {formik.values.gender === 'Prefer not to say' ? (
                <RadioCheckedSVG />
              ) : (
                <RadioUncheckedSVG />
              )}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Label id="dateOfBirth">
            {translation.steps.genderAndBirthStep.dateOfBirth}
          </Label>
          <DateOfBirthSelect onDateChange={handleDateChange} isPlus={true} />
        </div>
      </div>
      <Button
        color="generic"
        type="button"
        className={classNames(['w-full mt-[40px]'])}
        onClick={onNext}
        disabled={!isFormValid}
      >
        {translation.next}
      </Button>
    </div>
  );
};
