import { ROUTE_FAQ } from '../../constants/routes';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface JustSectionProps {
  title: string;
  description: string;
  typeMembership?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  typeCareers?: boolean;
}

export const JustSection: React.FC<JustSectionProps> = ({
  title,
  description,
  typeMembership,
  isFirst = false,
  isLast = false,
  typeCareers = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  const modifiedDescription = () => {
    const appLink = (
      <a
        href="https://apps.apple.com/ru/developer/apple/id284417353?mt=12"
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        {translations.questions.app}
      </a>
    );

    const emailLink = (
      <a href="mailto:support@cene.xyz" className="underline text-blue-500">
        support@cene.xyz
      </a>
    );

    if (description.includes('manage your membership details')) {
      return (
        <>
          {translations.questions.link}
          {appLink}.
        </>
      );
    }

    // Split the description into parts, including “FAQs” and “support@cene.xyz”
    const parts = description.split(/(FAQs|support@cene\.xyz)/);

    return parts.reduce((acc, part, index) => {
      acc.push(<React.Fragment key={`part-${index}`}>{part}</React.Fragment>);

      if (part === 'support@cene.xyz') {
        acc.pop();
        acc.push(
          <React.Fragment key={`email-link-${index}`}>
            {emailLink}
          </React.Fragment>
        );
      }

      if (part === 'FAQs') {
        acc.pop();
        acc.push(
          <Link
            key={`faq-link-${index}`}
            to={ROUTE_FAQ}
            className="text-blue-500 underline"
          >
            FAQs
          </Link>
        );
      }

      return acc;
    }, [] as React.ReactNode[]);
  };

  return (
    <div className="w-full h-auto flex flex-col justify-center items-center lg:px-[111px]">
      <div
        className={classNames(
          'border-t border-gray-medium rounded-lg pt-8 pb-8',
          {
            'border-t border-t-gray-medium': isFirst,
            'border-b border-b-gray-medium': isLast,
            'border-t-white border-t': isOpen,
          },
          'w-full sm:w-full'
        )}
        onClick={toggleSection}
      >
        <div className="flex justify-between items-center">
          <h2
            className={classNames(
              'text-[1.1875rem] font-light leading-[19px]',
              {
                'text-village': isOpen && !typeMembership && !typeCareers,
                'text-event': isOpen && typeCareers,
                'text-white': !isOpen || (isOpen && typeMembership),
              },
              'lg:text-[1.3125rem] lg:leading-[21px]'
            )}
          >
            {title}
          </h2>
          <div>
            <button
              className={classNames([
                `w-[44px] h-[44px] flex justify-center items-center font-light text-[1rem] rounded-none rounded-tl-[20px] rounded-br-[20px]`,
                isOpen
                  ? typeMembership
                    ? 'bg-white text-black'
                    : typeCareers
                      ? 'bg-event text-black'
                      : 'bg-village text-black'
                  : 'bg-gray-dark text-white',
                'sm:text-[1.125rem] sm:w-11 sm:h-11',
              ])}
            >
              {isOpen ? '-' : '+'}
            </button>
          </div>
        </div>
        {isOpen && (
          <p className="mt-4 lg:text-[1.0625rem] lg:leading-[22px] text-[1rem] leading-[21px] font-light text-white whitespace-pre-line">
            {modifiedDescription()}
          </p>
        )}
      </div>
    </div>
  );
};
