import {
  ROUTER_MY_BASKET,
  ROUTER_MY_EVENTS,
  ROUTER_MY_TICKETS,
  ROUTE_AUTH, // ROUTE_DOWNLOAD,
  ROUTE_EVENTS,
  ROUTE_EVENTS_PLUS,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_PROFILE,
} from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useCeneMode } from '../../providers/ceneMode';
import { useSupabase } from '../../providers/supabase';
import { ProfileMenu } from '../../sections/header/profileMenu';
import { ReactComponent as BasketSVG } from '../../svg/basket-icon.svg';
import { ReactComponent as BurgerMenuSVG } from '../../svg/burger-menu.svg';
import { ReactComponent as HeartSVG } from '../../svg/heartIcon.svg';
import { ReactComponent as CloseMenuSVG } from '../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import { ReactComponent as ProfileSVG } from '../../svg/profileIcon.svg';
import { ReactComponent as SettingsSVG } from '../../svg/settings.svg';
import { ReactComponent as LogOutSVG } from '../../svg/signOutIcon.svg';
import { ReactComponent as TicketSVG } from '../../svg/ticket-menu.svg';
import { DropdownOpen } from './dropdownOpen';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link, To, useLocation, useNavigate } from 'react-router-dom';

export const Header = () => {
  const { ceneMode, setMode } = useCeneMode();
  const { session, logout } = useSupabase();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [dropdownStates, setDropdownStates] = useState<{
    [key: number]: boolean;
  }>({});
  const dropdownRefs = useRef<Record<number, HTMLDivElement | null>>({});
  const profileMenuRefMobile = useRef<HTMLDivElement | null>(null);
  const profileMenuRefDesktop = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickInsideMobileMenu =
        profileMenuRefMobile.current &&
        profileMenuRefMobile.current.contains(event.target as Node);

      const clickInsideDesktopMenu =
        profileMenuRefDesktop.current &&
        profileMenuRefDesktop.current.contains(event.target as Node);

      if (!clickInsideMobileMenu && !clickInsideDesktopMenu) {
        setProfileMenuOpen(false);
      }

      Object.keys(dropdownRefs.current).forEach((key) => {
        const id = Number(key);
        if (
          dropdownRefs.current[id] &&
          !dropdownRefs.current[id]?.contains(event.target as Node)
        ) {
          setDropdownStates((prev) => ({
            ...prev,
            [id]: false,
          }));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const updateOverflow = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth > 1279) {
        document.body.style.overflow = 'auto';
      } else {
        const newOverflow = isOpen ? 'hidden' : 'auto';
        document.body.style.overflow = newOverflow;
      }
    };

    updateOverflow();
    window.addEventListener('resize', updateOverflow);

    return () => window.removeEventListener('resize', updateOverflow);
  }, [isOpen]);

  const route =
    session?.type === 'cene' || session === null
      ? ROUTE_EVENTS
      : ROUTE_EVENTS_PLUS;

  const isEventPage =
    location.pathname === '/explore-events' ||
    location.pathname === '/explore-events-plus';
  const isHome = location.pathname === '/';
  // const onboarding =
  //   session?.onboardingStatus ||
  //   ['google', 'apple'].includes(session?.user?.app_metadata?.provider || '');
  const isCeneUser = session?.type === 'cene' || session?.type === 'cene+';

  const menuItems = DropdownOpen(dropdownStates, setDropdownStates, isCeneUser);

  const checkLogo =
    (session?.type === 'cene' && session?.onboardingStatus === true) ||
    session === null ||
    !session?.onboardingStatus;

  const checkLogoType =
    (session && session.onboardingStatus && checkLogo) ||
    (!session && ceneMode === 'cene');

  const handleClick = () => {
    setIsOpen(!isOpen);

    if (window.innerWidth <= 1279) {
      document.body.style.overflow = !isOpen ? 'hidden' : 'auto';
    }
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    setDropdownStates({});
    setProfileMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleProfileClick = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const handleLogout = async () => {
    await logout();
    navigate(ROUTE_LOGIN);
  };

  const getProfileMenuItems = () => [
    {
      title: translations.header.profile,
      href: ROUTE_PROFILE,
      icon: <ProfileSVG />,
    },
    {
      title: translations.header.myTickets,
      href: ROUTER_MY_TICKETS,
      icon: <TicketSVG style={{ stroke: '#B8CAD9', width: '20px' }} />,
    },
    {
      title: translations.header.myEvents,
      href: ROUTER_MY_EVENTS,
      icon: <HeartSVG />,
    },
    {
      title: translations.header.basket,
      href: ROUTER_MY_BASKET,
      icon: (
        <BasketSVG
          style={{
            stroke: '#B8CAD9',
          }}
        />
      ),
    },
    {
      title: translations.header.settings,
      href: '/settings',
      icon: <SettingsSVG />,
    },
  ];

  const mapProfileMenu = (): JSX.Element => (
    <div>
      {getProfileMenuItems().map(({ title, href, icon }, index) =>
        href ? (
          <Link
            key={index}
            to={href as To}
            onClick={handleLinkClick}
            className="whitespace-nowrap px-[24px] py-[12px] text-generic hover:bg-gray-medium w-full rounded-tl-[20px] rounded-br-[20px] bg-gray-dark text-left mb-[16px] flex items-center"
          >
            {icon && <span className="mr-[10px]">{icon}</span>}
            {title}
          </Link>
        ) : null
      )}
      <button
        onClick={handleLogout}
        className="whitespace-nowrap px-[24px] py-[12px] text-generic hover:bg-gray-medium w-full rounded-tl-[20px] rounded-br-[20px] bg-gray-dark text-left flex items-center"
      >
        <LogOutSVG className="mr-2" />
        {translations.header.logOut}
      </button>
    </div>
  );

  return (
    <header
      className={classNames([
        'z-100 mx-auto flex py-[20px] justify-between max-xl:bg-black xl:bg-none xl:relative  xl:z-50',
      ])}
    >
      {checkLogoType ? (
        <LogoSVG
          onClick={() => navigate(ROUTE_HOME)}
          className={classNames([
            'min-w-[164px] min-h-[48px] max-w-[164px] max-h-[48px]',
            'max-lg:min-w-[137px] max-lg:min-h-[40px] max-lg:max-w-[137px] max-lg:max-h-[40px]',
          ])}
          style={{ zIndex: 20 }}
        />
      ) : (
        <LogoPlusSVG
          onClick={() => {
            navigate(ROUTE_HOME);
            setMode('cene');
          }}
          className={classNames([
            'min-w-[164px] min-h-[48px] max-w-[164px] max-h-[48px]',
            'max-lg:min-w-[137px] max-lg:min-h-[40px] max-lg:max-w-[137px] max-lg:max-h-[40px]',
          ])}
          style={{ zIndex: 20 }}
        />
      )}
      <div className="flex flex-row items-center justify-end xl:hidden w-full">
        <div
          onClick={handleClick}
          className={classNames([
            'hidden cursor-pointer w-[40px] h-[40px] rounded-tl-[16px] rounded-br-[16px] border border-gray-medium items-center justify-center p-[8px]',
            'max-xl:flex',
          ])}
          style={{ zIndex: 50 }}
        >
          {isOpen ? <CloseMenuSVG stroke="#B8CAD9" /> : <BurgerMenuSVG />}
        </div>
        {isCeneUser && (
          <>
            <ProfileMenu
              isProfileMenuOpen={profileMenuOpen}
              handleProfileClick={handleProfileClick}
              profileMenuRef={profileMenuRefMobile}
              mapProfileMenu={mapProfileMenu}
              isBurgerMenuOpen={isOpen}
            />
          </>
        )}
      </div>
      {profileMenuOpen ? (
        <div className="lg:hidden xl:hidden w-full h-[1px] bg-gray-medium absolute mt-[64px]" />
      ) : (
        <></>
      )}

      <div
        className={classNames([
          'flex items-center gap-[8px] cursor-pointer',
          'max-xl:absolute max-xl:w-full max-xl:top-0 max-xl:pt-[104px] max-xl:flex-col max-xl:h-full max-xl:bg-black max-xl:z-50 max-xl:px-[20px] max-xl:left-0 max-xl:right-0 max-xl:gap-[16px]',
          isOpen ? 'max-xl:flex-col' : 'max-xl:hidden',
        ])}
      >
        <Link
          to={ROUTE_HOME}
          className={classNames([
            ' h-[48px] font-medium text-[1rem] px-[20px] py-[12px] ',
            'max-md:w-full text-center',
            'max-xl:w-1/2',
            'rounded-tl-[20px] rounded-br-[20px]',
            isHome
              ? 'bg-event text-black'
              : 'bg-gray-dark text-generic hover:bg-gray-medium',
          ])}
        >
          {translations.header.home}
        </Link>
        <Link
          to={route}
          onClick={() => {
            handleLinkClick;
            setMode('cene');
          }}
          className={classNames([
            ' h-[48px] font-medium text-[1rem]  px-[20px] py-[12px] ',
            'max-md:w-full text-center',
            'max-xl:w-1/2',
            'rounded-tl-[20px] rounded-br-[20px]',
            isEventPage
              ? 'bg-event text-black'
              : 'bg-gray-dark text-generic hover:bg-gray-medium',
          ])}
        >
          {translations.header.events}
        </Link>
        {menuItems}
        {!isCeneUser && (
          <Link
            to={ROUTE_AUTH}
            onClick={() => {
              handleLinkClick;
              setMode('cene');
            }}
            className={classNames([
              'bg-content h-[48px] font-medium text-[1rem] text-black px-[20px] py-[12px] ',
              'max-md:w-full text-center',
              'max-xl:w-1/2',
              'rounded-tl-[20px] rounded-br-[20px]',
            ])}
          >
            {translations.header.authorization}
          </Link>
        )}
        {/* {!isCeneUser && (
          <Link
            to={ROUTE_DOWNLOAD}
            onClick={() => {
              handleLinkClick;
              setMode('cene');
            }}
            className={classNames([
              'bg-content h-[48px] font-medium text-[1rem] text-black px-[20px] py-[12px] ',
              'max-md:w-full text-center',
              'max-xl:w-1/2',
              'rounded-tl-[20px] rounded-br-[20px]',
            ])}
          >
            {translations.header.donwloadApp}
          </Link>
        )} */}
        {isCeneUser && (
          <ProfileMenu
            isProfileMenuOpen={profileMenuOpen}
            handleProfileClick={handleProfileClick}
            profileMenuRef={profileMenuRefDesktop}
            isBurgerMenuOpen={isOpen}
            mapProfileMenu={mapProfileMenu}
          />
        )}
      </div>
    </header>
  );
};
