import {
  ROUTER_ABOUT,
  // ROUTE_AUTH,
  ROUTE_BURST,
  ROUTE_CONTACT_WITH_US,
  ROUTE_FAQ,
  // ROUTE_LOGIN,
  ROUTE_MEMBERSHIP,
  ROUTE_PARTNER_WITH_US,
  ROUTE_TICKETING,
} from '../../constants/routes';
import translations from '../../constants/translations.json';
import { ReactComponent as ArrowDownSVG } from '../../svg/arrow-down.svg';
import { ReactComponent as BlackArrowDownSVG } from '../../svg/blackArrowDown.svg';
import classNames from 'classnames';
import { Link, To } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const menuGroups = [
  {
    id: 1,
    title: translations.header.cene,
    links: [
      {
        title: translations.header.whoWeAre,
        href: ROUTER_ABOUT,
      },
      {
        title: translations.header.membership,
        href: ROUTE_MEMBERSHIP,
      },
      {
        title: translations.header.contact,
        href: ROUTE_CONTACT_WITH_US,
      },
      {
        title: translations.header.faq,
        href: ROUTE_FAQ,
      },
    ],
  },
  {
    id: 2,
    title: translations.header.cenePartners,
    links: [
      { title: translations.header.burstOntoTheCene, href: ROUTE_BURST },
      {
        title: translations.header.becomeCenePartner,
        href: ROUTE_PARTNER_WITH_US,
      },
      {
        title: translations.header.moreThanTicketing,
        href: ROUTE_TICKETING,
      },
    ],
  },
  // {
  //   id: 5,
  //   title: translations.header.authorization,
  //   href: ROUTE_AUTH,
  // },
];

export const DropdownOpen = (
  dropdownStates: { [key: number]: boolean },
  setDropdownStates: React.Dispatch<
    React.SetStateAction<{ [key: number]: boolean }>
  >,
  isCeneUser: boolean
) => {
  const location = useLocation();

  const filteredMenuGroups = isCeneUser
    ? menuGroups.filter((group) => group.id !== 5)
    : menuGroups;

  const handleDropdownClick = (id: number) => {
    setDropdownStates((prevState) => {
      const newState = Object.keys(prevState).reduce(
        (acc, key) => {
          acc[parseInt(key)] = false;
          return acc;
        },
        {} as { [key: number]: boolean }
      );
      newState[id] = !prevState[id];
      return newState;
    });
  };

  return filteredMenuGroups.map(({ id, title, links }) => {
    const isDropdown = Array.isArray(links);
    const isSelected =
      isDropdown &&
      links.findIndex((item) => item.href === location.pathname) !== -1;
    const isDropdownOpen = dropdownStates[id];

    return (
      <div
        key={id}
        className="xl:relative max-md:w-full max-xl:w-1/2 text-center max-xl:rounded-tl-[20px] max-xl:rounded-br-[20px] max-xl:bg-gray-dark  "
      >
        {isDropdown ? (
          <>
            <div
              onClick={() => handleDropdownClick(id)}
              className={classNames([
                'h-[48px] font-medium text-[1rem] px-[20px] py-[12px] text-nowrap gap-2 overflow-hidden  ',
                'max-xl:w-full flex justify-center items-center',
                'rounded-tl-[20px] rounded-br-[20px]',
                location.pathname === '/'
                  ? 'bg-gray-dark text-generic hover:bg-gray-medium '
                  : isSelected
                    ? 'bg-event text-black'
                    : 'bg-gray-dark text-generic hover:bg-gray-medium',
                isDropdownOpen && !isSelected
                  ? 'bg-gray-medium text-generic'
                  : 'bg-event text-black',
              ])}
            >
              {title}
              {isSelected ? (
                <BlackArrowDownSVG
                  className={`transition-all ${dropdownStates[id] ? 'rotate-180' : ''}`}
                />
              ) : (
                <ArrowDownSVG
                  className={`transition-all ${dropdownStates[id] ? 'rotate-180' : ''}`}
                />
              )}
            </div>
            {dropdownStates[id] && (
              <div className="xl:absolute z-20 flex flex-col min-w-[188px] xl:mt-2 w-full overflow-hidden items-center bg-gray-dark rounded-tl-[20px] rounded-br-[20px] ">
                {links.map(({ title, href }, index) => (
                  <Link
                    key={index}
                    to={href as To}
                    onClick={() => handleDropdownClick(id)}
                    className={classNames([
                      'block px-4 rounded-tl-[20px] rounded-br-[20px] w-full py-[12px] text-center ',
                      {
                        'bg-event text-black': location.pathname === href,
                        'bg-gray-dark hover:bg-gray-medium text-generic':
                          location.pathname !== href ||
                          location.pathname === '/',
                      },
                    ])}
                  >
                    {title}
                  </Link>
                ))}
              </div>
            )}
          </>
        ) : (
          // <Link
          //   to={href as To}
          //   className={classNames([
          //     'block px-4 rounded-tl-[20px] rounded-br-[20px] w-full py-[12px] text-center',
          //     {
          //       'bg-event text-black':
          //         location.pathname === href ||
          //         location.pathname === ROUTE_LOGIN,
          //       'bg-gray-dark hover:bg-gray-medium text-generic':
          //         location.pathname !== href &&
          //         location.pathname !== ROUTE_LOGIN,
          //     },
          //   ])}
          // >
          //   {title}
          // </Link>
          <></>
        )}
      </div>
    );
  });
};
