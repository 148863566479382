import { useSupabase } from '../providers/supabase';
import { Ticket } from '@ceneteam/namespace';
import { useState } from 'react';


interface PaymentConfig {
  email: string;
  amount: number;
  quantity: number;
  metadata: {
    custom_fields: {
      display_name: string;
      variable_name: string;
      value: string;
    }[];
  };
  reference: string;
}

interface InitializePayment {
  (params: {
    onSuccess: () => void;
    onClose: () => void;
    config: PaymentConfig;
  }): void;
}

interface UsePaymentProps {
  totalPrice: number;
  tickets: Ticket[] | null;
  quantity: { [key: string]: number };
  id: string;
  initializePayment: InitializePayment;
  setIsPaymentSuccessful: (value: boolean) => void;
  setIsPaymentError: (value: boolean) => void;
  transaction?: string;
  isSeatsio?: boolean;
}

export const usePayment = ({
  totalPrice,
  tickets,
  quantity,
  id,
  initializePayment,
  setIsPaymentSuccessful,
  setIsPaymentError,
  transaction,
  isSeatsio = false,
}: UsePaymentProps) => {
  const { session } = useSupabase();
  const [isLoading, setIsLoading] = useState(false);

  const onSendPayment = async () => {
    setIsLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/api/v1/payment/checkout`;
    const token = session?.access_token;

    //WHAT DOES THIS DO???
    // const ticketsMap = isSeatsio
    //   ? tickets
    //   : tickets
    //       ?.map((ticket) => {
    //         const ticketQuantity = quantity[ticket.id] ?? ticket.quantity ?? 0;
    //         return ticketQuantity > 0
    //           ? {
    //               ticket_name_id: ticket.id,
    //               quantity: ticketQuantity,
    //             }
    //           : null;
    //       })
    //       .filter(Boolean);

    const ticketsMap = isSeatsio
      ? tickets
      : tickets
          ?.map((ticket) => {
            // Be more explicit about quantity selection
            let ticketQuantity;
            if (typeof quantity[ticket.id] === 'number') {
              ticketQuantity = quantity[ticket.id];
            } else {
              ticketQuantity = 0;
            }

            return ticketQuantity > 0
              ? {
                  ticket_name_id: ticket.id,
                  quantity: ticketQuantity,
                }
              : null;
          })
          .filter(Boolean);

    if (!ticketsMap || ticketsMap.length === 0) {
      console.log('No valid tickets selected');
    }

    const body = {
      currency: 'NGN',
      event_id: id,
      tickets: ticketsMap,
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) throw new Error(`Error! status: ${response.status}`);

      const data = await response.json();
      initializePayment({
        onSuccess: () => {
          setIsPaymentSuccessful(true);
        },
        onClose: () => {
          console.log('Payment cancelled');
        },
        config: {
          email: session?.user?.email || '',
          amount: totalPrice * 100,
          quantity: Object.values(quantity).reduce((sum, qty) => sum + qty, 0),
          metadata: {
            custom_fields: [
              {
                display_name: 'Customer Name',
                variable_name: 'customer_name',
                value: session?.user?.email || 'John Doe',
              },
            ],
          },
          reference: transaction ? transaction : data.transactionId,
        },
      });
    } catch (error) {
      console.error('Error:', error);
      setIsPaymentError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { onSendPayment, isLoading };
};