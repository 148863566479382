const purchaseTerms = {
  label: 'Purchase Terms',
  content: (
    <>
      <ol className="terms-list text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white list-decimal list-inside space-y-[4rem] max-lg:space-y-[2.5rem]">
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            About us
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              Cene is a disclosed ticketing agent selling tickets via our App
              and the Services on behalf of promoters, organisers, providers of
              entertainment activities, and similar events (our &quot;Event
              Partners&quot;). We sell tickets allocated to us by our Event
              Partners, so the tickets we have available for sale for each event
              will vary and we do not control the ticket inventory or
              availability.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Contract
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              <p className="inline">
                Any ticket purchased or otherwise acquired through the App is
                subject to:
              </p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>These Purchase Terms;</li>
                <li>Our Terms of Use</li>
                <li>Our Privacy Policy; and</li>
                <li>The Event Rules and Regulations, if applicable;</li>
                <li>
                  Any additional terms and conditions of the relevant Event
                  Partner(s) which can be found on their respective websites or
                  at the venue itself or which are disclosed during the ticket
                  purchase process.
                </li>
              </ul>
            </li>
            <li>
              We facilitate the Purchase of tickets for events, and from time to
              time, where expressly stated we may act as a promoter or organiser
              of an event. Where Cene is not the event organiser, we exclude any
              liability and/or responsibility for the quality or nature of any
              event including (but not limited to): your view at the venue where
              the event or show takes place, or the sound quality, local
              conveniences or accessibility of the event.
            </li>
            <li>
              When you purchase a ticket you obtain a right to attend the event
              as granted by the relevant Event Partner. The relevant Event
              Partner will be shown in the event listing in the App or on the
              Services, preceded by the words: “Presented by”. We make available
              tickets on behalf of third party Event Partners who are not
              connected in any way with Cene. Those third party Event Partners
              are responsible to you for the relevant event. For the avoidance
              of doubt, from time to time, Cene may be the Event Partner and
              this will be made clear in the Event information on the App.
            </li>
            <li>
              Any changes to an event that occur after you have Purchased a
              ticket (such as a change of date/time, location, programme, or
              artist, or an event cancellation) are the sole responsibility of
              the Event Partner for that event. Please see Clause 3, which sets
              out how we will inform you of any changes to an event.
            </li>
            <li>
              By hitting the “Purchase Tickets” button in the App or the
              Services, you are making a contractual offer to purchase the
              ticket(s). This offer is only accepted by Cene on behalf of, or
              acting for the Event Partner once you receive a confirmation email
              from us (as such it&apos;s important that you provide an accurate
              email address to Cene).
            </li>
            <li>
              Upon Purchase of a ticket you will receive a confirmation to the
              email address provided to us during your registration to the App
              or the Services containing any additional details about the event.
              It is your responsibility to check these details carefully before
              the event. Your ticket is stored on the Cene App, and is found by
              clicking on the &quot;tickets&quot; option of the App menu. Your
              ticket is required for entry to the venue, and must be presented
              upon request for each ticket holder. Note that for certain events,
              a hardcopy ticket may be provided to you. In these circumstances,
              we will notify you by telephone, email or within the App of the
              arrangements for receiving or collecting your tickets. You may be
              asked by the venue to separately verify your identity and/or age
              by providing ID for events with age restrictions.
            </li>
            <li>
              When Purchasing tickets via the App or the Services, you will be
              limited to a maximum number of tickets for each event (as
              specified in the order page of the App or the Services).
            </li>
            <li>
              We may make available certain goods (for example, merchandise) and
              services (“Non-Ticket Items”) for purchase via the App. Please see
              Clause 11 below for more information.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Cancellations and charges
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              <p className="inline">
                By agreeing to Purchase a ticket, you agree to waive any rights
                you may have to withdraw from the contract. This means that once
                you have Purchased a ticket, it cannot be exchanged or refunded
                except in the following circumstances:
              </p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>
                  If an event is cancelled by the Event Partner, they are
                  responsible for refunding the original ticket proceeds to you.
                  Cene will arrange for the full refund to ticket holders on the
                  Event Partner&apos;s behalf and subject to us obtaining the
                  funds to be provided from or on behalf of the Event Partner.
                  We aim to do this within fourteen (14) working days of the
                  Event Partner returning the original proceeds to us to enable
                  us to process refunds.
                </li>
              </ul>
            </li>
            <li>
              <p className="inline">
                If an Event Partner notifies us of any changes to an event, or
                we are aware of any significant changes to an event where Cene
                is the Event Partner, we will:
              </p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>
                  Send a message to the mobile phone number or email address
                  specified by you when registering with the App or the Services
                  informing you of the change; and
                </li>
                <li>
                  Inform you about the Event Partner&apos;s procedure for
                  reimbursements and refunds, where appropriate.
                </li>
              </ul>
            </li>
            <li>
              We will not reimburse the cost of Purchased tickets after an event
              has taken place. You will not be entitled to a refund if you fail
              to attend an event or make an error when purchasing tickets (for
              example you buy the wrong quantity). In no circumstances will a
              refund be issued if requested for an Event due to take place
              within 24 hours of the request
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Price and fees
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              The price of the ticket(s) or Non-Ticket Item(s) listed in the App
              includes VAT and will be the price indicated on the order pages of
              the App when you placed your order.The price of a ticket may go up
              or down over time but you will pay the price shown when you place
              your order.
            </li>
            <li>
              We accept payment with Visa, MasterCard, American Express, Apple
              Pay, Android Pay and any other payment methods indicated in the
              App. You must pay for your order before the ticket(s) or
              Non-Ticket Item(s) will be provided to you.
            </li>
            <li>
              We take all reasonable care to ensure that the price of the
              ticket(s) or Non-Ticket Item(s) set out in the App or the Services
              is correct. If we discover an error in the price in your order, we
              will contact you to give you the option of either reconfirming
              your order at the correct price, or cancelling your order. We will
              debit or refund the method you used for payment of the order, as
              applicable. If we are unable to contact you, you agree that we may
              treat your order as cancelled.
            </li>
            <li>
              If you need a VAT invoice in respect of a ticket (or tickets), you
              will need to contact the relevant Event Partner with whom we can
              put you in touch.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Seat/Table selection
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              Some tickets available on the App or the Services will be issued
              for a particular seat/table or seating/table area at the event
              venue. When ordering such tickets, the App or the Services will
              show you the option of selecting your preferred seating/table
              area.
            </li>
            <li>
              Any floor plan shown via the App will reflect the general layout
              of the venue at that time. For some events the layout and specific
              seat/ table locations may vary without notice. Neither Cene nor
              the Event Partner will be liable for any difference between floor
              plans shown in the App and the layout of the venue.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Accidental purchases
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              You are responsible for checking the event details before
              Purchasing a ticket to ensure that the event&apos;s artist, date,
              time, location, age limit, and any other restrictions are correct
              and acceptable to you. You will not be eligible for a refund in
              instances where such restrictions prevent your attendance.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Non-resale of tickets
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              You may not resell or transfer your tickets, except for under the
              &quot;Limited Right to Transfer Tickets&quot; feature described
              below in Clause 8, and only if such reselling or transfer is not
              prohibited by law or by the relevant Event Partner. Further, you
              may not duplicate a ticket, for example, by taking a screenshot of
              the ticket on your device.
            </li>
            <li>
              Any resale or transfer (or attempted resale or transfer) of a
              ticket in breach of Clause 7.1 is grounds for the immediate
              confiscation, invalidation, or cancellation of that ticket without
              refund or other compensation and may result in full suspension of
              your use of the ticket transfer feature or full suspension of the
              original ticket holder&apos;s use of the App or the Services in
              general, at Cene&apos;s sole discretion.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Limited right to transfer tickets
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              <p className="inline">
                For certain events a person who has purchased a ticket (a
                &quot;Sender&quot;) may be permitted to transfer the ticket via
                the App to another registered Cene user (a
                &quot;Recipient&quot;). This can be done at any time prior to
                the ticket transfer cut-off date and time specified by the Event
                Partner, provided that the conditions below are met:
              </p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>The Recipient has a device compatible with the App; and</li>
                <li>
                  The Recipient&apos;s mobile number associated with the App is
                  stored and accessible from the contact list or phone book of
                  the Sender&apos;s personal device; and
                </li>
                <li>
                  The Recipient has downloaded the most recent and updated
                  version of the App to their phone; and
                </li>
                <li>
                  The Recipient has successfully registered and created a Cene
                  account using the device and mobile number mentioned above.
                </li>
              </ul>
            </li>
            <li>
              Cene provides this feature to facilitate the transfer of the
              rights to a ticket only. The transfer does not extend to the
              payment for the ticket, which must be arranged separately and
              directly between the Sender and the Recipient.
            </li>
            <li>
              Due to restrictions placed on Cene by Event Partners, only tickets
              for certain events may be transferred using this feature. If the
              event does not allow the transfer of tickets as a feature within
              the App, you may contact support@cene.xyz to request this manually
              by providing the full name and mobile number of the Recipient to
              whom you wish to transfer a ticket. Conditions for the Recipient
              to receive the ticket are as described above. You must contact
              Cene at least two (2) weeks in advance of the event dates if you
              wish to transfer a ticket in this way. If you contact us to
              transfer your ticket within less than this notice period, we
              cannot guarantee we will be able to fulfil your request. Any
              requests to transfer tickets in this manner that are received less
              than two weeks before the event may be denied at Cene&apos;s sole
              discretion.
            </li>
            <li>
              When using the ticket transfer feature the Sender is responsible
              for ensuring that the mobile number of the Recipient is correct
              for the intended purchaser of the ticket. Any ticket successfully
              transferred to a Recipient automatically becomes a right of that
              Recipient and the Sender then has no rights in relation to the
              ticket. Cene is not responsible for any tickets mistakenly sent to
              the wrong Recipient and cannot transfer the tickets in question
              back to the Sender without the Recipients prior authorisation or
              consent.
            </li>
            <li>
              The Sender is responsible for requesting and arranging any payment
              for the ticket separately and directly with the Recipient. Any
              such payment must not be for a sum exceeding the face value of the
              ticket. Cene is not responsible for any Recipient refusing to pay
              any Sender. If an event is cancelled, a refund will be made to the
              Sender as the original ticket holder by the same means as payment
              for the ticket was originally made. The Sender is then responsible
              for remitting the refund separately and directly to the Recipient.
            </li>
            <li>
              When requested by the Sender, Recipients of tickets are
              responsible for settling payment separately and directly with the
              Sender for an amount no greater than the face value of the ticket.
            </li>
            <li>
              If the Recipient of a transferred ticket is unable to attend the
              event, the tickets may be transferred again to a new Recipient or
              returned to the original Sender using the process set out above.
            </li>
            <li>
              It is prohibited for any Recipient to pay more than face value for
              a ticket. Cene will conduct periodic checks with Recipients to
              ensure that no more than face value was paid for any ticket.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Liability
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              Cene assumes no responsibility for tickets Purchased through third
              parties or unofficial sales points. Cene is not responsible for
              any differences in price with other media or sales channels.
            </li>
            <li>
              <p className="inline">Cene is not responsible for:</p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>
                  Loss or theft of personal mobile devices, any tickets stored
                  within the App on a lost or stolen device or any hardcopy
                  tickets. Events in real life are generally provided with a
                  door list with names of individuals who have Purchased tickets
                  through the App or the Services. Our Event Partners may seek
                  to allow you to gain entry to the event without your mobile
                  phone by you speaking to a Cene or event representative who
                  will ask for ID verification in order to allow access to the
                  event. However, this is not guaranteed.
                </li>
                <li>
                  Any personal arrangements including travel, accommodation or
                  hospitality relating to the attendance or an event. Neither we
                  nor the relevant Event Partner shall be liable to you for any
                  loss of enjoyment or wasted expenditure.
                </li>
                <li>
                  Any exchange or bank fees incurred by you arising from the
                  normal course of payment or reimbursement.
                </li>
              </ul>
            </li>
            <li>
              <h3 className="inline">COVID-19</h3>
              <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light">
                <li>
                  &quot;Covid-19&quot; means: the global outbreak of the
                  respiratory coronavirus disease known as COVID-19 and the
                  virus known as severe acute respiratory syndrome coronavirus 2
                  (SARS-CoV-2) (and any subsequent waves or derivatives of the
                  same). There is a risk of the transmission of Covid-19 in any
                  environment where people come together, which is increased in
                  enclosed spaces with an increased number of people.
                </li>
                <li>
                  By purchasing tickets to an event via the App or the Services,
                  you accept that neither Cene nor the Event Partner can
                  guarantee that you will not be exposed to Covid-19. You
                  voluntarily accept the risk of being exposed to, contracting
                  or spreading Covid-19. You agree that CENE and the Event
                  Partner are not responsible or liable for any injuries,
                  sickness, or death resulting from such causes.
                </li>
                <li>
                  <p className="inline">You also agree:</p>
                  <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                    <li>
                      To comply with all health and safety protocols implemented
                      by the Event Partner (which can be amended at any time
                      according to the latest guidance or legislation, or as
                      otherwise deemed necessary). These protocols may be
                      communicated to you via various channels including but not
                      limited to the Event Partners website, venue signage or as
                      instructed by venue staff at the event;
                    </li>
                    <li>
                      To abide by all government guidance aimed at preventing
                      the transmission of Covid-19; and
                    </li>
                    <li>
                      Not to enter the venue if you believe you may have been
                      infected with Covid-19; benefit, no one other than a party
                      to these Terms of Use has any right to enforce any of
                      these Terms of Use.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Access to venues
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              <p className="inline">
                Your purchase of a ticket to an event via the App or Services
                will enable you to attend the event, subject always to the terms
                and conditions specified in these Purchase Terms. By purchasing
                a ticket on the App or the Services, you acknowledge that the
                Event Partner reserves the right, on reasonable and lawful
                grounds, to refuse entry to, or remove from the premises any
                ticket holder without requirement to offer a refund or other
                compensation. By way of example, the Event Partner may remove or
                refuse entry to a ticket holder who:
              </p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>
                  Has behaved in a manner which, in the reasonable opinion of
                  the Event Partner has, or is likely to, affect the enjoyment
                  of other visitors; or
                </li>
                <li>
                  Uses threatening, abusive or insulting words or behaviour, or
                  in any way provokes or behaves in a manner which may provoke a
                  breach of the peace; or
                </li>
                <li>
                  In the reasonable opinion of the Event Partner, is under the
                  influence of alcohol or drugs; or
                </li>
                <li>
                  Fails, when required, to produce proof of identity or age (as
                  or when appropriate); or
                </li>
                <li>
                  Fails to comply with any measures intended to fight the spread
                  of Covid-19 or other public health measures.
                </li>
              </ul>
            </li>
            <li>
              By accepting these Purchase Terms, you acknowledge that Cene shall
              have no liability if you are refused entry to an event, by an
              Event Partner or otherwise for the actions or inactions of the
              Event Partner.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Non-ticket items
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              We may make available certain Non-Ticket Items for purchase via
              the App or the Services (including goods and services), for and on
              behalf of an Event Partner. Such Non-Ticket Items include but are
              not limited to VIP passes and parking passes. The Event Partner is
              responsible for fulfilling any Non-Ticket Item you purchase, and
              Cene accepts no liability for any non-fulfilment of a Non-Ticket
              Item by an Event Partner.
            </li>
            <li>
              <p className="inline">
                Any Non-Ticket Item purchased through the App or the Services is
                subject to:
              </p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>These Purchase Terms;</li>
                <li>Our Terms of Use</li>
                <li>Our Privacy Policy; and</li>
                <li>
                  Any additional terms and conditions of the relevant Event
                  Partner(s) which can be found on the order pages when making a
                  purchase.
                </li>
              </ul>
            </li>
            <li>
              Where the Non-Ticket Item is a physical item (for example, goods),
              we are under a legal duty to supply goods that are in conformity
              with this contract.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Suspected fraud
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              We have the right to remove or revoke your tickets (including any
              tickets transferred in accordance with Clause 8) and provide a
              full refund as provided in These Purchase Terms to the payment
              card account holder if your Purchase is suspected of being
              fraudulent and/or if we have grounds to suspect that your tickets
              were Purchased using a stolen card (the &quot;Suspected
              Fraud&quot;).
            </li>
            <li>
              Where clause 12.1 applies, we are not obliged to notify you of the
              removal, revocation or refund, nor provide you with any detail of
              the Suspected Fraud. This is to ensure that those committing fraud
              are not alerted to the methods of investigation.
            </li>
            <li>
              If you believe that your tickets have been removed incorrectly due
              to Suspected Fraud, you may dispute this by contacting our support
              team at support@cene.xyz
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            General
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              Certain events may be recorded for commercial and/or promotional
              purposes by the Event Partner. When accessing the venue or show
              you consent to your image being used in this way.
            </li>
            <li>
              Cene reserves the right to obtain photographs, audio or video of
              all events for which Cene makes tickets available. When accessing
              the venue or show you consent to your image being used in this
              way. We may reproduce and/or publish your image in the App, our
              website, and in other promotional materials, social media channels
              and other materials related to the App. For more information about
              how we use your personal information, and how to request details
              of the personal information that we hold about you then please see
              our Privacy Policy.
            </li>
            <li>
              Except for Event Partners, who shall have the right to enforce any
              right expressly granted for their benefit, no one other than a
              party to these Purchase Terms has any right to enforce any of the
              terms of these Purchase Terms.
            </li>
            <li>
              These Purchase Terms are governed by and shall be construed in
              accordance with Nigerian laws and any disputes arising out of any
              transaction with Cene are subject to the exclusive jurisdiction of
              the Nigerian courts.
            </li>
            <li>
              Should you have a complaint, please see details of our complaints
              procedure as set out in our Terms of Use
            </li>
          </ol>
        </li>
      </ol>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[64px]">
        Please read these Terms of Use carefully as they set out the basis on
        which you are allowed to use the Cene mobile software applications (the
        “App”) or the websites (together with the App, collectively the
        “Services”) offered by Cene Entertainment Limited (“Cene”, “we”, “us” or
        “our”). If you have any questions about them or do not accept them,
        please contact us at support@cene.xyz before registering with the
        Services. We may change these Terms of Use from time to time so please
        check our App or website at www.cene.xyz/terms_and_conditions from time
        to time for any updates to them to ensure that you are happy with any
        changes. Your continued use of any part of the Services following any
        update to these Terms of Use will constitute your acceptance of those
        changes.
      </p>
    </>
  ),
};

export default purchaseTerms;
