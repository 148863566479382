import { ReactComponent as ArrowDownSVG } from '../../svg/arrow-down.svg';
import classNames from 'classnames';
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  LegacyRef,
  SyntheticEvent,
  useState,
} from 'react';

interface IInputProps {
  id: string;
  placeholder?: string;
  value?: string;
  error?: string[] | string | undefined;
  touched?: boolean | undefined;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void;
  className?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  sessionType: boolean;
  label: string;
  options?: { value: string; label: string }[];
  disabled?: boolean;
}

export const ProfileInput = ({
  sessionType,
  placeholder,
  className,
  value,
  id,
  error,
  onChange = () => null,
  onBlur = () => null,
  // onFocus = () => null,
  // onKeyDown = () => null,
  touched,
  // inputRef,
  label,
  options = [],
  disabled = false,
}: IInputProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionClick = (selectedValue: string) => {
    if (onChange) {
      onChange({
        target: { value: selectedValue },
      } as ChangeEvent<HTMLInputElement>);
    }
    setIsDropdownOpen(false);
  };

  const isDropdown = options && options.length > 0;
  const hasError = touched && error;

  return (
    <div className={classNames([className, 'w-full relative text-start'])}>
      <label
        className={classNames(
          'uppercase text-[0.75rem]/[0.75rem] text-gray-light'
        )}
      >
        {label}
      </label>
      <div
        className={classNames('relative', {
          'cursor-pointer': isDropdown,
        })}
        tabIndex={isDropdown ? 0 : -1}
        onBlur={(e) => {
          if (isDropdown) setIsDropdownOpen(false);
          onBlur(e as FocusEvent<HTMLInputElement>);
        }}
      >
        {isDropdown ? (
          <div
            id={id}
            key={id}
            className={classNames([
              'flex items-center justify-between',
              'w-full py-[8px] pr-[8px] font-light bg-black border-b border-b-gray-medium outline-0',
              'text-[1rem]',
              sessionType
                ? 'focus:border-b-content text-white'
                : 'focus:border-b-generic text-generic',
              touched && error && 'border-red',
            ])}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {value || placeholder}
            <ArrowDownSVG
              className={classNames('transition-transform duration-200', {
                'rotate-180': isDropdownOpen,
              })}
              style={{ width: '16px', height: '16px', marginLeft: '8px' }}
            />
          </div>
        ) : (
          <input
            id={id}
            name={id}
            type="text"
            autoComplete="off"
            disabled={disabled}
            className={classNames([
              'placeholder:text-gray-light w-full py-[8px] font-light bg-black border-b border-b-gray-medium outline-0',
              'text-[1rem]',
              sessionType
                ? 'focus:border-b-content text-white'
                : 'focus:border-b-generic text-generic',
              hasError && 'border-b-red',
            ])}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(e: SyntheticEvent<HTMLInputElement, Event>) =>
              onChange(e)
            }
            value={value}
          />
        )}
        {isDropdownOpen && (
          <ul className="absolute z-10 bg-black border border-gray-medium w-full mt-2 rounded-tl-[20px] rounded-br-[20px] max-h-48 overflow-auto">
            {options.map((option) => (
              <li
                key={option.value}
                className={classNames(
                  'px-[1rem] py-[0.75rem] hover:bg-gray-dark cursor-pointer',
                  sessionType ? 'text-white' : 'text-generic',
                  { 'bg-gray-medium': option.value === value }
                )}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {hasError && (
        <div className="text-[0.875rem] text-red font-light mt-[8px]">
          {error}
        </div>
      )}
    </div>
  );
};
