import { Button } from '../../../../components/button/button';
import { Input } from '../../../../components/input/cenePlus/input';
import { Label } from '../../../../components/label/cene/label';
import translation from '../../../../constants/translations.json';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

interface FormProps {
  onNext: () => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
}

export const NameForm = ({ onNext = () => null }: FormProps) => {
  const { values, errors, touched, handleChange, handleBlur, isValid, dirty } =
    useFormikContext<FormValues>();

  return (
    <div className={classNames(['pt-[24px] pb-[20px] px-5', 'sm:px-0'])}>
      <h1 className="text-[2rem] text-generic font-light mb-[12px]">
        {translation.steps.nameStep.titleCenePlus}
      </h1>
      <p className="text-[0.875rem] text-generic font-light mb-[12px]">
        {translation.steps.nameStep.firstDescriptionCenePlus}
      </p>
      <p className="text-[0.875rem] text-generic font-light mb-[40px]">
        {translation.steps.nameStep.secondDescriptionCenePlus}
      </p>

      <div className="flex flex-col gap-[40px]">
        <div>
          <Label classLabel="text-generic" id="firstName" isRequired={true}>
            {translation.steps.nameStep.firstname}
          </Label>
          <Input
            id="firstName"
            placeholder={translation.steps.nameStep.enterFirstName}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.firstName}
            touched={Boolean(touched.firstName)}
          />
        </div>
        <div>
          <Label classLabel="text-generic" id="lastName" isRequired={true}>
            {translation.steps.nameStep.lastname}
          </Label>
          <Input
            id="lastName"
            placeholder={translation.steps.nameStep.enterLastname}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.lastName}
            touched={Boolean(touched.lastName)}
          />
        </div>
        <Button
          color="generic"
          type="button"
          onClick={onNext}
          disabled={!(isValid && dirty)}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};
