import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

const Road14 = '/pictures/road14Banner.png';

export const Road14Section = () => {
  return (
    <div className={classNames(['lg:mb-[120px] lg:mx-[64px] mb-[64px]'])}>
      <div
        className={classNames([
          'flex flex-col container mx-auto',
          'lg:flex-row lg:gap-[135px] gap-0',
        ])}
      >
        <div
          className={classNames([
            'w-full flex justify-center',
            'lg:mb-0 lg:justify-end lg:w-1/2',
          ])}
        >
          <img
            src={Road14}
            alt="Img"
            className={classNames([
              'lg:w-[644px] lg:h-[644px] rounded-tl-[30px] object-cover w-full',
              'lg:max-w-none',
            ])}
          />
        </div>
        <div className="w-full lg:w-[55%] mt-[60px]">
          <div className="w-full flex flex-row lg:mb-[46px]">
            <div className="w-[359px] flex flex-row items-center">
              <p className="text-event text-[1rem] leading-[24px] text-center font-light">
                {translations.road14.titleSmallFirst}
              </p>
            </div>
          </div>
          <div className="lg:mt-0 mt-[44px]">
            <h1 className="text-[0.9375rem] text-gray-light leading-[20px] font-normal mb-[8px]">
              {translations.road14.titleSmall}
            </h1>
            <h2
              className={classNames([
                'text-[1.75rem] leading-[36px] text-event',
                'lg:leading-[64px] lg:text-[3.5rem] font-light',
              ])}
            >
              {translations.road14.title}
            </h2>
            <p className="lg:text-[1.0625rem] text-white mt-[24px] lg:leading-[22px] font-light text-[1rem] leading-[21px]">
              {translations.road14.desc}
            </p>
          </div>
          <a
            href="https://road14studios.com"
            className="inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="text-black bg-event w-[199px] h-[48px] lg:mt-[48px] mt-[24px] font-medium">
              {translations.road14.textButton}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
