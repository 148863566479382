import { Button } from '../../../../components/button/button';
import { PreferenceCard } from '../../../../components/preferenceCard/cene/preferenceCard';
import translation from '../../../../constants/translations.json';
import { RegisterSchema } from '../../../../schemas/registerSchema';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useFormikContext } from 'formik';


interface FormProps {
  onNext: () => void;
}

export const GenrePreferencesForm = ({ onNext }: FormProps) => {
  const formik = useFormikContext<RegisterSchema>();

  const formikGenres = formik.values.genres || [];

  const selected = formikGenres.length;

  const handleGenreSelect = (genre: string) => {
    if (formikGenres.includes(genre)) {
      formik.setFieldValue(
        'genres',
        formikGenres.filter((item) => item !== genre)
      );
    } else if (selected < 5) {
      formik.setFieldValue('genres', [...formikGenres, genre]);
    }
  };

  return (
    <div className={classNames(['pt-[24px] pb-[20px]', 'max-lg:w-full'])}>
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.genres.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.genres.description}
      </p>
      <div>
        <Grid
          container
          sx={{
            width: { xs: '100%', md: '456px' },
            gap: { xs: '12px', md: '16px' },
          }}
        >
          {genres.map(({ title, icon }) => (
            <Grid item xs={3.65157} sm={3.65157} md={2.684} key={title}>
              <PreferenceCard
                selected={formikGenres.includes(title)}
                onClick={() => handleGenreSelect(title)}
                href={icon}
              >
                {title}
              </PreferenceCard>
            </Grid>
          ))}
        </Grid>
        <div
          className={classNames([
            'mt-[40px] max-w-[456px] flex items-center gap-[40px]',
            'max-lg:gap-[24px]',
          ])}
        >
          <div className={classNames(['w-full', 'max-lg:w-2/3'])}>
            <p className="text-[1rem] text-white font-medium">
              {selected}/5 {translation.selected}
            </p>
            <p className="text-[0.875rem] text-generic font-light">
              {translation.selectionInfo}
            </p>
          </div>
          <Button
            className={classNames(['w-full', 'max-lg:w-1/3'])}
            color="content"
            onClick={onNext}
          >
            {translation.continue}
          </Button>
        </div>
      </div>
    </div>
  );
};

const genres = [
  { icon: '/pictures/genre-cene/afrobeats-cene.jpg', title: 'Afrobeats' },
  { icon: '/pictures/genre-cene/alte-cene.jpg', title: 'Alté' },
  { icon: '/pictures/genre-cene/amapiano-cene.jpg', title: 'Amapiano' },
  { icon: '/pictures/genre-cene/country-cene.jpg', title: 'Country' },
  { icon: '/pictures/genre-cene/dancehall-cene.jpg', title: 'Dancehall' },
  { icon: '/pictures/genre-cene/drill-cene.jpg', title: 'Drill' },
  { icon: '/pictures/genre-cene/electronic-cene.jpg', title: 'Electronic' },
  { icon: '/pictures/genre-cene/hip-hop-cene.jpg', title: 'Hip-Hop' },
  { icon: '/pictures/genre-cene/house-cene.jpg', title: 'House' },
  { icon: '/pictures/genre-cene/jazz-cene.jpg', title: 'Jazz' },
  { icon: '/pictures/genre-cene/pop-cene.jpg', title: 'Pop' },
  { icon: '/pictures/genre-cene/rap-cene.jpg', title: 'Rap' },
  { icon: '/pictures/genre-cene/rnb-cene.jpg', title: 'RnB' },
  { icon: '/pictures/genre-cene/rock-cene.jpg', title: 'Rock' },
  { icon: '/pictures/genre-cene/soul-cene.jpg', title: 'Soul' },
  { icon: '/pictures/genre-cene/trap-cene.jpg', title: 'Trap' },
];