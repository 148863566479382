import { ReactComponent as ArrowDownSVG } from '../../../../../src/svg/arrow-down-event.svg';
import { ReactComponent as ArrowUpSVG } from '../../../../../src/svg/arrowUp.svg';
import translations from '../../../../constants/translations.json';
import { Event } from '@ceneteam/namespace';
import { useEffect, useRef, useState } from 'react';

interface AboutEventProps {
  event: Event | null;
}

const AboutEventSection = ({ event }: AboutEventProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const descriptionEl = descriptionRef.current;
    if (descriptionEl) {
      const { lineHeight } = window.getComputedStyle(descriptionEl);
      const maxHeight = parseFloat(lineHeight) * 3;
      setShowToggle(descriptionEl.scrollHeight > maxHeight);
    }
  }, [event?.description]);

  return (
    <div className="w-full">
      <p className="lg:text-[0.9375rem] lg:leading-[20px] text-[0.75rem] leading-[12px] text-white">
        {translations.eventDetailsSection.about}
      </p>

      {isExpanded && (
        <p className="mt-[16px] whitespace-pre-line text-white text-[1.0625rem] leading-[22px] lg:w-[628px]">
          {event?.description}
        </p>
      )}

      {!isExpanded && (
        <p
          ref={descriptionRef}
          className="mt-[16px] text-white text-[1.0625rem] leading-[22px] line-clamp-3"
        >
          {event?.description}
        </p>
      )}

      {showToggle && (
        <div
          className="mt-[8px] text-event text-[1rem] leading-[24px] flex flex-row items-center cursor-pointer"
          onClick={toggleContent}
        >
          <p className="mr-[12.5px]">
            {isExpanded
              ? translations.eventDetailsSection.hide
              : translations.eventDetailsSection.more}
          </p>
          {isExpanded ? <ArrowUpSVG /> : <ArrowDownSVG />}
        </div>
      )}
    </div>
  );
};

export default AboutEventSection;
