import { ROUTE_HOME } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { ReactComponent as CloseSVG } from '../../svg/closeIconGeneric.svg';
import { ReactComponent as SuccessSVG } from '../../svg/iconSuccess.svg';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = '/pictures/delete-account/delete-account.png';

export const DeleteAccountSuccessful: React.FC = () => (
  <div className="w-full flex flex-col items-center m-auto max-lg:w-full lg:mt-0 mt-[16px] px-[20px] h-screen lg:px-0 overflow-x-hidden">
    <div className="flex items-center justify-between w-full lg:py-[20px] lg:px-[64px]">
      <LogoSVG />
      <Link to={ROUTE_HOME}>
        <button className="flex justify-center items-center border border-gray-medium rounded-tl-[16px] rounded-br-[16px] w-[48px] h-[48px]">
          <div className="h-[24px] w-[24px] flex justify-center items-center">
            <CloseSVG />
          </div>
        </button>
      </Link>
    </div>

    <div className="flex flex-col items-center text-center h-full lg:w-[644px] lg:my-[120px] max-lg:flex-grow">
      <div className="max-lg:h-[50vh] flex flex-col items-center justify-center lg:h-auto">
        <SuccessSVG className="mb-[12px]" />
        <h1
          className={classNames([
            'text-[2rem] leading-[40px] text-white mb-[12px] font-light',
            'text-center',
            'lg:w-[551px]',
            'lg:text-[4.5rem] lg:leading-[80px]',
          ])}
        >
          {translations.deletePage.title}
        </h1>
        <h2
          className={classNames([
            'text-white text-[1rem] leading-[24px] lg:text-[2rem] lg:leading-[40px] font-light',
            'lg:w-[551px]',
          ])}
        >
          {translations.deletePage.desc}
        </h2>
      </div>
    </div>

    <div className="block w-screen relative lg:my-[0px] mt-auto max-lg:h-[60vh]">
      <img
        src={Logo}
        alt="Banner"
        className="w-full h-full object-cover max-lg:object-[85%]"
      />
    </div>
  </div>
);
