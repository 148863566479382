import { ReactComponent as CheckSvg } from '../../../src/svg/check.svg';
import { ReactComponent as FacebookLogoSvg } from '../../../src/svg/facebookLogo.svg';
import { ReactComponent as LinkSvg } from '../../../src/svg/linkNew.svg';
import { ReactComponent as SocialmediaSvg } from '../../../src/svg/socialmedia.svg';
import { ReactComponent as WhatsappLogoSvg } from '../../../src/svg/whatsappLogo.svg';
import translations from '../../constants/translations.json';
import { useClickOutside } from '../../hooks/useClickOutside';
import React, { useRef } from 'react';

interface ShareOptionsProps {
  onCopyLink: (event: React.MouseEvent) => void;
  linkCopied?: boolean;
  onClose: () => void;
}

export const ShareOptions: React.FC<ShareOptionsProps> = ({
  onCopyLink,
  linkCopied,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose);

  return (
    <div
      ref={ref}
      className="mt-[8px] p-[10px] bg-gray-800 rounded-[8px] shadow-lg w-[180px] border border-gray-medium"
    >
      <button
        className="text-white text-[1rem] leading-[24px] font-normal hover:bg-gray-dark py-2 px-4 rounded flex justify-between items-center w-full"
        onClick={onCopyLink}
      >
        {translations.shareOptions.copyLink}
        <div className="flex items-center">
          <LinkSvg />
          {linkCopied && <CheckSvg className="ml-[10px]" />}
        </div>
      </button>
      <div>
        <a
          className="text-white text-[1rem] leading-[24px] font-normal hover:bg-gray-dark py-2 px-4 rounded flex justify-between items-center w-full"
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClose}
        >
          {translations.shareOptions.facebook}
          <FacebookLogoSvg className="ml-2" />
        </a>
        <a
          className="text-white text-[1rem] leading-[24px] font-normal hover:bg-gray-dark py-2 px-4 rounded flex justify-between items-center w-full"
          href={`https://wa.me/?text=${encodeURIComponent(window.location.href)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClose}
        >
          {translations.shareOptions.whatsapp}
          <WhatsappLogoSvg className="ml-2" />
        </a>
        <a
          className="text-white text-[1rem] leading-[24px] font-normal hover:bg-gray-dark py-2 px-4 rounded flex justify-between items-center w-full"
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClose}
        >
          {translations.shareOptions.X}
          <SocialmediaSvg className="ml-2" />
        </a>
      </div>
    </div>
  );
};
