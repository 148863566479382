import translations from '../../constants/translations.json';
import { ContactWithUsForm } from '../../forms/contactUs/contactUs';
import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
import React from 'react';
import { Helmet } from 'react-helmet';

const Logo = '/pictures/contactUsLogo.png';

export const ContactUs: React.FC = () => {
  usePreventHorizontalScroll();

  return (
    <>
      <Helmet>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=2ea7c41c-1441-49cb-ac72-8ded98ead6fa"
        ></script>
      </Helmet>
      
      <div className="w-full flex flex-col mt-[32px] items-center lg:my-[120px]">
        <div className="w-full items-start">
          <h1 className=" lg:text-[4.5rem] lg:leading-[80px] text-white font-light lg:mb-[72px] mb-[32px] lg:w-[562px] leading-[44px] text-[2.25rem]">
            {translations.contactUs.title}
          </h1>
        </div>
        <div className="w-full flex justify-between items-start max-lg:flex-col max-lg:items-center max-lg:gap-[24px] gap-[32px]">
          <div className="w-1/2 flex flex-col items-start max-lg:w-full max-lg:items-center max-lg:text-center">
            <div className="flex flex-col lg:gap-[64px] gap-[32px] ">
              <p className="text-ticket lg:leading-[40px] lg:text-[2rem] font-light text-start text-[1.125rem] leading-[26px]">
                {translations.contactUs.desc}
              </p>
              <img
                src={Logo}
                alt="Logo"
                className="lg:w-[537px] lg:h-[535px] object-cover"
              />
            </div>
          </div>
          <div className="w-1/2 max-lg:w-full">
            <ContactWithUsForm />
          </div>
        </div>
      </div>
    </>
  );
};
