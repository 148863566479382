import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import React from 'react';

interface SubscriptionCardProps {
  title: string;
  description: string;
  features: string[];
  popular: boolean;
  popularButtonColor?: 'content' | 'ticket' | 'generic';
  buttonColor:
    | 'content'
    | 'village'
    | 'event'
    | 'ticket'
    | 'generic'
    | 'outlined'
    | 'text';
  buttonText: string;
  titleColor: string;
  starterPlus: string;
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  title,
  description,
  features,
  popular,
  popularButtonColor,
  buttonColor,
  buttonText,
  titleColor,
  starterPlus,
}) => {
  return (
    <div className="flex flex-col justify-between p-6 rounded-tl-[30px] rounded-br-[30px] relative border font-normal border-gray-medium  lg:h-[554px] ">
      {popular && popularButtonColor && (
        <Button
          className={classNames(
            'absolute -top-4 -right-3 mt-[40px] mr-[40px] px-2 w-[99px] text-[0.9375rem] leading-[20px] !h-[24px] font-normal '
          )}
          color={popularButtonColor}
          type="button"
          isPopular={true}
        >
          {translations.subscriptionCard.popularText}
        </Button>
      )}
      <div>
        <h1
          className={classNames([
            `text-[1.5rem] leading-[36px] mb-4 ${titleColor}`,
            'lg:text-[3rem] lg:leading-[56px]  lg:mb-2 font-light ',
          ])}
        >
          {title}
        </h1>
        <h2
          className={classNames([
            `font-light mb-[32px] text-[1.1875rem] leading-[19px] ${titleColor}`,
            'lg:text-[1.3125rem] lg:leading-[21px] lg:mb-[40px] ',
          ])}
        >
          {description}
        </h2>
        <h5 className="text-[0.9375rem] leading-[20px] text-gray-light pb-[4px]">
          {translations.subscriptionCard.feature}
        </h5>
        <h2
          className={classNames([
            `font-light mb-[24px] text-[1.1875rem] leading-[19px] text-generic`,
            'lg:leading-[21px] lg:text-[1.3125rem] lg:mb-[32px]  ',
          ])}
        >
          {starterPlus}
        </h2>
        <ul className="text-white mb-4 space-y-2">
          {features.map((feature, index) => (
            <li
              key={index}
              className="flex items-center text-[1rem] leading-[24px]"
            >
              <svg
                className="w-5 h-5 text-generic mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <Button
        color={buttonColor}
        type="button"
        className={classNames(
          'w-full !rounded-tl-0 rounded-none rounded-tl-[20px] rounded-br-[20px]',
          {
            'mt-[64px]': popularButtonColor === 'content',
            'mt-[32px]': popularButtonColor !== 'content',
          }
        )}
      >
        {buttonText}
      </Button>
    </div>
  );
};
