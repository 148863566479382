import { Button } from '../../../components/button/button';
import { ROUTE_HOME, ROUTE_LOGIN } from '../../../constants/routes';
import translation from '../../../constants/translations.json';
import { ForgotPasswordForm } from '../../../forms/forgotPassword/cene/forgotPasswordForm';
import { ForgotPasswordForm as ForgotPasswordCenePlusForm } from '../../../forms/forgotPassword/cenePlus/forgotPasswordForm';
import { ReactComponent as MessageSVG } from '../../../svg/EnvelopeSimple.svg';
import { ReactComponent as CloseSVG } from '../../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../../svg/logotype.svg';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [isSubmit, setSubmit] = useState(false);

  const { state } = useLocation();

  const isCene = state?.type === 'cene';

  const handleSubmit = () => {
    setSubmit(!isSubmit);
  };

  const gotoBack = useCallback(() => {
    if (isCene) {
      navigate(ROUTE_LOGIN, { state: { type: 'cene' } });
    } else {
      navigate(ROUTE_LOGIN, { state: { type: 'cenePlus' } });
    }
  }, [isCene, navigate]);

  return (
    <>
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between px-[20px]',
        ])}
      >
        {isCene ? (
          <LogoSVG
            onClick={() => navigate(ROUTE_HOME)}
            className={classNames([
              'min-w-[164px] min-h-[48px] max-w-[164px] max-h-[48px]',
              'max-lg:min-w-[137px] max-lg:min-h-[40px] max-lg:max-w-[137px] max-lg:max-h-[40px]',
            ])}
            style={{ zIndex: 20 }}
          />
        ) : (
          <LogoPlusSVG
            onClick={() => navigate(ROUTE_HOME)}
            className={classNames([
              'min-w-[164px] min-h-[48px] max-w-[164px] max-h-[48px]',
              'max-lg:min-w-[137px] max-lg:min-h-[40px] max-lg:max-w-[137px] max-lg:max-h-[40px]',
            ])}
            style={{ zIndex: 20 }}
          />
        )}
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center max-w-[422px] h-[calc(100vh-88px)]">
        {isSubmit ? (
          <div
            className={classNames([
              'w-full flex items-center justify-center text-center flex-col',
              'px-[44px] sm:px-0',
            ])}
          >
            <MessageSVG
              className={classNames([
                isCene ? 'stroke-white' : 'stroke-generic',
                'h-[auto] max-md:h-[72px] mb-[16px]',
              ])}
            />

            <h1
              className={classNames([
                'text-h1 font-light mb-[16px]',
                isCene ? 'text-white' : 'text-generic',
              ])}
            >
              {isCene
                ? translation.forgotPasswordPage.checkEmailTitleCene
                : translation.forgotPasswordPage.checkEmailTitleCenePlus}
            </h1>
            <p className={classNames(['text-h4 text-generic font-light'])}>
              {isCene
                ? translation.forgotPasswordPage.checkEmailDescriptionCene
                : translation.forgotPasswordPage.checkEmailDescriptionCenePlus}
            </p>
            <Button
              onClick={gotoBack}
              color={isCene ? 'content' : 'generic'}
              className={classNames(['mt-[32px] w-[335px]', 'max-sm: w-full'])}
            >
              {translation.forgotPasswordPage.backToLogin}
            </Button>
          </div>
        ) : isCene ? (
          <ForgotPasswordForm onSubmit={handleSubmit} />
        ) : (
          <ForgotPasswordCenePlusForm onSubmit={handleSubmit} />
        )}
      </div>
    </>
  );
};
