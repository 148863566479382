import { Button } from '../../components/button/button';
import { ROUTE_EVENTS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Live = '/pictures/frontImg.png';

export const LiveEventsSection = () => {
  const navigate = useNavigate();
  return (
    <div className={classNames(['lg:my-[120px] my-[80px]'])}>
      <div
        className={classNames([
          'flex flex-col container items-center justify-center mx-auto',
          'lg:flex-row lg:gap-[135px] gap-0 lg:h-full',
        ])}
      >
        <div
          className={classNames([
            'w-full  flex justify-center',
            'lg:mb-0 lg:justify-end lg:w-full',
          ])}
        >
          <img
            src={Live}
            alt="Live"
            className={classNames(['xl:w-full lg:w-full rounded-tl-[30px]'])}
          />
        </div>
        <div className={classNames(['w-full xl:w-full lg:w-full '])}>
          <div className="mt-[32px] lg:mt-[56px] xl:w-full lg:w-full">
            <h1
              className={classNames([
                'lg:text-[3.5rem] text-white lg:leading-[64px]',
                'text-[1.75rem] leading-[36px] font-light',
              ])}
            >
              {translations.homePage.liveTitle}
            </h1>
            <h2
              className={classNames([
                'text-[1.1875rem] leading-[19px]  text-village ',
                'lg:leading-[21px] lg:text-[1.3125rem] font-light mt-[24px]',
              ])}
            >
              {translations.homePage.liveDesc}
            </h2>
          </div>
          <Button
            className={classNames([
              'lg:w-[186px] lg:h-[48px] mt-[24px] bg-village',
              'w-[186px] h-[48px]',
            ])}
            type="button"
            onClick={() => navigate(ROUTE_EVENTS)}
          >
            {translations.homePage.textStep}
          </Button>
        </div>
      </div>
    </div>
  );
};
