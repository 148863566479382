import { ROUTE_HOME } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { ReactComponent as CloseSVG } from '../../svg/closeIconGeneric.svg';
import { ReactComponent as ErrorSVG } from '../../svg/errorVillage.svg';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = '/pictures/logoError.jpg';

// Function for creating an email link
const createEmailLink = (text: string): string => {
  const emailRegex = /support@cene\.xyz/g;
  return text.replace(
    emailRegex,
    (match: string) =>
      `<a href="mailto:${match}" class="text-white underline">${match}</a>`
  );
};

export const ErrorPage: React.FC = () => {
  // Convert the description with an email link
  const desc = createEmailLink(translations.ErrorPage.desc);

  return (
    <div className="w-full flex flex-col items-center m-auto max-lg:w-full lg:mt-[32px] mt-[16px] px-[20px] lg:px-0 h-screen overflow-x-hidden">
      <div className="flex items-center justify-between w-full lg:px-[64px]">
        <LogoSVG />
        <Link to={ROUTE_HOME}>
          <button className="flex justify-center items-center border border-gray-medium rounded-tl-[16px] rounded-br-[16px] w-[48px] h-[48px]">
            <div className="h-[24px] w-[24px] flex justify-center items-center">
              <CloseSVG />
            </div>
          </button>
        </Link>
      </div>

      <div className="flex flex-col items-center text-center lg:w-[360px] lg:mt-[120px] justify-center max-lg:flex-grow">
      
        <div className="max-lg:h-[50vh] flex flex-col items-center justify-center lg:h-auto">
          <ErrorSVG className="mb-[16px]" />
          <h1
            className={classNames([
              'text-[2.25rem] leading-[40px] text-village lg:mb-[16px] mb-[12px] font-light',
              'text-center',
              'lg:w-[335px]',
              'lg:text-[4.5rem] lg:leading-[80px]',
            ])}
          >
            {translations.ErrorPage.title}
          </h1>
          <h2
            className="text-white text-[1rem] leading-[24px]"
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        </div>
      </div>

      {/* Image Section */}
      <div className="block w-screen relative lg:mt-[83px] mt-auto max-lg:h-[50vh]">
        <img
          src={Logo}
          alt="Banner"
          className="w-full h-full object-cover max-lg:object-[30%]"
        />
      </div>
    </div>
  );
};
