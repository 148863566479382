import { ReactComponent as CalendarBlankSvg } from '../../../src/svg/CalendarBlankGeneric.svg';
import { ReactComponent as MapSvg } from '../../../src/svg/MapPinLineGeneric.svg';
import { ReactComponent as ArrowRighEventtSvg } from '../../../src/svg/arrowEvent.svg';
import { ReactComponent as ArrowRightSvg } from '../../../src/svg/arrowUpGenericFilter.svg';
import { ReactComponent as CloseSvg } from '../../../src/svg/close.svg';
import translations from '../../constants/translations.json';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { Header } from '../../layout/header/header';
import classNames from 'classnames';
import React from 'react';

interface ModalProps {
  isOpen: boolean;
  session?: string | undefined;
  onClose: () => void;
}

const eventDetails = [
  {
    location: translations.eventDetailsSection.location,
    date: translations.eventDetailsSection.date,
  },
  {
    location: translations.eventDetailsSection.location,
    date: translations.eventDetailsSection.date,
  },
  {
    location: translations.eventDetailsSection.location,
    date: translations.eventDetailsSection.date,
  },
  {
    location: translations.eventDetailsSection.location,
    date: translations.eventDetailsSection.date,
  },
];

export const ModalDate = ({ isOpen, onClose, session }: ModalProps) => {
  useDisableBodyScroll(isOpen);
  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const sessionType = session === 'cene+';

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-[16px] flex justify-center items-center z-[100]"
      onClick={handleOverlayClick}
    >
      <div
        className="bg-black lg:w-[365px] w-full relative lg:rounded-tl-[30px] lg:rounded-br-[30px] border border-gray-medium lg:h-[656px] h-full max-h-full lg:max-h-[90%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="lg:hidden flex px-[20px]">
          <Header />
        </div>

        <div className="flex justify-between lg:justify-center items-center lg:pt-[32px] pb-[24px] px-[20px] lg:px-0 pt-[16px]">
          <h1
            className={classNames([
              'text-[2rem] leading-[40px]',
              sessionType ? 'text-generic' : 'text-white ',
            ])}
          >
            {translations.eventDetailsSection.otherDate}
          </h1>
          <button
            onClick={onClose}
            aria-label="Close"
            className="p-[8px] border border-gray-medium rounded-tl-[16px] rounded-br-[16px] lg:hidden"
          >
            <CloseSvg />
          </button>
        </div>

        <div className="h-full lg:h-[calc(100%-96px)] overflow-y-auto lg:px-8 px-[20px]">
          {eventDetails.map((detail, index) => (
            <div
              className="lg:mb-[32px] mb-[40px] lg:max-w-[300px] max-w-full"
              key={index}
            >
              <div className="flex items-start">
                <MapSvg className="mr-[12px]" />
                <div className="flex-1 overflow-hidden">
                  <p
                    className={classNames([
                      'text-[1.0625rem] leading-[22px]',
                      sessionType ? 'text-generic' : 'text-white ',
                    ])}
                  >
                    {detail.location}
                  </p>
                </div>
              </div>
              <div className="mt-[8px] flex">
                <CalendarBlankSvg className="mr-[12px]" />
                <div className="flex-1 overflow-hidden">
                  <p
                    className={classNames([
                      'text-[1.0625rem] leading-[22px]',
                      sessionType ? 'text-generic' : 'text-white ',
                    ])}
                  >
                    {detail.date}
                  </p>
                  <div
                    className={classNames([
                      'mt-[8px]  text-[1rem] leading-[24px] flex items-center',
                      sessionType ? 'text-generic' : 'text-event ',
                    ])}
                  >
                    <p className="mr-[12.5px] whitespace-nowrap overflow-hidden text-ellipsis">
                      {translations.eventDetailsSection.bookNow}
                    </p>
                    {sessionType ? (
                      <ArrowRightSvg className="transform rotate-90" />
                    ) : (
                      <ArrowRighEventtSvg className="transform rotate-180" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
