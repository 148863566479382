import { Button } from '../../../components/button/button';
import { Icon } from '../../../components/icon/icon';
import { Input } from '../../../components/input/cene/input';
import { Label } from '../../../components/label/cene/label';
import { ROUTE_LOGIN } from '../../../constants/routes';
import translation from '../../../constants/translations.json';
import {
  ForgotPasswordSchema,
  forgotPasswordSchema,
} from '../../../schemas/forgotPasswordSchema';
import { supabaseCeneClient } from '../../../supabase/supabase';
import { ReactComponent as BackSVG } from '../../../svg/arrow-left.svg';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IFormProps {
  onSubmit: () => void;
}

export const ForgotPasswordForm = ({ onSubmit = () => null }: IFormProps) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const formik = useFormik<ForgotPasswordSchema>({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        setLoading(true);
        const { error } = await supabaseCeneClient.auth.resetPasswordForEmail(
          values.email,
          {
            redirectTo: `${window.location.origin}/auth/forgot-password/create-new-password`,
          }
        );

        if (error) throw error;

        onSubmit();
      } catch (error) {
        if (error) return setFieldError('email', 'Your email is not verified');
      } finally {
        setLoading(false);
      }
    },
  });

  const onBackStep = useCallback(() => {
    navigate(ROUTE_LOGIN, { state: { type: 'cene' } });
  }, []);

  return (
    <div className={classNames(['w-full flex flex-col', 'px-5 sm:px-0'])}>
      <div className="mb-[24px]">
        <Icon onClick={onBackStep}>
          <BackSVG />
        </Icon>
      </div>
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.forgotPasswordPage.titleCene}
      </h1>
      <p className="text-[0.875rem] text-generic font-light ">
        {translation.forgotPasswordPage.subtitleCene}
      </p>
      <div className="flex flex-col">
        <div className="mt-[40px]">
          <Label id="email" isRequired={true}>
            {translation.forgotPasswordPage.email}
          </Label>
          <Input
            id="email"
            type="email"
            placeholder={translation.forgotPasswordPage.enterEmail}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.email}
            touched={Boolean(formik.touched.email)}
          />
        </div>
        <Button
          onClick={formik.handleSubmit}
          className={classNames([
            'w-full ',
            'mt-[26vh] mb-[24px] sm:mt-[24px]',
          ])}
          disabled={isLoading || !formik.isValid || !formik.dirty}
          color="content"
          type="submit"
        >
          {translation.forgotPasswordPage.continue}
        </Button>
      </div>
    </div>
  );
};
