import { useEffect } from 'react';

type UseAutoCloseFiltersParams = {
  showFiltersPage: boolean;
  handleCloseFilters: () => void;
};

export const useAutoCloseFilters = ({
  showFiltersPage,
  handleCloseFilters,
}: UseAutoCloseFiltersParams) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && showFiltersPage) {
        handleCloseFilters();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showFiltersPage, handleCloseFilters]);
};
