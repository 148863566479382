import classNames from 'classnames';
import React, { useState } from 'react';

interface CollapsibleSectionProps {
  title: string;
  description: string;
  isFirst?: boolean; 
  isLast?: boolean;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  description,
  isFirst = false,
  isLast = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={classNames([
        ' border-t border-t-gray-medium rounded-lg pt-8 pb-8 w-full sm:w-full lg:px-0',
        {
          'border-t border-t-gray-medium': isFirst,
          'border-b border-b-gray-medium': isLast,
          'border-t-white border-t': isOpen, 
        },
      ])}
      onClick={toggleSection}
    >
      <div className="flex justify-between items-center">
        <h2
          className={classNames(
            'text-[1.1875rem] leading-[19px] font-light',
            isOpen ? 'text-village' : 'text-white',
            'lg:text-[1.3125rem] lg:leading-[21px]'
          )}
        >
          {title}
        </h2>
        <div>
          <button
            className={classNames([
              `w-[44px] h-[44px] flex justify-center items-center text-[1rem] rounded-none rounded-tl-[20px] rounded-br-[20px]
                    ${isOpen ? 'bg-village text-black' : 'bg-gray-dark text-white'}`,
              'sm:text-[1.125rem] sm:w-11 sm:h-11',
            ])}
          >
            {isOpen ? '-' : '+'}
          </button>
        </div>
      </div>
      {isOpen && (
        <p className="mt-4 lg:text-[1.0625rem] lg:leading-[22px] font-light text-white whitespace-pre-line text-[1rem] leading-[21px]">
          {description}
        </p>
      )}
    </div>
  );
};
