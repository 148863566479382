import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import AttendedEventsSection from '../../sections/myTickets/attended';
import UpcomingEventsSection from '../../sections/myTickets/upcoming';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

enum Filter {
  PURCHASED = 'purchased',
  RECEIVED = 'received',
}

const MyTicketsPage = () => {
  const { session } = useSupabase();

  const [activeFilter, setActiveFilter] = useState<Filter>(Filter.PURCHASED);
  const [tickets, setTickets] = useState<Event[]>([]);
  const totalTickets = tickets.reduce((sum, item) => {
    if (item.tickets && Array.isArray(item.tickets)) {
      return sum + item.tickets.length;
    }
    return sum;
  }, 0);

  const handleFilterChange = (filter: Filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const client = sessionType
          ? supabaseCeneClient
          : supabaseCenePlusClient;
        const { data, error } = await client
          .from('tickets')
          .select<string, Event>('tickets');

        if (error) throw error;

        setTickets(data || []);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, []);

  const sessionType = session?.type == 'cene';

  const sessionText = sessionType ? 'text-ticket' : ' text-generic';
  return (
    <div className={classNames('mt-[2.5rem]', 'max-lg:mt-[1rem]')}>
      <h1
        className={classNames(
          'text-[2.25rem]/[2.75rem] font-light',
          'lg:text-[4.5rem]/[5rem]',
          sessionText
        )}
      >
        {sessionType
          ? translations.myTickets.titleCene
          : translations.myTickets.titleCenePlus}
      </h1>
      <div
        className={classNames(
          'flex items-center gap-[1rem] mt-[2.5rem]',
          'max-lg:mt-[1.5rem]'
        )}
      >
        <Button
          onClick={() => handleFilterChange(Filter.PURCHASED)}
          className={classNames(
            'w-[160px] h-[48px] font-medium !gap-[4px] border !text-[0.75rem]/[1.125rem]',
            activeFilter === Filter.PURCHASED
              ? 'text-black bg-generic'
              : 'text-generic bg-transparent border-gray-medium',
            sessionType ? 'bg-ticket' : 'bg-generic'
          )}
        >
          {translations.myTickets.purchasedTickets}
          <span
            className={classNames(
              '!text-[0.625rem]/[0.625rem] text-gray-light',
              activeFilter === Filter.PURCHASED
                ? 'text-gray-medium'
                : 'text-gray-light'
            )}
            style={{ position: 'relative', top: '-6px' }}
          >
            {totalTickets}
          </span>
        </Button>
        <Button
          onClick={() => handleFilterChange(Filter.RECEIVED)}
          className={classNames(
            'w-[160px] h-[48px] font-medium !gap-[4px] border !text-[0.75rem]/[1.125rem]',
            activeFilter === Filter.RECEIVED
              ? 'text-black bg-generic'
              : `bg-transparent border-gray-medium ${sessionText}`,
            sessionType ? 'bg-ticket' : 'bg-generic'
          )}
        >
          {translations.myTickets.ticketsReceived}
          <span
            className={classNames(
              '!text-[0.625rem]/[0.625rem] text-gray-light',
              activeFilter === Filter.RECEIVED
                ? 'text-gray-medium'
                : 'text-gray-light'
            )}
            style={{ position: 'relative', top: '-6px' }}
          >
            0
          </span>
        </Button>
      </div>
      <UpcomingEventsSection />
      <AttendedEventsSection />
    </div>
  );
};
export default MyTicketsPage;
