import { Loader } from '../../components/loader/cene/loader';
import translations from '../../constants/translations.json';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const LoadingPaymentPage = () => {
  const params = useLocation();
  const [progress, setProgress] = React.useState(
    params.state?.skipLoading ? 100 : 0
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (progress >= 100) {
        clearInterval(interval);
      } else {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 1
        );
      }
    }, 44);

    return () => clearInterval(interval);
  }, [progress]);

  return (
    <div
      className={classNames(
        'fixed inset-0 bg-loading-bg bg-no-repeat bg-cover flex flex-col items-center justify-center',
        'max-sm:bg-loading-bg-mobile',
        'z-50'
      )}
    >
      <div
        className={classNames(
          'w-full h-[88px] flex items-center justify-center mb-[40px] px-[20px]',
          'max-lg:h-[72px] max-lg:px-[20px] max-lg:border-b border-[#ffffff28] max-lg:mb-0'
        )}
      >
        <LogoSVG
          className={classNames(
            'w-[164px] h-[48px]',
            'max-lg:w-[137px] max-lg:h-[40px]'
          )}
        />
      </div>
      <div
        className={classNames(
          'flex flex-col items-center justify-center w-full h-full',
          'max-lg:h-[calc(100vh-72px)]'
        )}
      >
        <div className="text-center">
          <h2 className="text-[2rem] text-white font-light w-[266px]">
            {translations.loadingPayment.title}
          </h2>
          <div className="mt-[48px] flex flex-col items-center">
            <Loader />
          </div>
          <p className="text-[1.3125rem] font-light text-generic mt-[48px]">
            {progress}%
          </p>
        </div>
      </div>
    </div>
  );
};
