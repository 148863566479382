export const ROUTE_HOME = '/';

export const ROUTE_EVENTS = '/explore-events';

export const ROUTE_EVENTS_PLUS = '/explore-events-plus';

export const ROUTE_TICKETING = '/workwithus/tiers-and-plans';

export const ROUTE_MEMBERSHIP = '/cene+';

export const ROUTE_BURST = '/workwithus/cene-partners';

export const ROUTE_PARTNER_WITH_US = '/workwithus/become-a-partner';

export const ROUTE_CONTACT_WITH_US = '/contact-us';

export const ROUTE_DELETE_ACCOUNT = '/delete-account';

export const ROUTE_FAQ = '/faqs';

export const ROUTE_DOWNLOAD = '/download';

export const ROUTE_NOT_FOUND = '/404';

export const ROUTE_TERMS = '/terms';

export const ROUTE_DIVERSITY = '/diversity';

export const ROUTE_CAREERS = '/careers';

export const ROUTE_AUTH = '/auth';

export const ROUTE_LOGIN = '/login';

export const ROUTE_CENE = '/cene';

export const ROUTE_EVENTS_DETAILS = '/event-details/:id';

export const ROUTE_ORGANIZER = '/organizer';

export const ROUTE_CENE_PLUS = '/cene-plus';

export const ROUTER_REGISTER = ROUTE_AUTH + '/register';

export const ROUTER_ABOUT = '/who-we-are';

export const ROUTE_SUCCESS = '/success';

export const ROUTE_DELETE_SUCCESSFUL = '/delete-account/submitted';

export const ROUTE_ERROR = '/error';

export const ROUTER_WAITLIST = '/waitlist';

export const ROUTER_FAVOURITE = '/favourite';

export const ROUTER_FAVOURITE_PLUS = '/favourite-plus';

export const ROUTER_REGISTER_CENE = ROUTER_REGISTER + ROUTE_CENE;

export const ROUTER_REGISTER_CENE_PLUS = ROUTER_REGISTER + ROUTE_CENE_PLUS;

export const ROUTER_REGISTER_GUEST = ROUTE_AUTH + '/guest';

export const ROUTER_REGISTER_CENE_PLUS_RESEND =
  ROUTER_REGISTER_CENE_PLUS + '/resend';

export const ROUTER_REGISTER_CENE_PLUS_TERM =
  ROUTER_REGISTER_CENE_PLUS + '/term';

export const ROUTER_REGISTER_CENE_PLUS_THANKS =
  ROUTER_REGISTER_CENE_PLUS + '/thanks';

export const ROUTER_REGISTER_CENE_PLUS_CONTINUE_REGISTRTION =
  ROUTER_REGISTER_CENE_PLUS + '/continue';

export const ROUTER_REGISTER_CENE_PLUS_SECOND_PART =
  ROUTER_REGISTER_CENE_PLUS + '/second-part';

export const ROUTER_LOADER = ROUTER_REGISTER + '/loader';

export const ROUTE_WELCOME = '/welcome';

export const ROUTER_LOADER_PLUS =
  ROUTER_REGISTER_CENE_PLUS_SECOND_PART + '/loader-plus';

export const ROUTER_FORGOT_PASSWORD = ROUTE_AUTH + '/forgot-password';

export const ROUTER_FORGOT_PASSWORD_CREATE_NEW_PASSWORD =
  ROUTER_FORGOT_PASSWORD + '/create-new-password';

export const ROUTER_FORGOT_PASSWORD_RESET_SUCCESS =
  ROUTER_FORGOT_PASSWORD + '/reset-success';

export const ROUTER_MY_TICKETS = '/my-tickets';

export const ROUTER_MY_EVENTS = '/my-events';

export const ROUTE_PROFILE = '/profile';

export const ROUTE_SETTINGS = '/settings';

export const ROUTER_SETTINGS_NOTIFICATIONS = ROUTE_SETTINGS + '/notifications';
export const ROUTER_SETTINGS_INTERESTS = ROUTE_SETTINGS + '/interests';
export const ROUTER_SETTINGS_MUSIC = ROUTE_SETTINGS + '/music';
export const ROUTER_SETTINGS_RESET_PASSWORD =
  ROUTE_SETTINGS + '/reset-password';

export const ROUTER_MY_BASKET = '/my-basket';

export const ROUTER_TICKET_DETAILS = '/ticket-details/:id';

export const ROUTER_CHOOSE_TABLES = '/event/:id/tables';
