import { SelectableObject } from '../types/seatsio';
import { Ticket } from '@ceneteam/namespace';
import { ReactNode, createContext, useContext, useState } from 'react';

export type TCart = Ticket & {
  selectableObject: SelectableObject & { seatId: string };
};

export interface CenePlusBasketProps {
  cart: TCart[];
  addCartItem: (value: TCart) => void;
  removeCartItem: (id: string) => void;
  clearCart: () => void;
}

const CenePlusBasketContext = createContext<CenePlusBasketProps>({
  cart: [],
  addCartItem: () => null,
  removeCartItem: () => null,
  clearCart: () => null,
});

export const CenePlusBasketContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [cart, setCart] = useState<TCart[]>([]);

  const addCartItem = (ticket: TCart) => {
    setCart([...cart, ticket]);
  };

  const removeCartItem = (id: string) => {
    setCart((prev) =>
      prev.filter((item) => item.selectableObject.seatId !== id)
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CenePlusBasketContext.Provider
      value={{
        cart,
        clearCart,
        addCartItem,
        removeCartItem,
      }}
    >
      {children}
    </CenePlusBasketContext.Provider>
  );
};

export const useCenePlusCart = () => {
  const context = useContext(CenePlusBasketContext);

  if (context === undefined) {
    throw new Error(
      'useCenePlusCart must be used within a CenePlusBasketContextProvider'
    );
  }
  return context;
};
