import { ReactComponent as DeviceWhiteSvg } from '../../../src/svg/DeviceMobileCamera.svg';
import classNames from 'classnames';
import React from 'react';

type FeatureTitleProps = {
  index: number;
  title: string;
  description: string;
  activeTitle: number;
  setActiveTitle: (index: number) => void;
  isLast: boolean;
  resetProgress: () => void;
};

const PhoneImages = [
  '/pictures/phoneOneBurst.png',
  '/pictures/phoneTwoBurst.png',
  '/pictures/phoneThreeBurst.png',
];

export const FeatureTitle: React.FC<FeatureTitleProps> = ({
  index,
  title,
  description,
  activeTitle,
  setActiveTitle,
  isLast,
  resetProgress,
}) => {
  const handleClick = () => {
    setActiveTitle(index);
    resetProgress();
  };
  const isTitleActive = activeTitle === index;

  return (
    <div
      className={classNames([
        {
          'border-b border-none lg:mt-[64px] mt-[0px] border-gray-medium': !isLast, // Conditionally apply border-b
        }
      ])}
    >
      <div className='border-t border-gray-medium lg:border-none mb-[40px]'/>
      <h2
        onClick={handleClick}
        className={classNames([
          'lg:text-[2rem] lg:leading-[40px] flex items-center cursor-pointer  text-[1.125rem] leading-[26px]',
          {
            'text-event': isTitleActive,
            'text-white lg:my-[56px] my-[32px]': !isTitleActive,
            'lg:mt-[56px] mt-[32px]': index !== 0 && isTitleActive,
          },
        ])}
      >
        <DeviceWhiteSvg className="lg:mr-[24px] mr-[16px]" />
        {title}
      </h2>
      {isTitleActive && (
        <div>
          <p
            className={classNames([
              'text-[1.1875rem] text-generic lg:mt-[24px] font-light mt-[16px] lg:mb-[56px] mb-[32px] leading-[19px]',
              'lg:text-[1.3125rem] lg:leading-[21px]',
            ])}
          >
            {description}
          </p>
          <div
            className={classNames([
              'w-full flex justify-center mt-8',
              'xl:mt-0 xl:justify-end xl:hidden',
            ])}
          >
            <img
              src={PhoneImages[index]}
              alt="Phone"
              className={classNames(['w-full', ' rounded-tl-[16px] xl:max-w-none mb-[32px] lg:mb-0 lg:rounded-tl-0'])}
            />
          </div>
        </div>
      )}
    </div>
  );
};
