import classNames from 'classnames';
import { ReactNode } from 'react';

type ContentItem = ReactNode;

interface ContainerProps {
  title: string;
  contentList: ContentItem[];
  sessionType: boolean;
}

const Container = ({ title, contentList, sessionType }: ContainerProps) => {
  return (
    <div
      className={classNames('w-full flex flex-col gap-[1.5rem] text-center')}
    >
      <h1
        className={classNames(
          'text-[2.5rem]/[3rem] font-light',
          'max-lg:text-[2rem]/[2.5rem]',
          sessionType ? 'text-content' : 'text-generic'
        )}
      >
        {title}
      </h1>
      <div className="flex flex-col gap-[1.5rem] max-lg:gap-[1rem]">
        {contentList.map((item: ContentItem, index) => (
          <div
            key={index}
            className={classNames(
              'w-full px-[2.5rem] py-[1.5rem] border border-generic-light rounded-tl-[20px] rounded-br-[20px]',
              'max-lg:p-[1.25rem]'
            )}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Container;
