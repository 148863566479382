import { ModalDate } from '../../components/modalDate/modal';
import { ModalLocation } from '../../components/modalLocation/modal';
import translations from '../../constants/translations.json';
import { formatEventDate } from '../../helpers/formatDate';
import { OrganizerSection } from '../../pages/ticketDetails/organizer';
import { useSupabase } from '../../providers/supabase';
import { ReactComponent as CalendarSVG } from '../../svg/CalendarBlank.svg';
import { ReactComponent as CalendarBlankSVG } from '../../svg/CalendarBlankGeneric.svg';
import { ReactComponent as MapPinSVG } from '../../svg/MapPinLineGeneric.svg';
import { ReactComponent as RightArrowSVG } from '../../svg/arrowRightCenePlus.svg';
import { ReactComponent as RightArrowCeneSVG } from '../../svg/caretRight.svg';
import { ReactComponent as MapSVG } from '../../svg/mapTrifold.svg';
import { Event } from './aboutEvent';
import classNames from 'classnames';
import { useState } from 'react';

interface Ticket {
  ticket: Event;
}

const LocationSection = ({ ticket }: Ticket) => {
  const { session } = useSupabase();
  const [isLocationOpen, setLocationOpen] = useState(false);
  const [isDateOpen, setDateOpen] = useState(false);
  const sessionType = session?.type == 'cene' || session?.type == undefined;
  const formattedDate = formatEventDate(ticket?.starts_at || '');

  const toggleModalDate = () => {
    setDateOpen(!isDateOpen);
  };

  const toggleModalLocation = () => {
    setLocationOpen(!isLocationOpen);
  };

  return (
    <>
      <div className="bg-generic-06 border border-gray-medium rounded-tl-[30px] rounded-br-[30px] flex flex-col lg:gap-[24px] gap-[16px] lg:p-[40px] p-[20px]">
        <p
          className={classNames(
            'leading-[12px] font-normal',
            sessionType ? 'text-ticket' : 'text-white'
          )}
        >
          {translations.eventDetailsSection.locatioTitlePlus}
        </p>

        <div className="flex items-center gap-[1.25rem]">
          {sessionType ? (
            <MapPinSVG style={{ minWidth: '32px', minHeight: '32px' }} />
          ) : (
            <MapSVG style={{ minWidth: '32px', minHeight: '32px' }} />
          )}
          <div className="flex items-center w-full">
            <p
              className={classNames(
                'lg:text-[1.3125rem] lg:leading-[21px] flex-grow text-[1.0625rem] leading-[22px] max-md:max-w-[70%]',
                sessionType ? 'text-white' : 'text-generic'
              )}
            >
              {ticket?.address}
            </p>
            <button
              className="ml-auto bg-gray-dark w-[40px] h-[40px] rounded-tl-[8px] rounded-br-[8px] flex items-center justify-center"
              onClick={toggleModalLocation}
            >
              {sessionType ? (
                <RightArrowCeneSVG style={{ stroke: '#ec67f7' }} />
              ) : (
                <RightArrowSVG />
              )}
            </button>
          </div>
        </div>

        <div className="flex items-center mb-[26px] lg:mb-0 gap-[1.25rem]">
          {!sessionType ? (
            <CalendarSVG style={{ minWidth: '32px', minHeight: '32px' }} />
          ) : (
            <CalendarBlankSVG style={{ minWidth: '32px', minHeight: '32px' }} />
          )}

          <div className="flex items-center w-full">
            <p
              className={classNames(
                'lg:text-[1.3125rem] lg:leading-[21px] flex-grow text-[1.0625rem] leading-[22px] max-md:max-w-[70%]',
                sessionType ? 'text-white' : 'text-generic'
              )}
            >
              {formattedDate || translations.eventDetailsSection.date}
            </p>
            <button
              className="ml-auto bg-gray-dark w-[40px] h-[40px] rounded-tl-[8px] rounded-br-[8px] flex items-center justify-center"
              onClick={toggleModalDate}
            >
              {sessionType ? (
                <RightArrowCeneSVG style={{ stroke: '#ec67f7' }} />
              ) : (
                <RightArrowSVG />
              )}
            </button>
          </div>
        </div>
        <div className="lg:hidden">
          <OrganizerSection sessionType={sessionType} event={ticket} />
        </div>
      </div>
      <ModalDate
        isOpen={isDateOpen}
        onClose={toggleModalDate}
        session={session?.type}
      />
      <ModalLocation
        session={session?.type}
        isOpen={isLocationOpen}
        onClose={toggleModalLocation}
      />
    </>
  );
};

export default LocationSection;
