import { ReactNode, createContext, useContext, useState } from 'react';

export interface CeneModeProps {
  ceneMode: 'cene' | 'cene+';
  setMode: (ceneMode: 'cene' | 'cene+') => void;
}

const CeneModeContext = createContext<CeneModeProps>({
  ceneMode: 'cene',
  setMode: () => null,
});

export const CeneModeProvider = ({ children }: { children: ReactNode }) => {
  const [ceneMode, setMode] = useState<CeneModeProps['ceneMode']>('cene');

  return (
    <CeneModeContext.Provider
      value={{
        ceneMode,
        setMode,
      }}
    >
      {children}
    </CeneModeContext.Provider>
  );
};

export const useCeneMode = () => {
  const context = useContext(CeneModeContext);

  if (context === undefined) {
    throw new Error('useCeneMode must be used within a CeneModeProvider');
  }
  return context;
};
