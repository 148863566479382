import { Button } from '../../components/button/button';
import { ROUTER_REGISTER_CENE_PLUS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Logo = '/pictures/membershipBanner.png';
const LogoSmall = '/pictures/bannerMembershipSmall.png';

export const MembershipDesc = () => {
  usePreventHorizontalScroll();
  const navigate = useNavigate();

  return (
    <div
      className={classNames([
        'relative w-full h-auto flex flex-col justify-center items-center lg:mt-[92px]',
        'lg:px-0',
      ])}
    >
      <div className="relative lg:hidden w-screen lg:h-[578px] h-[554px]">
        <img
          src={LogoSmall}
          alt="Banner"
          className="object-cover w-full h-full"
        />

        <div className="absolute inset-0 flex flex-col mt-[115px]  items-center z-[1] px-[20px]">
          <p className="text-gray-light text-[1rem] leading-[21px] mb-[16px] text-center font-normal">
            {translations.membership.titleTwo}
          </p>
          <h1
            className={classNames([
              'text-[2.25rem] leading-[44px] font-light text-generic text-center lg:whitespace-pre-line',
              'lg:text-[4.5rem] lg:leading-[80px]',
            ])}
          >
            {translations.membership.title}
          </h1>
          <h3 className="text-[1rem] font-light text-generic text-center mt-[16px] w-full lg:w-[537.92px] leading-[21px] ">
            {translations.membership.description}
          </h3>
          <div className="flex mt-[16px] w-full justify-center space-x-4">
            <Button
              color="generic"
              type="button"
              className={classNames([
                'lg:w-[18px] lg:h-[48px]',
                'w-[198px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px]',
              ])}
              onClick={() => navigate(ROUTER_REGISTER_CENE_PLUS)}
            >
              {translations.membership.textButton}
            </Button>
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className=" hidden lg:flex flex-col justify-center items-center">
        <p className="text-gray-light text-[1.0625rem] leading-[22px] mb-[24px] text-center font-normal">
          {translations.membership.titleTwo}
        </p>
        <h1
          className={classNames([
            'text-[3.5rem] leading-[64px] font-light text-generic text-center whitespace-pre-line',
            'lg:text-[4.5rem] lg:leading-[80px]',
          ])}
        >
          {translations.membership.title}
        </h1>
        <h3 className="text-[1.0625rem] font-light text-generic text-center mt-[24px] w-full max-w-[537.92px] leading-[22px] lg:px-0 px-[20px]">
          {translations.membership.description}
        </h3>
        <div
          className={classNames([
            'flex mt-[24px] w-full justify-center',
          ])}
        >
          <Button
            color="generic"
            type="button"
            className={classNames([
              'lg:w-[199px] lg:h-[46px]',
              'w-[159.5px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px]',
            ])}
            onClick={() => navigate(ROUTER_REGISTER_CENE_PLUS)}
          >
            {translations.membership.textButton}
          </Button>
        </div>

        <div className="relative w-screen h-[613px] overflow-hidden">
          <img
            src={Logo}
            alt="Banner"
            className="object-contain w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};
