import classNames from 'classnames';
import { ReactComponent as ShareIcon } from '../../../../src/svg/share-event.svg';
import { Friend } from './ friendList';

interface FooterProps {
  filteredFriends: Friend[];
  selectedFriends: Set<number>;
  isInvaiting: boolean;
  setIsInvaiting: React.Dispatch<React.SetStateAction<boolean>>;
  onShare: () => void;
  sessionType:boolean
}

export const FooterShare: React.FC<FooterProps> = ({
  filteredFriends,
  selectedFriends,
  sessionType,
  onShare,
}) => (
  <>
    {selectedFriends.size > 0 && (
      <button
        onClick={onShare}
        className={classNames(["py-3 px-6  text-black rounded-lg flex items-center justify-between  rounded-tl-[20px] rounded-br-[20px]",sessionType?'bg-ticket':'bg-generic'])}
      >
        {selectedFriends.size === 1
          ? `Share Ticket with: ${
              filteredFriends.find(
                (friend) => friend.id === [...selectedFriends][0]
              )?.name
            }`
          : selectedFriends.size <= 2
            ? `Share Tickets with: ${[...selectedFriends]
                .map(
                  (id) =>
                    filteredFriends.find((friend) => friend.id === id)?.name
                )
                .join(', ')}`
            : `Share Tickets with: ${selectedFriends.size} people`}
        <ShareIcon stroke="#000000" />
      </button>
    )}
  </>
);
