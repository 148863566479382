import { ReactComponent as HourGenericSvg } from '../../../../../src/svg/HourglassSimpleHighGeneric.svg';
import { ReactComponent as HourSvg } from '../../../../../src/svg/hourglassSimpleHigh.svg';
import { Button } from '../../../../components/button/button';
import { ROUTE_EVENTS, ROUTE_EVENTS_PLUS } from '../../../../constants/routes';
import translations from '../../../../constants/translations.json';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface WaitlistMessagesnProps {
  session: string | undefined;
}

export const NoWaitlistMessage: React.FC<WaitlistMessagesnProps> = ({
  session,
}) => {
  const navigate = useNavigate();

  const sessionType = session == 'cene';
  const sessionText = sessionType ? 'text-white' : 'text-generic';
  const sessionBg = sessionType ? 'bg-event' : 'bg-generic';
  const navigateButton = sessionType ? ROUTE_EVENTS : ROUTE_EVENTS_PLUS;

  return (
    <div className="flex flex-col items-center justify-center lg:my-[96px]">
      <div className="flex flex-col items-center  w-full lg:max-w-[576px]">
        {sessionType ? <HourSvg /> : <HourGenericSvg />}
        <h1
          className={classNames(
            'lg:text-[3rem] lg:leading-[56px] text-center text-[1.5rem] leading-[36px] mt-[16px] lg:mt-[24px] font-light',
            sessionText
          )}
        >
          {sessionType
            ? translations.noWaitlist.title
            : translations.noWaitlist.titlePlus}
        </h1>
        <p
          className={classNames(
            'lg:text-[1.0625rem] lg:leading-[22px] text-center mb-6 mt-2 font-light text-[16px] leading-[21px]',
            sessionText
          )}
        >
          {sessionType
            ? translations.noWaitlist.text
            : translations.noWaitlist.textPlus}
        </p>
        <Button
          className={classNames(
            ' w-[214px] lg:h-[48px] mb-[40px] lg:mb-0 font-medium',
            sessionBg
          )}
          onClick={() => navigate(navigateButton)}
        >
          {sessionType
            ? translations.noWaitlist.textButton
            : translations.noWaitlist.textButtonPlus}
        </Button>
      </div>
    </div>
  );
};
