import translations from '../../constants/translations.json';
import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
// import { CompaniesSection } from '../../sections/about/companies';
import { DiscoverAboutSection } from '../../sections/about/discover';
import { StorySection } from '../../sections/about/story';
import { ValuesSection } from '../../sections/about/values';
import classNames from 'classnames';

export const AboutPage = () => {
  usePreventHorizontalScroll();

  return (
    <div className="flex flex-col items-center justify-center w-full ">
      <StorySection />
      {/* <CompaniesSection /> */}
      <div className={classNames(['lg:w-[1444px] lg:h-[296px] w-full'])}>
        <h2
          className={classNames([
            'text-white text-center pb-[32px] text-[2rem] leading-[40px]',
            'lg:text-[4rem] lg:leading-[72px] font-light lg:pt-[160px] lg:pb-[64px]',
          ])}
        >
          {translations.aboutPage.titleOur}
        </h2>
      </div>
      <ValuesSection />
      <DiscoverAboutSection />
    </div>
  );
};
