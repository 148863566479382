import translation from '../../constants/translations.json';
import { ReactComponent as MessageSVG } from '../../svg/EnvelopeSimple.svg';

export const CheckEmail = () => {
  return (
    <div className="flex flex-col gap-[16px] text-center justify-center items-center mt-[40px]">
      <MessageSVG stroke="white" />
      <h1 className="text-[2rem] text-white font-light">
        {translation.steps.checkEmail.title}
      </h1>
      <p className="text-[1rem]/[1.5rem] text-white font-light">
        {translation.steps.checkEmail.description}
      </p>
    </div>
  );
};
