import { ReactComponent as AppStoreSvg } from '../../../src/svg/appStoreCene.svg';
import { ReactComponent as AppStoreCenePlusSvg } from '../../../src/svg/storeCenePlus.svg';
import { ReactComponent as GoogleSvg } from '../../../src/svg/googlePlayCene.svg';
import { ReactComponent as GooglePlusSvg } from '../../../src/svg/googlePlayCenePlus.svg';
import { ReactComponent as LogoSvg } from '../../../src/svg/logo.svg';
import { ReactComponent as LogoPlusSvg } from '../../../src/svg/logotype.svg';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

const PhoneSilverCenePlus = '/pictures/phoneCenePlus.png';
const PhoneSilverCene = '/pictures/phoneCene.png';

export const DownloadSection = () => {
  return (
    <>
      <div
        className={classNames([
          'flex flex-col justify-center relative',
          'lg:flex-row',
        ])}
      >
        <div
          className={classNames(
            'w-full bg-gradient-bg flex flex-col items-center justify-center rounded-tl-[28.7px]',
            'lg:w-full lg:h-[746px] lg:rounded-tl-[56px] '
          )}
        >
          <div
            className={classNames([
              'w-[221px] flex flex-col items-center justify-center mt-[24px]',
              'lg:w-[369px] lg:h-[224px] lg:mt-[48px]  ',
            ])}
          >
            <LogoSvg />
            <h4
              className={classNames([
                'text-white font-light text-center text-[1.3125rem] leading-[21px] lg:mt-4 mt-[12px]',
                'lg:leading-[40px] lg:text-[2rem] ',
              ])}
            >
              {translations.downloadPage.titleCene}
            </h4>
            <div className="flex gap-x-4 lg:mt-[24px] mt-[12px]">
              <a
                href="https://www.apple.com/app-store/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppStoreSvg className="lg:w-auto w-[120px]" />
              </a>
              <a
                href="https://play.google.com/store/games?device=windows&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GoogleSvg className="lg:w-auto w-[120px]" />
              </a>
            </div>
          </div>

          <img
            src={PhoneSilverCene}
            alt="Phone"
            className={classNames([
              'w-auto h-[356.52px] mt-[20px]',
              'lg:w-[330px] lg:h-[613px] lg:mt-[40px]',
            ])}
          />
          <div
            className={classNames([
              'absolute lg:top-[488.5px] top-[489px] left-0 right-0 h-[81.96px] bg-phone to-transparent',
              'lg:hidden ',
            ])}
          />
        </div>

        <div
          className={classNames([
            'w-full  border border-generic flex flex-col items-center justify-center rounded-tl-[30px]',
            'lg:w-full lg:h-[746px] border-t-[1px] mt-[32px] lg:mt-0 lg:rounded-tl-[0px] rounded-br-[30px] border-b-0',
          ])}
        >
          <div
            className={classNames([
              'w-[291px] flex flex-col items-center justify-center mt-[24px]',
              'lg:w-[520px] lg:h-[224px] lg:mt-[48px] ',
            ])}
          >
            <LogoPlusSvg width={165.49} height={48} />
            <h4
              className={classNames([
                'text-generic font-light text-center text-[1.3125rem] leading-[21px] lg:mt-4 mt-[12px]',
                'lg:text-[2rem] lg:leading-[40px] ',
              ])}
            >
              {translations.downloadPage.titleCenePlus}
            </h4>
            <div className="flex gap-x-4 lg:mt-[24px] mt-[12px]">
              <a
                href="https://www.apple.com/app-store/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppStoreCenePlusSvg className="lg:w-auto w-[120px]" />
              </a>
              <a
                href="https://play.google.com/store/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GooglePlusSvg className="lg:w-auto w-[120px]" />
              </a>
            </div>
          </div>
          <img
            src={PhoneSilverCenePlus}
            alt="Silver"
            className={classNames([
              'w-auto h-[356.52px]  mt-[20px]',
              'lg:w-[330px] lg:h-[613px] lg:mt-[40px]',
            ])}
          />
        </div>
        <div className="absolute bottom-[-1px] left-0 right-0 h-[160px]  bg-phone  to-transparent" />
      </div>
    </>
  );
};
