import { ReactComponent as LeftQuoteSvg } from '../../../src/svg/arrowEvent.svg';
import { ReactComponent as LeftArrowPlusSvg } from '../../../src/svg/arrowLeftCenePlus.svg';
import { ReactComponent as CheckSvg } from '../../../src/svg/checkOne.svg';
import { ReactComponent as CloseSvg } from '../../../src/svg/close.svg';
import { ReactComponent as ShareEventSvg } from '../../../src/svg/share-event.svg';
import { ReactComponent as ShareSvg } from '../../../src/svg/share.svg';
import { Button } from '../../components/button/button';
import { WaitlistNotification } from '../../components/notification/waitlistNotification';
import { ShareOptions } from '../../components/shareOptions/shareOptionsOrganization';
import translations from '../../constants/translations.json';
import { useWaitlistNotification } from '../../hooks/useWaitlistNotification';
import { useSupabase } from '../../providers/supabase';
import { DiscoverSection } from '../../sections/events/cene/discover';
import { PersonalizedSection } from '../../sections/home/personalized';
import { DetailsOrganizerSection } from '../../sections/organizer/detailsOrganizer';
import { EventsOrganizerSection } from '../../sections/organizer/eventsOrganizer';
import classNames from 'classnames';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Organizations = '/pictures/logoOrganaizer.png';
const OrganizationsPlus = '/pictures/logoOrganaizerPlus.png';

export const OrganizerPage = () => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const location = useLocation();
  const partnerInfo = location.state?.partnerInfo;
  

  const [isFollowing, setIsFollowing] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const {
    showWaitlistNotification,
    handleHeartClick,
    hideWaitlistNotification,
    handleFollowClick,
  } = useWaitlistNotification();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleFollow = () => {
    setIsFollowing((prevState) => !prevState);
    handleFollowClick();
    setNotificationMessage(
      `Welcome to the club! Following ${translations.organizer.title} means you’ll be the first to get updates on their events, surprises, and epic experiences.`
    );
  };

  const handleHeart = () => {
    handleHeartClick(translations.organizer.title);
    setNotificationMessage(translations.eventDetailsSection.addWaitList);
  };

  const sessionType = session?.type === 'cene' || session?.type === undefined;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setNotificationMessage('Link copied to clipboard!');
  };

  const toggleShareOptions = () => {
    setShowShareOptions((prev) => !prev);
  };

  return (
    <>
      <div className="lg:flex items-center hidden lg:ml-[31px]">
        <button
          className={classNames([
            'text-event mr-[16px] leading-[14px] text-[0.875rem] px-[17.75px] py-[16.5px] bg-gray-dark',
            sessionType ? 'rounded-tl-[8px] rounded-br-[8px]' : 'rounded-big',
          ])}
          onClick={handleBackClick}
        >
          {sessionType ? <LeftQuoteSvg /> : <LeftArrowPlusSvg />}
        </button>
        <p className="text-white text-[0.875rem] leading-[14px]">
          {translations.organizer.back}
        </p>
      </div>

      <div
        className={classNames([
          'lg:hidden',
          'flex justify-between items-center',
        ])}
      >
        <p
          className={classNames([
            'text-[1.5rem] leading-[36px] flex-1',
            sessionType ? 'text-white ' : 'text-generic',
          ])}
        >
          {translations.organizer.organizer}
        </p>
        <button
          className="p-[8px] border border-gray-medium rounded-tl-[16px] rounded-br-[16px]"
          onClick={handleBackClick}
        >
          <CloseSvg />
        </button>
      </div>

      <DetailsOrganizerSection
        imageSrc={sessionType ? Organizations : OrganizationsPlus}
        handleFollowClick={handleFollow}
        session={session?.type}
        organizerDetails = {partnerInfo}
      />
      <EventsOrganizerSection
        handleHeartClick={handleHeart}
        session={session?.type}
      />

      <div className="lg:flex hidden">
        {sessionType ? (
          <div className="w-full flex justify-center">
            <PersonalizedSection />
          </div>
        ) : (
          <DiscoverSection
            sessionType={session?.type}
            title={translations.eventDiscover.titleTwoPlus}
            desc={translations.eventDiscover.descTwoPlus}
            similar={true}
            buttonText={translations.eventDiscover.textButton}
          />
        )}
      </div>
      <div className="lg:hidden flex w-full justify-between">
        <div>
          <button
            className={classNames([
              '   text-generic flex items-center justify-center rounded-big w-[48px] h-[48px]',
              sessionType ? 'bg-gray-dark' : 'bg-generic-light',
            ])}
            type="button"
            onClick={toggleShareOptions}
          >
            {sessionType ? <ShareEventSvg stroke='#91DBBF'/> : <ShareSvg />}
          </button>
        </div>
        <Button
          className={`w-full ml-[11px] ${isFollowing ? 'bg-village text-black border-none' : 'bg-generic-06 border border-gray-medium text-generic'} flex items-center justify-center`}
          type="button"
          onClick={handleFollow}
        >
          {isFollowing
            ? translations.eventDetailsSection.followed
            : translations.eventDetailsSection.follow}
          {isFollowing && <CheckSvg className="ml-[8px]" />}
        </Button>
      </div>

      {showShareOptions && (
        <ShareOptions
          onCopyLink={handleCopyLink}
          onClose={toggleShareOptions}
        />
      )}

      <div className="flex lg:hidden mt-[40px]">
        {sessionType ? (
          <div className="w-full flex justify-center">
            <PersonalizedSection />
          </div>
        ) : (
          <DiscoverSection
            sessionType={session?.type}
            title={translations.eventDiscover.titleTwoPlus}
            desc={translations.eventDiscover.descTwoPlus}
            similar={true}
            buttonText={translations.eventDiscover.textButton}
          />
        )}
      </div>

      <WaitlistNotification
        visible={showWaitlistNotification}
        message={notificationMessage}
        onClose={hideWaitlistNotification}
      />
    </>
  );
};
