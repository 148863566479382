import { GlobalLoader } from '../components/globalLoader/globalLoader';
import { Footer } from './footer/footer';
import { Header } from './header/header';
import { ReactNode } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

interface IRootLayout {
  withoutContainer?: boolean;
  shownHeader?: boolean;
  shownFooter?: boolean;
  guard?: ReactNode;
}

export const RootLayout = ({
  withoutContainer = false,
  shownHeader = true,
  shownFooter = true,
  guard,
}: IRootLayout) => {
  const layoutContent = (
    <>
      {shownHeader && <Header />}
      <Outlet />
    </>
  );

  return (
    <>
      <GlobalLoader>
        <ScrollRestoration />
        {withoutContainer ? (
          layoutContent
        ) : (
          <div className="px-[64px] max-w-[1440px] mx-auto max-lg:px-[20px] bg-black h-full max-lg:h-full relative">

            {layoutContent}
          </div>
        )}
        {shownFooter && (
          <div className=" bg-black h-full max-lg:h-full relative">
            <Footer />
          </div>
        )}
      </GlobalLoader>

      {guard}
    </>
  );
};
