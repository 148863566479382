import translations from '../../constants/translations.json';
import classNames from 'classnames';

const Photo = 'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/creative.gif';
const PhotoTwo = '/pictures/excellenceImg.png';
const PhotoThree = '/pictures/nobleImg.png';
const PhotoFour = 'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/welcome.gif';
const PhotoFourSmall = 'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/welcomeSmall.gif';

export const ValuesSection = () => {
  return (
    <section>
      <div className="lg:sticky ">
        <div
          className={classNames([
            ' justify-between w-full h-auto ',
            'lg:flex-row lg:sticky lg:top-0  lg:mb-[21rem] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div
            className={classNames([
              'w-auto flex flex-row text-generic lg:leading-[22px] lg:text-[1.0625rem] text-[1rem] leading-[21px] font-light py-[21px] ',
              'lg:mb-[48px]',
            ])}
          >
            01
            <p
              className={classNames([
                'text-generic  ml-[24px]',
                'lg:text-start',
              ])}
            >
              {translations.aboutPage.creative}
            </p>
          </div>
          <div
            className={classNames([
              ' justify-center items-center flex flex-col relative',
              'lg:w-full  lg:flex-row',
            ])}
          >
            <div
              className={classNames(['w-full', 'lg:w-[423px] lg:h-[268px] '])}
            >
              <h2
                className={classNames([
                  'text-ticket text-[2rem] leading-[40px] font-light pt-[40px] ',
                  'lg:pt-0 lg:text-[4rem] lg:leading-[72px]',
                ])}
              >
                {translations.aboutPage.creativeHeader}
              </h2>
              <p
                className={classNames([
                  'text-white text-[1.1875rem] font-light leading-[19px] mt-[16px]',
                  'lg:mt-[40px]',
                ])}
              >
                {translations.aboutPage.creativeDesc}
              </p>
            </div>
            <img
              src={Photo}
              alt="Photo"
              className={classNames([
                'w-full mt-[24px]  mb-[56px] rounded-tl-[30px] rounded-br-[30px] ',
                'lg:w-[656px] lg:h-[640px] lg:ml-[41px] lg:mt-0 lg:mb-0 ',
              ])}
            />
          </div>
        </div>

        <div
          className={classNames([
            'flex flex-col w-full h-auto',
            'lg:sticky lg:mt-[-14rem] lg:top-[4rem] lg:mb-[16rem] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div className="w-full flex flex-row text-generic lg:leading-[22px] lg:text-[1.0625rem] font-light text-[1rem] leading-[21px]  py-[21px] lg:mb-[48px] mb-[40px]">
            02
            <p className="text-generic text-start ml-[24px]">
              {translations.aboutPage.excellence}
            </p>
          </div>

          <div
            className={classNames([
              'flex flex-col w-full items-center',
              'lg:flex-row lg:items-center lg:justify-between',
            ])}
          >
            <img
              src={PhotoTwo}
              alt="PhotoTwo"
              className={classNames([
                'w-full mt-[24px] mb-[56px]',
                'lg:w-[656px] lg:h-[640px] lg:mt-0 lg:mb-0 lg:mr-[24px]',
              ])}
            />

            <div
              className={classNames([
                'h-auto w-full text-center lg:w-[423px] lg:text-left lg:pl-[24px]',
              ])}
            >
              <h2
                className={classNames([
                  'text-content text-[2rem] leading-[40px] font-light whitespace-pre-line text-left',
                  'lg:text-[4rem] lg:leading-[72px]',
                ])}
              >
                {translations.aboutPage.excellenceHeader}
              </h2>
              <p className="text-white text-[1.1875rem] leading-[19px] lg:mt-[24px] mt-[16px] font-light text-left">
                {translations.aboutPage.excellenceDesc}
              </p>
            </div>
          </div>
        </div>

        <div
          className={classNames([
            'flex-col justify-between w-full h-auto',
            'lg:flex-row lg:sticky lg:mt-[-12rem] lg:top-[8rem] lg:mb-[13rem] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div className="w-full flex flex-row text-generic lg:leading-[22px] lg:text-[1.0625rem] text-[1rem] leading-[21px] font-light py-[21px] lg:mb-[48px] mb-[40px]">
            03
            <p className="text-generic text-start ml-[24px]">
              {translations.aboutPage.noble}
            </p>
          </div>
          <div
            className={classNames([
              'w-full lg:w-full flex flex-col',
              'lg:relative lg:flex-row-reverse',
            ])}
          >
            <img
              src={PhotoThree}
              alt="PhotoThree"
              className={classNames([
                'w-full order-2 mt-[24px] mb-[56px]',
                'lg:mt-0 lg:order-1 lg:w-[601px] lg:ml-[96px] lg:mb-0',
              ])}
            />
            <div
              className={classNames([
                'order-1 lg:order-2',
                'lg:w-[423px] lg:h-[217px] lg:mt-[150px]',
              ])}
            >
              <h2
                className={classNames([
                  'text-event text-[2rem] leading-[40px] font-light',
                  'lg:text-[4rem] lg:leading-[72px]',
                ])}
              >
                {translations.aboutPage.nobleHeader}
              </h2>
              <p className="text-white text-[1.1875rem] leading-[19px] lg:mt-[40px] mt-[16px] font-light">
                {translations.aboutPage.nobleDesc}
              </p>
            </div>
          </div>
        </div>

        <div
          className={classNames([
            'justify-between w-full h-auto',
            'lg:flex-row lg:sticky lg:top-[12rem] lg:mb-[4rem] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div className="w-full flex flex-row text-generic lg:leading-[22px] lg:text-[1.0625rem] text-[1rem] leading-[21px] font-light py-[21px] lg:mb-[48px] mb-[40px]">
            04
            <p className="text-generic text-start ml-[24px]">
              {translations.aboutPage.everyone}
            </p>
          </div>
          <div className="w-full lg:w-full justify-center items-center flex flex-col relative">
            <div className="w-full lg:w-[1098px] h-auto text-center">
              <h2
                className={classNames([
                  'text-village font-light text-[2rem] leading-[40px] text-start ',
                  'lg:text-[4rem] lg:leading-[72px] lg:text-center',
                ])}
              >
                {translations.aboutPage.everyoneHeader}
              </h2>
              <p
                className={classNames([
                  'text-white text-[1.1875rem] leading-[19px]  mt-[16px] text-start font-light ',
                  'lg:text-center lg:mt-[24px]',
                ])}
              >
                {translations.aboutPage.everyoneDesc}
              </p>
            </div>
            <img
              src={PhotoFourSmall}
              alt="PhotoFourSmall"
              className="w-full h-[335px] mt-[24px] mb-[56px] rounded-tl-[30px] rounded-br-[30px] lg:hidden"
            />
            <img
              src={PhotoFour}
              alt="PhotoFour"
              className="hidden lg:block w-full lg:w-[866px] rounded-tl-[30px] rounded-br-[30px] lg:h-[500px] lg:mt-[48px] lg:mb-[112px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
