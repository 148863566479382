import { ReactComponent as ArrowRightSVG } from '../../svg/arrow-right.svg';
import classNames from 'classnames';

interface INavItemProps {
  title: string;
  path: string;
  index: number;
  activeTab: number;
}

export const NavItem = ({ title, path, index, activeTab }: INavItemProps) => {
  const handleClick = () => {
    const newTabUrl = `${path}?index=${index}`;

    window.open(newTabUrl, '_blank');
  };

  return (
    <div
      className={classNames([
        'border-t rounded-lg py-[32px] w-full',
        index === activeTab ? 'border-generic' : 'border-gray-light',
      ])}
    >
      <div
        className="flex justify-between items-center gap-[16px]"
        onClick={handleClick}
      >
        <h2 className={classNames('text-h2 leading-h2 font-light text-white')}>
          {title}
        </h2>
        <div>
          <button
            className={classNames([
              'w-10 h-10 flex justify-center items-center  text-[1rem] rounded-none rounded-tl-[20px] rounded-br-[20px]',
              index === activeTab
                ? 'bg-generic text-black'
                : 'bg-gray-dark text-generic',
              'sm:text-[1.125rem] sm:w-11 sm:h-11',
            ])}
          >
            <ArrowRightSVG
              className={classNames([
                index === activeTab ? 'stroke-content' : 'stroke-generic',
              ])}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
