import { Button } from '../../../components/button/button';
import translation from '../../../constants/translations.json';
import { RegisterSchema } from '../../../schemas/registerSchema';
import { ReactComponent as DoneSVG } from '../../../svg/icon-done.svg';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';

interface NotificationsProps {
  isPremium?: boolean;
  onNext?: () => void;
}

interface Notification {
  title: string;
  isSelected: boolean;
}

const notifications = [
  { title: translation.steps.notifications.events, isSelected: false },
  { title: translation.steps.notifications.ceneNews, isSelected: false },
  { title: translation.steps.notifications.newsletter, isSelected: false },
  { title: translation.steps.notifications.inapp, isSelected: false },
];

const notificationsPlus = [
  { title: translation.steps.notifications.events, isSelected: false },
  { title: translation.steps.notifications.interests, isSelected: false },
  { title: translation.steps.notifications.memberBenefits, isSelected: false },
  { title: translation.steps.notifications.cenePlusUpdates, isSelected: false },
  { title: translation.steps.notifications.newsletter, isSelected: false },
  { title: translation.steps.notifications.inapp, isSelected: false },
];

export const Notifications = ({
  onNext = () => null,
  isPremium = false,
}: NotificationsProps) => {
  const formik = useFormikContext<RegisterSchema>();
  const [isAll, setAll] = useState(true);
  const [notificationSwitch, setNotificationSwitch] = useState<Notification[]>(
    isPremium ? notificationsPlus : notifications
  );

  const handleSelectAllButtonClick = () => {
    setAll(true);

    const updatedNotifications = notificationSwitch.map((item) => ({
      ...item,
      isSelected: false,
    }));

    setNotificationSwitch(updatedNotifications);
    if (isAll) {
      formik.setFieldValue('notifications', []);
    } else {
      const allNotifications = notificationSwitch.map((item) => item.title);

      formik.setFieldValue('notifications', allNotifications);
    }
  };

  const handleSelectElement = (index: number) => {
    setAll(false);

    const updatedNotifications = notificationSwitch.map(
      (item, indexElement) => {
        if (indexElement === index) {
          return { ...item, isSelected: !item.isSelected };
        }
        return item;
      }
    );

    const selectedNotifications = updatedNotifications
      .filter((item) => item.isSelected)
      .map((item) => item.title);

    formik.setFieldValue('notifications', selectedNotifications);

    const allSelected = updatedNotifications.every((item) => item.isSelected);
    setAll(allSelected);
    setNotificationSwitch(updatedNotifications);

    if (allSelected) {
      const updatedNotifications = notificationSwitch.map((item) => ({
        ...item,
        isSelected: false,
      }));
      setNotificationSwitch(updatedNotifications);
    }
  };

  return (
    <div
      className={classNames(['pt-[24px] pb-[20px]', 'sm:px-0 px-5 max-w-sm'])}
    >
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {isPremium
          ? translation.steps.notifications.titlePlus
          : translation.steps.notifications.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {isPremium
          ? translation.steps.notifications.descriptionPlus
          : translation.steps.notifications.description}
      </p>
      <div>
        <div
          className={classNames('flex flex-wrap gap-4 w-full max-w-sm mx-auto')}
        >
          <div
            key="all"
            onClick={() => handleSelectAllButtonClick()}
            className={classNames([
              'relative cursor-pointer font-medium text-[1rem] rounded-tl-[20px] rounded-br-[20px] px-[22px] py-[12px]',
              !isPremium && isAll && 'bg-gray-dark text-content',
              !isPremium && !isAll && 'bg-gray-dark text-gray-light',

              isPremium &&
                isAll &&
                'text-black bg-generic border !border-generic',
              isPremium &&
                !isAll &&
                'text-gray-light bg-black border border-gray-medium',
            ])}
          >
            All
            {isAll && !isPremium && (
              <DoneSVG className="w-[20px] h-[20px] border-black border-[2px] rounded-big absolute top-[0px] right-[0px]" />
            )}
          </div>

          {notificationSwitch.map(({ title, isSelected }, index) => (
            <div
              key={index}
              onClick={() => handleSelectElement(index)}
              className={classNames([
                'relative cursor-pointer font-medium text-[1rem] rounded-tl-[20px] rounded-br-[20px] px-[22px] py-[12px] border',
                isAll &&
                  !isSelected &&
                  !isPremium &&
                  'bg-gray-dark text-gray-light !border-none',
                isAll &&
                  !isSelected &&
                  isPremium &&
                  'text-gray-light bg-black border border-gray-medium',
                isAll &&
                  isSelected &&
                  !isPremium &&
                  'bg-gray-dark text-gray-light !border-none',
                isAll &&
                  isSelected &&
                  isPremium &&
                  'text-gray-light bg-black border border-gray-medium',

                !isAll &&
                  !isPremium &&
                  isSelected &&
                  'bg-gray-dark text-content !border-none',
                !isAll &&
                  !isPremium &&
                  !isSelected &&
                  'bg-gray-dark text-gray-light !border-none',
                !isAll &&
                  isPremium &&
                  !isSelected &&
                  '!text-gray-light !bg-black border !border-gray-medium',
                !isAll &&
                  isPremium &&
                  isSelected &&
                  '!text-black !bg-generic !border-generic',
              ])}
            >
              {title}
              {!isAll && isSelected && !isPremium && (
                <DoneSVG className="w-[20px] h-[20px] border-black border-[2px] rounded-big absolute top-[0px] right-[0px]" />
              )}
            </div>
          ))}
        </div>

        <Button
          color="generic"
          type="submit"
          className={classNames(['w-full', 'mt-[34vh] sm:mt-[40px]'])}
          onClick={onNext}
        >
          {translation.continue}
        </Button>
      </div>
    </div>
  );
};
