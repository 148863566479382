const TicketSwitch = ({
  isCombined,
  setIsCombined,
}: {
  isCombined: boolean;
  setIsCombined: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleSwitchChange = () => setIsCombined(!isCombined);

  return (
    <div className="flex items-center space-x-3">
      <div
        className={`relative h-[2rem] w-[3.5rem] cursor-pointer bg-gray-medium rounded-big transition-colors`}
        onClick={handleSwitchChange}
      >
        <div
          className={`absolute top-1/2 -translate-y-1/2 left-0 h-[1.75rem] w-[1.75rem] bg-gray-dark border border-generic rounded-big shadow-md transform transition-transform ${
            isCombined ? 'translate-x-[100%]' : ''
          }`}
        ></div>
      </div>
    </div>
  );
};

export default TicketSwitch;
