/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as CalendarBlankSvg } from '../../../src/svg/CalendarBlank.svg';
import { ReactComponent as MapSvg } from '../../../src/svg/MapPinLineWhite.svg';
import { ReactComponent as InfoSvg } from '../../../src/svg/info.svg';
import { ReactComponent as TicketErrorSvg } from '../../../src/svg/ticketError.svg';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import AccessibleSection from '../../sections/modalTicketing/accessible';
import { TicketList } from '../../sections/modalTicketing/ticketItem';
import { TicketSummary } from '../../sections/modalTicketing/ticketSummary';
import { supabaseCeneClient } from '../../supabase/supabase';
import { Button } from '../button/button';
import TicketSwitch from '../switch/ticketSwitch';
import Toast from '../toast/toast';
import CurrencySwitch from './currencySwitch';
import {
  OrderDetails,
  Ticket as OriginalTicket,
  PaymentProviders,
  TicketRequestType,
} from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { useState } from 'react';

interface Ticket extends OriginalTicket {
  title: OriginalTicket['name'];
}
interface TicketDetailsProps {
  isPayNowClicked: boolean;
  id: string;
  currency: PaymentProviders | undefined;
  isPaymentSuccessful: boolean;
  isCheckoutClicked: boolean;
  firstImage: string;
  title: string;
  place: string;
  formattedDate: string;
  tickets: Ticket[] | null;
  quantity: { [key: string]: number };
  totalPrice: number;
  formatPriceString: (price: number) => string;
  isTermsAccepted: boolean;
  setIsTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  isCheckoutButtonActive: boolean;
  isPurchaseDisabled: boolean | undefined;
  handleIncrement: (ticketId: string) => void;
  handleDecrement: (ticketId: string) => void;
  maxTotalQuantity: number | undefined;
  isMaxQuantityReached: boolean;
  checkoutButtonClass: string;
  termsButtonClass: string;
  handlePayNowClick: () => void;
  onSendPayment: () => void;
  handleCheckoutClick: () => void;
  paymentProvider: string;
  onClose: () => void;
  tax: OrderDetails;
}

export const TicketDetails: React.FC<TicketDetailsProps> = ({
  isPayNowClicked,
  isPaymentSuccessful,
  isCheckoutClicked,
  firstImage,
  id,
  currency,
  title,
  place,
  paymentProvider,
  formattedDate,
  tickets,
  quantity,
  totalPrice,
  formatPriceString,
  isTermsAccepted,
  isCheckoutButtonActive,
  setIsTermsAccepted,
  isPurchaseDisabled,
  handleIncrement,
  handleDecrement,
  maxTotalQuantity,
  isMaxQuantityReached,
  checkoutButtonClass,
  termsButtonClass,
  handlePayNowClick,
  onSendPayment,
  handleCheckoutClick,
  onClose,
  tax,
}) => {
  const [stripe, setStripe] = useState(false);
  const { session } = useSupabase();

  const [isCombined, setIsCombined] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isVisibleToast, setVisibleToast] = useState(false);
  const getSelectedTickets = () => {
    return (
      tickets
        ?.map((ticket) => ({
          id: ticket.id,
          title: ticket.title || ticket.name,
          quantity: quantity[ticket.id] || 0,
        }))
        ?.filter((ticket) => ticket.quantity > 0) || []
    );
  };

  const hasAccessibleTickets =
    tickets?.some((ticket) => ticket.is_accessible) ?? false;

  const accessibleTickets =
    tickets?.filter((ticket) => ticket.is_accessible) ?? [];
  const getTotalQuantity = () =>
    Object.values(quantity).reduce((sum, q) => sum + q, 0);

  const handleSendTicketRequest = async () => {
    try {
      const selectedTickets = getSelectedTickets();
      const ticketDetails = selectedTickets.map((ticket) => ({
        name: ticket.title,
        quantity: ticket.quantity,
        ticket_name_id: ticket.id,
      }));

      const { error } = await supabaseCeneClient.rpc(
        `send_bulk_ticket_request`,
        {
          event_id: id,
          details: ticketDetails,
          request_type: 'accessible',
        }
      );

      if (error) {
        setSuccess(false);
      } else {
        setSuccess(true);
      }
      setVisibleToast(true);

      setTimeout(() => {
        setVisibleToast(false);
        onClose();
      }, 3000);
    } catch (err) {
      setSuccess(false);
      setTimeout(() => {
        setVisibleToast(false);
        onClose();
      }, 3000);
    }
  };

  return (
    <>
      {isVisibleToast && (
        <Toast
          message={
            isSuccess ? 'Request sent successfully' : 'Error sending request'
          }
          bgColor={isSuccess ? 'bg-event' : 'bg-error'}
          onClose={() => setVisibleToast(false)}
        />
      )}
      {!isPayNowClicked && (
        <>
          <div
            className={classNames([
              'flex mt-[110px] flex-col lg:mt-0 lg:flex-row',
              { 'overflow-y-auto': !isPaymentSuccessful },
            ])}
          >
            <div className="flex flex-row lg:hidden">
              <img
                src={firstImage}
                alt="Organization"
                className="mr-[16px] w-[48px] h-[62px] lg:w-[119px] lg:h-[153.68px] rounded-tl-[8px] rounded-br-[8px]"
              />
              <h2 className="text-white text-[1.1875rem] leading-[19px] mb-[16px] font-light">
                {title}
              </h2>
            </div>
            <img
              src={firstImage}
              alt="Organization"
              className="mr-[32px] lg:w-[119px] lg:h-[153.68px] lg:flex hidden rounded-tl-[30px] rounded-br-[30px]"
            />
            <div className="overflow-hidden w-full flex flex-col lg:w-[301px] lg:justify-start">
              <h2 className="text-white text-lg leading-6 mb-[16px] hidden font-light lg:flex">
                {title}
              </h2>

              <div className="flex mt-[16px] lg:flex-row lg:items-center lg:mt-0">
                <MapSvg className="mr-[12px]" />
                <p
                  title={place}
                  className="text-white text-[0.875rem] leading-[14px] font-light lg:w-[265px] truncate"
                >
                  {place}
                </p>
              </div>
              <div className="flex mt-[16px] lg:flex-row items-center">
                <CalendarBlankSvg className="mr-[12px]" />
                <p className="text-white text-[0.875rem] leading-[14px] font-light">
                  {formattedDate}
                </p>
              </div>
              {!isCheckoutClicked && (
                <div>
                  <CurrencySwitch currency={currency} />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isCheckoutClicked ? (
        <TicketSummary
          tax={tax}
          id={id}
          stripe={stripe}
          ticketTypes={tickets}
          quantity={quantity}
          totalPrice={totalPrice}
          formatPriceString={formatPriceString}
          onTermsAccepted={setIsTermsAccepted}
        />
      ) : (
        <>
          <div
            className={classNames(
              isCombined &&
                'opacity-70 cursor-not-allowed transition-opacity duration-300'
            )}
          >
            <div className="h-[1px] bg-gray-medium my-[24px]" />
            <TicketList
              session={session?.type}
              ticketTypes={tickets}
              isPurchaseDisabled={isPurchaseDisabled}
              quantity={isCombined ? [0, 0] : quantity}
              handleIncrement={isCombined ? () => {} : handleIncrement}
              handleDecrement={isCombined ? () => {} : handleDecrement}
              maxTotalQuantity={isCombined ? 0 : maxTotalQuantity}
            />
            {isMaxQuantityReached && !isCombined && (
              <div className="mt-[16px] flex flex-row items-center">
                <TicketErrorSvg className="lg:mr-[24px] mr-[16px]" />
                <p className="text-red lg:text-[0.875rem] lg:leading-[14px] font-light leading-[18px] text-[0.75rem]">
                  {translations.modalTicketing.error}
                </p>
              </div>
            )}
          </div>
          {hasAccessibleTickets && (
            <div
              className={classNames(
                'border border-generic-light p-[1.25rem] rounded rounded-tl-[30px] rounded-br-[30px] mt-[1rem]'
              )}
            >
              <div className="flex items-center gap-[1.25rem]">
                <TicketSwitch
                  isCombined={isCombined}
                  setIsCombined={setIsCombined}
                />
                <span
                  className={classNames(
                    'text-generic text-[1.0625rem]/[1.375rem] font-light'
                  )}
                >
                  Accessibility & Special Needs
                </span>
              </div>
              {isCombined && (
                <AccessibleSection
                  isCombined={isCombined}
                  session={session?.type}
                  ticketTypes={accessibleTickets}
                  isPurchaseDisabled={isPurchaseDisabled}
                  quantity={quantity}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  maxTotalQuantity={maxTotalQuantity}
                />
              )}
              {isMaxQuantityReached && isCombined && (
                <div className="mt-[16px] flex flex-row items-center">
                  <TicketErrorSvg className="lg:mr-[24px] mr-[16px]" />
                  <p className="text-red lg:text-[0.875rem] lg:leading-[14px] font-light leading-[18px] text-[0.75rem]">
                    {translations.modalTicketing.error}
                  </p>
                </div>
              )}
            </div>
          )}
          <div
            className={`flex flex-row ${isMaxQuantityReached ? 'mt-[16px]' : 'lg:mt-[30px] mt-[32px]'}`}
          >
            <InfoSvg className="w-[32px] h-[32px] lg:mr-[24px] mr-[16px]" />
            <p className="text-generic lg:text-[0.875rem] lg:leading-[14px] lg:w-[396px] w-full font-light leading-[18px] text-[0.75rem]">
              {translations.modalTicketing.infoPlus}
            </p>
          </div>
        </>
      )}

      {!stripe && (
        <div className="mt-[24px] w-full flex justify-between items-center">
          {isCheckoutClicked ? (
            <Button
              className={`${termsButtonClass} w-full flex justify-center items-center lg:mb-[40px] mb-[16px]`}
              disabled={!isTermsAccepted}
              onClick={() => {
                paymentProvider === 'paystack'
                  ? (handlePayNowClick(), onSendPayment())
                  : paymentProvider === 'stripe' && setStripe(true);
              }}
            >
              <p className="text-black">{translations.modalTicketing.payNow}</p>
            </Button>
          ) : isCombined ? (
            <Button
              color="event"
              className=" w-full flex justify-center items-center lg:mb-[40px] mb-[16px]"
              disabled={getTotalQuantity() === 0 || getTotalQuantity() > 4}
              onClick={() => handleSendTicketRequest()}
            >
              <p className="text-black">
                Send {TicketRequestType.Accessible} request
              </p>
            </Button>
          ) : (
            <Button
              color="event"
              className={`${checkoutButtonClass} w-full flex justify-between items-center lg:mb-[40px] mb-[16px]`}
              disabled={!isCheckoutButtonActive}
              onClick={handleCheckoutClick}
            >
              <p className="text-black">{translations.modalTicketing.check}</p>
              <p className="text-black">
                ₦ {formatPriceString(tax?.total_net_amount as number)}
              </p>
            </Button>
          )}
        </div>
      )}
    </>
  );
};
