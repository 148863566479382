import { ReactComponent as ArrowRightSVG } from '../../svg/arrow-right.svg';
import termsCategories from './termsCategories';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ICategory {
  label: string;
  content: ReactNode;
}

const Tabs = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialIndex = parseInt(params.get('index') || '0', 10);

  const [activeTab, setActiveTab] = useState(initialIndex);
  const [isExpanded, setIsExpanded] = useState(true);

  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 1024;

      if (isMobile && tabRefs.current[activeTab]) {
        tabRefs.current[activeTab]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeTab]);

  const handleTabClick = (index: number) => {
    if (index === activeTab) {
      setIsExpanded(!isExpanded);
    } else {
      setActiveTab(index);
      setIsExpanded(true);
    }
  };

  const colors = ['#4A7DFF', '#8056DB', '#EC67F7', '#91DBBF'];

  return (
    <div className="max-lg:pt-[60px] pt-[120px] max-lg:pb-[40px]">
      <h1
        className={classNames([
          'text-[4.5rem]/[5rem] max-lg:text-[2.25rem]/[2.75rem] font-light',
        ])}
        style={{ color: colors[activeTab] }}
      >
        {termsCategories[activeTab].label}
      </h1>
      <p className="text-h2 text-white font-light mt-[24px]">
        Last updated: 17 July, 2024
      </p>

      <div className="lg:flex justify-between mt-[40px]">
        <div className="flex flex-col max-lg:w-full max-xl:w-[380px] w-[440px] items-start">
          <div className="flex flex-col font-light text-[2rem]/[2.5rem] max-lg:text-[1.125rem]/[1.625rem] max-lg:w-full w-[320px] items-start gap-[24px]">
            {termsCategories.map((category: ICategory, index: number) => (
              <div
                key={index}
                className="w-full"
                ref={(el) => (tabRefs.current[index] = el)}
              >
                <div
                  className={classNames([
                    'flex justify-between items-center w-full cursor-pointer',
                  ])}
                  style={{
                    color: index === activeTab ? colors[activeTab] : '#FFFFFF',
                  }}
                  onClick={() => handleTabClick(index)}
                >
                  <span className="flex-grow">{category.label}</span>

                  <ArrowRightSVG
                    className="max-lg:hidden"
                    style={{
                      stroke:
                        index === activeTab ? colors[activeTab] : '#FFFFFF',
                    }}
                  />
                  {activeTab === index && isExpanded ? (
                    <ArrowRightSVG
                      className="lg:hidden rotate-90"
                      style={{ stroke: colors[activeTab] }}
                    />
                  ) : (
                    <ArrowRightSVG
                      className="lg:hidden"
                      style={{ stroke: '#FFFFFF' }}
                    />
                  )}
                </div>

                {activeTab === index && isExpanded && (
                  <div className="lg:hidden mt-4 p-4 pt-0">
                    {category.content}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          className={classNames([
            'w-[55%] flex flex-col flex-grow p-4 pt-0',
            'max-lg:hidden',
          ])}
        >
          {termsCategories[activeTab].content}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
