import translations from '../../../constants/translations.json';
import { ICategory } from '../../../types/category';

const general: ICategory = {
  label: 'General',
  children: [
    {
      question: 'What is Cene?',
      answer: <p>{translations.general.answerFirst}</p>,
    },
    {
      question: 'How Does Cene Work?',
      answer: (
        <p className="whitespace-pre-line">{translations.general.answerTwo}</p>
      ),
    },
    {
      question: 'How Do I Download the Cene or Cene+ App?',
      answer: <p>{translations.general.answerThree}</p>,
    },
    {
      question: 'What Kinds of Events Can I Find on Cene?',
      answer: <p>{translations.general.answerFour}</p>,
    },
    {
      question: 'How Do I Find Events on Cene?',
      answer: (
        <p className="whitespace-pre-line">{translations.general.answerFive}</p>
      ),
    },
    {
      question: 'Is There a Limit to How Many Tickets I Can Buy?',
      answer: (
        <p className="whitespace-pre-line">{translations.general.answerSix}</p>
      ),
    },
    {
      question: 'What Is the Difference Between Cene and Cene+?',
      answer: (
        <p className="whitespace-pre-line">
          {translations.general.answerSeven}
        </p>
      ),
    },
    {
      question:
        'How Do I Stay in the Loop About Event Updates and Changes on Cene?',
      answer: (
        <p className="whitespace-pre-line">
          {translations.general.answerEight}
        </p>
      ),
    },
    {
      question: 'How Do I Contact Cene for Help?',
      answer: (
        <p className="whitespace-pre-line">{translations.general.answerNine}</p>
      ),
    },
  ],
};

export default general;
