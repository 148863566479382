import { Button } from '../../../../components/button/button';
import { CountryCodeSelect } from '../../../../components/countryCodeSelect/countryCodeSelect';
import { Input } from '../../../../components/input/cenePlus/input';
import { Label } from '../../../../components/label/cenePlus/label';
import translation from '../../../../constants/translations.json';
import { GuestSchema } from '../../../../schemas/guestSchema';
import { supabaseCeneClient } from '../../../../supabase/supabase';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';

interface FormProps {
  onNext: () => void;
}

export const PhoneForm = ({ onNext = () => null }: FormProps) => {
  const formik = useFormikContext<GuestSchema>();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const phone = `+${formik.values.countryCode}${formik.values.phone}`;

      await supabaseCeneClient.auth.signInWithOtp({
        phone,
        options: {
          data: {
            mobileApp: 'cene',
          },
        },
      });

      onNext();
    } catch (error) {
      //todo use Snackbar / Toast / tooltip
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const isPhoneValid = formik.values.phone ? !formik.errors.phone : false;

  const isFormValid = isPhoneValid;

  return (
    <div className="pt-[24px]">
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.phoneForm.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.phoneForm.description}
      </p>
      <div className="flex flex-col">
        <div>
          <div className="flex gap-[8px]">
            <div className="w-[164px]">
              <Label classLabel="text-generic" id="phone" isRequired={true}>
                {translation.steps.phoneForm.code}
              </Label>
              <CountryCodeSelect
                isCenePlus
                value={formik.values.countryCode}
                onChange={(value) => {
                  formik.setFieldValue('countryCode', value);
                }}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Label classLabel="text-generic" id="phone" isRequired={true}>
                {translation.steps.phoneForm.phoneNumber}
              </Label>
              <Input
                id="phone"
                placeholder={translation.steps.phoneForm.enterPhoneNumber}
                value={formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue('phone', e.currentTarget?.value)
                }
                onBlur={formik.handleBlur}
                error={formik.errors.phone}
                touched={Boolean(formik.touched.phone)}
              />
            </div>
          </div>
          {formik.errors.phone && formik.touched.phone && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {formik.errors.phone}
            </div>
          )}
        </div>
        <Button
          color="generic"
          type="button"
          className={'mt-[40px]'}
          onClick={onSubmit}
          disabled={!isFormValid || isLoading}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};
