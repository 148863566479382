import { ReactComponent as InfoSvg } from '../../../src/svg/info.svg';
import { ReactComponent as MinusBlackSvg } from '../../../src/svg/minusBlack.svg';
import { ReactComponent as MinusEventSvg } from '../../../src/svg/minusEvent.svg';
import { ReactComponent as MinusGraySvg } from '../../../src/svg/minusGray.svg';
import { ReactComponent as PlusBlackSvg } from '../../../src/svg/plusBlack.svg';
import { ReactComponent as PlusEventSvg } from '../../../src/svg/plusEvent.svg';
import { ReactComponent as PlusGraySvg } from '../../../src/svg/plusGray.svg';
import { ReactComponent as TicketGenericSvg } from '../../../src/svg/ticketGenericPlus.svg';
import { ReactComponent as TicketSvg } from '../../../src/svg/ticketModal.svg';
import translations from '../../constants/translations.json';
import { Ticket as OriginalTicket } from '@ceneteam/namespace';
import classNames from 'classnames';
import React from 'react';

interface Ticket extends OriginalTicket {
  title: OriginalTicket['name'];
}

export interface TicketListProps {
  ticketTypes?: Ticket[] | null;
  quantity: { [key: string]: number } | number[];
  handleIncrement: (ticketId: string) => void;
  handleDecrement: (ticketId: string) => void;
  maxTotalQuantity: number | undefined;
  session: string | undefined;
  onTicketSelection?: (ticketTypes: Ticket[], selectedIndex: number) => void;
  isPurchaseDisabled?: boolean;
}

export const TicketList: React.FC<TicketListProps> = ({
  ticketTypes,
  quantity,
  handleIncrement,
  handleDecrement,
  maxTotalQuantity,
  onTicketSelection,
  isPurchaseDisabled,
  session,
}) => {
  const sessionType = session === 'cene';
  const getQuantity = (ticketId: string): number => {
    if (Array.isArray(quantity)) {
      return quantity[
        ticketTypes?.findIndex((ticket) => ticket.id === ticketId) || 0
      ];
    } else {
      return quantity[ticketId] || 0;
    }
  };

  const getTotalQuantity = () => {
    if (Array.isArray(quantity)) {
      return quantity.reduce((sum, q) => sum + q, 0);
    } else {
      return Object.values(quantity).reduce((sum, q) => sum + q, 0);
    }
  };

  const formatPriceString = (price: number) => {
    return new Intl.NumberFormat('en-US').format(price);
  };
  const handleTicketSelect = (index: number) => {
    if (onTicketSelection && ticketTypes) {
      onTicketSelection(ticketTypes, index);
    }
  };

  const isComingSoon = (startDate: string) => {
    const currentDate = new Date();
    const saleStartDate = new Date(startDate);
    return currentDate < saleStartDate;
  };

  const isSoldOut = (endData: string) => {
    const currentDate = new Date();
    const saleStartDate = new Date(endData);
    return currentDate > saleStartDate;
  };

  return (
    <div
      className={classNames([
        'flex flex-col space-y-[24px] flex-grow',
        {
          'bg-generic-06 border border-gray-medium rounded-tl-[30px] rounded-br-[30px] p-[20px] lg:bg-black lg:border-none lg:rounded-none lg:p-0':
            !sessionType,
        },
      ])}
    >
      <div className="flex items-start">
        {sessionType ? (
          <TicketSvg className="mr-[29px] lg:flex hidden" />
        ) : (
          <TicketGenericSvg className="mr-[29px] lg:flex hidden" />
        )}

        <div className="flex flex-col space-y-[32px] flex-grow">
          {ticketTypes?.map((ticket, index) => (
            <div
              key={index}
              className="flex items-start justify-between mt-[17px] lg:mt-0"
            >
              <div className="flex items-center w-full">
                <div className="flex flex-col flex-grow">
                  <div className="flex items-center mb-[5px]">
                    <p className="text-generic text-[0.875rem] leading-[14px] font-light">
                      {ticket?.name}
                    </p>
                    <InfoSvg className="ml-[8px]" />
                  </div>
                  <p
                    className={classNames([
                      'font-light',
                      sessionType
                        ? 'text-white text-[2rem] leading-[40px] '
                        : 'text-generic leading-[25px] text-[1.5625rem]',
                    ])}
                  >{`₦ ${formatPriceString(ticket.NGN || 0)}`}</p>
                </div>
                <div className="flex items-center lg:space-x-[24px] space-x-[12px] lg:w-[165px] w-[117px] lg:mt-[6px] justify-center">
                  {isPurchaseDisabled ||
                  isComingSoon(ticket?.sale_starts_at || '') ||
                  isSoldOut(ticket?.sale_ends_at || '') ? (
                    <p
                      className={
                        'text-center  text-white bg-gray-medium w-[110px] h-[38px] rounded-tl-[16px] rounded-br-[16px] text-[1rem] flex items-center justify-center'
                      }
                    >
                      {isPurchaseDisabled
                        ? translations.ticketStatus.maxTickets
                        : isComingSoon(ticket?.sale_starts_at || '')
                          ? translations.ticketStatus.comingSoon
                          : translations.ticketStatus.soldOut}
                    </p>
                  ) : (
                    <>
                      <button
                        className={`border  ${
                          getQuantity(ticket.id) === 0
                            ? sessionType
                              ? 'border-gray-light lg:rounded-tl-[16px] lg:rounded-br-[16px] rounded-tl-[12px] rounded-br-[12px]'
                              : 'border-gray-light rounded-big'
                            : sessionType
                              ? 'border-event lg:rounded-tl-[16px] lg:rounded-br-[16px] rounded-tl-[12px] rounded-br-[12px]'
                              : 'bg-generic rounded-big'
                        } lg:w-[48px] lg:h-[48px] h-[36px] w-[36px] flex items-center justify-center`}
                        onClick={() => {
                          handleDecrement(ticket.id);
                          handleTicketSelect(index);
                        }}
                        disabled={getQuantity(ticket.id) === 0}
                      >
                        {getQuantity(ticket.id) === 0 ? (
                          <MinusGraySvg />
                        ) : sessionType ? (
                          <MinusEventSvg />
                        ) : (
                          <MinusBlackSvg />
                        )}
                      </button>
                      <p
                        className={`flex justify-center items-center w-[21px] h-[21px] text-[1.3125rem] leading-[21px] ${
                          getQuantity(ticket.id) === 0
                            ? 'text-gray-light'
                            : 'text-white'
                        }`}
                      >
                        {getQuantity(ticket.id)}
                      </p>
                      <button
                        className={`border  ${
                          maxTotalQuantity !== undefined &&
                          getTotalQuantity() >= maxTotalQuantity
                            ? sessionType
                              ? 'border-gray-light lg:rounded-tl-[16px] lg:rounded-br-[16px] rounded-tl-[12px] rounded-br-[12px]'
                              : 'border-gray-light rounded-big'
                            : sessionType
                              ? 'border-event lg:rounded-tl-[16px] lg:rounded-br-[16px] rounded-tl-[12px] rounded-br-[12px]'
                              : 'bg-generic rounded-big'
                        } lg:w-[48px] lg:h-[48px] h-[36px] w-[36px] flex items-center justify-center`}
                        onClick={() => {
                          handleIncrement(ticket.id);
                          handleTicketSelect(index);
                        }}
                        disabled={
                          maxTotalQuantity !== undefined &&
                          getTotalQuantity() >= maxTotalQuantity
                        }
                      >
                        {maxTotalQuantity !== undefined &&
                        getTotalQuantity() >= maxTotalQuantity ? (
                          <PlusGraySvg />
                        ) : sessionType ? (
                          <PlusEventSvg />
                        ) : (
                          <PlusBlackSvg />
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
