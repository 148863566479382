import { useEffect } from 'react';

interface FaviconManagerProps {
  isCenePlus?: boolean;
}

function FaviconManager({ isCenePlus = false }: FaviconManagerProps) {
  useEffect(() => {
    const faviconURL = isCenePlus
      ? '/pictures/cene-plus-fav.png'
      : '/pictures/cene-fav.png';

    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (link) {
      link.href = faviconURL;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = faviconURL;
      document.head.appendChild(newLink);
    }
  }, [isCenePlus]);

  return null;
}

export default FaviconManager;
