import { useSupabase } from '../../providers/supabase';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IGlobalLoader {
  children: ReactNode;
}

export const GlobalLoader = ({ children }: IGlobalLoader) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { session } = useSupabase();
  const checkLogo =
    session?.type === 'cene' &&
    session?.onboardingStatus === true ||
    session === null ||
    session?.onboardingStatus === false ||
    session?.onboardingStatus === undefined;

  useEffect(() => {
    handleStart();

    const timer = setTimeout(() => {
      handleComplete();
    }, 1000);

    return () => clearTimeout(timer);
  }, [location]);

  const handleStart = () => setLoading(true);
  const handleComplete = () => setLoading(false);

  return (
    <>
      {loading ? (
        <>
          <style>{keyframes}</style>
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-100 z-50">
            {checkLogo ? (
              <LogoSVG style={pulseAnimation} />
            ) : (
              <LogoPlusSVG style={pulseAnimation} />
            )}
          </div>
        </>
      ) : (
        children
      )}
    </>
  );
};

const pulseAnimation = {
  animation: 'pulse 1.5s infinite',
};

const keyframes = `
    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.1);
        opacity: 0.7;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  `;
