import { ReactComponent as ArrowDownSVG } from '../../../../../src/svg/arrow-down-event.svg';
import { ReactComponent as ArrowUpSVG } from '../../../../../src/svg/arrowUp.svg';
import translations from '../../../../constants/translations.json';
import { ReactComponent as CrownSvg } from '../../../../svg/crownSimplePlus.svg';
import { ReactComponent as TableSvg } from '../../../../svg/homeTable.svg';
import { ReactComponent as TicketsSvg } from '../../../../svg/iconTickets.svg';
import { ReactComponent as InfoSvg } from '../../../../svg/infoPlus.svg';
import { Option, OptionProps } from '../option';
import OrganizerSection from './organizer';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

interface AboutEventProps {
  event: Event | null;
}

const options = [
  {
    id: 'tables',
    Icon: TableSvg,
    label: translations.eventDetailsSection.tables,
    onClick: () => console.log('tables'),
    description:
      'Reserve your private space at this event. Indulge in dedicated seating,  bottle service, and more.',
  },
  {
    id: 'bulk',
    Icon: TicketsSvg,
    label: translations.eventDetailsSection.bulk,
    onClick: () => console.log('bulk'),
    description: 'Purchase more than four (4) tickets for this event.',
  },
  {
    id: 'vvip',
    Icon: CrownSvg,
    label: translations.eventDetailsSection.vvip,
    onClick: () => console.log('vvip'),
    description:
      'Gain exclusive general admission to this event with up to four (4) tickets.',
  },
];

const DetailsSection = ({ event }: AboutEventProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionProps | null>(
    null
  );

  const handleInfoClick = (option: OptionProps) => {
    setSelectedOption(option);
  };
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionRef.current &&
      !optionRef.current.contains(event.target as Node)
    ) {
      setSelectedOption(null);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedOption]);

  useEffect(() => {
    const descriptionEl = descriptionRef.current;
    if (descriptionEl) {
      const { lineHeight } = window.getComputedStyle(descriptionEl);
      const maxHeight = parseFloat(lineHeight) * 3;
      setShowToggle(descriptionEl.scrollHeight > maxHeight);
    }
  }, [event?.description]);

  return (
    <div className="bg-generic-06 border border-generic-light max-md:p-[1.25rem] p-[2.5rem] rounded-tl-[30px] rounded-br-[30px] w-full">
      <div className="max-lg:block hidden max-lg:mb-[1rem]">
        <h3
          className={classNames(
            'text-[1.0625rem]/[1.375rem] font-light',
            'text-gray-light'
          )}
        >
          {event?.category}
        </h3>
        <h1
          className={classNames(
            'text-[2.25rem]/[2.75rem] font-light mt-[0.5rem]',
            'text-generic'
          )}
        >
          {event?.name}
        </h1>
      </div>

      <p className="lg:text-[1.0625rem]/[1.375rem] text-[0.75rem]/[0.75rem] text-white uppercase">
        {translations.eventDetailsSection.aboutPlus}
      </p>

      {isExpanded && (
        <p className="mt-[16px] whitespace-pre-line text-generic lg:text-[1.0625rem]/[1.375rem] text-[0.75rem]/[0.75rem] max-w-full">
          {event?.description}
        </p>
      )}

      {!isExpanded && (
        <p
          ref={descriptionRef}
          className="mt-[16px] text-generic lg:text-[1.0625rem]/[1.375rem] text-[0.75rem]/[0.75rem] line-clamp-3 max-w-full"
        >
          {event?.description}
        </p>
      )}

      {showToggle && (
        <div
          className="mt-[8px] text-generic text-[1rem]/[1.5rem] flex flex-row items-center cursor-pointer"
          onClick={toggleContent}
        >
          <p className="mr-[12.5px]">
            {isExpanded
              ? translations.eventDetailsSection.hide
              : translations.eventDetailsSection.more}
          </p>
          {isExpanded ? <ArrowUpSVG /> : <ArrowDownSVG />}
        </div>
      )}

      <div className="lg:px-0 flex flex-col gap-[24px] mt-[1.5rem]">
        <p className="text-white lg:text-[1.0625rem]/[1.375rem] text-[0.75rem]/[0.75rem] font-normal">
          {translations.eventDetailsSection.ticketOption}
        </p>

        <div className="flex flex-col max-lg:gap-[1rem] gap-[1.5rem] justify-center">
          {options.map((option) => (
            <Option
              key={option.id}
              id={option.id}
              Icon={option.Icon}
              label={option.label}
              description={option.description}
              onClick={() => handleInfoClick(option)}
            />
          ))}
        </div>
      </div>
      <div className="md:hidden">
        <OrganizerSection event={event} />
      </div>
      {selectedOption && (
        <div
          ref={optionRef}
          className={classNames(
            'fixed bottom-0 left-0 right-0 bg-gray-dark p-[1.25rem] rounded-t-[20px] shadow-lg z-[1000] border border-generic-bg',
            'lg:hidden'
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-center w-full">
              <InfoSvg className="w-[24px] h-[24px] text-generic mr-[12px]" />
              <p className="text-generic text-[1.0625rem]/[1.375rem]">
                {selectedOption.label}
              </p>
            </div>
            <button
              className="text-generic text-[1rem]"
              onClick={() => setSelectedOption(null)}
            >
              ✕
            </button>
          </div>
          <div className={classNames('max-md:max-w-[336px] text-center')}>
            <p className="text-generic mt-[12px] font-light text-[0.875rem]/[1.25rem]">
              {selectedOption.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsSection;
