import Card from '../../components/customCard/card';
import { Icon } from '../../components/icon/icon';
import { WaitlistNotification } from '../../components/notification/waitlistNotification';
import { TicketRequest } from '../../pages/myBasket/myBasket';
import { ReactComponent as ArrowDownSVG } from '../../svg/caretDown.svg';
import CarouselBar from '../carouselBar/carouselBar';
import RequestCard from '../customCard/requestCard';
import { Event } from '@ceneteam/namespace';
import { Grid, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

export interface CarouselProps {
  data: Event[] | TicketRequest[];
  eventsLiked?: Event[] | undefined;
  title?: string;
  isWaitlist?: boolean;
  liked?: boolean;
  sessionType: boolean;
  leftArrow?: React.ReactElement;
  handleCardClick: (id: string, isWaitlist: boolean) => void;
  color?: 'ticket' | 'village' | 'generic' | 'content' | 'white' | 'event';
  colorCard?:
    | 'ticket'
    | 'village'
    | 'generic'
    | 'content'
    | 'white'
    | 'event'
    | 'black';
  isRequest?: boolean;
  isActive?: boolean;
  onClick?: () => void;
}

const CarouselSection = ({
  data,
  title,
  eventsLiked,
  liked,
  isWaitlist,
  handleCardClick,
  color = 'generic',
  sessionType,
  colorCard = 'black',
  isRequest = false,
  isActive = false,
}: CarouselProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const slidesToShow = isMobile ? 1 : 4;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isShowNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [direction, setDirection] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const totalSlides = data.length;
  const isVisibleCarousel = totalSlides > slidesToShow && !showAll;

  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex + slidesToShow >= totalSlides;

  const colorMapping = {
    ticket: '#EC67F7',
    village: '#8056DB',
    generic: '#B8CAD9',
    content: '#4A7DFF',
    white: '#ffffff',
    event: '#91DBBF',
    black: '#000000',
  };

  const customColor = colorMapping[color];
  const customColorCard = colorMapping[colorCard];

  useEffect(() => {
    if (isPaused) return;
    if (!isMobile) {
      const interval = setInterval(() => {
        handleNext();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, isMobile, isPaused]);


  const handleShareOpen = (isOpen: boolean) => {
    setIsPaused(isOpen);
  };

  const handleIconClick = () => {
    setShowAll((prev) => !prev);
    setCurrentIndex(0);
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => {
      if (prevIndex + slidesToShow >= totalSlides) {
        return 0;
      }
      return prevIndex + slidesToShow;
    });
  };

  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => {
      if (prevIndex - slidesToShow < 0) {
        return totalSlides - slidesToShow;
      }
      return prevIndex - slidesToShow;
    });
  };

  // const navigate = useNavigate();

  // const handleCardClick = (id: string, isWaitlist: boolean) => {
  //   navigate(`/event-details/${id}`, { state: { isWaitlist } });
  // };

  const handleCardClickWithWaitlist = (id: string) => {
    handleCardClick(id, isWaitlist ?? false);
  };

  const handleHeartClick = (title: string) => {
    const message = `Yay! You’ve added ${title} to your favourite events`;
    setNotificationMessage(message);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 5000);
  };

  const displayedCards = showAll
    ? data
    : data.slice(currentIndex, currentIndex + slidesToShow);

  const emptySlotsCount = slidesToShow - displayedCards.length;

  return (
    <div className="flex flex-col gap-[2.5rem] max-lg:gap-[1.5rem]">
      <div className="flex items-center justify-between">
        <div className="flex">
          <h2
            className="text-[2rem]/[2.5rem] max-lg:text-[1.5rem]/[2.25rem] text-generic font-light"
            style={{ color: customColor }}
          >
            {title}
          </h2>
          <p className="text-[0.9375rem] leading-[20px] text-event-light ml-[8px] self-start">
            {data.length}
          </p>
        </div>

        {totalSlides > slidesToShow && (
          <Icon
            className="rounded-tl-[8px] rounded-br-[8px] w-[48px] h-[48px] max-lg:w-[40px] max-lg:h-[40px]"
            onClick={handleIconClick}
          >
            {showAll ? (
              <ArrowDownSVG
                style={{ stroke: customColor }}
                className={classNames(
                  'w-[12px] h-[12px] rotate-180 transition-all duration-500'
                )}
              />
            ) : (
              <ArrowDownSVG
                style={{ stroke: customColor }}
                className={classNames(
                  'w-[12px] h-[12px] transition-all duration-500'
                )}
              />
            )}
          </Icon>
        )}
      </div>

      <Grid
        container
        spacing={3}
        rowSpacing={5}
        flexWrap={showAll ? 'wrap' : 'nowrap'}
        sx={{
          position: 'relative',
          height: showAll ? 'auto' : '564px',
        }}
      >
        <AnimatePresence initial={false} custom={direction}>
          {showAll ? (
            displayedCards.map((event) => (
              <Grid
                key={event.id}
                item
                xs={12}
                sm={6}
                md={3}
                className="flex-shrink-0"
                onClick={() => handleCardClickWithWaitlist(event.id)}
              >
                {isRequest ? (
                  <RequestCard
                    ticketRequest={event as TicketRequest}
                    sessionType={sessionType}
                    color={color}
                    isActive={isActive}
                  />
                ) : (
                  <Card
                    liked={liked}
                    event={event as Event}
                    eventsLiked={eventsLiked as Event | undefined}
                    handleHeartClick={handleHeartClick}
                    sessionType={sessionType}
                    colorTitle={customColorCard}
                    color={customColor}
                    onShareOpen={handleShareOpen}
                  />
                )}
              </Grid>
            ))
          ) : (
            <>
              {displayedCards.map((event) => (
                <Grid
                  key={event.id}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className="flex-shrink-0"
                  onClick={() => handleCardClickWithWaitlist(event.id)}
                >
                  <motion.div
                    key={currentIndex}
                    custom={direction}
                    initial={{ opacity: 0, x: direction * 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -direction * 100 }}
                    transition={{ duration: 0.5 }}
                    drag={isMobile ? 'x' : false}
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragEnd={(event, info) => {
                      if (info.offset.x < -50) handleNext();
                      else if (info.offset.x > 50) handlePrev();
                    }}
                  >
                    {isRequest ? (
                      <RequestCard
                        ticketRequest={event as TicketRequest}
                        sessionType={sessionType}
                        color={color}
                        isActive={isActive}
                      />
                    ) : (
                      <Card
                        liked={liked}
                        eventsLiked={eventsLiked as Event | undefined}
                        event={event as Event}
                        handleHeartClick={handleHeartClick}
                        sessionType={sessionType}
                        colorTitle={customColorCard}
                        color={customColor}
                        onShareOpen={handleShareOpen}
                      />
                    )}
                  </motion.div>
                </Grid>
              ))}
              {emptySlotsCount > 0 &&
                Array.from({ length: emptySlotsCount }).map((_, index) => (
                  <Grid
                    key={`empty-${index}`}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className="flex-shrink-0"
                  >
                    <div className="h-[100%]"></div>
                  </Grid>
                ))}
            </>
          )}
        </AnimatePresence>
      </Grid>

      {isVisibleCarousel && (
        <div className="w-full">
          <CarouselBar
            totalSlides={totalSlides}
            currentIndex={currentIndex}
            slidesToShow={slidesToShow}
            handlePrev={handlePrev}
            handleNext={handleNext}
            prevDisabled={isAtStart}
            nextDisabled={isAtEnd}
            color={customColor}
          />
        </div>
      )}
      <WaitlistNotification
        visible={isShowNotification}
        message={notificationMessage}
        onClose={() => setShowNotification(false)}
      />
    </div>
  );
};

export default CarouselSection;
