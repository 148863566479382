import cookiePolicy from './cookiePolicy';
import privacyPolicy from './privacyPolicy';
import purchaseTerms from './purchaseTerms';
import termsOfUse from './termsOfUse';

const termsCategories = [
  privacyPolicy,
  termsOfUse,
  purchaseTerms,
  cookiePolicy,
];

export default termsCategories;
