export const seo = {
  home: {
    title: 'Find Your Cene | More than Ticketing',
    description:
      'With Cene, you’re in the best seat for life’s most memorable moments. From popular shows to hidden gems, your next unforgettable experience is just a ticket away. Explore events that excite you and connect with a community that truly gets it.',
  },
  about: {
    title: 'About Cene | Our Story',
    description:
      'Cene revolutionises ticketing and live events by creating unforgettable experiences that foster genuine connections. With a focus on creativity and integrity, we transform every occasion into a powerful celebration.',
  },
  partners: {
    title: 'Partner with Cene | Innovate, Personalise, Elevate',
    description:
      'Revolutionise your events with Cene - a cutting-edge ticketing solution that empowers organisers,venues and artists to uncover what truly works. With Cene you optimise sales, boost engagement, and forge unforgettable connections with your fans.',
  },
  plansAndPricing: {
    title: 'Partner with Cene | Start Small, Scale Big',
    description:
      'Your events, your way! Whether you’re running a small workshop or a large festival, Cene lets you customise ticketing, engage audiences effortlessly, and scale your operations smoothly. ',
  },
  plansAndTiers: {
    title: 'Partner with Cene | Transform your Events',
    description:
      'Your Vision our Expertise -  As a Cene partner, you’ll innovate your ticketing strategies, personalise attendee experiences, and elevate your brand to new heights. Let’s create unforgettable events together. Join Cene Today',
  },
  becomePartner: {
    title: 'Join Cene+ | The Members-Only Event Club  ',
    description:
      'Join Cene+, the first private members club offering exclusive access to globally coveted seats at the most sought-after events. Experience unparalleled privileges, personalized service, and unforgettable moments reserved just for you',
  },
  download: {
    title: 'Cene or Cene+ | Download Now',
    description:
      'Unlock incredible events with Cene, your go-to ticketing platform! Download Cene for everyday access or upgrade to Cene+ for exclusive perks and premium experiences. ',
  },
  policy: {
    title: 'Cene | Our Privacy Policy',
    description:
      'At Cene, we prioritise your privacy. Learn how we collect, protect, and utilise your personal data while providing an exceptional ticketing experience for exclusive events.',
  },
  conditions: {
    title: 'Cene | Our Terms of Use ',
    description:
      'Understanding our Terms of Use is essential for a smooth experience at Cene. These terms clarify your rights and responsibilities, ensuring that your experience on Cene is seamless and hassle-free.',
  },
  cookiePolicy: {
    title: 'Cene | Our Cookie Policy',
    description:
      'Cene’s Cookie Policy helps you understand how we enhance your experience through cookies. This information ensures you know how Cene personalises experience for your benefit.',
  },
  terms: {
    title: 'Cene | Our Purchase Terms',
    description:
      'At Cene, we’re dedicated to making your ticket-buying experience simple and enjoyable! With straightforward guidelines around refunds, cancellation policies and payment options, you can focus on what truly matters—enjoying the events you love.',
  },
  notFound: {
    title: '404 | Let Us Help You Find Your Cene',
    description:
      "Oops! The page you were looking for isn’t here. But don’t worry—let's help you find the perfect event with Cene. ",
  },
  faq: {
    title: "Cene FAQ's | Navigating the Cene",
    description:
      'Got questions? We have answers! Dive into our FAQs for Cene, Cene+, Cene Partners and everything else',
  },
  login: {
    title: 'Login | Get back to your Cene',
    description:
      'Ready to dive back into the world of events? Log in to your Cene account and unlock exclusive experiences today. Haven’t signed up yet? Join us and discover what you’ve been missing!',
  },
  pricing: {
    title: 'Sign Up | Find your Cene',
    description:
      'Become part of the Cene community! Sign up now to access exclusive events, personalized recommendations, and special offers. Your next adventure awaits—let’s get started!',
  },
};
