import translations from '../../constants/translations.json';
import { DownloadSection } from '../../sections/download/download';
import classNames from 'classnames';

export const DownloadPage = () => {
  return (
    <div className="lg:mb-[120px] mb-[72px]">
      <h1
        className={classNames([
          'text-white text-[2.25rem] leading-[44px] font-light text-center lg:mt-[73px] mt-[72px]',
          'lg:text-[4.5rem] lg:leading-[80px] lg:w-full w-[335px] justify-center items-center mx-auto',
        ])}
      >
        {translations.downloadPage.title}
      </h1>
      <h1
        className={classNames([
          'text-event text-[2.25rem] leading-[44px] font-light text-center lg:mb-[39px] mb-[40px]',
          'lg:text-[4.5rem] lg:leading-[80px] lg:w-full w-[335px] justify-center items-center mx-auto',
        ])}
      >
        {translations.downloadPage.titleTwo}
      </h1>

      <p className='text-white text-[1rem] leading-[24px] lg:w-[510px] text-center mx-auto lg:mb-[31px] mb-[40px]'>{translations.downloadPage.desc}</p>
      <DownloadSection />
    </div>
  );
};
