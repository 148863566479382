import { seo } from '../constants/seo';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface ISeo {
  title: string;
  description: string;
  children: ReactNode;
}

export const Seo = ({ title, description, children }: ISeo) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || seo.home.title}</title>
        {description && <meta name="description" content={description} />}
      </Helmet>
      {children}
    </>
  );
};
