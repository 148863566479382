import { ReactComponent as ArrowLeftCenePlusIcon } from '../../../../src/svg/arrowLeftCenePlus.svg';
import { ReactComponent as ArrowIcon } from '../../../../src/svg/arrowPink.svg';
import { ReactComponent as CloseIcon } from '../../../../src/svg/closePink.svg';
import classNames from 'classnames';

interface ModalHeaderProps {
  onClose: () => void;
  sessionType: boolean;
  isInvaiting:boolean;
  setIsInvaiting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  onClose,
  sessionType,
  isInvaiting,
  setIsInvaiting
}) => (
  <div className="flex justify-between items-center px-6 py-4 text-white">
    <div className="flex items-center">
      <button
        onClick={isInvaiting ? () => setIsInvaiting(false) : onClose}
        className={classNames([
          'p-[14px] bg-gray-dark flex items-center justify-center ',
          sessionType ? 'rounded-tl-[8px] rounded-br-[8px]' : 'rounded-big',
        ])}
      >
        {sessionType ? (
          <ArrowIcon stroke="#EC67F7" />
        ) : (
          <ArrowLeftCenePlusIcon />
        )}
      </button>
      <span className="ml-2">Back</span>
    </div>
    <button
      onClick={onClose}
      className={classNames(["p-[14px] bg-gray-dark flex items-center justify-center ",sessionType?'rounded-tl-[8px] rounded-br-[8px]':'rounded-big'])}
    >
      <CloseIcon stroke={sessionType ? '#EC67F7' : '#B8CAD9'} />
    </button>
  </div>
);
