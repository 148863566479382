import { ROUTE_BURST, ROUTE_PARTNER_WITH_US } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { Button } from '../../components/button/button';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export const MoreTicketing = () => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames([
        'w-full z-20 absolute flex flex-col justify-center items-center lg:top-[50%] top-[60%] -translate-y-[50%]',
      ])}
    >
      <p className='text-white text-[1.0625rem] leading-[22px] lg:mb-[24px] mb-[16px]'>{translations.moreTicketing.titleTwo}</p>
      <h1
        className={classNames([
          'font-light text-white text-center ',
          'lg:text-[4.5rem] lg:leading-[80px] text-[2.25rem] leading-[44px]',
        ])}
      >
        {translations.moreTicketing.title}
      </h1>
      <h3 className="text-[1.0625rem] font-light text-white text-center lg:mt-[24px] mt-[16px] whitespace-pre-line leading-[22px] px-[20px] lg:px-0 lg:w-[537.92px]">
        {translations.moreTicketing.description}
      </h3>

      <div
        className={classNames([
          'flex lg:mt-[24px] mt-[16px] w-full justify-center space-x-4',
        ])}
      >
        <Button
          color="content"
          type="button"
          className={classNames([
            'lg:w-[199px] lg:h-[46px]',
            'w-[159.5px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px]',
          ])}
          onClick={() => navigate(ROUTE_PARTNER_WITH_US)}
        >
          {translations.moreTicketing.buttonOne}
        </Button>
        <Button
          color="village"
          type="button"
          className={classNames([
            'lg:w-[199px] lg:h-[46px]',
            'w-[159.5px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px]',
          ])}
          onClick={() => navigate(ROUTE_BURST)}
        >
          {translations.moreTicketing.buttonTwo}
        </Button>
      </div>
    </div>
  );
};
