import { Button } from '../../../../components/button/button';
import { PreferenceCard } from '../../../../components/preferenceCard/cenePlus/preferenceCard';
import translation from '../../../../constants/translations.json';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

interface FormProps {
  onNext: () => void;
}

interface FormValues {
  interestGenres: string[];
}

export const InterestPreferencesForm = ({ onNext }: FormProps) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [selectedGenres, setSelectedGenres] = useState<string[]>(
    values.interestGenres
  );

  const formikInterests = values.interestGenres || [];

  const selected = formikInterests.length;

  useEffect(() => {
    setFieldValue('interestGenres', selectedGenres);
  }, [selectedGenres]);

  const handleGenreSelect = (genre: string) => {
    if (selectedGenres.includes(genre)) {
      setSelectedGenres(selectedGenres.filter((g) => g !== genre));
    } else {
      if (selectedGenres.length < 5) {
        setSelectedGenres([...selectedGenres, genre]);
      }
    }
  };

  return (
    <div className={classNames(['mt-[24px] pb-[20px] w-full', 'sm:px-0 px-5'])}>
      <h1
        className={classNames([
          'text-[2rem] text-generic font-light mb-[12px]',
        ])}
      >
        {translation.steps.interests.titleCenePlus}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.interests.descriptionCenePlus}
      </p>

      <Grid
        container
        sx={{
          width: { xs: '100%', md: '456px' },
          gap: { xs: '14px', md: '16px' },
        }}
      >
        {genres.map(({ title, icon }) => (
          <Grid item xs={3.65157} sm={3.65157} md={2.684} key={title}>
            <PreferenceCard
              selected={values.interestGenres.includes(title)}
              onClick={() => handleGenreSelect(title)}
              href={icon}
            >
              {title}
            </PreferenceCard>
          </Grid>
        ))}
      </Grid>

      <div
        className={classNames([
          'mt-[40px] max-w-[456px] flex items-center gap-[40px]',
          'max-lg:gap-[24px]',
        ])}
      >
        <div className={classNames(['w-full', 'max-lg:w-2/3'])}>
          <p className="text-[1rem] text-white font-medium">
            {selected}/5 {translation.selected}
          </p>
          <p className="text-[0.875rem] text-generic font-light">
            {translation.selectionInfo}
          </p>
        </div>
        <Button
          className={classNames(['w-full', 'max-lg:w-1/3'])}
          color="generic"
          onClick={onNext}
        >
          {translation.continue}
        </Button>
      </div>
    </div>
  );
};

const genres = [
  { icon: '/pictures/lifestyle-ceneplus/art-ceneplus.jpg', title: 'Art' },
  { icon: '/pictures/lifestyle-ceneplus/beauty-ceneplus.jpg', title: 'Beauty' },
  { icon: '/pictures/lifestyle-ceneplus/cars-ceneplus.jpg', title: 'Cars' },
  {
    icon: '/pictures/lifestyle-ceneplus/entertainment-ceneplus.jpg',
    title: 'Entertainment',
  },
  { icon: '/pictures/lifestyle-ceneplus/events-ceneplus.jpg', title: 'Events' },
  {
    icon: '/pictures/lifestyle-ceneplus/fine-dining-ceneplus.jpg',
    title: 'Fine Dining',
  },
  {
    icon: '/pictures/lifestyle-ceneplus/movies-ceneplus.jpg',
    title: 'Movies/TV',
  },
  { icon: '/pictures/lifestyle-ceneplus/music-ceneplus.jpg', title: 'Music' },
  {
    icon: '/pictures/lifestyle-ceneplus/podcast-ceneplus.jpg',
    title: 'Podcasts',
  },
  {
    icon: '/pictures/lifestyle-ceneplus/pop-culture-ceneplus.jpg',
    title: 'Pop Culture',
  },
  { icon: '/pictures/lifestyle-ceneplus/sports-ceneplus.jpg', title: 'Sports' },
  { icon: '/pictures/lifestyle-ceneplus/travel-ceneplus.jpg', title: 'Travel' },
];
