import { Button } from '../../../components/button/button';
import { Loader } from '../../../components/loader/cenePlus/loader';
import { ROUTE_LOGIN } from '../../../constants/routes';
import translation from '../../../constants/translations.json';
import { useCeneMode } from '../../../providers/ceneMode';
import { ReactComponent as LogoPlusSVG } from '../../../svg/logotype.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoadingPage = () => {
  const navigate = useNavigate();
  const { setMode } = useCeneMode();

  const [progress, setProgress] = useState(0);

  const isSuccess = progress === 100;

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress >= 100) {
        clearInterval(interval);
      } else {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 1
        );
      }
    }, 100);

    return () => clearInterval(interval);
  }, [progress]);

  const getProgressMessage = () => {
    if (progress <= 25) {
      return 'Indulge in the Extraordinary…';
    } else if (progress <= 50) {
      return 'Tailoring your recommendations';
    } else if (progress <= 75) {
      return 'Refinement in Progress…';
    } else {
      return 'With us, you’ll never miss out again – on tickets, on events, on life.';
    }
  };

  return (
    <div
      className={classNames(
        // 'bg-welcomePlus bg-cover bg-center sm:bg-no-repeat sm:shadow-welcome':
        //   isSuccess,
        ['bg-black']
      )}
    >
      <header className="py-5 px-4 md:px-16 flex justify-between items-center">
        {!isSuccess && (
          <div>
            <LogoPlusSVG
              className={classNames([
                'w-[120px] h-[36px]',
                ' md:w-[164px] md:h-[48px]',
              ])}
            />
          </div>
        )}
      </header>
      {isSuccess ? (
        <div className="relative w-full h-[calc(100vh-40px)] flex flex-col items-center justify-center max-md:justify-end">
          <div
            className={classNames([
              'w-full text-center flex flex-col items-center gap-[32px] max-md:h-[60%] max-md:justify-between max-md:mb-[32px]',
            ])}
          >
            <h2 className="text-[4.5rem] max-md:text-[2rem] font-light text-generic">
              Welcome to Cene+
            </h2>

            <Button
              color="generic"
              className="w-[336px]"
              onClick={() => {
                setMode('cene+');
                navigate(ROUTE_LOGIN, {
                  replace: true,
                });
              }}
            >
              {translation.continue}
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-full h-[calc(100vh-88px)] flex flex-col items-center justify-center">
          <div
            className={classNames([
              'w-[456px] text-center h-[400px] flex flex-col justify-between items-center',
              'w-[90%] max-w-[456px]',
            ])}
          >
            <div className="h-[144px] flex justify-center items-center">
              <h2 className={classNames(['text-h1 font-light text-generic '])}>
                {getProgressMessage()}
              </h2>
            </div>
            <div className=" flex flex-col items-center">
              <Loader />
            </div>
            <p className="text-h1 font-light text-generic">{progress}%</p>
          </div>
        </div>
      )}
    </div>
  );
};
