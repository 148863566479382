import { Button } from '../../../../components/button/button';
import { CountryCodeSelect } from '../../../../components/countryCodeSelect/countryCodeSelect';
import { Input } from '../../../../components/input/cene/input';
import { Label } from '../../../../components/label/cene/label';
import translation from '../../../../constants/translations.json';
import { RegisterSchema } from '../../../../schemas/registerSchema';
// import { supabaseCeneClient } from '../../../../supabase/supabase';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';


interface FormProps {
  onNext: () => void;
}

export const PhoneForm = ({ onNext = () => null }: FormProps) => {
  const formik = useFormikContext<RegisterSchema>();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      // const phone = `+${formik.values.countryCode}${formik.values.phone}`;

      // await supabaseCeneClient.auth.signInWithOtp({
      //   phone,
      //   options: {
      //     data: {
      //       mobileApp: 'cene',
      //     },
      //   },
      // });

      onNext();
    } catch (error) {
      //todo use Snackbar / Toast / tooltip
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const isCountryalid = formik.values.country ? !formik.errors.country : false;

  const isPhoneValid = formik.values.phone ? !formik.errors.phone : false;

  const isFormValid = isCountryalid && isPhoneValid;

  return (
    <div className="pt-[24px]">
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.phoneForm.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.phoneForm.description}
      </p>
      <div className={classNames(['flex flex-col'])}>
        <div>
          <div className="flex w-full gap-[16px] max-lg:gap-[8px]">
            <div className="w-[164px]">
              <Label id="phone" isRequired={true}>
                {translation.steps.phoneForm.code}
              </Label>
              <CountryCodeSelect
                value={formik.values.countryCode}
                onChange={(value) => {
                  formik.setFieldValue('countryCode', value);
                }}
              />
            </div>
            <div className="w-full">
              <Label id="phone" isRequired={true}>
                {translation.steps.phoneForm.phoneNumber}
              </Label>
              <Input
                id="phone"
                className="mt-[8px]"
                placeholder={translation.steps.phoneForm.phoneNumber}
                value={formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue('phone', e.currentTarget?.value)
                }
                onBlur={formik.handleBlur}
                error={formik.errors.phone}
                touched={Boolean(formik.touched.phone)}
              />
            </div>
          </div>
          {formik.errors.phone && formik.touched.phone && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {formik.errors.phone}
            </div>
          )}
        </div>
        <Button
          color="content"
          type="button"
          className={classNames(['w-full mt-[40px]'])}
          onClick={onSubmit}
          disabled={!isFormValid || isLoading}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};