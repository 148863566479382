import { ReactComponent as DonePlusSVG } from '../../../svg/done-plus.svg';
import classNames from 'classnames';

interface IPreferenceCard {
  href: string;
  children: string;
  selected: boolean;
  onClick: () => void;
}

export const PreferenceCard = ({
  href,
  children,
  selected,
  onClick = () => null,
}: IPreferenceCard) => {
  return (
    <div
      className={classNames(['flex flex-col relative w-[100%] cursor-pointer'])}
      onClick={onClick}
    >
      <div
        className={classNames([
          'relative rounded-tl-[20px] rounded-br-[20px] overflow-hidden',
        ])}
      >
        <div className="bg-gradient w-full h-full z-[2] absolute"></div>
        <img
          src={href}
          className={classNames(['w-full h-auto relative z-[1]'])}
        />

        {selected && (
          <DonePlusSVG className="absolute bottom-[8px] right-[8px] z-[3]" />
        )}
      </div>

      <p
        className={classNames([
          'font-medium text-[1rem] pt-[10px] text-center',
          selected ? 'text-generic' : 'text-gray-light',
        ])}
      >
        {children}
      </p>
    </div>
  );
};
