import { createClient } from '@supabase/supabase-js';

export const supabaseCeneClient = createClient(
  `${process.env.REACT_APP_SUPABASE_CENE_BASE_URL}`,
  `${process.env.REACT_APP_SUPABASE_CENE_API_KEY}`
);

export const supabaseCenePlusClient = createClient(
  `${process.env.REACT_APP_SUPABASE_CENE_PLUS_BASE_URL}`,
  `${process.env.REACT_APP_SUPABASE_CENE_PLUS_API_KEY}`
);

export const supabase = createClient(
  `${process.env.REACT_APP_SUPABASE_BASE_URL}`,
  `${process.env.REACT_APP_SUPABASE_API_KEY}`
);
