import { Button } from '../../../../components/button/button';
import { CountryCodeSelect } from '../../../../components/countryCodeSelect/countryCodeSelect';
import { Input } from '../../../../components/input/cenePlus/input';
import { Label } from '../../../../components/label/cene/label';
import translation from '../../../../constants/translations.json';
import { PhoneWithoutCountrySchema } from '../../../../schemas/registerSchema';
import { supabaseCenePlusClient } from '../../../../supabase/supabase';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';

interface FormProps {
  onNext: () => void;
}

export const PhoneForm = ({ onNext = () => null }: FormProps) => {
  const formik = useFormikContext<PhoneWithoutCountrySchema>();
  const [isLoading, setLoading] = useState(false);

  const isValidPhone = formik.values.phone && !formik.errors.phone;

  const onSubmit = async () => {
    formik.setFieldError('phone', undefined);

    setLoading(true);

    const phone = `+${formik.values.countryCode}${formik.values.phone}`;

    const { error } = await supabaseCenePlusClient.auth.signInWithOtp({
      phone,
      options: {
        data: {
          mobileApp: 'Cene+',
        },
      },
    });

    setLoading(false);

    if (error) return formik.setFieldError('phone', error?.message);

    onNext();
  };

  return (
    <div className={classNames(['pt-[24px] pb-[20px] px-5', 'sm:px-0'])}>
      <h1 className="text-[2rem] text-generic font-light mb-[12px]">
        {translation.steps.phoneForm.titleCenePlus}
      </h1>
      <p className="text-[0.875rem] text-generic font-light mb-[40px]">
        {translation.steps.phoneForm.descriptionCenePlus}
      </p>

      <div className="flex flex-col">
        <div>
          <div className="flex gap-[8px]">
            <div className="w-[164px]">
              <Label classLabel="text-generic" id="phone" isRequired={true}>
                {translation.steps.phoneForm.code}
              </Label>
              <CountryCodeSelect
                isCenePlus
                value={formik.values.countryCode}
                onChange={(value) => {
                  formik.setFieldValue('countryCode', value);
                }}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Label classLabel="text-generic" id="phone" isRequired={true}>
                {translation.steps.phoneForm.phoneNumber}
              </Label>
              <Input
                id="phone"
                placeholder={translation.steps.phoneForm.enterPhoneNumber}
                value={formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue('phone', e.currentTarget?.value)
                }
                onBlur={formik.handleBlur}
                error={formik.errors.phone}
                touched={Boolean(formik.touched.phone)}
              />
            </div>
          </div>
          {formik.errors.phone && formik.touched.phone && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {formik.errors.phone}
            </div>
          )}
        </div>
        <Button
          color="generic"
          type="button"
          className={'mt-[40px]'}
          onClick={onSubmit}
          disabled={!isValidPhone || isLoading}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};
