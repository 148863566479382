import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/button/button';
import { ROUTE_LOGIN } from '../../../constants/routes';
import { ReactComponent as LinkSVG } from '../../../svg/Link.svg';

export const TermSplashPage = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate(ROUTE_LOGIN);

  return (
    <div className="w-full h-[calc(100vh-88px)] relative">
      <div className="w-[246px] flex flex-col justify-center items-center absolute top-[50%] left-[50%] transform -translate-y-1/2 -translate-x-1/2">
        <LinkSVG className="w-[276px] h-[80px]" />

        <h1 className="mt-[24px] text-h1 text-center text-generic font-light mb-[12px]">
          This link is no longer valid
        </h1>
        <p className="text-center text-h6 text-generic font-light mb-[12px]">
          Please sign in with the email address and password you used to
          register
        </p>
        <Button color="generic" className={classNames(["mt-[25px] w-full", "sm:mt-[64px] mb-[24px]"])} onClick={handleClick}>
          Go to Sign in
        </Button>
      </div>
    </div>
  );
};
