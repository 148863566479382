import classNames from 'classnames';

interface ILabelProps {
  isRequired?: boolean;
  children: string;
  id: string;
  classLabel?: string;
  classText?: string;
}

export const Label = ({
  children,
  classLabel = '',
  classText = '',
  isRequired,
  id,
}: ILabelProps) => {
  return (
    <label
      className={classNames([
        classText,
        'block text-generic text-[0.875rem] font-light mb-[8px]',
      ])}
      htmlFor={id}
    >
      {children}
      {isRequired ? (
        <span className={classNames([classLabel, 'text-content'])}>*</span>
      ) : (
        ''
      )}
    </label>
  );
};
