import { ModalDate } from '../../../../components/modalDate/modal';
import { ModalLocation } from '../../../../components/modalLocation/modal';
import translations from '../../../../constants/translations.json';
import { ReactComponent as CalendarSVG } from '../../../../svg/CalendarBlank.svg';
import { ReactComponent as MapPinSVG } from '../../../../svg/MapPinLineGeneric.svg';
import { ReactComponent as RightArrowCeneSVG } from '../../../../svg/caretRight.svg';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';


interface Props {
  event: Event | null;
}

const VenueSection = ({ event }: Props) => {
  const [isLocationOpen, setLocationOpen] = useState(false);
  const [isDateOpen, setDateOpen] = useState(false);

  const formattedDate =
    moment(event?.starts_at).format('dddd, MMMM D / h:mm a') + ' WAT';

  const toggleModalDate = () => {
    setDateOpen(!isDateOpen);
  };

  const toggleModalLocation = () => {
    setLocationOpen(!isLocationOpen);
  };

  return (
    <>
      <div className="lg:mt-[32px] flex flex-col lg:gap-[24px] gap-[16px]">
        <div className="flex items-center gap-[1.25rem]">
          <CalendarSVG style={{ minWidth: '32px', minHeight: '32px' }} />

          <div className="flex flex-col w-full gap-[0.5rem]">
            <p
              className={classNames(
                'lg:text-[1.3125rem]/[1.3125rem] flex-grow text-[1.0625rem]/[1.375rem]',
                'text-white'
              )}
            >
              {formattedDate}
            </p>
            {/* <button
              className="text-event text-[1rem]/[1.5rem] flex flex-row items-center gap-[0.5rem]"
              onClick={toggleModalDate}
            >
              <p>{translations.eventDetailsSection.otherDate}</p>
              <RightArrowCeneSVG
                style={{ stroke: '#91DBBF', width: '12px', height: '12px' }}
              />
            </button> */}
          </div>
        </div>

        <div className="flex items-center gap-[1.25rem]">
          <MapPinSVG style={{ minWidth: '32px', minHeight: '32px' }} />

          <div className="flex flex-col w-full gap-[0.5rem]">
            <p
              className={classNames(
                'lg:text-[1.3125rem]/[1.3125rem] flex-grow text-[1.0625rem]/[1.375rem]',
                'text-white'
              )}
            >
              {event?.address}
            </p>
            <button
              className="text-event text-[1rem]/[1.5rem] flex flex-row items-center gap-[0.5rem]"
              onClick={toggleModalLocation}
            >
              <p>{translations.eventDetailsSection.fullMap}</p>
              <RightArrowCeneSVG
                style={{ stroke: '#91DBBF', width: '12px', height: '12px' }}
              />
            </button>
          </div>
        </div>
      </div>
      <ModalDate isOpen={isDateOpen} onClose={toggleModalDate} />
      <ModalLocation
        isOpen={isLocationOpen}
        onClose={toggleModalLocation}
        event={event}
      />
    </>
  );
};

export default VenueSection;