import { ReactComponent as HeartFullSvg } from '../../../src/svg/filledGenericHeart.svg';
import { ReactComponent as HeartFilledEventSvg } from '../../../src/svg/filledHeart.svg';
import { useSupabase } from '../../providers/supabase';
import classNames from 'classnames';
import React from 'react';

interface WaitlistNotificationProps {
  visible: boolean;
  message: string;
  onClose: () => void;
}

export const WaitlistNotification: React.FC<WaitlistNotificationProps> = ({
  visible,
  message,
}) => {
  if (!visible) return null;
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';

  return (
    <div
      className={classNames([
        'py-[16px] px-[24px] fixed bg-black text-white rounded-tl-[20px] rounded-br-[20px] border border-gray-medium flex items-center z-50',
        'lg:top-[40px] lg:right-[46px] top-0 right-0 lg:w-[341px] w-[calc(100%-34px)] mx-[17px]',
      ])}
    >
      <p className="w-[48px] h-[48px] rounded-big bg-gray-dark flex items-center justify-center mr-[12px]">
        {sessionType || session === null ? (
          <HeartFilledEventSvg style={{ fill: '#91DBBF' }} />
        ) : (
          <HeartFullSvg />
        )}
      </p>
      <p className=" text-[1rem] leading-[24px] w-[235px] text-start">
        {message}
      </p>
    </div>
  );
};
