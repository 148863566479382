import { ROUTE_HOME } from '../../../constants/routes';
import { ICategory } from '../../../types/category';
import translations from '../../../constants/translations.json';

const account: ICategory = {
  label: 'Account & Set Up',
  children: [
    {
      question: 'How Do I Create an Account on Cene?',
      answer: (
        <div className="flex flex-col gap-[16px]">
          <div>On the App:</div>
          <ol className="text-generic list-decimal list-inside">
            <li>
              First things first—download the Cene app from the App Store (iOS)
              or Play Store (Android).
            </li>
            <li>Open it and tap on &quot;Create an Account.&quot;</li>
            <li>
              Enter some basic details like your name, phone number, and date of
              birth (don’t worry, we’re not judging your age!).
            </li>
            <li>
              You’ll receive an OTP via text message. Enter it to verify your
              identity.
            </li>
            <li>
              Add your email address and set a password to secure your account.
            </li>
            <li>
              Pick your favorite genres and interests, then set up your
              notification preferences for a personalized experience. That’s
              it—you’re all set to explore the best live events and experiences
              Cene has to offer.
            </li>
          </ol>
          <div>On the Website:</div>
          <ol className="text-generic list-decimal list-inside">
            <li>
              Visit{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href={`${window.location.origin}${ROUTE_HOME}`}
                className="underline hover:text-content"
              >
                www.cene.xyz{' '}
              </a>
              and click &quot;Log In&quot; at the top-right corner.
            </li>
            <li>
              Since you&apos;re new, select &quot;Create an Account.&quot;
            </li>
            <li>
              Enter your full name and phone number, and we’ll send you a
              verification code (OTP) via text.
            </li>
            <li>
              Add your email, create a strong password, and click
              &quot;Next.&quot;
            </li>
            <li>
              Choose your interests to personalize your experience, then click
              &quot;Submit.&quot; And just like that, your account is ready!
            </li>
          </ol>
        </div>
      ),
    },
    {
      question: 'What’s an OTP and Why Do I Need a One?',
      answer: (
        <div>
          <p>{translations.account.answerTwo}</p>
        </div>
      ),
    },
    {
      question: 'What if I Don’t Receive an OTP?',
      answer: (
        <div className="flex flex-col gap-[16px]">
          <p className="whitespace-pre-line">
            {translations.account.answerThree.split('[support@cene.xyz]')[0]}
            <a className="underline" href="mailto:support@cene.xyz">
              [support@cene.xyz]
            </a>
            {translations.account.answerThree.split('[support@cene.xyz]')[1]}
          </p>
        </div>
      ),
    },
    {
      question: 'Why Do You Need My Information?',
      answer: <p>{translations.account.answerFour}</p>,
    },
    {
      question: 'How Do I Set Up My Password?',
      answer: <p>{translations.account.answerFive}</p>,
    },
    {
      question: 'Why Do I Need To Select Genres and Interests?',
      answer: <p>{translations.account.answerSix}</p>,
    },
    {
      question: 'How Do I Log Into My Cene Account?',
      answer: <p>{translations.account.answerSeven}</p>,
    },
    {
      question: 'What Should I Do if I Forget My Password?',
      answer: <p>{translations.account.answerEight}</p>,
    },
    {
      question: 'Why Can’t I Log into My Account?',
      answer: <p>{translations.account.answerNine}</p>,
    },
    {
      question: 'How Do I Update My Name, Email Address, or Phone Number?',
      answer: <p className="whitespace-pre-line">{translations.account.answerTen}</p>,
    },
    {
      question: 'Can I Delete My Cene Account?',
      answer: <p className="whitespace-pre-line">{translations.account.answerEleven}</p>,
    },
    {
      question: 'What’s the Process for Account & Data Deletion?',
      answer: (
        <p className="whitespace-pre-line">
          {translations.account.answerTwelve.split('support@cene.xyz')[0]}
          <a className="underline" href="mailto:support@cene.xyz">
            support@cene.xyz
          </a>
          {translations.account.answerTwelve.split('support@cene.xyz')[1]}
        </p>
      ),
    },
  ],
};

export default account;
