import { QuestionItem } from '../../components/questionItem/questionItem';
import { ReactComponent as ArrowRightSVG } from '../../svg/arrow-right.svg';
import { IBlock, ICategory, IItem } from '../../types/category';
import categoryList from './faqsCategories';
import { NavItem } from './navItem';
import classNames from 'classnames';
import { useRef, useState } from 'react';

interface IService {
  label: string;
  children: IServiceItem[];
}
export interface IServiceItem {
  label: string;
  path: string;
}

const isIItem = (item: unknown): item is IItem => {
  return (
    typeof item === 'object' &&
    item !== null &&
    'question' in item &&
    typeof (item as IItem).question === 'string' &&
    'answer' in item
  );
};

const isBlock = (child: unknown): child is IBlock => {
  return (
    typeof child === 'object' &&
    child !== null &&
    'block' in child &&
    Array.isArray((child as IBlock).items)
  );
};

const isCategory = (item: ICategory | IService): item is ICategory => {
  return (
    'children' in item &&
    Array.isArray(item.children) &&
    (item.children as unknown[]).every(
      (child): child is IItem | IBlock => isIItem(child) || isBlock(child)
    )
  );
};

const isService = (item: ICategory | IService): item is IService => {
  return (
    'children' in item &&
    Array.isArray(item.children) &&
    (item.children as unknown[]).every(
      (child): child is IServiceItem =>
        typeof child === 'object' &&
        child !== null &&
        'path' in child &&
        typeof (child as IServiceItem).path === 'string'
    )
  );
};

const Tabs = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    if (window.innerWidth < 1024 && contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="lg:flex justify-between min-h-[500px]">
      <div className="flex flex-col max-lg:w-full max-xl:w-[380px] w-[440px] items-start">
        <div className="flex flex-col font-light text-[2rem]/[2.5rem] max-lg:text-[1.125rem]/[1.625rem] max-lg:w-full w-[320px] items-start gap-[24px]">
          {categoryList.map((category: ICategory | IService, index: number) => (
            <div
              key={index}
              className={classNames([
                'flex justify-between items-center w-full cursor-pointer',
                index === activeTab ? 'text-village' : 'text-white',
              ])}
              onClick={() => handleTabClick(index)}
            >
              {category.label}
              <ArrowRightSVG
                className={classNames([
                  index === activeTab ? 'stroke-village' : 'stroke-white',
                ])}
              />
            </div>
          ))}
        </div>
        <h2
          ref={contentRef}
          className={classNames([
            'text-event text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-light max-lg:mt-[60px] mt-[160px]',
          ])}
        >
          Have more questions than we&apos;ve answered?
        </h2>
        <h3
          className={classNames([
            'text-generic text-h2/[1.3125rem] font-light mt-[24px]',
          ])}
        >
          Contact us here:
        </h3>
        <a
          href="mailto:support@cene.xyz"
          className={classNames(
            'text-content text-h2/[1.3125rem] font-light hover:underline focus:underline'
          )}
        >
          support@cene.xyz
        </a>
      </div>

      <div
        className={classNames([
          'w-[55%] flex flex-col max-w-[756px]',
          'max-lg:w-full max-lg:mt-[40px]',
        ])}
      >
        {(() => {
          const activeCategory = categoryList[activeTab];

          if (isCategory(activeCategory)) {
            return activeCategory.children.map((child, index) => (
              <div key={index}>
                {isBlock(child) ? (
                  <div>
                    <h3 className="text-white py-[24px] border-t font-normal text-[1.3125rem]">
                      {child.block}
                    </h3>
                    {child.items.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        {isIItem(item) && (
                          <QuestionItem
                            title={item.question}
                            description={item.answer}
                            activeTab={activeTab}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  isIItem(child) && (
                    <QuestionItem
                      key={index}
                      title={child.question}
                      description={child.answer}
                      activeTab={activeTab}
                    />
                  )
                )}
              </div>
            ));
          } else if (isService(activeCategory)) {
            return (
              <div>
                {activeCategory.children.map((service, index) => (
                  <NavItem
                    key={index}
                    title={service.label}
                    path={service.path}
                    index={index}
                    activeTab={activeTab}
                  />
                ))}
              </div>
            );
          }
          return null;
        })()}
      </div>
    </div>
  );
};

export default Tabs;
