import { Button } from '../../../../components/button/button';
import { PreferenceCard } from '../../../../components/preferenceCard/cene/preferenceCard';
import translation from '../../../../constants/translations.json';
import { RegisterSchema } from '../../../../schemas/registerSchema';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

interface FormProps {
  onNext: () => void;
}

export const InterestPreferencesForm = ({ onNext }: FormProps) => {
  const formik = useFormikContext<RegisterSchema>();

  const formikInterests = formik.values.interests || [];

  const selected = formikInterests.length;

  const handleInterestsSelect = (genre: string) => {
    if (formikInterests.includes(genre)) {
      formik.setFieldValue(
        'interests',
        formikInterests.filter((item) => item !== genre)
      );
    } else if (selected < 5) {
      formik.setFieldValue('interests', [...formikInterests, genre]);
    }
  };

  return (
    <div className="pt-[24px] pb-[20px]">
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.interests.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.interests.description}
      </p>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            width: { xs: '100%', md: '456px' },
            gap: { xs: '12px', md: '16px' },
          }}
        >
          {interests.map(({ title, icon }) => (
            <Grid item xs={3.65157} sm={3.65157} md={2.684} key={title}>
              <PreferenceCard
                selected={formikInterests.includes(title)}
                onClick={() => handleInterestsSelect(title)}
                href={icon}
              >
                {title}
              </PreferenceCard>
            </Grid>
          ))}
        </Grid>
        <div
          className={classNames([
            'mt-[40px] max-w-[456px] flex items-center gap-[40px]',
            'max-lg:gap-[24px]',
          ])}
        >
          <div className={classNames(['w-full', 'max-lg:w-2/3'])}>
            <p className="text-[1rem] text-white font-medium">
              {selected}/5 {translation.selected}
            </p>
            <p className="text-[0.875rem] text-generic font-light">
              {translation.selectionInfo}
            </p>
          </div>
          <Button
            className={classNames(['w-full', 'max-lg:w-1/3'])}
            color="content"
            onClick={onNext}
          >
            {translation.continue}
          </Button>
        </div>
      </form>
    </div>
  );
};

const interests = [
  { icon: '/pictures/interests-cene/art-cene.jpg', title: 'Art' },
  { icon: '/pictures/interests-cene/beauty-cene.jpg', title: 'Beauty' },
  {
    icon: '/pictures/interests-cene/celebrities-cene.jpg',
    title: 'Celebrities',
  },
  { icon: '/pictures/interests-cene/events-cene.jpg', title: 'Events' },
  { icon: '/pictures/interests-cene/gaming-cene.jpg', title: 'Gaming' },
  { icon: '/pictures/interests-cene/lifestyle-cene.jpg', title: 'Lifestyle' },
  { icon: '/pictures/interests-cene/tv-cene.jpg', title: 'Movies & TV' },
  { icon: '/pictures/interests-cene/music-cene.jpg', title: 'Music' },
  { icon: '/pictures/interests-cene/podcasts-cene.jpg', title: 'Podcast' },
  {
    icon: '/pictures/interests-cene/pop-culture-cene.jpg',
    title: 'Pop culture',
  },
  { icon: '/pictures/interests-cene/sports-cene.jpg', title: 'Sports' },
  { icon: '/pictures/interests-cene/travel-cene.jpg', title: 'Travel' },
];
