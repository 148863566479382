import { ReactComponent as ErrorSvg } from '../../../src/svg/paymentFailed.svg';
import { Button } from '../../components/button/button';
import { ROUTE_EVENTS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const PaymentErrorMessage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames([
        'flex flex-col items-center justify-center mt-[145.5px] border-none border-gray-medium rounded-br-[30px] rounded-tl-[30px]',
        'lg:py-[40px] lg:px-[82.5px]  lg:mt-[162] lg:border',
      ])}
    >
      <ErrorSvg className="lg:mb-[32px] mb-[24px]" />
      <div className="flex flex-col items-center text-center lg:w-[367px]">
        <h1
          className={classNames([
            'text-white  leading-[36px] text-[1.75rem] mb-[16px] font-light ',
            'lg:text-[3.5rem] lg:leading-[64px]',
          ])}
        >
          {translations.paymentError.title}
        </h1>
        <p
          className={classNames([
            'text-white text-[1rem] leading-[21px] w-[252px] font-light items-center',
            'lg:w-[268px] lg:text-[1.0625rem] lg:leading-[22px] ',
          ])}
        >
          {translations.paymentError.desc}
        </p>
      </div>
      <Button
        className="w-full bg-event lg:mt-[32px] mt-[24px]"
        onClick={() => navigate(ROUTE_EVENTS)}
      >
        {translations.paymentError.textButton}
      </Button>
    </div>
  );
};
