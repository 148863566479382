import CarouselSection from '../../components/carouselSection/carouselSection';
import translations from '../../constants/translations.json';
import { TicketRequest } from '../../pages/myBasket/myBasket';
import NotFoundSection from './notFoundRequests';
import classNames from 'classnames';

interface RequestsProps {
  ticketRequests: TicketRequest[];
  sessionType: boolean;
}

const ExpiredRequestsSection = ({
  ticketRequests,
  sessionType,
}: RequestsProps) => {
  const filteredRequests = ticketRequests.filter(
    (request) => request.status !== 'new' && request.status !== 'approved'
  );

  return (
    <div className={classNames('max-lg:pt-[1.5rem] pt-[3rem]')}>
      {filteredRequests.length === 0 ? (
        <NotFoundSection
          title={translations.myBasket.expired}
          sessionType={sessionType}
          ticketRequestsCount={filteredRequests.length}
        />
      ) : (
        <CarouselSection
          data={filteredRequests}
          title={translations.myBasket.active}
          sessionType={sessionType}
          color={sessionType ? 'ticket' : 'generic'}
          handleCardClick={() => {}}
          colorCard={'black'}
          isRequest={true}
          isActive={true}
        />
      )}
    </div>
  );
};

export default ExpiredRequestsSection;
