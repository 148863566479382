import { useEffect } from 'react';

export const useDisableBodyScroll = (isOpen: boolean) => {
  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;

    if (isOpen) {
      body.style.overflow = 'hidden';
      html.style.overflow = 'hidden';

      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      if (scrollbarWidth > 0) {
        body.style.paddingRight = `${scrollbarWidth}px`;
      }
    } else {
      body.style.overflow = 'initial';
      html.style.overflow = 'initial';
      body.style.paddingRight = '0';
    }

    return () => {
      body.style.overflow = 'initial';
      html.style.overflow = 'initial';
      body.style.paddingRight = '0';
    };
  }, [isOpen]);
};
