import { ROUTER_CHOOSE_TABLES } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { usePayment } from '../../hooks/useSendPayment';
import { Ticket as SelectedTicket } from '../../pages/myBasket/myBasket';
import { useCurrency } from '../../providers/currency';
import { HeaderPayment } from '../../sections/modalTicketing/headerPayment';
import { ModalHeader } from '../../sections/modalTicketing/modalHeader';
import { PaymentErrorMessage } from '../../sections/modalTicketing/paymentErrorMessage';
import { PaymentSuccessMessage } from '../../sections/modalTicketing/paymentSuccessMessage';
import { TabContent } from '../../sections/modalTicketing/tabContent';
import { TicketSummary } from '../../sections/modalTicketing/ticketSummary';
import { supabaseCenePlusClient } from '../../supabase/supabase';
import { ReactComponent as CalendarBlankSvg } from '../../svg/calendarPlus.svg';
import { ReactComponent as MapSvg } from '../../svg/mapTrifold.svg';
import { Button } from '../button/button';
import Toast from '../toast/toast';
import CurrencySwitch from './currencySwitch';
import {
  Event,
  OrderDetails,
  Ticket as OriginalTicket,
  TicketRequestType,
} from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface ModalPlusProps {
  isOpen: boolean;
  onClose: () => void;
  session?: string | undefined;
  maxTotalQuantity?: number | undefined;
  isPurchaseDisabled?: boolean;
  event: Event | null;
}
interface Ticket extends OriginalTicket {
  title: OriginalTicket['name'];
}

const formatPriceString = (totalPrice: number) => {
  return new Intl.NumberFormat('en-US').format(totalPrice);
};

export const ModalPlusTicketing = ({
  isOpen,
  onClose,
  session,
  event,
  maxTotalQuantity,
  isPurchaseDisabled,
}: ModalPlusProps) => {
  useDisableBodyScroll(isOpen);
  const location = useLocation();
  const navigation = useNavigate();
  const { selectedCurrency } = useCurrency();
  const { selectedTickets, transaction, selectedTax } = location.state || {};
  const [tickets] = useState<Ticket[]>(
    event?.tickets?.map((ticket) => ({
      ...ticket,
      title: ticket.name,
    })) || []
  );
  const [quantity, setQuantity] = useState<{ [key: string]: number }>({});
  const { id } = useParams();
  const [isCheckoutClicked, setIsCheckoutClicked] = useState(
    false || selectedTickets
  );
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPayNowClicked, setIsPayNowClicked] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [stripe, setStripe] = useState(false);
  const hasFloorplan = tickets?.some((ticket) => ticket.floorplan);
  const [activeTab, setActiveTab] = useState<'tickets' | 'tables' | 'bulk'>(
    hasFloorplan ? 'tables' : 'tickets'
  );
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [isAccessible, setAccessible] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isVisibleToast, setVisibleToast] = useState(false);
  const [tax, setTax] = useState<OrderDetails>();
  const publicKey = process.env.REACT_APP_PAY_STACK_KEY || '';
  const initializePayment = usePaystackPayment({ publicKey });

  useEffect(() => {
    if (Object.keys(quantity).length > 0) {
      const selectedTickets = getSelectedTickets();
      getTax(selectedTickets);
    }
  }, [quantity]);

  useEffect(() => {
    const initialQuantities = selectedTickets
      ? selectedTickets.reduce(
          (acc: Record<string, number>, ticket: SelectedTicket) => ({
            ...acc,
            [ticket.ticket_name_id]: ticket.quantity || 0,
          }),
          {}
        )
      : tickets.reduce<Record<string, number>>(
          (acc, ticket: Ticket) => ({
            ...acc,
            [ticket.id]: 0,
          }),
          {}
        );

    setQuantity(initialQuantities);
  }, [selectedTickets, tickets]);

  const getTotalQuantity = () =>
    Object.values(quantity).reduce((sum, q) => sum + q, 0);

  const getTotalPrice = () =>
    tickets.reduce((total, ticket) => {
      const ticketQuantity = quantity[ticket.id] || 0;
      return total + (ticket?.NGN || 0) * ticketQuantity;
    }, 0);

  const handleIncrement = (ticketId: string) => {
    if (
      maxTotalQuantity !== undefined &&
      getTotalQuantity() >= maxTotalQuantity
    ) {
      return;
    }
    setQuantity((prev) => ({
      ...prev,
      [ticketId]: (prev[ticketId] || 0) + 1,
    }));
  };

  const handleDecrement = (ticketId: string) => {
    setQuantity((prev) => ({
      ...prev,
      [ticketId]: Math.max((prev[ticketId] || 0) - 1, 0),
    }));
  };

  const handleQuantityChange = (ticketId: string, value: number) => {
    setQuantity((prev) => ({
      ...prev,
      [ticketId]: value,
    }));
  };

  const totalPrice = React.useMemo(() => getTotalPrice(), [quantity, tickets]);

  const isCheckoutButtonActive = totalPrice > 0;

  useEffect(() => {
    if (!tickets || tickets.length === 0) return;

    const initialQuantities = selectedTickets?.length
      ? selectedTickets.reduce(
          (acc: Record<string, number>, ticket: SelectedTicket) => ({
            ...acc,
            [ticket.ticket_name_id]: ticket.quantity || 0,
          }),
          {}
        )
      : Object.fromEntries(tickets.map((ticket) => [ticket.id, 0]));

    setQuantity(initialQuantities);
  }, [selectedTickets, tickets]);

  const prepareTicketsForSummary = () => {
    if (selectedTickets) {
      return selectedTickets.map((selectedTicket: SelectedTicket) => {
        const matchedTicket = tickets.find(
          (eventTicket) => eventTicket.id === selectedTicket.ticket_name_id
        );
        if (matchedTicket) {
          return {
            ...selectedTicket,
            ...matchedTicket,
            quantity: selectedTicket.quantity || 0,
          };
        }
        return selectedTicket;
      });
    }
    return tickets;
  };
  const combinedTickets = prepareTicketsForSummary();

  const ticketQuantities = combinedTickets?.map(
    (ticket: Ticket) => ticket.quantity || 0
  );
  const calculateTotalPriceForCurrency = () => {
    return combinedTickets.reduce(
      (total: number, ticket: Ticket, index: number) => {
        const ticketQuantity = ticketQuantities[index] || 0;
        return total + (ticket[selectedCurrency] || 0) * ticketQuantity;
      },
      0
    );
  };

  const { onSendPayment } = usePayment({
    totalPrice: selectedTickets
      ? selectedTax?.total_gross_amount
      : tax?.total_gross_amount,
    tickets: selectedTickets ? combinedTickets : tickets,
    quantity: selectedTickets ? ticketQuantities : quantity,
    id: id || '',
    initializePayment,
    setIsPaymentSuccessful,
    setIsPaymentError,
    transaction,
  });

  const sessionType = session === 'cene';
  if (!isOpen) return null;

  const paymentProvider = selectedCurrency === 'NGN' ? 'paystack' : 'stripe';

  const handleBackClick = () => {
    if (isCheckoutClicked) {
      setIsCheckoutClicked(false);
    } else {
      onClose();
    }
  };
  const handleCloseClick = () => {
    onClose();
  };

  const checkoutButtonClass = isCheckoutButtonActive
    ? 'bg-generic'
    : 'bg-gray-light';
  const termsButtonClass = isTermsAccepted ? 'bg-generic' : 'bg-gray-light';
  const isMaxQuantityReached =
    maxTotalQuantity !== undefined && getTotalQuantity() >= maxTotalQuantity;

  const handleCheckoutClick = () => {
    getTax(getSelectedTickets());
    if (isCheckoutButtonActive) {
      setIsCheckoutClicked(true);
    }
  };

  const handleSendTicketRequest = async (type: TicketRequestType) => {
    try {
      const selectedTickets = getSelectedTickets();
      const ticketDetails = selectedTickets.map((ticket) => ({
        name: ticket.title,
        quantity: ticket.quantity,
        ticket_name_id: ticket.id,
      }));

      const { error } = await supabaseCenePlusClient.rpc(
        `send_bulk_ticket_request`,
        {
          event_id: id,
          details: ticketDetails,
          request_type: type,
          currency: selectedCurrency,
        }
      );

      if (error) {
        setSuccess(false);
      } else {
        setSuccess(true);
      }
      setVisibleToast(true);

      setTimeout(() => {
        setVisibleToast(false);
        onClose();
      }, 3000);
    } catch (err) {
      setSuccess(false);
      setTimeout(() => {
        setVisibleToast(false);
        onClose();
      }, 3000);
    }
  };

  const handlePayNowClick = () => {
    if (isTermsAccepted) {
      setIsPayNowClicked(true);
    }
  };

  const resetValues = () => {
    if (event?.tickets) {
      const initialQuantities = event.tickets.reduce(
        (acc, ticket) => ({
          ...acc,
          [ticket.id]: 0,
        }),
        {}
      );
      setQuantity(initialQuantities);
    }
  };

  const getSelectedTickets = () => {
    return (
      tickets
        ?.map((ticket) => ({
          id: ticket.id,
          title: ticket.title || ticket.name,
          quantity: quantity[ticket.id] || 0,
        }))
        ?.filter((ticket) => ticket.quantity > 0) || []
    );
  };

  useEffect(() => {
    resetValues();
  }, [activeTab]);

  const getTax = async (
    tickets: Ticket[] | { id: string; title: string; quantity: number }[]
  ) => {
    const ticketDetails = tickets.map((ticket) => ({
      quantity: ticket.quantity,
      ticket_name_id: ticket.id,
    }));
    try {
      const { data } = await supabaseCenePlusClient.rpc(
        'build_event_order_finance_details',
        {
          currency: selectedCurrency,
          event_id: event?.id,
          ordered_tickets: ticketDetails,
        }
      );
      setTax(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{ zIndex: 200 }}
      className={`fixed inset-0 flex justify-center  overflow-y-auto z-[100] ${
        isPaymentSuccessful || isPaymentError
          ? 'bg-black overflow-hidden'
          : 'bg-opacity-50 backdrop-blur-[16px] pt-[70px] lg:pb-[70px]'
      }`}
    >
      {isPaymentSuccessful ? (
        <></>
      ) : (
        <ModalHeader
          onBackClick={handleBackClick}
          onCloseClick={handleCloseClick}
          currentStep={isCheckoutClicked ? (isPayNowClicked ? 3 : 1) : 0}
          isPaymentSuccessful={isPaymentSuccessful}
          isPaymentError={isPaymentError}
          session={session}
        />
      )}

      <div
        className={classNames(
          'bg-black  relative h-auto lg:rounded-tl-[30px] lg:rounded-br-[30px]',
          {
            'lg:h-screen overflow-hidden overflow-y-auto w-full ':
              isPaymentSuccessful,
            'lg:h-screen w-full ': isPaymentError,
            'border lg:border-gray-medium  overflow-y-auto max-w-[532px] max-h-[85vh] xl:max-h-[90vh] 2xl:max-h-[86vh] 3xl:max-h-[60vh]':
              !isPaymentSuccessful && !isPaymentError,
          }
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {isVisibleToast && (
          <Toast
            message={
              isSuccess ? 'Request sent successfully' : 'Error sending request'
            }
            bgColor={isSuccess ? 'bg-event' : 'bg-error'}
            onClose={() => setVisibleToast(false)}
          />
        )}
        <div
          className={classNames([
            'px-[20px] h-full',
            {
              'lg:px-[0px] lg:py-[0px] w-full':
                isPaymentSuccessful || isPaymentError,
              'lg:px-[40px] lg:py-[40px] py-[40px]':
                !isPaymentSuccessful && !isPaymentError,
            },
          ])}
        >
          {isPaymentSuccessful ? (
            <div className="flex flex-col items-center ">
              <HeaderPayment
                sessionType={sessionType}
                handleCloseClick={handleCloseClick}
              />

              <PaymentSuccessMessage
                session={session}
                place={event?.address}
                background={event?.featured_image.url}
                selectedTickets={getSelectedTickets()}
                title={event?.name}
                starts_at={event?.starts_at as string}
              />
            </div>
          ) : isPaymentError ? (
            <div className="flex flex-col items-center ">
              <HeaderPayment
                sessionType={sessionType}
                handleCloseClick={handleCloseClick}
              />
              <PaymentErrorMessage />
            </div>
          ) : (
            <>
              {!isPayNowClicked && (
                <>
                  <div
                    className={classNames([
                      'flex mt-[110px] flex-col lg:mt-0 lg:flex-row ',
                      { 'overflow-y-auto': !isPaymentSuccessful },
                    ])}
                  >
                    <div className="flex flex-row lg:hidden">
                      <img
                        src={event?.featured_image.url}
                        alt="Organization"
                        className="mr-[16px] w-[48px] h-[62px] lg:w-[119px] lg:h-[153.68px] rounded-tl-[8px] rounded-br-[8px]"
                      />
                      <h2 className="text-generic text-[1.1875rem] leading-[19px] mb-[16px] font-light">
                        {translations.modalTicketing.titlePlus}
                      </h2>
                    </div>
                    <img
                      src={event?.featured_image.url}
                      alt="Organization"
                      className="mr-[32px] lg:w-[119px] lg:h-[153.68px] lg:flex hidden rounded-tl-[8px] rounded-br-[8px]"
                    />
                    <div className="overflow-hidden w-full flex flex-col lg:w-[301px] lg:justify-start">
                      <h2 className="text-generic text-[1.3125rem] leading-[21px] mb-[16px] hidden font-light lg:flex ">
                        {event?.name}
                      </h2>
                      <div className="flex mt-[16px] lg:flex-row lg:items-center lg:mt-0">
                        <MapSvg className="mr-[12px]" />
                        <p className="text-generic text-[0.875rem] leading-[14px] font-light lg:w-[265px] flex items-center">
                          {event?.address}
                        </p>
                      </div>
                      <div className="flex mt-[16px] lg:flex-row items-center">
                        <CalendarBlankSvg className="mr-[12px]" />
                        <p className="text-generic text-[0.875rem] leading-[14px] font-light">
                          {translations.modalTicketing.date}
                        </p>
                      </div>
                      {!isCheckoutClicked && (
                        <div>
                          <CurrencySwitch currency={event?.payments} />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {isCheckoutClicked ? (
                <TicketSummary
                  tax={selectedTax ? selectedTax : tax}
                  id={id}
                  stripe={stripe}
                  ticketTypes={combinedTickets}
                  quantity={selectedTickets ? ticketQuantities : quantity}
                  totalPrice={
                    selectedTickets
                      ? calculateTotalPriceForCurrency()
                      : totalPrice
                  }
                  formatPriceString={formatPriceString}
                  onTermsAccepted={setIsTermsAccepted}
                />
              ) : (
                <>
                  <div className="flex flex-col my-[24px]">
                    <div className="flex flex-row justify-between w-full mb-[14.32px]">
                      {['tickets', 'tables', 'bulk']
                        .filter((tab) =>
                          hasFloorplan ? tab === 'tables' : tab !== 'tables'
                        )
                        .map((tab) => (
                          <button
                            key={tab}
                            className={classNames('flex-1 px-[10px]', {
                              'text-generic': activeTab === tab,
                              'text-gray-light': activeTab !== tab,
                            })}
                            onClick={() =>
                              setActiveTab(tab as 'tickets' | 'tables' | 'bulk')
                            }
                          >
                            {
                              translations.modalTicketing[
                                tab as keyof typeof translations.modalTicketing
                              ]
                            }
                          </button>
                        ))}
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      {['tickets', 'tables', 'bulk']
                        .filter((tab) =>
                          hasFloorplan ? tab === 'tables' : tab !== 'tables'
                        )
                        .map((tab) => (
                          <div
                            key={tab}
                            className={`h-[1px] ${
                              activeTab === tab
                                ? 'bg-generic'
                                : 'bg-gray-medium'
                            } flex-1`}
                          />
                        ))}
                    </div>
                  </div>

                  <TabContent
                    setAccessible={setAccessible}
                    session={session}
                    activeTab={activeTab}
                    ticketTypes={tickets}
                    isPurchaseDisabled={isPurchaseDisabled}
                    quantity={quantity}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    maxTotalQuantity={maxTotalQuantity}
                    isMaxQuantityReached={isMaxQuantityReached}
                    handleQuantityChange={handleQuantityChange}
                  />
                </>
              )}

              <div className="lg:hidden flex h-[1px] bg-gray-medium my-[16px]" />

              {!stripe && (
                <div className="lg:mt-[24px] w-full flex justify-between items-center">
                  {isCheckoutClicked ? (
                    <Button
                      className={`${termsButtonClass} w-full flex justify-center items-center lg:mb-[40px] mb-[16px]`}
                      disabled={!isTermsAccepted}
                      onClick={() => {
                        paymentProvider === 'paystack'
                          ? (handlePayNowClick(), onSendPayment())
                          : paymentProvider === 'stripe' && setStripe(true);
                      }}
                    >
                      <p className="text-black">
                        {translations.modalTicketing.payNow}
                      </p>
                    </Button>
                  ) : activeTab === 'bulk' ? (
                    <Button
                      className="bg-generic w-full flex justify-between items-center lg:mb-[40px] mb-[16px]"
                      disabled={
                        getTotalQuantity() === 0 || getTotalQuantity() > 100
                      }
                      onClick={() =>
                        handleSendTicketRequest(TicketRequestType.Bulk)
                      }
                    >
                      <p className="text-black">
                        Send {TicketRequestType.Bulk} request
                      </p>
                      <p className="text-black">
                        ₦ {formatPriceString(Number(tax?.total_gross_amount))}
                      </p>
                    </Button>
                  ) : isAccessible ? (
                    <Button
                      className="bg-generic w-full flex justify-between items-center lg:mb-[40px] mb-[16px] "
                      disabled={!isCheckoutButtonActive}
                      onClick={() =>
                        handleSendTicketRequest(TicketRequestType.Accessible)
                      }
                    >
                      <p className="text-black">
                        Send {TicketRequestType.Accessible} request
                      </p>
                      <p className="text-black">
                        ₦ {formatPriceString(Number(tax?.total_gross_amount))}
                      </p>
                    </Button>
                  ) : activeTab === 'tables' ? (
                    <Button
                      className={`bg-generic w-full flex justify-center items-center lg:mb-[40px] mb-[16px]`}
                      onClick={() =>
                        navigation(ROUTER_CHOOSE_TABLES.replaceAll(':id', id as string))
                      }
                    >
                      <p className="text-black">Choose Seating Option</p>
                    </Button>
                  ) : (
                    <Button
                      className={`${checkoutButtonClass} w-full flex justify-between items-center lg:mb-[40px] mb-[16px]`}
                      disabled={!isCheckoutButtonActive}
                      onClick={handleCheckoutClick}
                    >
                      <p className="text-black">
                        {translations.modalTicketing.check}
                      </p>
                      <p className="text-black">
                        ₦ {formatPriceString(totalPrice)}
                      </p>
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
