import { ReactComponent as InfoSvg } from '../../../svg/infoPlus.svg';
import React, { useEffect, useState } from 'react';

export interface OptionProps {
  Icon: React.ElementType;
  label: string;
  description: string;
  id: string;
  onClick: () => void;
}

export const Option: React.FC<OptionProps> = ({
  Icon,
  label,
  description,
  onClick,
  id,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeOptionId, setActiveOptionId] = useState<string | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) setActiveOptionId(id);
  };

  const handleMouseLeave = () => {
    if (!isMobile) setActiveOptionId(null);
  };

  const isPopupVisible = activeOptionId === id;

  return (
    <div
      className="relative flex items-start"
      key={id}
      onClick={isMobile ? onClick : undefined}
    >
      <div>
        <Icon className="mr-[20px] lg:w-[36px] lg:h-[36px] w-[24px] h-[24px]" />
      </div>
      <div className="flex items-center w-full">
        <p className="lg:text-[1.3125rem] lg:leading-[21px] leading-[22px] text-[1.0625rem] text-generic flex-grow">
          {label}
        </p>

        {isMobile ? (
          <InfoSvg className=" lg:w-[36px] lg:h-[36px] w-[24px] h-[24px]" />
        ) : (
          <button
            className="ml-auto flex items-center justify-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <InfoSvg className="lg:w-[36px] lg:h-[36px] w-[24px] h-[24px]" />
          </button>
        )}
      </div>
      {isPopupVisible && (
        <div className="absolute top-0 right-[50px] bg-gray-dark border border-generic-bg px-[1.25rem] py-[1rem] rounded-tl-[30px] rounded-br-[30px] shadow-lg z-10 w-[304px] text-center">
          <div className="flex items-center justify-center w-full gap-[0.5rem]">
            <InfoSvg className="w-[20px] h-[20px]" />
            <p className="text-[1rem]/[1.5rem] text-generic font-light">
              {label}
            </p>
          </div>

          <p className="mt-[0.25rem] text-[0.75rem]/[1.125rem] text-generic font-light">
            {description}
          </p>
        </div>
      )}
    </div>
  );
};
