import { ReactComponent as BackSVG } from '../../svg/arrow-left.svg';
import { ReactComponent as BackPlusSVG } from '../../svg/arrowLeftCenePlus.svg';
import { Icon } from '../icon/icon';
import classNames from 'classnames';
import { Children, ReactNode } from 'react';

interface StepsProps {
  children: ReactNode;
  activeStep: number;
  canSkip?: boolean;
  onBack: () => void;
  onSkip?: () => void;
  hideBackButton?: boolean;
  isPremium?: boolean;
  width?: number;
  size?: string;
}

const Steps = ({
  children,
  canSkip = false,
  onBack,
  onSkip = () => null,
  activeStep = 1,
  hideBackButton = false,
  isPremium = false,
  width = 100,
  size = '%',
}: StepsProps) => {
  const childrenCount = Children.count(children);
  const itemWidth = width / childrenCount;

  const createArray = Array.from(
    { length: childrenCount },
    (_, index) => index + 1
  );

  const createStepsElements = createArray.map((item) => {
    const isCompletedOrActive = activeStep >= item;
  
    return (
      <div
        style={{
          width: `${itemWidth}${size}`,
        }}
        className={classNames([
          'h-[1px]',
          isCompletedOrActive ? (isPremium ? 'bg-generic' : 'bg-content') : 'bg-gray-medium',
        ])}
        key={item}
      ></div>
    );
  });

  return (
    <div className={classNames([canSkip ? 'max-lg:w-full' : 'w-full'])}>
      <div
        className={classNames([
          'w-full',
          canSkip && '',
          isPremium && 'flex items-center justify-between max-md:px-[20px]',
        ])}
      >
        <div
          className={classNames([
            'flex items-center justify-between',
            'max-lg:px-0',
          ])}
        >
          {!hideBackButton && (
            <Icon onClick={onBack} className="mb-[8px]">
              {isPremium ? <BackPlusSVG /> : <BackSVG />}
            </Icon>
          )}
          {hideBackButton && <div />}
          {canSkip && !isPremium && (
            <div
              onClick={onSkip}
              className=" cursor-pointer text-[1rem] text-white font-medium max-lg:pr-0"
            >
              Skip
            </div>
          )}
        </div>

        <div
          className={classNames([
            'h-[20px] flex items-center justify-between',
            hideBackButton && isPremium && !canSkip && 'flex ',
          ])}
        >
          <div className={classNames(['w-full h-[2px] flex gap-[4px]'])}>
            {createStepsElements}
          </div>

          {!isPremium && (
            <p className={classNames(["text-[0.75rem] font-medium pl-[16px]", isPremium?'text-generic':'text-content'])}>{`${activeStep}/${childrenCount}`}</p>
          )}
        </div>
        {isPremium && !canSkip && <div></div>}
        {canSkip && isPremium && (
          <div
            onClick={onSkip}
            className={classNames([
              'pl-[16px] cursor-pointer text-[1rem] text-white font-medium',
              ' sm:pr-0 pr-[20px]',
            ])}
          >
            Skip
          </div>
        )}
      </div>
      {Children.map(
        children,
        (child, index) => index + 1 === activeStep && child
      )}
    </div>
  );
};

export default Steps;
