import translations from '../../constants/translations.json';
import { FeatureList } from './featureList';


export const FeaturesBurst = () => {
  return (
    <div className="w-full h-auto flex flex-col justify-center items-center xl:py-[120px] lg:mt-[144px] max-xl:px-0 xl:px-[171px] px-[20px]  ">
      <div className="w-full md:w-[744px] mx-auto">
        <h1 className="text-gray-light text-[0.9375rem] leading-[20px] text-center font-normal">
          {translations.infoFeatures.feature}
        </h1>
        <p className="text-[2rem] leading-[40px] font-light text-white text-center lg:text-[4rem] lg:leading-[72px] mt-[8px] lg:mt-0">
          {translations.infoFeatures.title}
        </p>
      </div>

      <p className="text-[1.0625rem] font-normal text-generic text-center mt-[24px] lg:mx-[20px] md:mx-0 max-w-[644px] leading-[22px] lg:mb-[64px] mb-[40px]">
        {translations.infoFeatures.descriptionOne}
      </p>
      <FeatureList />
    </div>
  );
};
