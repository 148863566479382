import { countryCodes } from '../forms/signup/phoneStep/countryCodes';
import {
  emailRule,
  lowercaseRule,
  numberRule,
  onlyLatinCharactersRule,
  specialCharacterRule,
  uppercaseRule,
} from './validationRules';
import { CountryCode, isValidNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export const nameFormSchema = Yup.object().shape({
  firstName: Yup.string().min(2).required('First name is required'),
  lastName: Yup.string().min(2).required('Last name is required'),
});

export const genderAndBirthSchema = Yup.object().shape({
  gender: Yup.string(),
  dateOfBirth: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in the format YYYY-MM-DD')
    .nullable(),
});

export const jobFormSchema = Yup.object().shape({
  jobTitle: Yup.string().required('Job title is required'),
  company: Yup.string().required('Company is required'),
});

export const phoneFormSchema = Yup.object().shape({
  country: Yup.string().required(),
  countryCode: Yup.string().required(),
  phone: Yup.string()
    .test('is-valid-phone', 'Phone number is invalid', (_, context) => {
      const isValidPhone = isValidNumber(
        context.parent.phone,
        countryCodes.find((item) => context.parent.countryCode === item.value)
          ?.code as CountryCode
      );

      return isValidPhone;
    })
    .required('Phone number is required'),
});

export const phoneSchemaWithoutContry = Yup.object().shape({
  countryCode: Yup.string().required(),
  phone: Yup.string()
    .test('is-valid-phone', 'Phone number is invalid', (_, context) => {
      const isValidPhone = isValidNumber(
        context.parent.phone,
        countryCodes.find((item) => context.parent.countryCode === item.value)
          ?.code as CountryCode
      );

      return isValidPhone;
    })
    .required('Phone number is required'),
});

export const referenceInviteFormSchema = Yup.object().shape({
  email: emailRule,
  reference: Yup.string(),
});

export const passwordFormSchema = Yup.object().shape({
  email: emailRule,
  password: Yup.string()
    .min(8)
    .max(32)
    .required('Password is required')
    .concat(onlyLatinCharactersRule)
    .concat(uppercaseRule)
    .concat(lowercaseRule)
    .concat(numberRule)
    .concat(specialCharacterRule)
    .min(8, 'Password must be at least 8 characters long')
    .max(16, 'Password cannot exceed 16 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password missmatch')
    .required('Please confirm your password'),
  agreement: Yup.boolean().oneOf([true], 'Field must be checked'),
});

export const passwordFormWithoutEmailSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .max(32)
    .required('Password is required')
    .concat(onlyLatinCharactersRule)
    .concat(uppercaseRule)
    .concat(lowercaseRule)
    .concat(numberRule)
    .concat(specialCharacterRule)
    .min(8, 'Password must be at least 8 characters long')
    .max(16, 'Password cannot exceed 16 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password missmatch')
    .required('Please confirm your password'),
  agreement: Yup.boolean().oneOf([true], 'Field must be checked'),
  notifications: Yup.array(
    Yup.object({ title: Yup.string(), isSelected: Yup.string() })
  ),
  musicGenres: Yup.array(Yup.string()),
  interestGenres: Yup.array(Yup.string()),
});

export const registerSchema = [
  Yup.object().shape({
    otp: Yup.string(),
  }),
];

export const newRegisterSchema = Yup.object().shape({
  firstName: Yup.string().min(2).required('First name is required'),
  lastName: Yup.string().min(2).required('Last name is required'),
  gender: Yup.string(),
  dateOfBirth: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in the format YYYY-MM-DD')
    .nullable(),
  country: Yup.string().required(),
  countryCode: Yup.string().required(),
  phone: Yup.string()
    .test('is-valid-phone', 'Phone number is invalid', (_, context) => {
      const isValidPhone = isValidNumber(
        context.parent.phone,
        countryCodes.find((item) => context.parent.countryCode === item.value)
          ?.code as CountryCode
      );

      return isValidPhone;
    })
    .required('Phone number is required'),
  email: emailRule,
  password: Yup.string()
    .min(8)
    .max(32)
    .required('Password is required')
    .concat(onlyLatinCharactersRule)
    .concat(uppercaseRule)
    .concat(lowercaseRule)
    .concat(numberRule)
    .concat(specialCharacterRule)
    .min(8, 'Password must be at least 8 characters long')
    .max(16, 'Password cannot exceed 16 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password missmatch')
    .required('Please confirm your password'),
  agreement: Yup.boolean().oneOf([true], 'Field must be checked'),
  otp: Yup.string(),
  genres: Yup.array(Yup.string()),
  interests: Yup.array(Yup.string()),
  notifications: Yup.array(
    Yup.object({ title: Yup.string(), isSelected: Yup.string() })
  ),
});

export type RegisterSchema = Yup.InferType<typeof newRegisterSchema>;
export type PhoneWithoutCountrySchema = Yup.InferType<
  typeof phoneSchemaWithoutContry
>;
export type PasswordFormWithoutEmailSchema = Yup.InferType<
  typeof passwordFormWithoutEmailSchema
>;
