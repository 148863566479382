import { ReactComponent as DoneSVG } from '../../../svg/icon-done.svg';
import classNames from 'classnames';

interface IPreferenceCard {
  href: string;
  children: string;
  selected: boolean;
  onClick: () => void;
}

export const PreferenceCard = ({
  href,
  children,
  selected,
  onClick = () => null,
}: IPreferenceCard) => {
  return (
    <div
      className="flex flex-col relative w-[100%] cursor-pointer"
      onClick={onClick}
    >
      <div className="relative rounded-tl-[20px] rounded-br-[20px] overflow-hidden">
        <div className="bg-gradient w-full h-full z-[2] absolute"></div>
        <img src={href} className="w-full h-auto relative z-[1]" />

        {selected && (
          <DoneSVG className="absolute bottom-[8px] right-[8px] z-[3]" />
        )}
      </div>

      <p
        className={classNames([
          selected ? 'text-content' : 'text-gray-light',
          'text-[1rem] font-medium text-center',
        ])}
      >
        {children}
      </p>
    </div>
  );
};
