import { ReactComponent as SearchIcon } from '../../../../src/svg/glassPink.svg';
import classNames from 'classnames';

interface SearchFriendsProps {
  searchQuery: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchFocus: () => void;
  onSearchBlur: () => void;
  isSearching: boolean;
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>;
  sessionType: boolean;
}

export const SearchFriends: React.FC<SearchFriendsProps> = ({
  searchQuery,
  onSearchChange,
  onSearchFocus,
  sessionType,
  onSearchBlur,
  isSearching,
  setIsSearching,
}) => (
  <div className="flex flex-col  lg:border-t lg:border-gray-medium">
    <div className="flex items-center lg:pt-[16px]">
      <p className={classNames([sessionType ? 'text-ticket' : 'text-generic'])}>
        With a Friend
      </p>
      <div className="flex items-center rounded-lg flex-1 justify-end">
        <button
          className={`p-[14px] flex items-center justify-center rounded-tl-[8px] rounded-br-[8px] ${
            isSearching
              ? sessionType
                ? 'bg-ticket'
                : 'bg-generic'
              : 'bg-gray-dark'
          }`}
          onClick={() => setIsSearching((prev) => !prev)}
        >
          <SearchIcon
            style={{
              stroke: isSearching
                ? '#000000'
                : sessionType
                  ? '#EC67F7'
                  : '#b8cad9',
            }}
          />
        </button>
      </div>
    </div>
    {isSearching && (
      <div className="relative w-full mt-[16px]">
        <SearchIcon
          style={{ stroke: '#B8CAD9' }}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 w-[24px] h-[24px] "
        />
        <input
          type="text"
          placeholder="Search for someone"
          value={searchQuery}
          onChange={onSearchChange}
          onFocus={onSearchFocus}
          onBlur={onSearchBlur}
          className="bg-transparent text-white pl-12 pr-[16px] py-[12px] focus:outline-none border border-gray-medium rounded-tl-[20px] rounded-br-[20px] w-full"
        />
      </div>
    )}
  </div>
);
