import { InputSelect } from '../select/select';
import { useEffect, useState } from 'react';

const getDaysInMonth = (
  monthName: string,
  year: number
): { value: string; label: string }[] => {
  const month = months.find(
    (m) => m.value.toLowerCase() === monthName.toLowerCase()
  );

  const daysInMonth = new Date(year, Number(month?.label), 0).getDate();

  return Array.from({ length: daysInMonth }, (_, i) => ({
    value: String(i + 1),
    label: String(i + 1),
  }));
};

export const months = [
  { value: 'January', label: '1' },
  { value: 'February', label: '2' },
  { value: 'March', label: '3' },
  { value: 'April', label: '4' },
  { value: 'May', label: '5' },
  { value: 'June', label: '6' },
  { value: 'July', label: '7' },
  { value: 'August', label: '8' },
  { value: 'September', label: '9' },
  { value: 'October', label: '10' },
  { value: 'November', label: '11' },
  { value: 'December', label: '12' },
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (_, i) => ({
  value: String(currentYear - i),
  label: String(currentYear - i),
}));

interface DateOfBirthSelectProps {
  onDateChange: (date: { day: string; month: string; year: string }) => void;
  isPlus: boolean;
}

export const DateOfBirthSelect = ({
  onDateChange,
  isPlus,
}: DateOfBirthSelectProps) => {
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [days, setDays] = useState<{ value: string; label: string }[]>(
    Array.from({ length: 31 }, (_, i) => ({
      value: String(i + 1),
      label: String(i + 1),
    }))
  );

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const updatedDays = getDaysInMonth(
        selectedMonth,
        parseInt(selectedYear, 10)
      );
      setDays(updatedDays);

      if (selectedDay) {
        const dayNumber = parseInt(selectedDay, 10);
        if (dayNumber > updatedDays.length) {
          setSelectedDay('1');
          onDateChange({ day: '1', month: selectedMonth, year: selectedYear });
        }
      }
    } else {
      setDays(
        Array.from({ length: 31 }, (_, i) => ({
          value: String(i + 1),
          label: String(i + 1),
        }))
      );
    }
  }, [selectedMonth, selectedYear]);

  const handleDayChange = (day: string) => {
    if (day !== '0') {
      setSelectedDay(day);
      onDateChange({ day, month: selectedMonth, year: selectedYear });
    }
  };

  const handleMonthChange = (month: string) => {
    setSelectedMonth(month);
    const updatedDays = getDaysInMonth(month, parseInt(selectedYear, 10));
    setDays(updatedDays);

    if (selectedDay && parseInt(selectedDay, 10) > updatedDays.length) {
      setSelectedDay('1');
      onDateChange({ day: '1', month, year: selectedYear });
    }
  };

  const handleYearChange = (year: string) => {
    setSelectedYear(year);

    if (selectedMonth) {
      const updatedDays = getDaysInMonth(selectedMonth, parseInt(year, 10));
      setDays(updatedDays);

      if (selectedDay) {
        onDateChange({ day: selectedDay, month: selectedMonth, year });

        if (parseInt(selectedDay, 10) > updatedDays.length) {
          setSelectedDay('1');
          onDateChange({ day: '1', month: selectedMonth, year });
        }
      }
    } else {
      setDays(
        Array.from({ length: 31 }, (_, i) => ({
          value: String(i + 1),
          label: String(i + 1),
        }))
      );
    }
  };

  return (
    <div className="w-full flex gap-2 ">
      <InputSelect
        options={months}
        value={selectedMonth}
        onChange={handleMonthChange}
        placeholder="MM"
        className="!pl-[16px]"
        type="text"
        isPlus={isPlus}
      />
      <InputSelect
        options={days}
        value={selectedDay}
        onChange={handleDayChange}
        placeholder="DD"
        className="!pl-[16px]"
        type="text"
        isPlus={isPlus}
      />
      <InputSelect
        options={years}
        value={selectedYear}
        onChange={handleYearChange}
        placeholder="YYYY"
        className="!pl-[16px]"
        type="text"
        isPlus={isPlus}
      />
    </div>
  );
};
