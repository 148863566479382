import moment from 'moment';

export const formatEventDate = (starts_at: string) => {
  return moment(starts_at).format('dddd, MMMM D / h:mm a') + ' WAT';
};

export const formatEventDateSucess = (starts_at: string): string => {
  return moment(starts_at).format('Do MMM H.mm');
};

export const formatTicketDate = (starts_at: string) => {
  return moment(starts_at).format('dddd, MMMM D · h:mm a');
};
