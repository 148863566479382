import { Button } from '../../components/button/button';
import { Loader } from '../../components/loader/cene/loader';
import translations from '../../constants/translations.json';
import { useGetAttendedEvents } from '../../hooks/useGetUserAttendedEvents';
import { CardListOrganizer } from './cardListOrganizer';
import classNames from 'classnames';
import React, { useState } from 'react';

interface EventsOrganizerProps {
  handleHeartClick: () => void;
  session: string | undefined;
}

const Loading = () => (
  <div className="flex justify-center items-center h-[558px]">
    <Loader />
  </div>
);

export const EventsOrganizerSection: React.FC<EventsOrganizerProps> = ({
  handleHeartClick,
  session,
}) => {
  const { eventsAttended, loading } = useGetAttendedEvents();

  const isLoading = loading && !eventsAttended.length;
  const [activeFilter, setActiveFilter] = useState<'upcoming' | 'previous'>(
    'upcoming'
  );

  console.log(eventsAttended,'qwe')

  const handleButtonClick = (filter: 'upcoming' | 'previous') => {
    setActiveFilter(filter);
  };
  const sessionType = session == 'cene' || session == undefined;

  return (
    <div
      className={classNames(
        'flex flex-col justify-center w-full items-center',
        'lg:flex-row'
      )}
    >
      <div className="w-full">
        <div className=" lg:flex hidden mb-[48px] ">
          <p
            className={classNames([
              ' lg:flex hidden text-[2rem] leading-[40px] ',
              sessionType ? 'text-event' : 'text-generic',
            ])}
          >
            {translations.organizer.eventOrgTitle}
          </p>
        </div>
        <div className="flex flex-row lg:mb-[40px] mb-[24px] mt-[40px] lg:mt-0">
          <Button
            className={classNames(
              'w-1/2 lg:w-[165.5px] mr-[4px]',
              activeFilter === 'upcoming'
                ? sessionType
                  ? 'bg-event text-black'
                  : 'bg-generic text-black'
                : sessionType
                  ? 'bg-gray-medium text-event'
                  : 'bg-gray-medium text-generic'
            )}
            onClick={() => handleButtonClick('upcoming')}
          >
            <p className="text-[0.75rem] leading-[18px]">
              {translations.organizer.upcoming}
            </p>
          </Button>

          <Button
            className={classNames(
              'w-1/2 lg:w-[165.5px]',
              activeFilter === 'previous'
                ? sessionType
                  ? 'bg-event text-black'
                  : 'bg-generic text-black'
                : sessionType
                  ? 'bg-gray-medium text-event'
                  : 'bg-gray-medium text-generic'
            )}
            onClick={() => handleButtonClick('previous')}
          >
            <p className="text-[0.75rem] leading-[18px]">
              {translations.organizer.previous}
            </p>
          </Button>
        </div>


        {isLoading ? (
          <Loading />
          
        ) : (
          <CardListOrganizer
            cardsPlus={eventsAttended}
            cards={eventsAttended}
            session={session}
            handleHeartClick={handleHeartClick}
            filterType={activeFilter}
          />
        )}
      </div>
    </div>
  );
};
