import { useEffect } from 'react';

export const usePreventHorizontalScroll = () => {
  useEffect(() => {
    // Prevent horizontal scroll
    document.documentElement.style.overflowX = 'hidden';
    document.body.style.overflowX = 'hidden';

    return () => {
      // Restore original overflow styles
      document.documentElement.style.overflowX = '';
      document.body.style.overflowX = '';
    };
  }, []);
};


