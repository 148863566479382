import { ReactComponent as CheckedPlusSVG } from '../../svg/checked-plus.svg';
import { ReactComponent as CheckedSVG } from '../../svg/checked.svg';
import { ReactComponent as UncheckedPlusSVG } from '../../svg/unchecked-plus.svg';
import { ReactComponent as UncheckedSVG } from '../../svg/unchecked.svg';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

interface ICheckboxProps {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  className?: string;
  isPremium?: boolean;
  classText?:string
}

export const Checkbox = ({
  checked = false,
  label,
  onChange = () => null,
  className = '',
  classText='',
  isPremium = false,
}: ICheckboxProps) => {
  const [isChecked, setChecked] = useState(checked);

  const handleCheck = useCallback(() => {
    setChecked(!isChecked);
    onChange(!isChecked);
  }, [isChecked]);

  return (
    <div
      onClick={handleCheck}
      className={classNames([
        className,
        'cursor-pointer flex items-center gap-[8px]',
      ])}
    >
      <div className="flex">
        {!isPremium ? (
          isChecked ? (
            <CheckedSVG />
          ) : (
            <UncheckedSVG />
          )
        ) : isChecked ? (
          <CheckedPlusSVG />
        ) : (
          <UncheckedPlusSVG />
        )}
      </div>
      {label && (
        <label className={classNames([classText,"cursor-pointer text-generic text-[0.875rem] font-light"])}>
          {label}
        </label>
      )}
    </div>
  );
};
