import translations from '../../constants/translations.json';
import { ReactComponent as FomoPhoneSVG } from '../../svg/cene_banner_fomo.svg';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ROUTE_DOWNLOAD } from '../../constants/routes';

// const Phone = '/pictures/phone_discover.png';

export const DiscoverAboutSection = () => {
  return (
    <div
      className={classNames([
        'flex flex-col relative bg-loading-bg bg-cover rounded-tl-[30px] rounded-br-[30px] justify-between',
        'lg:flex-row lg:bg-loading-bg lg:h-[520px] lg:w-[1090px] ',
      ])}
    >
      <div
        className={classNames([
          'max-w-[420px] m-[24px] flex-col flex justify-center',
          'lg:ml-[70px]',
        ])}
      >
        <h1
          className={classNames([
            'lg:text-[3rem] text-white font-light lg:leading-[56px]',
            'leading-[36px] text-[1.5rem]',
          ])}
        >
          {translations.aboutPage.fomoBanner.title} <br />
          {translations.aboutPage.fomoBanner.secondTitle}
        </h1>
        <p
          className={classNames([
            'lg:mt-[24px] mt-[16px] lg:whitespace-pre-line lg:text-[1.3125rem] lg:leading-[21px] text-[1.1875rem] leading-[19px] text-white font-light ',
          ])}
        >
          {translations.aboutPage.fomoBanner.description}
        </p>
        <Link
            className={classNames([
              'lg:w-[214px] lg:h-[48px] z-50 text-white mt-[16px] lg:mt-[99px] bg-content flex justify-center items-center rounded-tl-[20px] rounded-br-[20px]',
              'w-[214px] h-[48px]',
            ])}
            to={ROUTE_DOWNLOAD}
          >
            {translations.homePage.textButton}
          </Link>
      </div>
      <div
        className={classNames([
          'absolute bottom-0 left-0 right-0  lg:h-[157px] h-[96px] bg-discover lg:opacity-[0.5]',
          'h-[278px]',
        ])}
      />
      <FomoPhoneSVG
        className={classNames([
          'w-full h-[380px] lg:z-10',
          'lg:h-[521px] lg:mr-[70px] lg:w-[480px] ',
        ])}
      />
    </div>
  );
};
