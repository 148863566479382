import { Button } from '../../components/button/button';
import Container from '../../components/container/container';
import { Icon } from '../../components/icon/icon';
import { ProfileInput } from '../../components/profileInput/profileInput';
import Toast from '../../components/toast/toast';
import { ROUTE_HOME } from '../../constants/routes';
import { useSupabase } from '../../providers/supabase';
import {
  profileCenePlusSchema,
  profileCeneSchema,
} from '../../schemas/profileSchema';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { ReactComponent as CloseSVG } from '../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import { ReactComponent as EditSVG } from '../../svg/pencilSimple.svg';
import { ReactComponent as AvatarPlaceholderSVG } from '../../svg/sdoer.svg';
import { User } from '../../types/user';
import CustomDatePicker from './datepicker';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  NOT_TO_SAY = 'Prefer not to say',
}

const ProfilePage = () => {
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';
  const id = session?.user.id;
  const [customer, setCustomer] = useState<User | null>(null);
  const [isSuccess, setSuccess] = useState(false);
  const [isVisibleToast, setVisibleToast] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      gender: customer?.gender || Gender.MALE,
      phone: customer?.phone_number || '',
      companyName: customer?.metadata?.company_name || '',
      jobTitle: customer?.metadata?.job_title || '',
      birthdate: customer?.birthdate || null,
    },
    validationSchema: sessionType ? profileCeneSchema : profileCenePlusSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const client = sessionType
          ? supabaseCeneClient
          : supabaseCenePlusClient;

        const updatedData = {
          gender: values.gender,
          phone_number: values.phone,
          birthdate: values.birthdate,
          metadata: sessionType
            ? undefined
            : { job_title: values.jobTitle, company_name: values.companyName },
        };

        const { error } = await client
          .from('customers')
          .update(updatedData)
          .eq('id', id);

        if (error) {
          setSuccess(false);
        }
        setSuccess(true);

        setVisibleToast(true);

        setTimeout(() => {
          setVisibleToast(false);
        }, 3000);
      } catch (error) {
        setSuccess(false);
        setVisibleToast(true);

        setTimeout(() => {
          setVisibleToast(false);
        }, 3000);
      }
    },
  });

  const gotoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (id) {
      fetchCustomer();
    }
  }, [id]);

  const fetchCustomer = async () => {
    try {
      const client = sessionType ? supabaseCeneClient : supabaseCenePlusClient;

      const { data, error } = await client
        .from('customers')
        .select('*')
        .eq('id', id)
        .single();
      if (error) throw error;

      setCustomer(data);
    } catch (error) {
      console.error('Error fetching customer:', error);
    }
  };

  const content = [
    <div key={1} className="w-full flex flex-col items-center gap-[1.5rem]">
      <h2 className="text-[1.3125rem]/[1.3125rem] font-light text-white max-lg:text-[1.0625rem]/[1.375rem]">
        My details
      </h2>
      <div className="flex items-center gap-[1rem] w-full">
        <div className="relative">
          <AvatarPlaceholderSVG
            style={{ stroke: sessionType ? '#EC76F7' : '#B8CAD9' }}
          />
          <Icon
            color="black"
            className="absolute z-10 bottom-0 right-0 !w-[20px] !h-[20px] rounded-none"
          >
            <EditSVG />
          </Icon>
        </div>
        <div>
          <p
            className={`text-[1.1875rem]/[1.1875rem] font-light ${sessionType ? 'text-white' : 'text-generic'}`}
          >
            {customer?.first_name} {customer?.last_name}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-[1rem]">
        <ProfileInput
          label="Phone"
          id="phone"
          sessionType={sessionType}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.phone}
          touched={formik.touched.phone}
        />
        <ProfileInput
          label="E-mail"
          id="e-mail"
          sessionType={sessionType}
          value={customer?.email}
          disabled={!!customer?.email}
        />

        <ProfileInput
          label="Gender"
          id="gender"
          sessionType={sessionType}
          value={formik.values.gender}
          onChange={(e) =>
            formik.setFieldValue(
              'gender',
              (e.target as HTMLSelectElement).value
            )
          }
          onBlur={formik.handleBlur}
          options={[
            { value: Gender.MALE, label: Gender.MALE },
            { value: Gender.FEMALE, label: Gender.FEMALE },
            { value: Gender.NOT_TO_SAY, label: Gender.NOT_TO_SAY },
          ]}
        />

        <div className="flex flex-col text-start">
          <label className="uppercase text-[0.75rem]/[0.75rem] text-gray-light">
            Birth date
          </label>
          <CustomDatePicker
            date={formik.values.birthdate}
            sessionType={sessionType}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDate = format(newValue, 'yyyy-MM-dd');
                formik.setFieldValue('birthdate', formattedDate);
              } else {
                formik.setFieldValue('birthdate', null);
              }
            }}
            disabled={!!customer?.birthdate}
          />
        </div>

        {!sessionType && (
          <>
            <ProfileInput
              label="Company name"
              id="companyName"
              sessionType={sessionType}
              value={formik.values.companyName}
              onChange={(e) =>
                formik.setFieldValue(
                  'companyName',
                  (e.target as HTMLInputElement).value
                )
              }
              onBlur={formik.handleBlur}
              error={formik.errors.companyName}
              touched={Boolean(formik.touched.companyName)}
            />

            <ProfileInput
              label="Job title"
              id="jobTitle"
              sessionType={sessionType}
              value={formik.values.jobTitle}
              onChange={(e) =>
                formik.setFieldValue(
                  'jobTitle',
                  (e.target as HTMLInputElement).value
                )
              }
              onBlur={formik.handleBlur}
              error={formik.errors.jobTitle}
              touched={Boolean(formik.touched.jobTitle)}
            />
          </>
        )}
      </div>
    </div>,
  ];

  return (
    <div className="relative">
      {isVisibleToast && (
        <Toast
          message={
            isSuccess ? 'Profile update successfully' : 'Error updating profile'
          }
          bgColor={isSuccess ? 'bg-event' : 'bg-error'}
          onClose={() => setVisibleToast(false)}
        />
      )}
      <div className="container mx-auto h-[88px] flex items-center justify-between px-[20px]">
        {sessionType ? (
          <LogoSVG
            onClick={() => navigate(ROUTE_HOME)}
            className="min-w-[164px] min-h-[48px]"
          />
        ) : (
          <LogoPlusSVG
            onClick={() => navigate(ROUTE_HOME)}
            className="min-w-[164px] min-h-[48px]"
          />
        )}
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="lg:w-full  flex justify-center items-center mb-[2.5rem] max-lg:mt:[1rem] max-lg:w-full">
        <div className="!w-[420px] max-lg:w-full flex flex-col gap-[1rem]">
          <Container
            title="Profile"
            contentList={content}
            sessionType={sessionType}
          />
          <div className="w-full flex items-center gap-[1rem]">
            <Button color="outlined" className="w-1/2" onClick={gotoBack}>
              Cancel
            </Button>
            <Button
              color={sessionType ? 'content' : 'generic'}
              className="w-1/2"
              onClick={() => formik.handleSubmit()}
              disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
