import { ReactComponent as BellSvg } from '../../../src/svg/bellWait.svg';
import { ReactComponent as LockSvg } from '../../../src/svg/lock.svg';
import { ReactComponent as TicketSvg } from '../../../src/svg/ticket.svg';
import translations from '../../constants/translations.json';

interface StatusBadgeProps {
  status: string;
  quantity?: number;
  waitlist?: boolean;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  quantity,
  waitlist,
}) => {
  if (!waitlist) {
    return (
      <span className="py-[7px] lg:py-[10px] px-[10px] lg:px-[10px] rounded-big text-[0.625rem] leading-[10px] flex items-center invisible">
        {translations.statusButton.placeholder}
      </span>
    );
  }

  const badgeConfig = {
    Active: {
      condition: status == 'Active',
      content: (
        <>
          <BellSvg className="mr-[4px]" />
          {translations.statusButton.live}
        </>
      ),
      className: 'bg-content text-black',
    },
    'Sold out': {
      condition: quantity === 0 && status !== 'Coming soon',
      content: (
        <>
          <LockSvg className="mr-[4px]" />
          {translations.statusButton.sold}
        </>
      ),
      className: 'bg-gray-medium text-white',
    },
    'Coming soon': {
      condition: status === 'Coming soon',
      content: (
        <>
          <TicketSvg className="mr-[4px]" />
          {translations.statusButton.soon}
        </>
      ),
      className: 'bg-ticket text-black',
    },
  };

  const currentBadge = Object.values(badgeConfig).find(
    (badge) => badge.condition
  );

  return currentBadge ? (
    <span
      className={`py-[7px] lg:py-[10px] px-[10px] lg:px-[10px] rounded-big text-[0.625rem] leading-[10px] flex items-center ${currentBadge.className}`}
    >
      {currentBadge.content}
    </span>
  ) : null;
};
