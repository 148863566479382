import { ReactComponent as ArrowIcon } from '../../../../src/svg/arrowPink.svg';
import { ReactComponent as PeopleIcon } from '../../../../src/svg/peoplePink.svg';
import { ReactComponent as PlusIcon } from '../../../../src/svg/plusBlack.svg';
import { inviteFormSchema } from '../../../schemas/inviteFormSchema';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';

interface InviteFormProps {
  setIsInvaiting: React.Dispatch<React.SetStateAction<boolean>>;
  onSendClick: () => void;
  sessionType: boolean;
}

export const InviteForm: React.FC<InviteFormProps> = ({
  setIsInvaiting,
  onSendClick,
  sessionType,
}) => {
  return (
    <div className="bg-black flex flex-col items-center justify-center  lg:border-t lg:border-gray-medium">
      <div className="max-w-md w-full bg-black text-white relative pt-[16px]">
        <div className="flex items-center justify-between lg:mb-4 mb-[15px]">
          <button
            onClick={() => setIsInvaiting(false)}
            className={classNames([
              'p-[14px] bg-gray-dark items-center justify-center lg:flex hidden ',
              sessionType ? 'rounded-tl-[8px] rounded-br-[8px]' : 'rounded-big',
            ])}
          >
            <ArrowIcon stroke={sessionType ? '#EC67F7' : '#B8CAD9'} />
          </button>
          <h1
            className={classNames([
              'lg:text-[1.3125rem] lg:leading-[1.3125rem] font-normal text-center flex-1 ',
              'text-[1.0625rem] leading-[1.375rem]',
              sessionType ? 'text-ticket' : 'text-generic',
            ])}
          >
            Bring Your Cene
          </h1>
        </div>

        <p className="lg:text-center text-white mb-[24px] text-[1.0625rem] leading-[1.375rem] font-normal text-start">
          Invite someone New. Enter their full name and email below, and we’ll
          handle the rest
        </p>

        <Formik
          initialValues={{ name: '', email: '' }}
          validationSchema={inviteFormSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className={classNames([
                    'block  text-[1.0625rem] leading-[1.375rem] font-normal',
                    sessionType ? 'text-ticket' : 'text-generic',
                  ])}
                >
                  First Name and Last Name
                </label>
                <div className="relative">
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Joseph Ikoyi"
                    className="w-full bg-black border-b border-generic-light text-white focus:outline-none focus:border-purple-500 py-2 pr-10  placeholder-gray-light "
                  />
                  <PeopleIcon
                    className="absolute right-2 top-1/2 transform -translate-y-1/2  pointer-events-none"
                    stroke={sessionType ? '#EC67F7' : '#b8cad9'}
                  />
                </div>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red  text-[1rem] leading-[1.375rem] font-normal "
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className={classNames([
                    'block  text-[1.0625rem] leading-[1.375rem] font-normal',
                    sessionType ? 'text-ticket' : 'text-generic',
                  ])}
                >
                  Email Address
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder="joseph@cene.xyz"
                  className="w-full bg-black border-b border-generic-light text-white focus:outline-none focus:border-purple-500 py-2  placeholder-gray-light"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red text-[1rem] leading-[1.375rem] font-normal"
                />
              </div>

              <button
                onClick={onSendClick}
                type="submit"
                disabled={!isValid || !dirty}
                className={`w-full py-3 text-[1.0625rem] leading-[1.375rem] font-normal text-black rounded-lg flex items-center justify-center space-x-2 transition-colors rounded-tl-[20px] rounded-br-[20px] ${
                  isValid && dirty
                    ? sessionType
                      ? 'bg-ticket'
                      : 'bg-generic'
                    : 'bg-gray-medium'
                }`}
              >
                Send Invite <PlusIcon className="ml-[8px]" stroke="#000000" />
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
