import { ReactComponent as CompassIcon } from '../../../../src/svg/compass.svg';
import { ReactComponent as TicketIcon } from '../../../../src/svg/ticketWhite.svg';
import classNames from 'classnames';

interface TicketInfoProps {
  event: {
    image: string;
    title: string;
    zone: string;
    ticketsAvailable: number;
  };
  sessionType: boolean;
}

export const TicketInfo: React.FC<TicketInfoProps> = ({
  event,
  sessionType,
}) => (
  <div className="flex lg:gap-[32px] gap-[24px]">
    <img
      src={event.image}
      alt={event.title}
      className="lg:w-[119px] lg:h-[154px] rounded-tl-[12px] rounded-br-[12px] w-[48px] h-[62.38px]"
    />
    <div className="flex flex-col lg:gap-[16px] gap-[8px]">
      <p
        className={classNames([
          'lg:text-[1.3125rem] lg:leading-[1.3125rem] font-light',
          'text-[1.1875rem] leading-[1.1875rem]',
          sessionType ? 'text-white' : 'text-generic',
        ])}
      >
        {event.title}
      </p>
      <div className="flex items-center text-[0.875rem] leading-[0.875rem] text-gray-light">
        <CompassIcon stroke={sessionType ? '#FFFFFF' : '#b8cad9'} />
        <span
          className={classNames([
            'font-medium ml-[12px]',
            sessionType ? 'text-white' : 'text-generic',
          ])}
        >
          {event.zone}
        </span>
      </div>
      <div className="flex items-center text-[0.875rem] leading-[0.875rem]">
        <TicketIcon stroke={sessionType ? '#FFFFFF' : '#b8cad9'} />
        <span
          className={classNames([
            ' font-medium ml-[12px]',
            sessionType ? 'text-white' : 'text-generic',
          ])}
        >
          {event.ticketsAvailable} tickets available
        </span>
      </div>
    </div>
  </div>
);
