import { Button } from '../../../components/button/button';
import { Icon } from '../../../components/icon/icon';
import { Loader } from '../../../components/loader/cenePlus/loader';
import { ROUTE_HOME } from '../../../constants/routes';
import { ReactComponent as CloseSVG } from '../../../svg/icon-close.svg';
import { ReactComponent as LogoPlusSVG } from '../../../svg/logotype.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoadingPage = () => {
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);

  const isSuccess = progress === 100;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 3;
        if (nextProgress >= 100) {
          clearInterval(interval);
          navigate(ROUTE_HOME);
          return 100;
        }
        return nextProgress;
      });
    }, 75);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div
      className={classNames({
        'bg-welcomePlus bg-cover bg-center sm:bg-no-repeat sm:shadow-welcome':
          isSuccess,
        'bg-black': !isSuccess,
      })}
    >
      <header className="py-5 px-4 md:px-16 flex justify-between items-center">
        {!isSuccess && (
          <div>
            <LogoPlusSVG
              className={classNames([
                'w-[120px] h-[36px]',
                ' md:w-[164px] md:h-[48px]',
              ])}
            />
          </div>
        )}
        {isSuccess && (
          <Icon color="outlined" onClick={() => navigate(ROUTE_HOME)}>
            <CloseSVG className="fill-white stroke-white text-white absolute top-5 right-5 md:top-10 md:right-10" />
          </Icon>
        )}
      </header>
      {isSuccess ? (
        <div className="relative w-full h-[calc(100vh-92px)]">
          <div
            className={classNames([
              'absolute text-center left-1/2 transform -translate-x-1/2 flex flex-col items-center',
              'bottom-[20px] ',
            ])}
          >
            <h2 className="text-[3rem]/[3.5rem] font-light text-generic">
              Welcome to
            </h2>
            <div>
              <LogoPlusSVG className="w-[196px] h-[56px]" />
            </div>
            <Button
              color="text"
              className="text-generic"
              onClick={() => {
                navigate(ROUTE_HOME, { replace: true });
              }}
            >
              Go to Login
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-full h-[calc(100vh - 92px)] flex flex-col items-center justify-center">
          <div
            className={classNames([
              'w-[456px] text-center',
              'w-[90%] max-w-[456px]',
            ])}
          >
            <h2
              className={classNames([
                'text-[48px]/[56px] font-light text-generic',
              ])}
            >
              Personalising your Cene+ experience
            </h2>
            <div className="mt-[80px] flex flex-col items-center">
              <Loader />
            </div>
            <p className="text-h1 font-light text-generic mt-[80px]">
              {progress}%
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
