import { ROUTE_AUTH } from '../../constants/routes';
import translation from '../../constants/translations.json';
import { supabaseCenePlusClient } from '../../supabase/supabase';
import { ReactComponent as CloseSVG } from '../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../svg/logotype.svg';
import { ReactComponent as SuccessSVG } from '../../svg/successful.svg';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const ThanksPage = () => {
  const navigate = useNavigate();

  const gotoBack = useCallback(async () => {
    await supabaseCenePlusClient.auth.signOut();
    navigate(ROUTE_AUTH);
  }, []);
  return (
    <div className="w-full h-screen overflow-hidden">
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between mb-[40px] px-[20px] relative z-20',
        ])}
      >
        <LogoSVG className="w-[164px] h-[48px]" />
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="absolute top-[50%] -translate-y-[50%] max-lg:left-[50%] max-lg:-translate-x-[50%] lg:left-[204px] z-20 flex flex-col items-center justify-center w-[340px] text-center max-sm:w-full max-sm:px-[20px]">
        <SuccessSVG
          className={classNames(['w-[72px] h-[72px] fill-generic'])}
        />
        <h1 className="text-h1 font-light text-generic mt-[8px]">
          {translation.steps.thanksStep.title}
        </h1>
        <p className="mt-[16px] text-generic text-[1rem]/[1.5rem] font-light">
          {translation.steps.thanksStep.firstDescription}
        </p>
        {/* <p className="mt-[16px] text-generic text-[1rem]/[1.5rem] font-light">

          {translation.steps.thanksStep.secondDescription}
        </p> */}
      </div>
      <img
        src="/pictures/thank-you-page.png"
        className={classNames([
          'w-[545px] h-[696px] absolute bottom-0 right-[76px] z-10 max-md:w-[100%] max-md:h-auto max-md:right-0',
        ])}
        alt="Background"
      />
    </div>
  );
};
