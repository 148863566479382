import { Button } from '../../components/button/button';
import { ROUTE_EVENTS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames([
        'lg:h-[calc(100vh-88px)] flex items-center justify-center relative h-[672px]',
        'max-lg:block max-lg:h-full',
      ])}
    >
      <div
        className={classNames([
          'absolute lg:h-full w-full top-0 left-1/2 -translate-x-1/2 z-10 h-[696px]',
          'bg-bg404 bg-cover bg-center',
          'max-lg:relative max-lg:-mx-[20px] max-lg:-mt-[88px] max-lg:w-auto max-lg:left-0 max-lg:translate-x-0',
        ])}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-black opacity-75 "></div>
      </div>

      <div
        className={classNames([
          'relative z-20 flex flex-col items-center text-center',
          'max-lg:absolute max-lg:top-1/2 max-lg:left-1/2 max-lg:-translate-x-1/2 max-lg:-translate-y-1/2',
        ])}
      >
        <h1
          className={classNames([
            'text-white text-[2.25rem] leading-[44px] font-light  lg:w-[568px] w-[335px]',
            'lg:text-[4rem] lg:leading-[72px] lg:mb-[24px] mb-[16px]',
          ])}
        >
          {translations.notFound.title}
        </h1>
        <p
          className={classNames([
            'text-white text-[1.1875rem] leading-[19px] font-light lg:mb-[24px] lg:w-[562px] w-[335px]',
            'lg:mb-[24px] lg:text-[1.3125rem] lg:leading-[1.3125rem] mb-[32px]',
          ])}
        >
          {translations.notFound.desc}
        </p>
        <div className={classNames(['flex gap-[16px] justify-center'])}>
          <Button
            color="content"
            className="!px-[24px] !py-[12px] rounded-none rounded-tl-[20px] !rounded-br-[20px] lg:w-[195px] w-[159.5px] lg:mt-[16px]"
          >
            {translations.notFound.textButton}
          </Button>
          <Button
            color="event"
            className="px-[24px] py-[12px] rounded-none rounded-tl-[20px] rounded-br-[20px] lg:w-[193px] w-[159.5px] lg:mt-[16px]"
            onClick={() => navigate(ROUTE_EVENTS)}
          >
            {translations.notFound.textButtonTwo}
          </Button>
        </div>
      </div>
    </div>
  );
};
