import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import { Button } from '../button/button';
import { Ticket } from '@ceneteam/namespace';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import React, { useState } from 'react';

interface UsePaymentProps {
  totalPrice: number;
  tickets: Ticket[] | null;
  quantity: { [key: string]: number };
  id: string;
}

export const CheckoutForm: React.FC<UsePaymentProps> = ({
  tickets,
  quantity,
  id,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { session } = useSupabase();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const sessionType = session?.type === 'cene';

  const handleError = (error: Error | StripeError) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setErrorMessage('Stripe or elements not initialized');
      return;
    }

    setLoading(true);

    try {
      const submitResult = await elements.submit();
      if (submitResult.error) {
        handleError(submitResult.error);
        return;
      }
      const ticketsMap = tickets
        ?.map((ticket) => {
          const ticketQuantity = quantity[ticket.id] ?? ticket.quantity ?? 0;
          return ticketQuantity > 0
            ? {
                ticket_name_id: ticket.id,
                quantity: ticketQuantity,
              }
            : null;
        })
        .filter(Boolean);

      if (!ticketsMap || ticketsMap.length === 0) {
        throw new Error('No valid tickets selected');
      }

      const url = `${process.env.REACT_APP_API_URL}/api/v1/payment/checkout`;
      const token = session?.access_token;

      const body = {
        currency: 'USD',
        event_id: id,
        tickets: ticketsMap,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();
      const clientSecret = data.payment?.data?.paymentToken;

      if (!clientSecret) {
        throw new Error('Missing client secret');
      }

      // Confirm the PaymentIntent using the Payment Element (Stripe will handle the payment method automatically)
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'http://localhost:3000/',
        },
      });

      if (error) {
        handleError(error);
      }
    } catch (error) {
      handleError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        className={classNames([
          'bg-event w-full flex justify-center items-end lg:mt-[60px] mt-[16px]',
          sessionType ? 'bg-event' : 'bg-generic',
        ])}
        type="submit"
        disabled={!stripe || loading}
      >
        <p className="text-black">{translations.modalTicketing.payNow}</p>
      </Button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
