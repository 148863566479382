import { useCurrency } from '../../providers/currency';
import { ReactComponent as RadioUncheckedSVG } from '../../svg/radio-unchecked.svg';
import { ReactComponent as RadioCheckedSVG } from '../../svg/radiobuttonGeneric.svg';
import { PaymentProviders } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect } from 'react';

interface CurrencySwitchProps {
  currency?: PaymentProviders;
}

const paymentMethods = {
  NGN: 'paystack',
};

const CurrencySwitch = ({ currency }: CurrencySwitchProps) => {
  const currencyArray = Object.entries(paymentMethods || {}).map(
    ([currency, provider]) => ({ currency, provider })
  );

  const { selectedCurrency, setSelectedCurrency } = useCurrency();

  useEffect(() => {
    if (currency) {
      setSelectedCurrency(Object.keys(currency)[0] as keyof PaymentProviders);
    }
  }, [currency]);

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCurrency(event.target.value as keyof PaymentProviders);
  };

  return (
    <div className="mt-[0.5rem] border-t border-t-gray-medium px-[0.5rem] pt-[0.5rem] text-end">
      <h3 className="text-generic font-light text-[0.875rem]/[0.875rem]">
        Select Payment Currency
      </h3>
      <div
        className={classNames(
          'flex items-center gap-[0.5rem] mt-[0.5rem] justify-end'
        )}
      >
        {currencyArray.map(({ currency }) => (
          <div key={currency} className="flex items-center mt-2">
            <input
              type="radio"
              id={currency}
              name="currency"
              value={currency}
              checked={selectedCurrency === currency}
              onChange={handleCurrencyChange}
              className="hidden"
            />
            <label
              htmlFor={currency}
              className="flex items-center cursor-pointer"
            >
              {selectedCurrency === currency ? (
                <RadioCheckedSVG className="w-5 h-5" />
              ) : (
                <RadioUncheckedSVG className="w-5 h-5" />
              )}
              <span className="ml-2 text-generic font-light">{currency}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrencySwitch;
