import { ReactComponent as CheckIcon } from '../../../../src/svg/checkBlack.svg';
import classNames from 'classnames';

export interface Friend {
  id: number;
  name: string;
  image: string;
}

interface FriendListProps {
  filteredFriends: Friend[];
  selectedFriends: Set<number>;
  onFriendSelect: (id: number) => void;
  sessionType: boolean;
}

export const FriendList: React.FC<FriendListProps> = ({
  filteredFriends,
  selectedFriends,
  onFriendSelect,
  sessionType,
}) => (
  <div>
    {filteredFriends.length === 0 ? (
      <div className="text-center">
        <p className="text-[1.0625rem] leading-[1.375rem] font-normal text-white">Looks a Bit Lonely</p>
        <p className="text-[1.0625rem] leading-[1.375rem] font-normal text-gray-light pt-[8px]">Add a friend to your Scene</p>
      </div>
    ) : (
      <div className="grid grid-cols-4 lg:grid-cols-4 gap-4">
        {filteredFriends.map((friend) => (
          <div
            key={friend.id}
            className="relative rounded-lg text-center cursor-pointer"
            onClick={() => onFriendSelect(friend.id)}
          >
            <img
              src={friend.image}
              alt={friend.name}
              className="lg:w-[102px] lg:h-[102px] rounded-tl-[20px] rounded-br-[20px] w-[64px] h-[64px]"
            />
            {selectedFriends.has(friend.id) && (
              <div
                className={classNames([
                  'absolute lg:bottom-10 right-[6px] rounded-big p-[3.33px] bottom-12',
                  sessionType ? 'bg-ticket' : 'bg-generic',
                ])}
              >
                <CheckIcon />
              </div>
            )}
            <div
              className={classNames([
                'text-center mt-[10px]',
                sessionType ? 'text-white ' : 'text-generic',
              ])}
            >
              <p>{friend.name}</p>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);
