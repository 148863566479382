import { ROUTE_AUTH } from '../../../../constants/routes';
import { RegisterForm } from '../../../../forms/signup/registerForm/cene/registerForm';
import { ReactComponent as CloseSVG } from '../../../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../../../svg/logo.svg';
import { LoadingPage } from '../../../loading/cene/loadingPage';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const SignupPage = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const gotoBack = useCallback(() => navigate(ROUTE_AUTH), []);

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between mb-[40px] px-[20px]',
          'max-lg:h-[72px] max-lg:px-[16px] max-lg:mb-[16px]',
        ])}
      >
        <LogoSVG
          className={classNames([
            'w-[164px] h-[48px]',
            'max-lg:w-[137px] h-[40px]',
          ])}
        />
        <div
          onClick={gotoBack}
          className={classNames([
            'cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]',
            'max-lg:w-[40px] max-lg:h-[40px]',
          ])}
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div
        className={classNames([
          'container mx-auto flex flex-col items-center max-w-[422px]',
          'max-lg:px-[20px]',
        ])}
      >
        <RegisterForm onLoading={() => setLoading(true)} />
      </div>
    </>
  );
};
