import { ReactComponent as CheckSVG } from '../../../../../src/svg/checkBlack.svg';
import { ReactComponent as HeartFilledEventSVG } from '../../../../../src/svg/filledHeart.svg';
import { ReactComponent as HeartEventSVG } from '../../../../../src/svg/heart-event.svg';
import { ReactComponent as ShareEventSVG } from '../../../../../src/svg/share-event.svg';
import { Button } from '../../../../components/button/button';
import { ModalLogIn } from '../../../../components/modalLogIn/modal';
import { ModalTicketing } from '../../../../components/modalTicketing/modal';
import { WaitlistNotification } from '../../../../components/notification/waitlistNotification';
import { ShareOptions } from '../../../../components/shareOptions/shareOptionsOrganization';
import translations from '../../../../constants/translations.json';
import { useEventActions } from '../../../../hooks/useEventActions';
import { useTicketActions } from '../../../../hooks/useTicketActions';
import { CurrencyProvider } from '../../../../providers/currency';
import { useSupabase } from '../../../../providers/supabase';
import { StatusSales, getEventStatus } from './media';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  event: Event | null;
  eventsLiked: Event[];
}

const ActionsSection = ({ event, eventsLiked }: Props) => {
  const location = useLocation();
  const { openPaymentModal } = location.state || {};
  const isWaitlist = location.state?.isWaitlist;
  const [isWaitlisted, setIsWaitlisted] = useState(isWaitlist);
  const [heartClick, setHeartClick] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [isActiveModalOpen, setIsActiveModalOpen] = useState(false);
  const [isTicketingModalOpen, setIsTicketingModalOpen] = useState(
    false || (openPaymentModal as boolean)
  );
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [heartNotificationVisible, setHeartNotificationVisible] =
    useState(false);
  const [maxTotalQuantity, setMaxTotalQuantity] = useState(4);
  const [isPurchaseDisabled, setIsPurchaseDisabled] = useState(false);

  const { session } = useSupabase();
  const { setWaitlist, setLike } = useEventActions({
    userId: session?.user.id || null,
  });
  const { getTicketDetails } = useTicketActions();

  const isGuest = session?.type === undefined;

  const ticketStocks =
    event?.tickets && event?.stock
      ? event.tickets.map((ticket) => event.stock?.[ticket.id] ?? null)[0]
      : null;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (event?.id) {
      try {
        const data = await getTicketDetails(event?.id || '');
        if (data?.tickets && data?.tickets.length >= 4) {
          setIsPurchaseDisabled(true);
        } else if (data?.tickets) {
          const remainingTickets = 4 - data?.tickets.length;
          setMaxTotalQuantity(remainingTickets);

          if (ticketStocks && ticketStocks < maxTotalQuantity) {
            const remainingQuantity = Math.min(remainingTickets, ticketStocks);
            setMaxTotalQuantity(remainingQuantity);
          }
        }
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      }
    }
  };
  const event_id = event?.id || '';

  useEffect(() => {
    if (Array.isArray(eventsLiked)) {
      const isLiked = eventsLiked.some(
        (likedEvent: Event) => likedEvent.id === event_id
      );
      if (isLiked !== isHeartFilled) {
        setIsHeartFilled(isLiked);
      }
    }
  }, [eventsLiked, event_id]);

  const eventStatus = getEventStatus(event);
  const sessionType = session?.type === 'cene';

  const title = event?.name || '';

  const handleWaitlistNotification = () => {
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 5000);
  };

  const handleWaitlist = async () => {
    if (!event_id) return;

    try {
      if (isWaitlisted) {
        await setWaitlist(event_id, true);
        setIsWaitlisted(false);
        setNotificationVisible(false);
      } else {
        await setWaitlist(event_id, false);
        setIsWaitlisted(true);
        handleWaitlistNotification();
      }
    } catch (err) {
      console.error('Error handling waitlist:', err);
    }
  };

  const openActiveModal = () => {
    setHeartClick(false);
    setIsActiveModalOpen(true);
  };

  const closeActiveModal = () => {
    setIsActiveModalOpen(false);
  };

  const toggleTicketingModal = () => {
    setIsTicketingModalOpen((prev) => !prev);
  };

  const toggleShareOptions = () => {
    setShowShareOptions((prev) => !prev);
  };

  const handleCopyLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => setLinkCopied(true))
      .catch((err) => console.error('Failed to copy link:', err));
  };

  const handleButtonClick = async () => {
    if (!event) return;

    if (eventStatus === StatusSales.SOLD_OUT) {
      return;
    }

    if (eventStatus === StatusSales.SELLING_NOW) {
      if (session?.type !== 'cene') {
        setIsActiveModalOpen(true);
        return;
      }
      toggleTicketingModal();
    } else if (eventStatus === StatusSales.ON_THE_WAY) {
      await handleWaitlist();
    }
  };

  const toggleHeart = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (!session) {
      setHeartClick(true);
      openActiveModal();
      return;
    }

    const newIsHeartFilled = !isHeartFilled;

    setIsHeartFilled(newIsHeartFilled);
    setLike(event_id, !newIsHeartFilled);
    console.log(
      `Event ${title} ${
        newIsHeartFilled ? 'added to' : 'removed from'
      } the list`
    );

    if (newIsHeartFilled) {
      setNotificationMessage(
        `Yay! You’ve added ${title} to your favourite events`
      );
    } else {
      setNotificationMessage(
        `You’ve removed ${title} from your favourite events`
      );
    }
    setHeartNotificationVisible(true);

    setTimeout(() => {
      setHeartNotificationVisible(false);
    }, 5000);
  };

  const buttonText = (() => {
    switch (eventStatus) {
      case StatusSales.SOLD_OUT:
        return translations.eventDetailsSection.souldOut;
      case StatusSales.SELLING_NOW:
        return translations.eventDetailsSection.buyNow;
      case StatusSales.ON_THE_WAY:
        return isWaitlisted ? (
          <div className="flex items-center">
            <CheckSVG />
            <span className="ml-4">
              {translations.eventDetailsSection.notWaitList}
            </span>
          </div>
        ) : (
          translations.eventDetailsSection.comingSoon
        );
      default:
        return '';
    }
  })();

  const buttonClass = (() => {
    switch (eventStatus) {
      case StatusSales.SOLD_OUT:
        return 'bg-gray-light';
      case StatusSales.SELLING_NOW:
        return 'bg-event';
      default:
        return 'bg-content';
    }
  })();

  const textClass = 'flex justify-center items-center';

  return (
    <div className="sticky bottom-0 mt-[32px] flex items-center space-x-4 flex-wrap lg:w-full bg-black z-50">
      <div className="flex items-center space-x-[8px]">
        <div
          className="flex items-center justify-center w-[48px] h-[48px] rounded-big bg-gray-dark"
          onClick={toggleShareOptions}
        >
          <ShareEventSVG stroke='#91DBBF' />
        </div>
        <div
          className={classNames(
            'flex items-center justify-center w-[48px] h-[48px] rounded-big bg-gray-dark',
            isGuest && 'hidden'
          )}
          onClick={toggleHeart}
        >
          {isHeartFilled ? (
            <HeartFilledEventSVG style={{ fill: '#91DBBF' }} />
          ) : (
            <HeartEventSVG />
          )}
        </div>
      </div>
      <Button
        className={`flex-grow my-[24px] ${textClass} ${buttonClass}`}
        type="button"
        onClick={handleButtonClick}
      >
        <span className="font-medium">{buttonText}</span>
      </Button>
      {showShareOptions && (
        <div className="absolute z-20 bg-black left-10  -max-md:bottom-[200px] -max-md:left-[16px]">
          <ShareOptions
            onCopyLink={handleCopyLinkClick}
            onClose={toggleShareOptions}
            linkCopied={linkCopied}
          />
        </div>
      )}
      <div>
      <ModalLogIn
        isOpen={isActiveModalOpen}
        onClose={closeActiveModal}
        heartClick={heartClick}
        event={event}
      />
      </div>
      {isTicketingModalOpen && (
        <CurrencyProvider>
          <ModalTicketing
            event={event}
            isOpen={isTicketingModalOpen}
            isPurchaseDisabled={isPurchaseDisabled}
            maxTotalQuantity={maxTotalQuantity}
            onClose={toggleTicketingModal}
          />
        </CurrencyProvider>
      )}
      {heartNotificationVisible && (
        <WaitlistNotification
          visible={heartNotificationVisible}
          message={notificationMessage}
          onClose={() => setHeartNotificationVisible(false)}
        />
      )}
      <WaitlistNotification
        visible={notificationVisible}
        message={
          sessionType
            ? `You’re officially on the waitlist for ${event?.partner_info?.name || 'Event Name'}. Stay ready!`
            : `You’ve joined the waitlist for ${event?.partner_info?.name || 'Event Name'}. You’ll be the first to know as soon as tickets become available.`
        }
        onClose={() => setNotificationVisible(false)}
      />
    </div>
  );
};

export default ActionsSection;
