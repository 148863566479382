import { ROUTE_LOGIN } from '../constants/routes';
import { useSupabase } from '../providers/supabase';
import { Navigate } from 'react-router-dom';

/**
 * ProtectedRoute for authorized users
 */
export const ProtectedRoute = () => {
  const { session } = useSupabase();

  if (!session) return <Navigate to={ROUTE_LOGIN} />;

  return null;
};

/**
 * PublicRoute for unauthorized users
 */
export const PublicRoute = () => {
  const { session } = useSupabase();
  // const location = useLocation();

  if (session && session.onboardingStatus) return <Navigate to={'/'} />;

  return null;
};
