import { ROUTE_TERMS } from '../../../constants/routes';

const services = {
  label: 'Ts & Cs',
  children: [
    { label: 'Cookie Policy', path: ROUTE_TERMS },
    { label: 'Privacy Policy', path: ROUTE_TERMS },
    { label: 'Purchase Terms', path: ROUTE_TERMS },
    { label: 'Terms of Use', path: ROUTE_TERMS },
  ],
};

export default services;
