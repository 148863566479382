import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

const Sounds = '/pictures/soundsBanner.png';

export const SoundsLikeSection = () => {
  return (
    <div className={classNames(['lg:my-[120px] lg:mx-[64px] mb-[64px]'])}>
      <div
        className={classNames([
          'flex flex-col container mx-auto',
          'lg:flex-row lg:gap-[135px] gap-0',
        ])}
      >
        <div
          className={classNames([
            'w-full flex justify-center',
            'lg:mb-0 lg:justify-end lg:w-1/2',
          ])}
        >
          <img
            src={Sounds}
            alt="Img"
            className={classNames([
              'lg:w-[644px] lg:h-[644px] rounded-tl-[30px] object-cover w-full',
              'lg:max-w-none',
            ])}
          />
        </div>
        <div className="w-full lg:w-[55%] flex flex-col justify-center"> 
          <div className="lg:mt-0 mt-[24px] ">
            <h2
              className={classNames([
                'text-[2rem] leading-[40px] text-event lg:w-[533px]',
                'lg:leading-[72px] lg:text-[4rem] font-light',
              ])}
            >
             {translations.soundsLike.title}
            </h2>
            <p className="lg:text-[1.3125rem] text-white mt-[24px] lg:leading-[21px] font-light text-[1.1875rem] leading-[19px] whitespace-pre-line">
            {translations.soundsLike.desc}
            </p>
          </div>
        
          <Button className="text-black bg-event w-[199px] h-[48px] lg:mt-[48px] mt-[24px] font-medium">
          {translations.soundsLike.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
