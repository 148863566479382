import { Button } from '../../components/button/button';
import { ROUTER_MY_TICKETS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface StepThreeProps {
  session?: string | undefined;
}
export const StepThree: React.FC<StepThreeProps> = ({ session }) => {
  const sessionType = session === 'cene';
  const navigate = useNavigate();

  const gifUrl = sessionType
    ? 'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/cene_im_going_1.gif'
    : 'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/ceneplus_im_going_1.gif';

  const handleNavigate = () => {
    navigate(ROUTER_MY_TICKETS);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = gifUrl;
    link.setAttribute('download', 'cene_collectible.gif');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="relative my-[40px]">
        <picture>
          <img
            className={classNames([
              'h-full w-full object-cover rounded-tl-[30px] rounded-br-[30px]',
            ])}
            src={gifUrl}
            alt="GIF"
          />
        </picture>
        <div className="auto bg-card-gradient absolute w-full h-[110%] top-0 -bottom-3" />
      </div>
      <h1 className="text-[1.3125rem] leading-[21px] text-white mb-[16px] font-light lg:text-[2rem] lg:leading-[40px]">
        {translations.paymentSuccess.thanks}
      </h1>
      <p className=" text-[0.875rem] leading-[14px] font-light  text-white mb-[23px] lg:text-[1.3125rem] lg:leading-[21px]">
        {translations.paymentSuccess.exclusive}
      </p>

      <div className="flex flex-col gap-[0.75rem]">
        <Button
          color={sessionType ? 'event' : 'generic'}
          className="w-full"
          onClick={handleDownload}
        >
          Download your Collectible
        </Button>
        <Button
          color={sessionType ? 'ticket' : 'generic'}
          className="w-full"
          onClick={handleNavigate}
        >
          Go to My Tickets
        </Button>
      </div>
    </>
  );
};
