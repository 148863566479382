import { ReactComponent as ArrowLeftCenePlusIcon } from '../../../src/svg/arrowLeftCenePlus.svg';
import { ReactComponent as ArrowIcon } from '../../../src/svg/arrowPink.svg';
import { ReactComponent as CloseIcon } from '../../../src/svg/closePink.svg';
import { ReactComponent as InfoIcon } from '../../../src/svg/info-icon.svg';
import { ReactComponent as ShareIcon } from '../../../src/svg/shareWhite.svg';
import { ReactComponent as TicketIcon } from '../../../src/svg/ticketPink.svg';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import classNames from 'classnames';
import React, { useState } from 'react';

interface TicketModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSendClick: () => void;
  sessionType: boolean;
}

export const TicketsModal: React.FC<TicketModalProps> = ({
  isOpen,
  onClose,
  onSendClick,
  sessionType,
}) => {
  useDisableBodyScroll(isOpen);
  if (!isOpen) return null;

  const [currentSeat, setCurrentSeat] = useState(1);

  const changeSeat = (direction: 'left' | 'right') => {
    setCurrentSeat((prevSeat) => {
      if (direction === 'left') {
        return prevSeat > 1 ? prevSeat - 1 : 3;
      } else {
        return prevSeat < 3 ? prevSeat + 1 : 1;
      }
    });
  };

  return (
    <div className="fixed inset-0 bg-black lg:bg-opacity-50 backdrop-blur-[16px] z-50">
      {/* Header Section */}
      <div className="px-6 py-4 text-white">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <button
              onClick={onClose}
              className={classNames([
                'p-[14px] bg-gray-dark flex items-center justify-center',
                sessionType
                  ? 'rounded-tl-[8px] rounded-br-[8px]'
                  : 'rounded-big',
              ])}
            >
              {sessionType ? (
                <ArrowIcon stroke="#EC67F7" />
              ) : (
                <ArrowLeftCenePlusIcon />
              )}
            </button>
            <span className="ml-2 lg:flex hidden">Back</span>
          </div>
          <p
            className={classNames([
              'lg:text-[1.3125rem] lg:leading-[1.3125rem] font-light text-[1.0625rem] leading-[1.375rem]',
              sessionType ? 'text-white' : 'text-generic',
            ])}
          >
            Zone A - Table 13
          </p>

          <button
            onClick={onClose}
            className={classNames([
              'p-[14px] bg-gray-dark flex items-center justify-center ',
              sessionType ? 'rounded-tl-[8px] rounded-br-[8px]' : 'rounded-big',
            ])}
          >
            <CloseIcon stroke={sessionType ? '#EC67F7' : '#B8CAD9'} />
          </button>
        </div>

        <div className="text-center ">
          <p
            className={classNames([
              'lg:text-[1.0625rem] lg:leading-[1.375rem] font-light text-[1rem] leading-[1.5rem] mt-2',
              sessionType ? 'text-gray-light' : 'text-generic',
            ])}
          >
            5 out of 10 seats on this table are available
          </p>
        </div>
      </div>

      {/* Modal Content */}
      <div className="flex justify-center max-h-[85vh] xl:max-h-[90vh] lg:max-h-[90vh] 2xl:max-h-[86vh] 3xl:max-h-[80vh] relative ">
        <div className="absolute top-1/2  z-10 transform -translate-y-1/2  left-[5%]  xl:left-[30.5%] 2xl:left-[33.5%] 3xl:left-[35.6%]">
          <button
            onClick={() => changeSeat('left')}
            className="lg:p-[14px] lg:bg-gray-dark flex items-center justify-center rounded-tl-[8px] rounded-br-[8px]"
          >
            <ArrowIcon stroke={sessionType ? '#EC67F7' : '#B8CAD9'} />
          </button>
        </div>

        <div className="absolute top-1/2 z-10 transform -translate-y-1/2 right-[4%]  xl:right-[30.5%] 2xl:right-[33.5%] 3xl:right-[35.5%]">
          <button
            onClick={() => changeSeat('right')}
            className="lg:p-[14px] lg:bg-gray-dark flex items-center justify-center rounded-tl-[8px] rounded-br-[8px]"
          >
            <ArrowIcon
              className="transform rotate-180"
              stroke={sessionType ? '#EC67F7' : '#B8CAD9'}
            />
          </button>
        </div>

        <div className="bg-black text-white rounded-lg lg:p-[40px] p-[20px] lg:w-[452px] w-[285px] relative border border-gray-medium rounded-tl-[30px] rounded-br-[30px] flex flex-col lg:gap-[80px] gap-[40px]">
          {/* Content */}
          <div className="flex flex-col lg:gap-[80px] gap-[20px]">
            <div className="text-center lg:gap-[40px] gap-[20px] flex flex-col">
              <div className="flex justify-between">
                <div className="flex flex-col gap-[8px]">
                  <p
                    className={classNames([
                      ' text-start text-[1.3125rem] leading-[1.3125rem] font-light',
                      sessionType ? 'text-white' : 'text-generic',
                    ])}
                  >
                    Seat {currentSeat}
                  </p>
                  <p className="text-gray-light text-[1rem] leading-[1.3125rem] font-light">
                    Owner: You
                  </p>
                </div>

                <InfoIcon />
              </div>
            </div>

            <div className="flex flex-col items-center lg:gap-[40px]  gap-[20px]">
              <TicketIcon stroke={sessionType ? '#EC67F7' : '#B8CAD9'} />
              <div className="flex flex-col gap-[16px] text-center">
                <p
                  className={classNames([
                    ' text-[1.0625rem] leading-[1.375rem] font-light',
                    sessionType ? 'text-ticket' : 'text-generic',
                  ])}
                >
                  Your Ticket
                </p>
                <p
                  className={classNames([
                    '  lg:text-[1.0625rem] lg:leading-[1.375rem] text-[0.875rem] leading-[1.3125rem]',
                    sessionType ? 'text-white' : 'text-generic',
                  ])}
                >
                  For your security this{' '}
                  <span className=" font-bold">QR code</span> can’t be activated
                  until
                  <span className=" font-bold"> 2 hours</span> before the event.
                </p>
              </div>
              <button
                className="bg-gray-medium text-gray-light lg:w-[268px] w-full rounded-lg hover:bg-gray-dark rounded-tl-[20px] rounded-br-[20px] px-[24px] py-[12px] text-[1rem] leading-[1.5rem] font-medium"
                disabled
              >
                Activate in 2 hours
              </button>
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-between items-center ">
            <div className="flex items-center  hover:text-white cursor-pointer  ">
              <div>
                <p className="text-gray-light lg:text-[1rem] lg:leading-[1.3125rem] font-light text-[0.75rem] leading-[0.75rem] ">
                  Gift Ticket
                </p>
                <div
                  className="flex items-center pt-[16px] "
                  onClick={onSendClick}
                >
                  <p
                    className={classNames([
                      'lg:text-[1.0625rem] lg:leading-[1.375rem] font-normal text-[0.75rem] leading-[0.75rem] ',
                      sessionType ? 'text-white' : 'text-generic',
                    ])}
                  >
                    Send
                  </p>
                  <ShareIcon
                    className="ml-2"
                    width={20}
                    hanging={20}
                    stroke={sessionType ? '#FFFFFF' : '#b8cad9'}
                  />
                </div>
              </div>
            </div>

            <div>
              <p className="text-gray-light lg:text-[1rem] lg:leading-[1.3125rem] font-light text-[0.75rem] leading-[0.75rem]">
                Doors Open
              </p>
              <p
                className={classNames([
                  'text-end lg:text-[1.0625rem] lg:leading-[1.375rem] font-normal text-[0.75rem] leading-[0.75rem] pt-[16px]',
                  sessionType ? 'text-white ' : 'text-generic',
                ])}
              >
                20:00
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
