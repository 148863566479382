import { ReactComponent as LeftArrowPlusSvg } from '../../../src/svg/arrowLeftCenePlus.svg';
import { ReactComponent as ArrowRightSvg } from '../../../src/svg/arrowRight.svg';
import { ReactComponent as ArrowRightEventSvg } from '../../../src/svg/arrowRightEvent.svg';
import { ReactComponent as ArrowRightGenericSvg } from '../../../src/svg/arrowRightGeneric.svg';
import { ReactComponent as ArrowRightGraySvg } from '../../../src/svg/arrowRightGray.svg';
import { ReactComponent as CheckSvg } from '../../../src/svg/checkBlack.svg';
import { ReactComponent as CloseModalSvg } from '../../../src/svg/closeModalGeneric.svg';
import translations from '../../constants/translations.json';
import { Header } from '../../layout/header/header';
import classNames from 'classnames';
import React from 'react';

interface StepData {
  label: string;
  iconClass: string;
  textColor: string;
  arrowIcon?: React.ReactNode;
  activeArrowIcon?: React.ReactNode;
  completed?: boolean;
}

const steps: StepData[] = [
  {
    label: translations.modalHeader.ticket,
    iconClass: 'border-event',
    textColor: 'event',
    arrowIcon: <ArrowRightGenericSvg />,
    activeArrowIcon: <ArrowRightSvg />,
    completed: false,
  },
  {
    label: translations.modalHeader.payment,
    iconClass: 'border-generic',
    textColor: 'generic',
    arrowIcon: <ArrowRightGenericSvg />,
    activeArrowIcon: <ArrowRightSvg />,
    completed: false,
  },
  {
    label: translations.modalHeader.finish,
    iconClass: 'border-generic',
    textColor: 'generic',
  },
];

interface ModalHeaderProps {
  onCloseClick: () => void;
  onBackClick: () => void;
  currentStep: number;
  session: string | undefined;
  isPaymentSuccessful: boolean;
  isPaymentError: boolean;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  onBackClick,
  currentStep,
  onCloseClick,
  session,
  isPaymentSuccessful,
  isPaymentError,
}) => {
  const sessionType = session === 'cene';
  const updatedSteps = steps.map((step, index) => {
    const isCompleted = index < currentStep;

    return {
      ...step,
      completed: isCompleted,
      iconClass: isCompleted
        ? sessionType
          ? 'bg-event'
          : 'bg-generic'
        : index === currentStep
          ? sessionType
            ? 'border-event'
            : 'border-generic'
          : 'border-gray-light',
      textColor: isCompleted
        ? sessionType
          ? 'event'
          : 'generic'
        : index === currentStep
          ? sessionType
            ? 'event'
            : 'generic'
          : 'gray-light',
      arrowIcon: sessionType ? <ArrowRightGenericSvg /> : <ArrowRightGraySvg />,
      activeArrowIcon: sessionType ? (
        <ArrowRightSvg />
      ) : (
        <ArrowRightGenericSvg />
      ),
    };
  });

  return (
    <div className="fixed top-0 w-full z-[999] lg:mt-[18px]">
      <div
        className={classNames([
          'flex px-[20px] flex-col',
          'lg:hidden bg-black ',
        ])}
      >
        <div className="w-full">
          <Header />
        </div>
        {!isPaymentError ? (
          <div
            className={classNames([
              'flex justify-between items-center mt-[8px]  mb-[8px]',
              'lg:mt-[16px] lg:mb-0',
            ])}
          >
            <div className="flex items-center">
              {(!isPaymentSuccessful || isPaymentError) && (
                <>
                  <button
                    className={classNames([
                      'flex items-center mr-[16px] justify-center bg-gray-dark w-[40px] h-[40px]',
                      sessionType
                        ? ' rounded-tl-[8px] rounded-br-[8px]'
                        : ' rounded-big',
                    ])}
                    onClick={onBackClick}
                  >
                    {sessionType ? (
                      <ArrowRightEventSvg className="transform scale-x-[-1]" />
                    ) : (
                      <LeftArrowPlusSvg />
                    )}
                  </button>
                  <p className="text-white text-[0.875rem] leading-[14px]">
                    {translations.organizer.back}
                  </p>
                </>
              )}
            </div>
            <button
              className={classNames([
                ' flex justify-center items-center text-generic bg-gray-dark  w-[40px] h-[40px]',
                sessionType
                  ? 'rounded-tl-[8px] rounded-br-[8px]'
                  : ' rounded-big',
              ])}
              onClick={onCloseClick}
            >
              <CloseModalSvg />
            </button>
          </div>
        ) : (
          <button
            className={classNames([
              'absolute top-[72px] right-0 mt-[8px] mr-[20px] text-generic bg-gray-dark  w-[40px] h-[40px] flex items-center justify-center',
              sessionType ? 'rounded-tl-[8px] rounded-br-[8px]' : 'rounded-big',
            ])}
            onClick={onCloseClick}
          >
            <CloseModalSvg />
          </button>
        )}

        <div
          className={classNames([
            'text-event flex flex-row space-x-[24px] flex-grow justify-center mt-[14px] mb-[14px]',
            'lg:mb-0 lg:mt-[16px]',
          ])}
        >
          {!isPaymentError &&
            updatedSteps.map((step, index) => (
              <div key={index} className="flex flex-row items-center">
                <div
                  className={`w-[16px] h-[16px] rounded-big border ${step.iconClass} mr-[10px] flex items-center justify-center`}
                >
                  {step.completed && <CheckSvg className="w-[10px] h-[10px]" />}
                </div>
                <p
                  className={`text-${step.textColor} mr-[8px] lg:text-[1.0625rem] lg:leading-[22px] text-[1rem] leading-[21px]`}
                >
                  {step.label}
                </p>
                {step.arrowIcon &&
                  (index < currentStep ? step.activeArrowIcon : step.arrowIcon)}
              </div>
            ))}

          {!isPaymentError && (
            <button
              className={classNames([
                'lg:flex hidden items-center justify-center bg-gray-dark  w-[40px] h-[40px]',
                sessionType
                  ? 'rounded-tl-[8px] rounded-br-[8px]'
                  : 'rounded-big',
              ])}
              onClick={onCloseClick}
            >
              <CloseModalSvg />
            </button>
          )}
        </div>
      </div>
      <div
        className={classNames([
          'hidden items-center justify-between mx-[64px]',
          ' lg:flex',
        ])}
      >
        {!isPaymentError && (
          <div className="flex items-center">
            {(!isPaymentSuccessful || isPaymentError) && (
              <>
                <button
                  className={classNames([
                    ' mr-[16px] leading-[14px] text-[0.875rem] w-[40px] h-[40px] bg-gray-dark  flex items-center justify-center',
                    sessionType
                      ? 'rounded-tl-[8px] rounded-br-[8px]'
                      : 'rounded-big',
                  ])}
                  onClick={onBackClick}
                >
                  {sessionType ? (
                    <ArrowRightEventSvg className="transform scale-x-[-1]" />
                  ) : (
                    <LeftArrowPlusSvg />
                  )}
                </button>
                <p className="text-white text-[0.875rem] leading-[14px]">
                  {translations.organizer.back}
                </p>
              </>
            )}
          </div>
        )}
        <div className="text-event flex flex-row space-x-[24px] flex-grow justify-center">
          {!isPaymentError &&
            updatedSteps.map((step, index) => (
              <div key={index} className="flex flex-row items-center">
                <div
                  className={`w-[16px] h-[16px] rounded-big border ${step.iconClass} mr-[10px] flex items-center justify-center`}
                >
                  {step.completed && <CheckSvg className="w-[10px] h-[10px]" />}
                </div>
                <p
                  className={`text-${step.textColor} mr-[8px] text-[1.0625rem] leading-[22px]`}
                >
                  {step.label}
                </p>
                {step.arrowIcon &&
                  (index < currentStep ? step.activeArrowIcon : step.arrowIcon)}
              </div>
            ))}
        </div>

        {!isPaymentError && (
          <button
            className={classNames([
              'lg:flex hidden items-center justify-center bg-gray-dark w-[40px] h-[40px]',
              sessionType
                ? 'rounded-tl-[8px] rounded-br-[8px]'
                : ' rounded-big',
            ])}
            onClick={onCloseClick}
          >
            <CloseModalSvg />
          </button>
        )}
      </div>
    </div>
  );
};
