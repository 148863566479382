import { BackButton } from '../../components/backButton';
import { Button } from '../../components/button/button';
import { Loader } from '../../components/loader/cenePlus/loader';
import { ROUTE_EVENTS_PLUS } from '../../constants/routes';
import { TCart, useCenePlusCart } from '../../providers/cenePlusBasket';
import { SeatsioTables } from '../../seatsio/tables';
import { supabaseCenePlusClient } from '../../supabase/supabase';
import {
  Category,
  HoldToken,
  Pricing,
  SeatingChart,
  SelectableObject,
} from '../../types/seatsio';
import { CartModal } from './cart.modal';
import { EventInformation } from './eventInformation';
import { SeatsioFilters } from './seatsioFilters';
import { SelectionModal } from './selection.modal';
import { Timer } from './timer';
import { Event } from '@ceneteam/namespace';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type TSelectableObject = SelectableObject & {
  seatId: string;
  confirmSelection: (param: string) => void;
};

export const TablesPage = () => {
  const navigate = useNavigate();
  const { addCartItem, cart, removeCartItem, clearCart } = useCenePlusCart();

  const { id } = useParams();

  const [seatsioSession, setSeatsioSession] = useState<HoldToken | null>(null);
  const [selectableObject, setSelectableObject] =
    useState<TSelectableObject | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [chart, setChart] = useState<SeatingChart | null>(null);

  const [isLoading, setLoading] = useState(true);
  const [event, setEvent] = useState<Event | null>(null);
  const [isOpenCartModal, setOpenCartModal] = useState(false);

  useEffect(() => {
    return () => {
      clearCart();
    };
  }, []);

  useEffect(() => {
    fetchEventById();
  }, [id]);

  const gotoEvents = useCallback(() => navigate(ROUTE_EVENTS_PLUS), []);

  const fetchEventById = async () => {
    try {
      const { data, error } = await supabaseCenePlusClient
        .from('events')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      setEvent(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTokenExpired = useCallback(() => {
    /* Clear session */
    setSeatsioSession(null);

    /* Clear selectable object */
    setSelectableObject(null);

    clearCart();
  }, []);

  const handleObjectClicked = useCallback((object: TSelectableObject) => {
    setSelectableObject(object);
  }, []);

  const handleZoneFilter = useCallback(
    (filteredZone: string[]) => {
      if (!chart) return;

      chart.changeConfig({
        filteredCategories: filteredZone,
      });

      chart.zoomToFilteredCategories();
    },
    [chart]
  );

  const handleCloseModal = useCallback(() => setSelectableObject(null), []);

  const handleAddToBasket = useCallback(() => {
    if (!selectableObject || !event) return;

    selectableObject?.confirmSelection('Seat');

    const getTicketInformation = event.tickets?.find(
      (item) => item.name === selectableObject.labels.section
    );

    addCartItem({
      quantity: 1,
      selectableObject,
      ...getTicketInformation,
    } as TCart);

    setSelectableObject(null);
  }, [selectableObject]);

  const handleDeselect = (
    selectedObject: TSelectableObject & { seatId: string }
  ) => {
    removeCartItem(selectedObject.seatId);
  };

  const pricing = event?.tickets?.map((ticket) => ({
    category: ticket.name,
    ticketTypes: [{ ticketType: 'Seat', price: ticket.NGN || 0 }],
  }));

  const handleChartRendered = (chart: SeatingChart) => {
    setChart(chart);
  };

  const handleRemoveItem = (id: string) => {
    chart?.deselectObjects([id]);
  };

  const handleInitSelectableObjects = (
    selectableObjects: SelectableObject[]
  ) => {
    if (!event) return;

    if (selectableObjects.length === 0) return;

    selectableObjects.map((item) => {
      const getTicketInformation = event.tickets?.find(
        (item2) => item2.name === item.labels.section
      );

      addCartItem({
        quantity: 1,
        selectableObject: item,
        ...getTicketInformation,
      } as TCart);
    });
  };

  if (isLoading)
    return (
      <div className="flex items-center justify-center h-[calc(100vh-80px)]">
        <div className="flex h-[120px] w-[120px]">
          <Loader />
        </div>
      </div>
    );

  return (
    <>
      <div className="flex justify-between items-center sticky z-[200] top-[0px] pt-[10px] pb-[10px] bg-black">
        <BackButton onClick={gotoEvents} label="Back all events" />
        <div className="flex gap-2 items-center">
          {seatsioSession && (
            <Timer initial={seatsioSession.expiresInSeconds} />
          )}
          <Button
            disabled={cart.length === 0}
            className={`bg-generic min-w-[172px] w-full flex justify-center items-center`}
            onClick={() => setOpenCartModal(true)}
          >
            <p className="text-black">My Basket: {cart.length}</p>
          </Button>
        </div>
      </div>

      {event && <EventInformation event={event} />}

      <SeatsioFilters onChangeZone={handleZoneFilter} categories={categories} />

      <div className="relative min-h-[546px] mb-[50px] bg-black border-solid border border-[rgba(184,202,217,0.16)]  p-[32px] rounded-br-[30px] rounded-tl-[30px]">
        {selectableObject && event && (
          <SelectionModal
            event={event}
            onAddToBasket={handleAddToBasket}
            onCloseModal={handleCloseModal}
            selectableObject={selectableObject}
          />
        )}

        <SeatsioTables
          currencyLabel="₦"
          onInitSelectableObjects={handleInitSelectableObjects}
          pricing={pricing as Pricing}
          onChartRendered={(chart) => handleChartRendered(chart)}
          onListCategories={setCategories}
          onObjectClicked={(obj) =>
            handleObjectClicked(obj as TSelectableObject & { seatId: string })
          }
          onHoldTokenExpired={handleTokenExpired}
          onSessionInitialized={setSeatsioSession}
          onObjectDeselected={(obj) =>
            handleDeselect(obj as TSelectableObject & { seatId: string })
          }
          eventId={id as string}
        />
      </div>
      {isOpenCartModal && (
        <CartModal
          onRemoveItem={handleRemoveItem}
          onCloseModal={() => setOpenCartModal(false)}
          event={event as Event}
        />
      )}
    </>
  );
};
