import { ICategory } from '../../../types/category';

const partners: ICategory = {
  label: 'Cene Partners',
  children: [
    {
      question: 'How do I create an event on Cene?',
      answer: (
        <p>
          Simply log in to your Cene Partner dashboard, navigate to the
          &quot;Create Event&quot; section, and follow the on-screen
          instructions. You&apos;ll be able to input event details, set ticket
          prices, and choose ticket categories.
        </p>
      ),
    },
    {
      question: 'Can I customize my event page?',
      answer: (
        <p>
          Absolutely! You can personalize your event page with images, videos,
          and detailed descriptions to attract attendees.
        </p>
      ),
    },
    {
      question: 'How can I promote my event on Cene?',
      answer: (
        <p>
          Cene provides a range of tools to help you promote your event,
          including social media sharing, email marketing integration, and paid
          advertising options.
        </p>
      ),
    },
    {
      question: 'Can I use my own marketing materials?',
      answer: (
        <p>
          Absolutely! You can use your marketing materials alongside Cene&apos;s
          promotional tools to reach a wider audience.
        </p>
      ),
    },
    {
      question: 'How does Cene handle ticket sales and payments?',
      answer: (
        <p>
          Cene offers a secure ticketing platform that handles all ticket sales
          and payments. You&apos;ll receive detailed sales reports and payouts.
        </p>
      ),
    },
    {
      question: 'Can I offer different ticket types?',
      answer: (
        <p>
          Yes, you can create various ticket types with different prices and
          inclusions to cater to your audience.
        </p>
      ),
    },
    {
      question: 'How do I manage attendees?',
      answer: (
        <p>
          Cene provides tools to manage attendee information, including check-in
          and guest lists.
        </p>
      ),
    },
    {
      question: 'Are there any resources available to help me plan my event?',
      answer: (
        <p>
          Cene offers a variety of resources, including event planning guides,
          marketing tips, and industry best practices.
        </p>
      ),
    },
  ],
};

export default partners;
