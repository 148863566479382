import Tabs from './faqTabs';
import classNames from 'classnames';

export const FaqPage = () => {
  return (
    <>
      <div
        className={classNames(['h-[450px] max-md:h-[295px] flex items-end'])}
      >
        <h1
          className={classNames([
            'text-white text-[4.5rem]/[5rem] font-light relative z-20 mb-[56px]',
            'max-lg:text-[2.25rem]/[2.75rem] max-lg:mb-[40px]',
          ])}
        >
          FAQs
        </h1>
        <div
          className={classNames([
            'absolute h-auto top-0 left-1/2 -translate-x-1/2 z-10',
            'max-lg:w-full max-lg:h-full max-lg:max-h-[550px] max-md:max-h-[375px]',
            'lg:min-w-[860px]',
            'min-lg:max-w-[860px] min-lg:max-h-[520px]',
          ])}
        >
          <img
            src="/pictures/faq-image.png"
            className="lg:h-[538px] max-md:h-[375px] h-full w-auto max-sm:object-cover max-sm:object-[40%] max-lg:object-cover max-lg:object-[50%] max-lg:w-full"
          />
          <div
            className={classNames([
              'bg-faq-gradient absolute w-full h-full top-0',
              'max-lg:bg-faq-tab-gradient',
            ])}
          />
        </div>
      </div>
      <div className="py-[120px] max-lg:py-[72px]">
        <Tabs />
      </div>
    </>
  );
};
