import { CeneModeProvider } from './providers/ceneMode';
import { CenePlusBasketContextProvider } from './providers/cenePlusBasket';
import { SupabaseProvider } from './providers/supabase';
import router from './routes/router';
import './sentry';
import { RouterProvider } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const App = () => (
  <SupabaseProvider>
    <CeneModeProvider>
      <CenePlusBasketContextProvider>
        <RouterProvider router={router} />
      </CenePlusBasketContextProvider>
    </CeneModeProvider>
  </SupabaseProvider>
);

export default App;
