
import { authRouter } from './authRouter';
import { generalRouter } from './generalRouter';
import { unauthRouter } from './unauthRouter';
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  authRouter,
  unauthRouter,
  generalRouter,
]);

export default router;
