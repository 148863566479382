import { ROUTE_AUTH } from '../../../constants/routes';
import { PeriodExpiredForm } from '../../../forms/periodExpired/periodExpiredForm';
import { ReactComponent as CloseSVG } from '../../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../../svg/logo.svg';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const PeriodExpiredPage = () => {
  const navigate = useNavigate();

  const gotoBack = useCallback(() => navigate(ROUTE_AUTH), []);

  return (
    <>
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between mb-[40px]',
          ' px-5 sm:px-0 ',
        ])}
      >
        <LogoSVG className="w-[164px] h-[48px]" />
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-big"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center max-w-[422px]">
        <PeriodExpiredForm />
      </div>
    </>
  );
};
