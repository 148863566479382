import { ReactComponent as ArrowLeftSVG } from '../../../src/svg/caretLeft.svg';
import { ReactComponent as ArrowRightSVG } from '../../../src/svg/caretRight.svg';
import { Icon } from '../icon/icon';
import classNames from 'classnames';

interface CarouselBarProps {
  totalSlides: number;
  currentIndex: number;
  slidesToShow?: number;
  handlePrev: () => void;
  handleNext: () => void;
  prevDisabled: boolean;
  nextDisabled: boolean;
  color?: string | undefined;
}

const CarouselBar = ({
  totalSlides,
  currentIndex,
  slidesToShow,
  handlePrev,
  handleNext,
  prevDisabled,
  nextDisabled,
  color = '#B8CAD9',
}: CarouselBarProps) => {
  const totalGroups = Math.ceil(totalSlides / (slidesToShow || 1));
  const indicatorWidth = 100 / totalGroups;
  return (
    <>
      <div className={classNames('flex items-center justify-between mx-auto')}>
        <div className="flex-grow flex items-center lg:w-[1160px] w-full">
          {Array.from({ length: totalGroups }).map((_, index) => (
            <div
              key={index}
              className={'inline-block h-[2px]'}
              style={{
                backgroundColor:
                  index === Math.floor(currentIndex / (slidesToShow || 1))
                    ? color
                    : '#222',
                width: `${indicatorWidth}%`,
              }}
            />
          ))}
        </div>

        <div className="flex space-x-[8px] lg:ml-[48px] ml-[16px]">
          <Icon
            disabled={prevDisabled}
            onClick={handlePrev}
            className="rounded-tl-[8px] rounded-br-[8px] w-[48px] h-[48px] max-lg:w-[40px] max-lg:h-[40px]"
          >
            <ArrowLeftSVG
              style={{ stroke: color }}
              className={classNames('w-[12px] h-[12px]')}
            />
          </Icon>
          <Icon
            disabled={nextDisabled}
            onClick={handleNext}
            className="rounded-tl-[8px] rounded-br-[8px] w-[48px] h-[48px] max-lg:w-[40px] max-lg:h-[40px]"
          >
            <ArrowRightSVG
              style={{ stroke: color }}
              className={classNames('w-[12px] h-[12px]')}
            />
          </Icon>
        </div>
      </div>
    </>
  );
};

export default CarouselBar;
