import accessibility from './accessibility';
import account from './account';
import general from './general';
import partners from './partners';
import payment from './payment';
import services from './services';
import cenePlus from './cenePlus';
import tickets from './tickets';
import waitlist from './waitlist';

const categoryList = [
  accessibility,
  account,
  cenePlus,
  partners,
  general,
  payment,
  waitlist,
  tickets,
  services,
 
];

export default categoryList;
