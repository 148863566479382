import classNames from 'classnames';

interface ReusableTabsProps<T extends { [key: string]: string }> {
  tabs: T;
  activeTab: keyof T;
  onTabClick: (tabKey: keyof T) => void;
  className?: string;
  sessionType?: boolean;
  activeTabColor?: string;
  inactiveTabColor?: string;
  activeTextColor?: string;
  inactiveTextColor?: string;
}

const ReusableTabs = <T extends { [key: string]: string }>({
  tabs,
  activeTab,
  onTabClick,
  className,
  sessionType,
  activeTabColor = 'bg-generic',
  inactiveTabColor = 'bg-gray-light',
  activeTextColor = 'text-generic',
  inactiveTextColor = 'text-gray-light',
}: ReusableTabsProps<T>) => {
  return (
    <div
      className={classNames(
        'flex w-full mt-[2.5rem] max-md:mt-[1.5rem]',
        sessionType
          ? 'gap-[0.5rem] max-md:gap-[0.25rem]'
          : 'border-b border-gray-medium'
      )}
    >
      {Object.keys(tabs).map((tabKey) => (
        <button
          key={tabKey}
          onClick={() => onTabClick(tabKey as keyof T)}
          className={classNames(
            'flex-1 text-center font-medium relative',
            sessionType
              ? 'text-[0.875rem]/[0.875rem] px-[0.75rem] py-[0.75rem] bg-gray-dark rounded-tl-[1rem] rounded-br-[1rem] max-md:py-[0.5rem] max-md:px-[1rem] whitespace-nowrap'
              : 'pb-[1.25rem]',
            activeTab === tabKey
              ? classNames(activeTabColor, activeTextColor)
              : classNames(inactiveTabColor, inactiveTextColor)
          )}
        >
          <span
            className={classNames(
              'transition-colors text-[1rem]/[1.5rem] font-medium max-md:text-[0.75rem]/[1.125rem]',
              activeTab === tabKey
                ? classNames(activeTextColor)
                : classNames(inactiveTextColor)
            )}
          >
            {tabs[tabKey as keyof T]}
          </span>
          {activeTab === tabKey && !sessionType && (
            <div
              className={classNames(
                'absolute bottom-0 left-0 right-0 h-[1px] transition-all duration-300',
                className
              )}
            />
          )}
        </button>
      ))}
    </div>
  );
};

export default ReusableTabs;
