import ReusableTabs from '../../components/reusableTabs/reusableTabs';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import AboutEventSection from '../../sections/ticketDetails/aboutEvent';
import LocationSection from '../../sections/ticketDetails/location';
import ReceiptSection from '../../sections/ticketDetails/receipt';
import TicketsSection from '../../sections/ticketDetails/tickets';
import { ReactComponent as ArrowLeftSVG } from '../../svg/caretLeft.svg';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

enum Tabs {
  TICKETS = 'Tickets',
  RECEIPT = 'Receipt',
  LOCATION = 'Location',
}

const TicketDetailsPage = () => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.TICKETS);
  const location = useLocation();
  const { eventData } = location.state || {};

  const tabs = {
    [Tabs.TICKETS]: Tabs.TICKETS,
    [Tabs.RECEIPT]: Tabs.RECEIPT,
    [Tabs.LOCATION]: Tabs.LOCATION,
  };

  const sessionType = session?.type == 'cene' || session?.type == undefined;
  const date = eventData?.starts_at || '';

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="pt-[1.5rem] pb-[5rem] max-w-[1092px] mx-auto relative max-lg:pb-[1.5rem]">
      <div
        className={classNames(
          'items-center mb-[1.5rem] flex',
          'max-lg:absolute max-lg:top-[40px] max-lg:left-[16px] max-lg:z-20'
        )}
      >
        <button
          className={classNames([
            'text-event mr-[1rem] text-[0.875rem]/[0.875rem] px-[1rem] py-[1rem] bg-gray-dark',
            sessionType ? 'rounded-tl-[8px] rounded-br-[8px]' : 'rounded-big',
          ])}
          onClick={handleBackClick}
        >
          {sessionType ? (
            <ArrowLeftSVG
              style={{ width: '12px', height: '12px', stroke: '#EC67F7' }}
            />
          ) : (
            <ArrowLeftSVG
              style={{ width: '12px', height: '12px', stroke: '#B8CAD9' }}
            />
          )}
        </button>
        <p className="text-white text-[0.875rem] leading-[14px] max-lg:hidden">
          {translations.ticketDetails.backAllTickets}
        </p>
      </div>
      <Grid container spacing={{ xs: 2, md: 5 }}>
        <Grid item sm={12} md={5}>
          <AboutEventSection
            sessionType={sessionType}
            startEvent={date}
            eventData={eventData}
          />
        </Grid>
        <Grid item sm={12} md={7}>
          <div className="max-lg:hidden block">
            <h3
              className={classNames(
                'text-[1.0625rem]/[1.375rem] font-light',
                sessionType ? 'text-ticket uppercase' : 'text-gray-light'
              )}
            >
              {eventData?.category}
            </h3>
            <h1
              className={classNames(
                'text-[3.5rem]/[4rem] font-light mt-[0.5rem]',
                sessionType ? 'text-white' : 'text-generic'
              )}
            >
              {eventData?.name}
            </h1>
          </div>
          <ReusableTabs
            tabs={tabs}
            activeTab={activeTab}
            onTabClick={(tab) => setActiveTab(tab)}
            className="bg-generic"
            sessionType={sessionType}
            activeTabColor={sessionType ? 'bg-ticket' : 'transaparent'}
            activeTextColor={sessionType ? 'text-black' : 'text-generic'}
            inactiveTabColor={sessionType ? 'bg-gray-medium' : 'transaparent'}
            inactiveTextColor={sessionType ? 'text-ticket' : 'text-gray-light'}
          />
          <div className="mt-[2.5rem] max-lg:mt-[1.5rem] max-w-full">
            {activeTab === Tabs.TICKETS && (
              <TicketsSection ticket={eventData} sessionType={sessionType} />
            )}
            {activeTab === Tabs.RECEIPT && (
              <ReceiptSection sessionType={sessionType} eventData={eventData} />
            )}
            {activeTab === Tabs.LOCATION && (
              <LocationSection ticket={eventData} />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TicketDetailsPage;
