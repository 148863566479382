import { Button } from '../../components/button/button';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useSupabase } from '../../providers/supabase';
import { supabaseCeneClient } from '../../supabase/supabase';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import classNames from 'classnames';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export const WelcomePage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const isCene = state?.type === 'cene';

  const { session } = useSupabase();

  const handleLogout = async () => {
    try {
      await supabaseCeneClient.auth.signOut();
      navigate(ROUTE_LOGIN, {
        replace: true,
        state: { type: isCene ? 'cene' : 'cenePlus' },
      });
    } catch (err) {
      console.error(err);
    }
  };

  if (!session)
    return (
      <Navigate
        to={ROUTE_LOGIN}
        state={{ type: isCene ? 'cene' : 'cenePlus' }}
        replace={true}
      />
    );

  return (
    <div
      className={classNames([
        'relative w-full h-screen bg-welcome shadow-welcome bg-no-repeat bg-cover',
        'max-md:bg-[60%_top] max-md:h-[calc(100vh-100px)] max-md:shadow-welcomeMobile max-md:bg-[length:280%]',
        'max-lg:bg-center',
      ])}
    >
      <div className="absolute flex flex-col items-center text-center bottom-[58px] left-[50%] translate-x-[-50%]">
        <h2
          className={classNames([
            'text-h1 text-white font-light',
            'max-lg:text-[3rem]',
          ])}
        >
          Welcome to
        </h2>
        <LogoSVG
          className={classNames([
            'w-[164px] h-[48px]',
            'max-lg:w-[137px] max-lg:h-[40px]',
          ])}
        />
        <Button color="text" className="text-generic" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </div>
  );
};
