import { Button } from '../../../../components/button/button';
import { Input } from '../../../../components/input/cene/input';
import { Label } from '../../../../components/label/cene/label';
import translation from '../../../../constants/translations.json';
import { RegisterSchema } from '../../../../schemas/registerSchema';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

interface FormProps {
  onNext: () => void;
}

export const NameForm = ({ onNext = () => null }: FormProps) => {
  const formik = useFormikContext<RegisterSchema>();

  const isFirstNameValid = formik.values.lastName
    ? !formik.errors.firstName
    : false;

  const isLastNameValid = formik.values.lastName
    ? !formik.errors.lastName
    : false;

  const isFormValid = isFirstNameValid && isLastNameValid;

  return (
    <div className="pt-[24px]">
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.nameStep.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.nameStep.description}
      </p>
      <div
        className={classNames([
          'flex flex-col gap-[40px]',
          'max-lg:gap-[24px]',
        ])}
      >
        <div className="flex flex-col">
          <Label id="firstName" isRequired={true}>
            {translation.steps.nameStep.firstname}
          </Label>
          <Input
            id="firstName"
            placeholder={translation.steps.nameStep.enterFirstName}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.firstName}
            touched={Boolean(formik.touched.firstName)}
          />
        </div>
        <div className="flex flex-col">
          <Label id="lastName" isRequired={true}>
            {translation.steps.nameStep.lastname}
          </Label>
          <Input
            id="lastName"
            placeholder={translation.steps.nameStep.enterLastname}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.lastName}
            touched={Boolean(formik.touched.lastName)}
          />
        </div>
      </div>
      <Button
        color="content"
        type="button"
        className={classNames(['w-full mt-[40px]'])}
        onClick={onNext}
        disabled={!isFormValid}
      >
        {translation.next}
      </Button>
    </div>
  );
};
