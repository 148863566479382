import { JustSection } from '../../components/collapsibleSection/justSection';
import translations from '../../constants/translations.json';
import { FeaturesMembership } from '../../sections/membership/featuresMembership';
import { LifeMember } from '../../sections/membership/lifeMember';
import { MembershipDesc } from '../../sections/membership/membershipDesc';
import { MakeTicketSection } from '../../sections/membership/personalized';
import classNames from 'classnames';

interface SectionData {
  title: string;
  description: string;
}
const sections: SectionData[] = translations.membershipJust;

export const MembershipPage = () => {
  return (
    <div
      className={classNames([
        ' w-full px-0',
        'max-lg:flex-col-reverse max-lg:px-0 max-lg:mt-0 max-lg:gap-0',
      ])}
    >
      <MembershipDesc />
      <FeaturesMembership />
      <LifeMember />

      <div className="flex justify-center">
        <MakeTicketSection />
      </div>

      <div
        className={classNames([
          'flex flex-col items-center justify-center ',
          'lg:my-[120px] mx-0 my-[72px]',
        ])}
      >
        <h1 className="lg:text-[4rem] font-light text-generic text-center lg:leading-[72px] whitespace-pre-line text-[2rem] leading-[40px]">
          {translations.membership.titleQuestion}
        </h1>
        <h3
          className={classNames([
            'font-light text-white text-center mb-[40px] mt-[24px] px-[20px] leading-[19px] text-[1.1875rem]',
            'lg:text-[1.3125rem] lg:leading-[21px] lg:mb-[64px]',
          ])}
        >
          {translations.membership.descQuestion}
        </h3>
        {sections.map((section, index) => (
          <JustSection
            key={index}
            title={section.title}
            typeMembership={true}
            description={section.description}
            isLast={index === sections.length - 1}
          />
        ))}
      </div>
    </div>
  );
};
