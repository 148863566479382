import { supabaseCeneClient } from '../supabase/supabase';


export async function signUpWithProfile(
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  gender: string,
  birthdate: string,
  phone_number: string
) {
  // First, sign up the user
  const { data, error: signUpError } = await supabaseCeneClient.auth.signUp({
    email,
    password,
  });

  if (signUpError) throw signUpError;

  //if data is returned, update db
  const { error: updateUserError } = await supabaseCeneClient
    .from('customers')
    .update([
      {
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        birthdate: birthdate,
        phone_number: phone_number,
        onboarding_finished: true,
      },
    ])
    .eq('user_id', data?.user?.id);

  if (updateUserError) throw updateUserError;

  return data;
}