import { ROUTE_AUTH } from '../../../../constants/routes';
import { RegisterFormPartTwo } from '../../../../forms/signup/registerForm/registerFormPartTwo/registerFormPartTwo';
import { ReactComponent as CloseSVG } from '../../../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../../../svg/logotype.svg';
import { LoadingPage } from '../../../loading/cenePlus/loadingPage';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const SignupPartTwo = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const gotoBack = useCallback(() => navigate(ROUTE_AUTH), []);

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between mb-[40px]',
          'sm:px-0 px-5',
        ])}
      >
        <LogoSVG className="w-[164px] h-[48px]" />
        <div
          onClick={gotoBack}
          className={classNames([
            'cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]',
            'max-w-sm',
          ])}
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="container flex mx-auto items-center max-w-[422px]">
        <RegisterFormPartTwo onLoading={() => setLoading(true)} />
      </div>
    </>
  );
};
