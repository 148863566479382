import { ROUTE_ORGANIZER } from '../../../../constants/routes';
import translations from '../../../../constants/translations.json';
import { useSupabase } from '../../../../providers/supabase';
import { ReactComponent as RightArrowSVG } from '../../../../svg/arrowRightCenePlus.svg';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface OrganizerSectionProps {
  event: Event | null;
}

const OrganizerSection = ({ event }: OrganizerSectionProps) => {
  const navigate = useNavigate();

  const { session } = useSupabase();

  const isGuest = session?.type === undefined;

  const handleNavigate = () => {
    navigate(ROUTE_ORGANIZER, { state: { partnerInfo: event?.partner_info || {} } });
  };
  

  return (
    <div
      className="w-full cursor-pointer mt-[1.5rem]"
      onClick={handleNavigate}
    >
      <div className="flex justify-between items-center">
        <p
          className={classNames(
            'lg:text-[1.0625rem]/[1.375rem] text-[0.75rem]/[0.75rem] uppercase',
            'text-white'
          )}
        >
          {translations.eventDetailsSection.organizer}
        </p>
        <button
          className={classNames(
            'text-event w-[32px] h-[32px] bg-gray-dark rounded-tl-[8px] rounded-br-[8px] flex justify-center items-center',
            isGuest && 'hidden'
          )}
        >
          <RightArrowSVG
            className={classNames('w-[12px] h-[12px]', 'stroke-white')}
          />
        </button>
      </div>
      <div className="mt-[16px] flex flex-row items-center justify-between w-full">
        <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
            {event?.partner_info?.profile_picture_url ? (
              <img
                src={event.partner_info?.profile_picture_url}
                alt="Organizer Logo"
                className="w-[56px] h-[56px] rounded-full object-cover"
              />
            ) : (
              <div className="w-[56px] h-[56px] rounded-big bg-gray-medium flex items-center justify-center" />
            )}
            <p className="ml-[16px] text-white text-[1rem] leading-[22px] font-medium">
              {event?.partner_info?.name ||
                translations.eventDetailsSection.nameOrganizer}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizerSection;
