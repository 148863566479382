import classNames from 'classnames';

interface ILabelProps {
  isRequired?: boolean;
  children: string;
  id: string;
  classLabel?: string;
}

export const Label = ({
  children,
  classLabel = '',
  isRequired,
  id,
}: ILabelProps) => {
  return (
    <label
      className="block text-generic text-[0.875rem] font-light mb-[8px]"
      htmlFor={id}
    >
      {children}
      {isRequired ? (
        <span className={classNames([classLabel, 'text-generic'])}>*</span>
      ) : (
        ''
      )}
    </label>
  );
};
