import { Button } from '../../../../components/button/button';
import { PreferenceCard } from '../../../../components/preferenceCard/cenePlus/preferenceCard';
import translation from '../../../../constants/translations.json';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

interface FormProps {
  onNext: () => void;
}

interface FormValues {
  musicGenres: string[];
}

export const GenrePreferencesForm = ({ onNext }: FormProps) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [selectedGenres, setSelectedGenres] = useState<string[]>(
    values.musicGenres
  );

  const formikGenres = values.musicGenres || [];

  const selected = formikGenres.length;

  useEffect(() => {
    setFieldValue('musicGenres', selectedGenres);
  }, [selectedGenres]);

  const handleGenreSelect = (genre: string) => {
    if (selectedGenres.includes(genre)) {
      setSelectedGenres(selectedGenres.filter((g) => g !== genre));
    } else {
      if (selectedGenres.length < 5) {
        setSelectedGenres([...selectedGenres, genre]);
      }
    }
  };

  return (
    <div
      className={classNames([
        'mt-[24px] pb-[20px]',
        'sm:px-0 px-5 max-lg:w-full',
      ])}
    >
      <h1
        className={classNames([
          'text-[2rem] text-generic font-light mb-[12px]',
        ])}
      >
        {translation.steps.genres.titleCenePlus}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.genres.descriptionCenePlus}
      </p>

      <Grid
        container
        sx={{
          width: { xs: '100%', md: '456px' },
          gap: { xs: '12px', md: '16px' },
        }}
      >
        {genres.map(({ title, icon }) => (
          <Grid item xs={3.65157} sm={3.65157} md={2.684} key={title}>
            <PreferenceCard
              selected={values.musicGenres.includes(title)}
              onClick={() => handleGenreSelect(title)}
              href={icon}
            >
              {title}
            </PreferenceCard>
          </Grid>
        ))}
      </Grid>

      <div
        className={classNames([
          'mt-[40px] max-w-[456px] flex items-center gap-[40px]',
          'max-lg:gap-[24px]',
        ])}
      >
        <div className={classNames(['w-full', 'max-lg:w-2/3'])}>
          <p className="text-[1rem] text-white font-medium">
            {selected}/5 {translation.selected}
          </p>
          <p className="text-[0.875rem] text-generic font-light">
            {translation.selectionInfo}
          </p>
        </div>
        <Button
          className={classNames(['w-full', 'max-lg:w-1/3'])}
          color="generic"
          onClick={onNext}
        >
          {translation.continue}
        </Button>
      </div>
    </div>
  );
};

const genres = [
  {
    icon: '/pictures/music-ceneplus/afrobeats-ceneplus.jpg',
    title: 'Afrobeats',
  },
  {
    icon: '/pictures/music-ceneplus/alternative-ceneplus.jpg',
    title: 'Altérnative',
  },
  { icon: '/pictures/music-ceneplus/amapiano-ceneplus.jpg', title: 'Amapiano' },
  {
    icon: '/pictures/music-ceneplus/classical-ceneplus.jpg',
    title: 'Classical',
  },
  { icon: '/pictures/music-ceneplus/country-ceneplus.jpg', title: 'Country' },
  {
    icon: '/pictures/music-ceneplus/dancehall-ceneplus.jpg',
    title: 'Dancehall',
  },
  {
    icon: '/pictures/music-ceneplus/electronic-ceneplus.jpg',
    title: 'Electronic',
  },
  { icon: '/pictures/music-ceneplus/hip-hop-ceneplus.jpg', title: 'Hip-Hop' },
  { icon: '/pictures/music-ceneplus/house-ceneplus.jpg', title: 'House' },
  { icon: '/pictures/music-ceneplus/jazz-ceneplus.jpg', title: 'Jazz' },
  { icon: '/pictures/music-ceneplus/neo-soul-ceneplus.jpg', title: 'Neo-Soul' },
  { icon: '/pictures/music-ceneplus/pop-ceneplus.jpg', title: 'Pop' },
  { icon: '/pictures/music-ceneplus/rnb-ceneplus.jpg', title: 'R&B' },
  { icon: '/pictures/music-ceneplus/rap-ceneplus.jpg', title: 'Rap' },
  { icon: '/pictures/music-ceneplus/reggae-ceneplus.jpg', title: 'Reggae' },
  { icon: '/pictures/music-ceneplus/rock-ceneplus.jpg', title: 'Rock' },
];
