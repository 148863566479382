import { ReactComponent as CheckSvg } from '../../../src/svg/check.svg';
import { ReactComponent as FacebookLogoSvg } from '../../../src/svg/facebookLogo.svg';
import { ReactComponent as LinkSvg } from '../../../src/svg/linkNew.svg';
import { ReactComponent as SocialmediaSvg } from '../../../src/svg/socialmedia.svg';
import { ReactComponent as WhatsappLogoSvg } from '../../../src/svg/whatsappLogo.svg';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import classNames from 'classnames';
import React, { useState } from 'react';

interface EventDetails {
  id: string;
  title: string;
  description: string;
  link: string;
}

interface ShareOptionsProps {
  onCopyLink: (event: React.MouseEvent) => void;
  shareOrganizer?: boolean;
  onClose: (event?: React.MouseEvent) => void;
  event?: EventDetails;
}

export const ShareOptions: React.FC<ShareOptionsProps> = ({
  onCopyLink,
  shareOrganizer,
  event,
  onClose,
}) => {
  const { session } = useSupabase();
  const [linkCopied, setLinkCopied] = useState(false);

  const handleLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  const handleCopyLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCopyLink(event);
    setLinkCopied(true);
  };

  const generateFacebookShareUrl = (event: EventDetails) => {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      event.link
    )}&quote=${encodeURIComponent(event.title)}`;
  };

  const generateWhatsAppShareUrl = (event: EventDetails) => {
    return `https://wa.me/?text=${encodeURIComponent(
      `${event.title} - ${event.link}`
    )}`;
  };

  const generateXShareUrl = (event: EventDetails) => {
    return `https://x.com/intent/tweet?text=${encodeURIComponent(
      `${event.title} - ${event.link}`
    )}`;
  };

  const isCenePlus = session?.type === 'cene+';

  return (
    <div
      className={classNames([
        'absolute bg-black z-20 flex flex-col items-start rounded-[8px] border border-gray-medium',
        'lg:h-[192px] lg:w-[180px]',
        !shareOrganizer
          ? isCenePlus
            ? 'lg:top-[79px] lg:right-[78px] top-[79px] right-[90px]'
            : 'top-[151px] right-[24px]'
          : 'top-[0px] right-0',
      ])}
      onClick={(event) => event.stopPropagation()}
    >
      <button
        className="text-white hover:bg-gray-dark py-[12px] px-[24px] flex justify-between w-full text-[1rem] leading-[24px] font-normal rounded-tl-[8px] rounded-tr-[8px]"
        onClick={(event) => handleCopyLinkClick(event)}
      >
        {translations.shareOptions.copyLink}
        <div className="flex items-center">
          <LinkSvg />
          {linkCopied && <CheckSvg className="ml-[10px]" />}
        </div>
      </button>
      <a
        href={
          event ? generateFacebookShareUrl(event) : 'https://www.facebook.com'
        }
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:bg-gray-dark py-[12px] px-[24px] flex justify-between w-full text-[1rem] leading-[24px] font-normal"
      >
        {translations.shareOptions.facebook}
        <FacebookLogoSvg />
      </a>
      <a
        href={
          event ? generateWhatsAppShareUrl(event) : 'https://www.whatsapp.com'
        }
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:bg-gray-dark py-[12px] px-[24px] flex justify-between w-full text-[1rem] leading-[24px] font-normal"
        onClick={handleLinkClick}
      >
        {translations.shareOptions.whatsapp}
        <WhatsappLogoSvg />
      </a>
      <a
        href={event ? generateXShareUrl(event) : 'https://x.com/getcene?s=21'}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:bg-gray-dark py-[12px] px-[24px] flex justify-between w-full text-[1rem] leading-[24px] font-normal rounded-bl-[8px] rounded-br-[8px]"
        onClick={handleLinkClick}
      >
        {translations.shareOptions.X}
        <SocialmediaSvg />
      </a>
    </div>
  );
};
