import { countryCodes } from '../forms/signup/phoneStep/countryCodes';
import { SubscribePlan } from '../types/subscribePlan';
import { CountryCode, isValidNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export const partnerWithUsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('E-mail is required')
    .matches(
      /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid e-mail format. Please enter a valid e-mail address.'
    ),
  countryCode: Yup.string().required(),
  phone: Yup.string()
    .test('is-valid-phone', 'Phone number is invalid', (_, context) => {
      const isValidPhone = isValidNumber(
        context.parent.phone,
        countryCodes.find((item) => context.parent.countryCode === item.value)
          ?.code as CountryCode
      );

      return isValidPhone;
    })
    .required('Phone number is required'),
  companyName: Yup.string().required('Event company name is required'),
  dateFounded: Yup.string().required('Date founded is required'),
  subscriptionPlan: Yup.string()
    .oneOf(
      [SubscribePlan.STARTER, SubscribePlan.PRO, SubscribePlan.PREMIUM],
      'Invalid subscription plan'
    )
    .required('Subscription plan is required'),
  eventWebsite: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid event website url'
    )
    .required('Event website is required'),
  message: Yup.string(),
  newsletterAgreement: Yup.boolean(),
});

export type PartnerWithUsSchema = Yup.InferType<typeof partnerWithUsSchema>;
