import { Button } from '../../components/button/button';
import { ROUTE_EVENTS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Phone = '/pictures/bannerHome.png';
const PhoneSmall = '/pictures/homeSmallBanner.png';

export const PersonalizedSection = () => {
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene' || session === null;

  const navigate = useNavigate();
  return (
    <div
      className={classNames([
        'relative lg:bg-home-cta bg-loading-bg-new lg:h-[520px] rounded-tl-[30px] rounded-br-[30px] w-full flex flex-col h-[720px]',
        'lg:w-[1085px] lg:justify-between lg:flex-row lg:mt-[56px]',
      ])}
    >
      <div
        className={classNames([
          'flex flex-col justify-start mt-[32px] h-full px-[24px] relative',
          'lg:ml-[70px] lg:items-start lg:justify-center lg:mt-0 lg:px-0 lg:text-left lg:w-full lg:h-auto',
        ])}
      >
        <div
          className={classNames([
            'text-left w-full h-auto flex flex-col justify-start',
            'lg:w-[450px] lg:mr-[90px]',
          ])}
        >
          <div className="mb-[24px] lg:absolute lg:top-[48px]">
            {sessionType ? <LogoSVG /> : <LogoPlusSVG />}
          </div>

          <h2
            className={classNames([
              'text-white text-[1.5rem] leading-[36px] font-light',
              'lg:leading-[56px] lg:text-[3rem]',
            ])}
          >
            {translations.homePage.live}
          </h2>
          <p className="text-white mt-[20px] font-light lg:leading-[21px] lg:text-[1.3125rem] leading-[19px] text-[1.1875rem]">
            {translations.homePage.desc}
          </p>
          <Button
            onClick={() => navigate(ROUTE_EVENTS)}
            className={classNames([
              'w-[214px] h-[48px] mt-[20px]',
              'lg:w-[214px] lg:h-[48px] lg:mt-[32px]',
            ])}
            color="event"
            type="button"
          >
            {translations.homePage.textButtonTwo}
          </Button>
        </div>

        <img
          src={PhoneSmall}
          alt="PhoneSmall"
          className={classNames([
            'w-[330px] h-[364px] absolute bottom-0 lg:h-[491px] mt-[42px] max-md:left-1/2 max-md:-translate-x-1/2 max-md:rounded-br-[25px]',
            'lg:top-[29px] lg:mt-0 lg:right-[0px] lg:h-[480px]',
            'lg:hidden',
          ])}
        />
        <img
          src={Phone}
          alt="Phone"
          className={classNames([
            'hidden lg:block w-auto h-[30px] absolute bottom-0 lg:h-[491px] mt-[42px]',
            'lg:top-[29px] lg:mt-0 mx-auto lg:right-[50px] lg:h-[480px]',
          ])}
        />
      </div>
    </div>
  );
};
