import translations from '../../constants/translations.json';
import classNames from 'classnames';
import React from 'react';

const Commitment = '/pictures/commitment.png';

export const CommitmentSection = () => {
  return (
    <div
      className={classNames([' block lg:my-[120px] lg:mx-[64px] mt-[59px] mb-[64px] lg:mb-0 lg:mt-0 my-0'])}
    >
      <div
        className={classNames([
          'flex flex-col container mx-auto',
          'lg:flex-row lg:gap-[135px] gap-0',
        ])}
      >
        <div
          className={classNames([
            'w-full flex justify-center',
            'lg:mb-0 lg:justify-end lg:w-1/2',
          ])}
        >
          <img
            src={Commitment}
            alt="Img"
            className={classNames([
              'lg:w-[644px] lg:h-[644px] rounded-tl-[30px] object-cover w-full lg:flex hidden',
              'lg:max-w-none',
            ])}
          />
        </div>
        <div className="w-full lg:w-[55%]  justify-center items-center flex">
          <div className="lg:mt-0 mt-[64px]">
            <h2
              className={classNames([
                'text-[1.75rem] leading-[36px] text-content',
                'lg:leading-[64px] lg:text-[3.5rem] font-light lg:w-[533px] ',
              ])}
            >
              {translations.commitment.title}
            </h2>
            <p className="lg:text-[1.3125rem] text-white lg:mt-[24px] mt-[16px] lg:leading-[22px] font-light leading-[19px] text-[1.1875rem]">
              {translations.commitment.desc}
            </p>
          </div>
        </div>
        <img
          src={Commitment}
          alt="Img"
          className={classNames([
            'rounded-tl-[30px] object-cover w-full lg:hidden mt-[40px]',
          ])}
        />
      </div>
    </div>
  );
};
