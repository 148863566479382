// import { Button } from '../../../components/button/button';
import { ROUTE_AUTH } from '../../../constants/routes';
import translations from '../../../constants/translations.json';
import { LoginForm } from '../../../forms/login/cene/login';
import { LoginForm as LoginFormCenePlus } from '../../../forms/login/cenePlus/login';
import { useCeneMode } from '../../../providers/ceneMode';
// import {
//   supabaseCeneClient,
//   supabaseCenePlusClient,
// } from '../../../supabase/supabase';
// import { ReactComponent as AppleSVG } from '../../../svg/apple.svg';
// import { ReactComponent as GoogleSVG } from '../../../svg/google.svg';
import { CeneSwitch } from './ceneSwitch';
import classNames from 'classnames';
// import { useCallback } from 'react';
import { Link } from 'react-router-dom';

export const LoginPage = () => {
  const { setMode, ceneMode } = useCeneMode();

  // useEffect(() => {
  //   return () => setMode('cene');
  // }, []);

  // const redirectTo = `${window.location.origin}/`;

  // const signinWithGoogle = useCallback(async () => {
  //   ceneMode === 'cene'
  //     ? await supabaseCeneClient.auth.signInWithOAuth({
  //         provider: 'google',
  //         options: {
  //           redirectTo,
  //         },
  //       })
  //     : await supabaseCenePlusClient.auth.signInWithOAuth({
  //         provider: 'google',
  //         options: {
  //           redirectTo,
  //         },
  //       });
  // }, [ceneMode]);

  // const signinWithApple = useCallback(async () => {
  //   ceneMode === 'cene'
  //     ? await supabaseCeneClient.auth.signInWithOAuth({
  //         provider: 'apple',
  //         options: {
  //           redirectTo,
  //         },
  //       })
  //     : await supabaseCenePlusClient.auth.signInWithOAuth({
  //         provider: 'apple',
  //         options: {
  //           redirectTo,
  //         },
  //       });
  // }, [ceneMode]);

  return (
    <div
      className={classNames([
        'mb-[40px] gap-[24px] flex justify-between mt-[60px]',
        'max-xl:flex-col-reverse max-xl:items-center max-xl:px-0 max-xl:mt-0 max-xl:gap-0',
      ])}
    >
      <div
        className={classNames([
          'w-[40%]',
          'max-xl:w-full',
          'xl:w-[calc((100%-24px)-644px)] flex justify-center',
        ])}
      >
        <div className="flex flex-col xl:w-[415px] max-lg:w-[100%]">
          <h1
            className={classNames([
              'text-[2.5rem]/[3rem] text-nowrap text-white font-light mb-[16px]',
              'max-md:mb-[12px] max-lg:text-[2rem]/[2.5rem]',
            ])}
          >
            {ceneMode === 'cene'
              ? translations.loginPage.welcomeCene
              : translations.loginPage.welcomeCenePlus}
          </h1>
          <p
            className={classNames([
              'text-[1.0625rem]/[1.375rem] text-generic font-light mb-[32px] md:text-nowrap',
              'max-lg:text-h4/[1.3125rem]',
            ])}
          >
            {ceneMode === 'cene'
              ? translations.loginPage.descCene
              : translations.loginPage.descCenePlus}
          </p>
          <CeneSwitch ceneMode={ceneMode} onChange={setMode} />
          {ceneMode === 'cene' ? <LoginForm /> : <LoginFormCenePlus />}
          {/* <div
            className={classNames([
              'flex justify-center w-[217px] bg-gray-medium h-[2px] mt-[20px] self-center',
            ])}
          >
            <span className="mt-[-13px] absolute bg-black px-[10px] text-generic text-h4 font-medium">
              {translations.loginPage.or}
            </span>
          </div> */}
          {/* <div className={classNames(['flex mt-[20px] gap-[8px]'])}>
            <Button
              className={classNames([
                'w-full flex gap-[8px] border border-gray-medium',
                ceneMode === 'cene' ? 'text-white' : 'text-generic',
              ])}
              color=""
              onClick={signinWithGoogle}
            >
              <GoogleSVG fill={ceneMode === 'cene' ? '#fff' : '#b8cad9'} />
              {translations.loginPage.google}
            </Button>
            <Button
              className={classNames([
                'w-full flex gap-[8px] border border-gray-medium ',
                ceneMode === 'cene' ? 'text-white' : 'text-generic',
              ])}
              color=""
              onClick={signinWithApple}
            >
              <AppleSVG fill={ceneMode === 'cene' ? '#fff' : '#b8cad9'} />
              {translations.loginPage.apple}
            </Button>
          </div> */}
          <div
            className={classNames([
              'text-center text-h4/[1.5rem] font-[500] mt-[32px]',
              ceneMode === 'cene'
                ? 'text-[rgba(255,255,255,.70)]'
                : 'text-generic',
            ])}
          >
            {ceneMode === 'cene' ? (
              <>
                {translations.loginPage.new}{' '}
                <Link className="text-content hover:underline" to={ROUTE_AUTH}>
                  {translations.loginPage.signUp}
                </Link>{' '}
                <br />
                {translations.loginPage.discover}
              </>
            ) : (
              <>
                {translations.loginPage.notMember}{' '}
                <Link className="text-white hover:underline" to={ROUTE_AUTH}>
                  {translations.loginPage.access}
                </Link>{' '}
                <br />
                {translations.loginPage.join}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames([
          'relative w-[60%] flex justify-center items-center max-w-[644px] max-md:z-10 max-lg:z-10',
          'max-lg:w-[100vw] max-lg:h-[45%] max-lg:max-h-[540px] max-lg:-mx-[20px] max-lg:-mt-[80px]',
        ])}
      >
        <div className="media-container h-[540px] max-md:h-[280px] bg-black w-full">
          {ceneMode === 'cene' ? (
            <picture>
              <source
                srcSet="https://cene-bucket.s3.eu-north-1.amazonaws.com/app/ceneLoginMobile.gif"
                media="(max-width: 768px)"
              />
              <img
                className={classNames([
                  'rounded-tl-[30px] h-full w-full object-cover',
                  'max-lg:rounded-tl-none',
                ])}
                src="https://cene-bucket.s3.eu-north-1.amazonaws.com/app/welcomeGif.gif"
                alt="Welcome GIF"
              />
            </picture>
          ) : (
            <picture>
              <source
                srcSet="https://cene-bucket.s3.eu-north-1.amazonaws.com/app/cenePlusLoginMobile.gif"
                media="(max-width: 768px)"
              />
              <img
                className={classNames([
                  'rounded-tl-[30px] h-full w-full object-cover',
                  'max-lg:rounded-tl-none',
                ])}
                src="https://cene-bucket.s3.eu-north-1.amazonaws.com/app/cenePlus.gif"
                alt="Cene+ GIF"
              />
            </picture>
          )}
        </div>
        <div className="bg-login-gradient max-lg:bg-login-tab-gradient absolute w-full h-full top-0" />
      </div>
    </div>
  );
};
