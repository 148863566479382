import CarouselSection from '../../../../components/carouselSection/carouselSection';
import { Loader } from '../../../../components/loader/cene/loader';
import translations from '../../../../constants/translations.json';
import { supabaseCeneClient } from '../../../../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SimilarSection = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const handleCardClick = (id: string) => {
    navigate(`/event-details/${id}`);
  };

  useEffect(() => {
    fetchEventsCene();
  }, []);

  const fetchEventsCene = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabaseCeneClient
        .from('events')
        .select('*');
      if (error) throw error;
      setEvents(data || []);
    } catch (error) {
      console.error('Error fetching Cene events:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={classNames('pb-[2.5rem] pt-[4.5rem]', 'max-lg:mt-[1.5rem]')}
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[558px]">
          <Loader />
        </div>
      ) : (
        <CarouselSection
          data={events}
          title={translations.eventDetailsSection.similarEvents}
          sessionType={true}
          color={'event'}
          handleCardClick={handleCardClick}
          colorCard={'event'}
        />
      )}
    </div>
  );
};

export default SimilarSection;
