import { Button } from '../../components/button/button';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

interface EventDetails {
  title: string;
  startDate: string | null;
  endDate: string | null;
  details: string;
  location: string;
}

interface AddToCalendarProps {
  event: EventDetails;
  sessionType?: boolean;
}

const googleCalendarUrl = ({ event }: AddToCalendarProps) => {
  const { title, startDate, endDate, details, location } = event;

  const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=${details}&location=${location}&sf=true&output=xml`;

  window.open(googleCalendarUrl, '_blank');
};

export const AddToCalendar = ({ event, sessionType }: AddToCalendarProps) => {
  return (
    <Button
      onClick={() => googleCalendarUrl({ event })}
      color="outlined"
      type="button"
      className={classNames(
        'w-full',
        sessionType
          ? '!border-ticket !text-ticket'
          : '!border-generic !text-generic'
      )}
    >
      {translations.ticketDetails.addToCalendar}
    </Button>
  );
};
