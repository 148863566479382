import React from 'react';

interface InfoSectionProps {
  title: string;
  subtitle: string | undefined;
}

export const InfoSection: React.FC<InfoSectionProps> = ({
  title,
  subtitle,
}) => (
  <div className="border-b border-gray-medium mb-[16px]">
    <p className="text-white font-normal text-[0.75rem] leading-[12px] mb-[8px]">
      {title}
    </p>
    <p className="text-white font-medium text-[1rem] leading-[24px] mb-[8px] whitespace-pre-line">
      {subtitle}
    </p>
  </div>
);
