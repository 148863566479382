import { Checkbox } from '../../components/checkbox/checkbox';
import { InputSelect } from '../../components/select/select';
import { Category } from '../../types/seatsio';
import { useState } from 'react';

interface ISeatsioFilters {
  categories: Category[];
  onChangeZone: (values: string[]) => void;
}

export const SeatsioFilters = ({
  categories,
  onChangeZone,
}: ISeatsioFilters) => {
  const [categoriesValue, setCategoriesValue] = useState<string[]>([]);

  const handleFilterCategories = (value: string) => {
    const filteredCategories = categoriesValue.includes(value)
      ? categoriesValue.filter((item) => item !== value)
      : [...categoriesValue, value];

    setCategoriesValue(filteredCategories);

    onChangeZone(filteredCategories);
  };

  return (
    <div className="mt-[24px] mb-[24px] justify-between flex items-center relative bg-black border-solid border border-[rgba(184,202,217,0.16)] p-[24px] rounded-br-[30px] rounded-tl-[30px]">
      <div>
        <InputSelect
          isCloseAfterSelection={false}
          key={categoriesValue.join()}
          className="w-[123px]"
          options={categories.map((item) => ({
            value: item.label as string,
            label: (
              <span className="flex items-center gap-3">
                <Checkbox
                  key={item.key}
                  checked={categoriesValue.includes(item.label as string)}
                  onChange={() => {}}
                />
                {item.label as string}
                <div
                  className="flex"
                  style={{
                    borderRadius: '99px',
                    background: item.color,
                    width: '12px',
                    height: '12px',
                  }}
                />
              </span>
            ),
          }))}
          placeholder="Zones"
          value={''}
          onChange={(value) => handleFilterCategories(value as string)}
        />
      </div>
    </div>
  );
};
