import { useSupabase } from '../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import { useEffect, useState } from 'react';

export const useGetAttendedEvents = () => {
  const { session } = useSupabase();
  const [eventsAttended, setEventsAttended] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const sessionType = session?.type === 'cene'  || session == undefined;

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch the user's Attended events
        const client = sessionType
          ? supabaseCeneClient
          : supabaseCenePlusClient;
        const { data: userLikedEvents, error: likedEventsError } = await client
          .from('events')
          .select('*');

        if (likedEventsError) {
          throw likedEventsError;
        }

        const likedEventIds = userLikedEvents
          ? userLikedEvents.map((event) => event.id)
          : [];

        // Fetch all events
        const { data, error: fetchError } = await client
          .from('events')
          .select('*');

        if (fetchError) {
          throw fetchError;
        }

        // Filter events based on user liked events
        const filteredEvents = data.filter((event: Event) =>
          likedEventIds.includes(event.id)
        );
        setEventsAttended(filteredEvents);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('An unknown error occurred')
        );
        setEventsAttended([]); // Set events to empty array if there's an error
      } finally {
        setLoading(false);
        if (initialLoad) setInitialLoad(false);
      }
    };

    fetchEvents();
  }, []);

  return { eventsAttended, loading: initialLoad || loading, error };
};
