import classNames from 'classnames';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button/button';
import { Input } from '../../components/input/cenePlus/input';
import { Label } from '../../components/label/cenePlus/label';
import { ROUTER_REGISTER_CENE_PLUS_TERM } from '../../constants/routes';
import {
  ForgotPasswordSchema,
  forgotPasswordSchema,
} from '../../schemas/forgotPasswordSchema';

export const PeriodExpiredForm = () => {
  const navigate = useNavigate();
  const exampleEmail = 'test@gmail.com';

  const formik = useFormik<ForgotPasswordSchema>({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setFieldError }) => {
      if (values.email !== exampleEmail)
        return setFieldError('email', 'Your email is not verified');
    },
  });

  return (
    <div className={classNames(["w-[422px] flex flex-col", "mx-auto w-full px-5 sm:px-0 "])}>
      <h1 className="text-[1rem] text-white font-light mb-[12px]">
        Validity period expired
      </h1>
      <p className="text-[0.875rem] text-generic font-light ">
        Add your email below for us to re-send a new valid link to you.{' '}
      </p>
      <div>
        <div className="mt-[40px]">
          <Label id="email">E-mail</Label>
          <Input
            id="email"
            type="email"
            placeholder="Enter e-mail..."
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.email}
            touched={Boolean(formik.touched.email)}
          />
        </div>
        <Button
          color="generic"
          type="submit"
          className="mt-[24px] w-full"
          onClick={() => navigate(ROUTER_REGISTER_CENE_PLUS_TERM)}
          disabled={!(formik.isValid && formik.dirty)}
        >
          Send
        </Button>
      </div>
    </div>
  );
};
