import classNames from 'classnames';
import { ReactNode } from 'react';

interface IIconProps {
  onClick?: () => void;
  color?: string;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const Icon = ({
  onClick = () => null,
  disabled = false,
  children,
  color = 'gray-dark',
  className = '',
}: IIconProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames([
        `bg-${color}`,
        'w-[40px] h-[40px] flex items-center justify-center rounded-none rounded-tl-[20px] rounded-br-[20px] cursor-pointer',
        disabled && 'bg-gray-medium cursor-not-allowed',
        className,
      ])}
    >
      {children}
    </button>
  );
};
