import { Button } from '../../../components/button/button';
import { ROUTE_DOWNLOAD } from '../../../constants/routes';
import translations from '../../../constants/translations.json';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Phone = '/pictures/groupPhone.png';

export const DiscoverSection = () => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames([
        'relative bg-generic rounded-tl-[30px] rounded-br-[30px] lg:my-[72px] my-0 h-auto',
        'lg:bg-generic lg:h-auto lg:w-full w-full',
      ])}
    >
      <div
        className={classNames([
          'flex flex-col justify-between w-full mt-[20px] ',
          'lg:flex-row',
        ])}
      >
        <div
          className={classNames([
            'flex flex-col items-start',
            'lg:w-full lg:flex-row lg:items-center',
          ])}
        >
          <div
            className={classNames([
              'ml-0 mb-[42px] w-full px-[24px] lg:px-0 mt-[20px]',
              'lg:w-[532px] lg:h-[460px] lg:ml-[101px] lg:mb-0 lg:mt-[95px]',
            ])}
          >
            <div
              className={classNames([
                'lg:w-[532px] lg:h-[112px]',
                'w-full h-auto',
              ])}
            >
              <h2
                className={classNames([
                  'text-white text-[2rem] leading-[40px] font-normal',
                  'lg:text-[3rem] lg:leading-[56px]',
                ])}
              >
                {translations.eventDiscover.title}
              </h2>
            </div>
            <div
              className={classNames([
                'w-full mb-[24px]',
                ' lg:w-[414px] lg:h-[63px]',
              ])}
            >
              <p
                className={classNames([
                  'text-white text-[1.3125rem] leading-[21px] mt-[16px] mb-[16px] text-start relative font-normal',
                  'lg:mt-[24px] lg:mb-[24px]',
                ])}
              >
                {translations.eventDiscover.desc}
              </p>
            </div>
            <Button
              className={classNames([
                'z-10 relative ',
                ' w-[156px] h-[48px] lg:-mb-[42px]',
              ])}
              color="white"
              type="button"
              isFooter={true}
              onClick={() => navigate(ROUTE_DOWNLOAD)}
            >
              {translations.eventDiscover.textButton}
            </Button>
          </div>

          <img
            src={Phone}
            alt="Phone"
            className={classNames([
              'items-start flex justify-start mt-[16px] lg:mt-0 object-cover',
              '2xl:w-auto lg:w-auto lg:right-[100px] lg:h-[400px] 2xl:h-[460px] lg:absolute 2xl:right-[181px] lg:bottom-0 w-full px-[20px] lg:px-0',
            ])}
          />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-[170px] bg-gradient-desc lg:h-[278px]" />
    </div>
  );
};
