import { WaitlistNotification } from '../../../../components/notification/waitlistNotification';
import { ROUTE_ORGANIZER } from '../../../../constants/routes';
import translations from '../../../../constants/translations.json';
import { useWaitlistNotification } from '../../../../hooks/useWaitlistNotification';
import { useSupabase } from '../../../../providers/supabase';
import { ReactComponent as RightArrowSVG } from '../../../../svg/caretRight.svg';
// import { ReactComponent as CheckSVG } from '../../../../svg/checkOne.svg';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface OrganizerSectionProps {
  event: Event | null;
}

const OrganizerSection = ({ event }: OrganizerSectionProps) => {
  const navigate = useNavigate();
  const {
    // handleFollowClick,
    hideWaitlistNotification,
    showWaitlistNotification,
  } = useWaitlistNotification();

  // setNotificationMessage
  const [notificationMessage] = useState('');

  // const [isFollowing, setIsFollowing] = useState(false);
  const { session } = useSupabase();

  const isGuest = session?.type === undefined;

  // const handleFollow = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.stopPropagation();
  //   setIsFollowing((prevState) => !prevState);
  //   handleFollowClick();

  //   setNotificationMessage(
  //     `Welcome to the club! Following ${translations.organizer.title} means you’ll be the first to get updates on their events, surprises, and epic experiences.`
  //   );
  // };
  const handleNavigate = () => {
    navigate(ROUTE_ORGANIZER, {
      state: { partnerInfo: event?.partner_info || {} },
    });
  };

  return (
    <div
      className="w-full mt-0 cursor-pointer"
      onClick={handleNavigate}
    >
      <div className="flex justify-between items-center">
        <p
          className={classNames(
            'text-[1.0625rem] leading-[22px] uppercase',
            'text-white'
          )}
        >
          {translations.eventDetailsSection.organizer}
        </p>
        <button
          className={classNames(
            'text-event w-[32px] h-[32px] bg-gray-dark rounded-tl-[8px] rounded-br-[8px] flex justify-center items-center',
            isGuest && 'hidden'
          )}
        >
          <RightArrowSVG
            className={classNames('w-[12px] h-[12px]', 'stroke-white')}
          />
        </button>
      </div>
      <div className="mt-[16px] flex flex-row items-center justify-between w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            {event?.partner_info?.profile_picture_url ? (
              <img
                src={event.partner_info?.profile_picture_url}
                alt="Organizer Logo"
                className="w-[56px] h-[56px] rounded-full object-cover"
              />
            ) : (
              <div className="w-[56px] h-[56px] rounded-big bg-gray-medium flex items-center justify-center" />
            )}
            <p className="ml-[16px] text-white text-[1rem] leading-[22px] font-medium">
              {event?.partner_info?.name ||
                translations.eventDetailsSection.nameOrganizer}
            </p>
          </div>
          {/* <button
            className={classNames(
              'flex justify-center items-center rounded-tl-[20px] rounded-br-[20px] py-[20px] px-[12px]',
              isGuest && 'hidden',
              isFollowing
                ? 'bg-village text-black border-none h-[48px] w-auto'
                : 'border border-gray-medium text-generic w-[85px] h-[48px]'
            )}
            type="button"
            onClick={handleFollow}
          >
            {isFollowing
              ? translations.eventDetailsSection.followed
              : translations.eventDetailsSection.follow}
            {isFollowing && <CheckSVG className="ml-[8px]" />}
          </button> */}
        </div>
      </div>
      <WaitlistNotification
        visible={showWaitlistNotification}
        message={notificationMessage}
        onClose={hideWaitlistNotification}
      />
    </div>
  );
};

export default OrganizerSection;
