import { Button } from '../../../../components/button/button';
import { ErrorBox } from '../../../../components/errorBox/errorBox';
import { Input } from '../../../../components/input/cenePlus/input';
import { Label } from '../../../../components/label/cene/label';
// import { ROUTER_REGISTER_CENE_PLUS_THANKS } from '../../../../constants/routes';
import translation from '../../../../constants/translations.json';
import { GuestSchema } from '../../../../schemas/guestSchema';
// import { supabaseCenePlusClient } from '../../../../supabase/supabase';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';

// import { useNavigate } from 'react-router-dom';

// interface FormValues {
//   firstName: string;
//   lastName: string;
//   gender: string;
//   dateOfBirth: string;
//   jobTitle: string;
//   company: string;
//   countryCode: string;
//   phone: string;
//   email: string;
//   reference: string;
// }

// type CenePlusRequestStatus = 'new' | 'approved' | 'rejected';

interface FormProps {
  onNext: () => void;
}

export const InviteVerifyForm = ({ onNext }: FormProps) => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<GuestSchema>();
  const [supabaseError] = useState<string | null>(null);

  const isEmailValid = values.email ? !errors.email : false;

  const isFormValid = isEmailValid;

  //   const navigate = useNavigate();

  //   const [isLoading, setLoading] = useState(false);

  //   const handleCenePlusRequestError = (
  //     status: CenePlusRequestStatus,
  //     date: string
  //   ) => {
  //     const currentDate = new Date();
  //     const requestDate = new Date(date);
  //     const differenceInDays = (current: Date, request: Date) => {
  //       const diffTime = Math.abs(current.getTime() - request.getTime());
  //       return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //     };

  //     const canResubmitRequest = () => {
  //       const daysDifference = differenceInDays(currentDate, requestDate);
  //       const daysLeft = 60 - daysDifference;
  //       return {
  //         canResubmit: daysDifference >= 60,
  //         daysLeft: daysLeft > 0 ? daysLeft : 0,
  //       };
  //     };
  //     const { daysLeft } = canResubmitRequest();

  //     switch (status) {
  //       case 'new':
  //         setSupabaseError('Your request is under consideration');
  //         break;
  //       case 'approved':
  //         setSupabaseError('Your request is approved');
  //         break;
  //       case 'rejected':
  //         setSupabaseError(
  //           `Unfortunately, you have already created a request and we have rejected it. You can resubmit your request after ${daysLeft} days.`
  //         );
  //         break;
  //     }
  //   };

  const onSubmit = async () => {
    // try {
    //   setLoading(true);
    //   setSupabaseError(null);
    //   const { data } = await supabaseCenePlusClient
    //     .from('cene_plus_requests')
    //     .select('status, updated_at');
    //   const isRequestNewOrRejected =
    //     data !== null &&
    //     (data[0]?.status === 'new' ||
    //       data[0]?.status === 'rejected' ||
    //       data[0]?.status === 'approved');
    //   if (isRequestNewOrRejected) {
    //     handleCenePlusRequestError(data[0]?.status, data[0]?.updated_at);
    //     return;
    //   }
    //   const { error } = await supabaseCenePlusClient
    //     .from('cene_plus_requests')
    //     .insert([
    //       {
    //         first_name: values.firstName,
    //         last_name: values.lastName,
    //         gender: values.gender,
    //         birthdate: values.dateOfBirth,
    //         job_title: values.jobTitle,
    //         company_name: values.company,
    //         phone_number: `+${values.countryCode}${values.phone}`,
    //         email: values.email,
    //         reference: values.reference,
    //         redirect_to: `${window.location.origin}/auth/register/cene-plus/continue`,
    //       },
    //     ]);
    //   if (error) throw Error(error.message);
    //   navigate(ROUTER_REGISTER_CENE_PLUS_THANKS);
    // } catch (error) {
    //   if (error instanceof Error) {
    //     if (error.message.includes('cene_plus_requests_email_unique')) {
    //       return setSupabaseError('Email is already in use');
    //     }
    //     setSupabaseError(error.message);
    //   }
    // } finally {
    //   setLoading(false);
    // }
    onNext();
  };

  return (
    <div className={classNames(['pt-[24px] pb-[20px] px-5', 'sm:px-0'])}>
      <h1 className="text-[2rem] text-generic font-light mb-[12px]">
        {translation.steps.inviteVerifyStep.title}
      </h1>
      <p className="text-[1.0625rem] text-generic font-light mb-[40px]">
        {values.firstName}, {translation.steps.inviteVerifyStep.description}
      </p>

      <div className="flex flex-col gap-[40px]">
        <div>
          <Label classLabel="text-generic" id="email" isRequired={true}>
            {translation.steps.inviteVerifyStep.email}
          </Label>
          <Input
            id="email"
            placeholder={translation.steps.inviteVerifyStep.emailPlaceholder}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
            touched={Boolean(touched.email)}
          />
        </div>
        <div>
          <Label isRequired={false} classLabel="text-generic" id="reference">
            {translation.steps.inviteVerifyStep.reference}
          </Label>
          <Input
            id="reference"
            placeholder={
              translation.steps.inviteVerifyStep.referencePlaceholder
            }
            value={values.reference}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.reference}
            touched={Boolean(touched.reference)}
          />
        </div>
        {supabaseError && <ErrorBox message={supabaseError} />}
        <Button
          color="generic"
          type="button"
          onClick={onSubmit}
          disabled={!isFormValid}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};
