import { useSupabase } from '../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../supabase/supabase';
import { Event } from '@ceneteam/namespace';

interface UseTicketActionsReturn {
  getAllTickets: () => Promise<Event[]>;
  getTicketDetails: (id: string) => Promise<Event>;
}

export const useTicketActions = (): UseTicketActionsReturn => {
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';
  const client = sessionType ? supabaseCeneClient : supabaseCenePlusClient;
  const getAllTickets = async (): Promise<Event[] | undefined> => {
    try {
      const { data, error } = await client
        .from('tickets')
        .select<string, Event>('*');
      if (error) throw error;
      return data;
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const getTicketDetails = async (id: string): Promise<Event | undefined> => {
    try {
      const { data, error } = await client
        .from('tickets')
        .select<string, Event>('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      return data;
    } catch (error) {
      console.log(error, 'error');
    }
  };
  return {
    getAllTickets: getAllTickets as () => Promise<Event[]>,
    getTicketDetails: getTicketDetails as (id: string) => Promise<Event>,
  };
};
