import { CustomLayout } from '../../forms/partnerWithUs/pickerLayout';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { parse } from 'date-fns';

interface Props {
  date: string | null;
  sessionType: boolean;
  disabled: boolean;
  onChange: (date: Date | null) => void;
}

const CustomDatePicker = ({ date, sessionType, disabled, onChange }: Props) => {
  const parsedDate = date ? parse(date, 'yyyy-MM-dd', new Date()) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={parsedDate}
        format="dd.MM.yyyy"
        disabled={disabled}
        onChange={onChange}
        maxDate={new Date()}
        slots={{
          layout: CustomLayout,
        }}
        sx={{
          backgroundColor: '#000',

          padding: '16px',
          paddingLeft: '0px',
          paddingRight: '0px',
          height: '56px',

          border: 'none',
          borderBottom: '1px solid #222',

          '.MuiSvgIcon-root': {
            color: sessionType ? '#fff' : '#B8CAD9',
          },

          '& .MuiButtonBase-root': {
            outline: 'none',
          },

          '& .MuiInputBase-input': {
            padding: 0,
            alignItems: 'baseline',
            display: 'flex',
            color: sessionType ? '#fff' : '#B8CAD9',

            '& .MuiOutlinedInput-input': {
              padding: 0,
              outline: 'none',
            },
          },

          '& .MuiOutlinedInput-root': {
            '&.Mui-disabled': {
              '& .MuiOutlinedInput-input': {
                opacity: '1',
                WebkitTextFillColor: sessionType ? '#fff' : '#B8CAD9',
                color: sessionType ? '#fff' : '#B8CAD9',
              },
            },
          },

          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
          },

          '&:hover': {
            borderColor: disabled
              ? '#222'
              : sessionType
                ? '#4a7dff'
                : '#B8CAD9',
          },

          '.MuiPaper-root': {
            backgroundColor: '#000',
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
