import * as routes from '../constants/routes';
import { seo } from '../constants/seo';
import { RootLayout } from '../layout/rootLayout';
import { Seo } from '../layout/seo';
import { ForgotPasswordPage } from '../pages/auth/forgotPassword/forgotPassword';
import { LoginPage } from '../pages/auth/login/login';
import { PeriodExpiredPage } from '../pages/auth/periodExpired/periodExpired';
import { SignupPage } from '../pages/auth/signup/cene/signupPage';
import { SignupPage as SignupPlusPage } from '../pages/auth/signup/cenePlus/signupPage';
import { SignupPartTwo } from '../pages/auth/signup/cenePlus/signupPartTwo';
import { SignupGuestPage } from '../pages/auth/signup/guest/signupGuest';
import { TermSplashPage } from '../pages/auth/termSplash/termSplash';
import { DeleteAccountSuccessful } from '../pages/deleteAccount/deleteAccountSuccessful';
import EntryPage from '../pages/entry/entry';
import { LoadingPage } from '../pages/loading/cene/loadingPage';
import { LoadingPage as LoadingPageCenePlus } from '../pages/loading/cenePlus/loadingPage';
import { ErrorPage } from '../pages/partnerWithUs/errorPage';
import { SuccessPage } from '../pages/partnerWithUs/successPage';
import { SplashPage } from '../pages/splashPage/splashPage';
import { ThanksPage } from '../pages/thanksPage/thanksPage';
import { PublicRoute } from './guard';
import { RouteObject } from 'react-router-dom';


/**
 * Router for unauthorized users
 */

export const unauthRouter: RouteObject = {
  children: [
    {
      element: (
        <Seo title={seo.login.title} description={seo.login.description}>
          <RootLayout shownFooter={false} guard={<PublicRoute />} />
        </Seo>
      ),
      children: [
        {
          path: routes.ROUTE_LOGIN,
          element: <LoginPage />,
        },
        {
          path: routes.ROUTE_AUTH,
          element: (
            <Seo
              title={seo.pricing.title}
              description={seo.pricing.description}
            >
              <EntryPage />
            </Seo>
          ),
        },
      ],
    },

    // layout Without header and footer
    {
      element: (
        <RootLayout
          withoutContainer={true}
          shownFooter={false}
          shownHeader={false}
          guard={<PublicRoute />}
        />
      ),
      children: [
        {
          path: routes.ROUTER_FORGOT_PASSWORD,
          element: <ForgotPasswordPage />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE_PLUS_TERM,
          element: <TermSplashPage />,
        },
        {
          path: routes.ROUTER_LOADER,
          element: <LoadingPage />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE_PLUS_RESEND,
          element: <PeriodExpiredPage />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE_PLUS_CONTINUE_REGISTRTION,
          element: <SplashPage />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE_PLUS_THANKS,
          element: <ThanksPage />,
        },
        {
          path: routes.ROUTER_LOADER_PLUS,
          element: <LoadingPageCenePlus />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE,
          element: <SignupPage />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE_PLUS,
          element: <SignupPlusPage />,
        },
        {
          path: routes.ROUTER_REGISTER_CENE_PLUS_SECOND_PART,
          element: <SignupPartTwo />,
        },
        { path: routes.ROUTER_REGISTER_GUEST, element: <SignupGuestPage /> },
        {
          path: routes.ROUTE_SUCCESS,
          element: <SuccessPage />,
        },
        {
          path: routes.ROUTE_DELETE_SUCCESSFUL,
          element: <DeleteAccountSuccessful />,
        },
        {
          path: routes.ROUTE_ERROR,
          element: <ErrorPage />,
        },
      ],
    },
  ],
};