import * as routes from '../constants/routes';
import { RootLayout } from '../layout/rootLayout';
import EventsCenePlusPage from '../pages/events/cenePlus';
import MyBasketPage from '../pages/myBasket/myBasket';
import MyEventsPage from '../pages/myEvents/myEvents';
import MyTicketsPage from '../pages/myTickets/myTickets';
import ProfilePage from '../pages/profile/profile';
import ResetPasswordPage from '../pages/settings/manageAccount/resetPassword';
import InterestsPage from '../pages/settings/preferences/interests';
import MusicPage from '../pages/settings/preferences/music';
import NotificationsPage from '../pages/settings/preferences/notifications';
import SettingsPage from '../pages/settings/settings';
import { TablesPage } from '../pages/tables';
import TicketDetailsPage from '../pages/ticketDetails/ticketDetails';
import { ProtectedRoute } from './guard';
import { RouteObject } from 'react-router-dom';

/**
 * Router for authorized users
 */

export const authRouter: RouteObject = {
  children: [
    {
      element: <RootLayout guard={<ProtectedRoute />} />,
      children: [
        { path: routes.ROUTER_MY_TICKETS, element: <MyTicketsPage /> },
        { path: routes.ROUTER_MY_EVENTS, element: <MyEventsPage /> },
        {
          path: routes.ROUTER_MY_BASKET,
          element: <MyBasketPage />,
        },
        { path: routes.ROUTER_TICKET_DETAILS, element: <TicketDetailsPage /> },
        {
          path: routes.ROUTE_EVENTS_PLUS,
          element: <EventsCenePlusPage />,
        },
        {
          path: routes.ROUTER_CHOOSE_TABLES,
          element: <TablesPage />,
        },
      ],
    },
    {
      element: (
        <RootLayout
          guard={<ProtectedRoute />}
          shownHeader={false}
          shownFooter={false}
        />
      ),
      children: [
        { path: routes.ROUTE_PROFILE, element: <ProfilePage /> },
        { path: routes.ROUTE_SETTINGS, element: <SettingsPage /> },
        {
          path: routes.ROUTER_SETTINGS_NOTIFICATIONS,
          element: <NotificationsPage />,
        },
        { path: routes.ROUTER_SETTINGS_INTERESTS, element: <InterestsPage /> },
        { path: routes.ROUTER_SETTINGS_MUSIC, element: <MusicPage /> },
        {
          path: routes.ROUTER_SETTINGS_RESET_PASSWORD,
          element: <ResetPasswordPage />,
        },
      ],
    },
  ],
};
