import { ReactComponent as FeatherActiveSvg } from '../../../src/svg/featherActive.svg';
import { ReactComponent as FeatherNotActiveSvg } from '../../../src/svg/featherGray.svg';
import { ReactComponent as DeviceActiveSvg } from '../../../src/svg/mobileCameraActive.svg';
import { ReactComponent as DeviceNotActiveSvg } from '../../../src/svg/deviceMobileCameraGray.svg';
import { ReactComponent as PersonAddActiveSvg } from '../../../src/svg/personAddActive.svg';
import { ReactComponent as PersonAddNotActiveSvg } from '../../../src/svg/personGray.svg';
import classNames from 'classnames';
import React from 'react';

type FeatureTitleProps = {
  index: number;
  title: string;
  description: string;
  activeTitle: number;
  setActiveTitle: (index: number) => void;
  isLast: boolean;
  resetProgress: () => void;
};

const PhoneImages = [
  '/pictures/phoneBannerOne.png',
  '/pictures/phoneBannerTwo.png',
  '/pictures/phoneBannerThree.png',
];

const icons = [DeviceNotActiveSvg, FeatherNotActiveSvg, PersonAddNotActiveSvg];
const whiteIcons = [DeviceActiveSvg, FeatherActiveSvg, PersonAddActiveSvg];

export const FeatureTitle: React.FC<FeatureTitleProps> = ({
  index,
  title,
  description,
  activeTitle,
  setActiveTitle,
  isLast,
  resetProgress,
}) => {
  const handleClick = () => {
    setActiveTitle(index);
    resetProgress();
  };
  const isTitleActive = activeTitle === index;
  const Icon = isTitleActive ? whiteIcons[index] : icons[index];

  return (
    <div
      className={classNames([
        {
          'border-b lg:mt-[64px] mt-[40px] border-gray-medium': !isLast, // Conditionally apply border-b
        },
      ])}
    >
      <h2
        onClick={handleClick}
        className={classNames([
          'lg:text-[2rem] lg:leading-[40px] flex items-center cursor-pointer font-light text-[1.125rem] leading-[26px]',
          {
            'text-generic': isTitleActive,
            'text-gray-light my-[56px]': !isTitleActive,
            'lg:mt-[56px] mt-[40px]': index !== 0 && isTitleActive,
          },
        ])}
      >
        <Icon className="lg:mr-[24px] mr-[16px]" />
        {title}
      </h2>
      {isTitleActive && (
        <div>
          <p
            className={classNames([
              'text-[1.1875rem] text-white lg:mt-[30px] mt-[16px] lg:mb-[56px] mb-[32px] leading-[19px]',
              'lg:text-[1.3125rem] lg:leading-[21px] font-light',
            ])}
          >
            {description}
          </p>
          <div
            className={classNames([
              'w-full flex justify-center mt-8',
              'xl:mt-0 xl:justify-end xl:hidden',
            ])}
          >
            <img
              src={PhoneImages[index]}
              alt="Phone"
              className={classNames(['w-full', 'xl:max-w-none  lg:mb-0',{'mb-[32px]':!isLast}])}
            />
          </div>
        </div>
      )}
    </div>
  );
};
