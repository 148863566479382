import { Button } from '../../components/button/button';
import { Checkbox } from '../../components/checkbox/checkbox';
import { CountryCodeSelect } from '../../components/countryCodeSelect/countryCodeSelect';
import { ErrorBox } from '../../components/errorBox/errorBox';
import { Input } from '../../components/input/cene/input';
import { Label } from '../../components/label/cene/label';
import { InputSelect } from '../../components/select/select';
import translations from '../../constants/translations.json';
import {
  PartnerWithUsSchema,
  partnerWithUsSchema,
} from '../../schemas/partnerWithUsSchema';
import { supabase } from '../../supabase/supabase';
import { SubscribePlan } from '../../types/subscribePlan';
import { CustomLayout } from './pickerLayout';
import { subscriptionPlan } from './subscriptionPlan';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { AuthError } from '@supabase/supabase-js';
import classNames from 'classnames';
import { format, parse } from 'date-fns';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';

interface PartnerWithUsFormProps {
  onSuccess: () => void;
  onError: () => void;
}

export const PartnerWithUsForm = ({
  onSuccess,
  onError,
}: PartnerWithUsFormProps) => {
  const dateFounded = new Date();
  const [supabaseError, setSupabaseError] = useState<string | null>(null);

  const handleCenePlusRequestError = (message: string, date?: string) => {
    switch (true) {
      case message.includes('partner_request_already_exists_for_number'):
        setSupabaseError('Your request is under consideration.');
        break;
      case message.includes('partner_request_already_rejected'):
        if (date) {
          const currentDate = new Date();
          const requestDate = new Date(date);
          const differenceInDays = (current: Date, request: Date) => {
            const diffTime = Math.abs(current.getTime() - request.getTime());
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          };
          const daysDifference = differenceInDays(currentDate, requestDate);
          const daysLeft = 60 - daysDifference;
          setSupabaseError(
            `Unfortunately, you have already created a request and we have rejected it. You can resubmit your request after ${daysLeft > 0 ? daysLeft : 0} days.`
          );
        }
        break;
      case message.includes('partner_request_already_approved'):
        setSupabaseError('Your request has already been approved.');
        break;
      default:
        setSupabaseError('An unknown error occurred.');
        break;
    }
  };

  const formik = useFormik<PartnerWithUsSchema>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      countryCode: '',
      phone: '',
      companyName: '',
      dateFounded: format(dateFounded, 'yyyy-MM-dd'),
      subscriptionPlan: SubscribePlan.STARTER,
      eventWebsite: '',
      message: '',
      newsletterAgreement: false,
    },
    validationSchema: partnerWithUsSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSupabaseError(null);
      try {
        const { error } = await supabase.from('partner_requests').insert([
          {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone_number: `+${values.countryCode}${values.phone}`,
            company_name: values.companyName,
            subscription_plan_id: values.subscriptionPlan,
            website: values.eventWebsite,
            message: values.message,
            date_founded: values.dateFounded,
            subscribe_newsletters: values.newsletterAgreement,
          },
        ]);

        if (error) {
          const { message } = error;
          if (message.includes('partner_request_already_rejected')) {
            const date = message.split(', ')[1];
            handleCenePlusRequestError(message, date);
          } else {
            handleCenePlusRequestError(message);
          }
          onError();
          return;
        }

        if (error) throw error;

        setSubmitting(false);
        resetForm({
          values: {
            ...formik.initialValues,
            countryCode: values.countryCode,
          },
        });
        onSuccess();
      } catch (error) {
        if (error instanceof AuthError) {
          setSupabaseError(error.message);
        }
        onError();
      }
    },
  });

  const [isChecked, setChecked] = useState(formik.values.newsletterAgreement);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleCheckboxChange = (checked: boolean) => {
    formik.setFieldValue('newsletterAgreement', checked);
    setChecked(checked);
  };

  return (
    <>
      <form
        className={classNames([
          'w-[568px] flex flex-col gap-[40px]',
          'max-md:gap-[32px] max-md:w-full',
        ])}
        onSubmit={formik.handleSubmit}
      >
        <div
          className={classNames([
            'flex items-baseline w-[100%] gap-[16px]',
            'max-md:flex-col',
          ])}
        >
          <div className="flex flex-col gap-[8px] w-full">
            <Label isRequired={true} id="firstName">
              First name
            </Label>
            <Input
              id="firstName"
              placeholder="Abayomi"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.firstName}
              touched={Boolean(formik.touched.firstName)}
            />
          </div>
          <div className="flex flex-col gap-[8px] w-full">
            <Label isRequired={true} id="lastName">
              Last name
            </Label>
            <Input
              id="lastName"
              placeholder="Samuel"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.lastName}
              touched={Boolean(formik.touched.lastName)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <Label isRequired={true} id="email">
            E-mail
          </Label>
          <Input
            id="email"
            placeholder="Your e-mail..."
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.email}
            touched={Boolean(formik.touched.email)}
          />
        </div>
        <div className="flex flex-col gap-[8px]">
          <Label id="phone" isRequired={true}>
            Phone number
          </Label>
          <div
            className={classNames([
              'flex items-baseline w-[100%] gap-[16px]',
              'max-md:gap-[8px]',
            ])}
          >
            <div className="w-[164px]">
              <CountryCodeSelect
                value={formik.values.countryCode}
                onChange={(value) => {
                  formik.setFieldValue('countryCode', value);
                }}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Input
                id="phone"
                placeholder="Ex: 123456789012"
                value={formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue('phone', e.currentTarget?.value)
                }
                onBlur={formik.handleBlur}
                error={formik.errors.phone}
                touched={Boolean(formik.touched.phone)}
              />
              {formik.errors.phone && formik.touched.phone && (
                <div className="text-[0.875rem] text-red font-light mt-[8px]">
                  {formik.errors.phone}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <Label id="companyName" isRequired={true}>
            Event company name
          </Label>
          <Input
            id="companyName"
            placeholder="National Entertainment company"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.companyName}
            touched={Boolean(formik.touched.companyName)}
          />
        </div>
        <div className="flex items-baseline w-[100%] gap-[16px]">
          <div className="flex flex-col gap-[8px] w-full">
            <Label id="dateFounded" isRequired={true}>
              Date founded
            </Label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={parse(
                  formik.values.dateFounded,
                  'yyyy-MM-dd',
                  new Date()
                )}
                onChange={(newValue) => {
                  if (newValue) {
                    const formattedDate = format(newValue, 'yyyy-MM-dd');
                    formik.setFieldValue('dateFounded', formattedDate);
                  } else {
                    formik.setFieldValue('dateFounded', '');
                  }
                }}
                format="dd.MM.yyyy"
                slots={{
                  layout: CustomLayout,
                }}
                sx={{
                  backgroundColor: '#000',
                  color: '#fff',
                  padding: '16px',
                  paddingLeft: '24px',
                  paddingRight: '4px',
                  height: '56px',

                  border: '1px solid #222',
                  borderRadius: '20px 0',

                  '.MuiSvgIcon-root': {
                    color: 'white',
                  },

                  '& .MuiButtonBase-root': {
                    outline: 'none',
                  },

                  '& .MuiInputBase-input': {
                    padding: 0,
                    alignItems: 'baseline',
                    display: 'flex',
                    color: '#fff',

                    '& .MuiOutlinedInput-input': {
                      padding: 0,
                      outline: 'none',
                    },
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent !important',
                  },

                  '&:hover': {
                    borderColor: '#4a7dff',
                    boxShadow: '0px 0px 4px #4a7dff3d',
                  },

                  '.MuiPaper-root': {
                    backgroundColor: '#000',
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="flex flex-col gap-[8px] w-full">
            <Label id="subscribtionPlan" isRequired={true}>
              Subscription plan
            </Label>
            <InputSelect
              type="text"
              value={formik.values.subscriptionPlan}
              options={subscriptionPlan}
              onChange={(value) => {
                formik.setFieldValue('subscriptionPlan', value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <Label id="eventWebsite" isRequired={true}>
            Event website
          </Label>
          <Input
            value={formik.values.eventWebsite}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.eventWebsite}
            touched={Boolean(formik.touched.eventWebsite)}
            id="eventWebsite"
            placeholder="https://www.nationalent.com/"
          />
        </div>
        <div className="flex flex-col gap-[8px]">
          <Label id="message">Message</Label>
          <textarea
            value={formik.values.message}
            onChange={formik.handleChange}
            onInput={handleInput}
            ref={textareaRef}
            id="message"
            placeholder="A great event curator"
            rows={3}
            className={classNames([
              'focus:border-content',
              'placeholder:text-gray-light text-[1rem] font-light resize-none overflow-hidden w-full m-h-[96px] h-auto p-[16px] pl-[24px] outline-none text-white bg-black rounded-tl-[20px] rounded-br-[20px] border border-gray-medium',
            ])}
          />
        </div>
        <div className="w-full">
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            label="Sign up to our newsletter"
          />

          {supabaseError && (
            <div className="my-[20px]">
              <ErrorBox message={supabaseError} />
            </div>
          )}

          <Button className="w-full my-[24px] bg-village" type="submit">
            {translations.partnerWithUs.textButton}
          </Button>
        </div>
      </form>
    </>
  );
};
