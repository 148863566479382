import CarouselSection from '../../components/carouselSection/carouselSection';
import { Loader } from '../../components/loader/cene/loader';
import { Loader as LoaderCenePlus } from '../../components/loader/cenePlus/loader';
import translations from '../../constants/translations.json';
import { useFetchTicketsAttended } from '../../hooks/useFetchTicketsAttended';
import { useSupabase } from '../../providers/supabase';
import EmptyEventsSection, { EventType } from './notFound.tsx/notFound';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AttendedEventsSection = () => {
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';
  const navigate = useNavigate();

  const { events, isLoading, fetchAttendedTickets } = useFetchTicketsAttended();
  const handleCardClick = (id: string, eventData: Event) => {
    navigate(`/ticket-details/${id}`, { state: { eventData } });
  };

  useEffect(() => {
    fetchAttendedTickets(sessionType);
  }, [sessionType]);

  return (
    <div
      className={classNames(
        events.length > 0 ? 'pb-[2.5rem] pt-[4.5rem]' : '',
        'max-lg:py-[4.5rem]'
      )}
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[558px]">
          {sessionType ? <Loader /> : <LoaderCenePlus />}
        </div>
      ) : events.length === 0 ? (
        <EmptyEventsSection
          events={events}
          title={translations.myTickets.attendedEvents}
          sessionType={sessionType}
          type={EventType.ATTENDED}
        />
      ) : (
        <CarouselSection
          data={events}
          title={translations.myTickets.attendedEvents}
          sessionType={sessionType}
          color={sessionType ? 'ticket' : 'generic'}
          handleCardClick={(id) =>
            handleCardClick(id, events.find((event) => event.id === id)!)
          }
          colorCard={sessionType ? 'ticket' : 'black'}
        />
      )}
    </div>
  );
};

export default AttendedEventsSection;
