import translations from '../../constants/translations.json';
import classNames from 'classnames';

const Photo = '/pictures/creewBanner.png';
const PhotoTwo = '/pictures/diverseBanner.png';
const PhotoThree = '/pictures/doorsBanner.png';
const PhotoFour = '/pictures/dreamBanner.png';

export const CareersInfoSection = () => {
  return (
    <section>
      <div className="lg:sticky ">
        <div
          className={classNames([
            ' justify-between w-full h-auto ',
            'lg:flex-row lg:sticky lg:top-0  lg:mb-[21rem] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div
            className={classNames([
              'w-auto flex flex-row text-event lg:leading-[22px] lg:text-[1.0625rem] text-[1rem] leading-[21px] font-light py-[21px] ',
              'lg:mb-[48px]',
            ])}
          >
            01
            <p
              className={classNames(['text-event  ml-[24px]', 'lg:text-start'])}
            >
              {translations.careersInfo.whoWeAre}
            </p>
          </div>
          <div
            className={classNames([
              ' justify-center items-center flex flex-col relative',
              'lg:w-full  lg:flex-row',
            ])}
          >
            <div
              className={classNames(['w-full', 'lg:w-[423px] lg:h-[268px] '])}
            >
              <h2
                className={classNames([
                  'text-event text-[2rem] leading-[40px] font-light pt-[40px] lg:whitespace-pre-line ',
                  'lg:pt-0 lg:text-[4rem] lg:leading-[72px]',
                ])}
              >
                {translations.careersInfo.titleWhoWeAre}
              </h2>
              <p
                className={classNames([
                  'text-white lg:text-[1.3125rem] font-light lg:leading-[21px] mt-[16px] leading-[19px] text-[1.1875rem]',
                  'lg:mt-[40px]',
                ])}
              >
                {translations.careersInfo.descWhoWeAre}
              </p>
            </div>
            <img
              src={Photo}
              alt="Photo"
              className={classNames([
                'w-full mt-[24px]  mb-[56px] rounded-tl-[30px] rounded-br-[30px] ',
                'lg:w-[656px] lg:h-[640px] lg:ml-[41px] lg:mt-0 lg:mb-0 ',
              ])}
            />
          </div>
        </div>

        <div
          className={classNames([
            'flex flex-col w-full h-auto',
            'lg:sticky lg:mt-[-14rem] lg:top-[4rem] lg:mb-[16rem] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div className="w-full flex flex-row text-event lg:leading-[22px] lg:text-[1.0625rem] font-light text-[1rem] leading-[21px]  py-[21px] lg:mb-[48px] mb-[40px]">
            02
            <p className="text-event text-start ml-[24px]">
              {translations.careersInfo.whoWeAre}
            </p>
          </div>

          <div
            className={classNames([
              'flex flex-col w-full items-center lg:flex-row lg:items-center lg:justify-between',
            ])}
          >
            <div
              className={classNames([
                'h-auto w-full text-center lg:w-[423px] lg:text-left  lg:order-2',
              ])}
            >
              <h2
                className={classNames([
                  'text-event text-[2rem] leading-[40px] font-light whitespace-pre-line text-left',
                  'lg:text-[4rem] lg:leading-[72px]',
                ])}
              >
                {translations.careersInfo.titleWeBelieve}
              </h2>
              <p className="text-white lg:text-[1.3125rem] lg:leading-[21px] lg:mt-[24px] mt-[16px] font-light text-left whitespace-pre-line leading-[19px] text-[1.1875rem]">
                {translations.careersInfo.descWeBelieve}
              </p>
            </div>

            <img
              src={PhotoTwo}
              alt="PhotoTwo"
              className={classNames([
                'w-full mt-[24px] mb-[56px]',
                'lg:w-[656px] lg:h-[640px] lg:mt-0 lg:mb-0 lg:mr-[24px] lg:order-1',
              ])}
            />
          </div>
        </div>

        <div
          className={classNames([
            'flex-col justify-between w-full h-auto',
            'lg:flex-row lg:sticky lg:mt-[-12rem] lg:top-[8rem] lg:mb-[13rem] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div className="w-full flex flex-row text-event lg:leading-[22px] lg:text-[1.0625rem] text-[1rem] leading-[21px] font-light py-[21px] lg:mb-[48px] mb-[40px]">
            03
            <p className="text-event text-start ml-[24px]">
              {translations.careersInfo.howWeAct}
            </p>
          </div>
          <div
            className={classNames([
              'w-full lg:w-full flex flex-col',
              'lg:relative lg:flex-row-reverse',
            ])}
          >
            <img
              src={PhotoThree}
              alt="PhotoThree"
              className={classNames([
                'w-full order-2 mt-[24px] mb-[56px]',
                'lg:mt-0 lg:order-1 lg:w-[601px] lg:ml-[96px] lg:mb-0',
              ])}
            />
            <div
              className={classNames([
                'order-1 lg:order-2',
                'lg:w-[423px] lg:h-[217px] lg:mt-[150px]',
              ])}
            >
              <h2
                className={classNames([
                  'text-event text-[2rem] leading-[40px] font-light',
                  'lg:text-[4rem] lg:leading-[72px]',
                ])}
              >
                {translations.careersInfo.titleWeAct}
              </h2>
              <p className="text-white lg:text-[1.3125rem] lg:leading-[21px] lg:mt-[40px] mt-[16px] font-light whitespace-pre-line leading-[19px] text-[1.1875rem]">
                {translations.careersInfo.desctWeAct}
              </p>
            </div>
          </div>
        </div>

        <div
          className={classNames([
            'flex flex-col w-full h-auto',
            'lg:sticky lg:mt-[-14rem] lg:top-[4rem] lg:mb-[112px] lg:bg-black border-t border-t-gray-medium',
          ])}
        >
          <div className="w-full flex flex-row text-event lg:leading-[22px] lg:text-[1.0625rem] font-light text-[1rem] leading-[21px] py-[21px] lg:mb-[48px] mb-[40px]">
            04
            <p className="text-event text-start ml-[24px]">
              {translations.careersInfo.thisMeans}
            </p>
          </div>

          <div
            className={classNames([
              'flex flex-col w-full items-center lg:flex-row lg:items-center lg:justify-between',
            ])}
          >
            <div
              className={classNames([
                'h-auto w-full text-center lg:w-[423px] lg:text-left lg:order-2',
              ])}
            >
              <h2
                className={classNames([
                  'text-event text-[2rem] leading-[40px] font-light whitespace-pre-line text-left',
                  'lg:text-[4rem] lg:leading-[72px]',
                ])}
              >
                {translations.careersInfo.titleThisMeans}
              </h2>
              <p className="text-white lg:text-[1.3125rem] lg:leading-[21px] lg:mt-[24px] mt-[16px] font-light text-left whitespace-pre-line leading-[19px] text-[1.1875rem]">
                {translations.careersInfo.descThisMeans}
              </p>
            </div>

            <img
              src={PhotoFour}
              alt="PhotoFour"
              className={classNames([
                'w-full mt-[24px] mb-[56px]',
                'lg:w-[656px] lg:h-[640px] lg:mt-0 lg:mb-0 lg:mr-[24px] lg:order-1',
              ])}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
