import { YourCenePlan } from '../types/yourCenePlan';
import * as Yup from 'yup';

const reasonsForReachingOut = [
  'Technical Support',
  'Event Inquiries',
  'Payment Issues',
  'Account Management',
  'Membership Benefits',
  'Member-only offers',
  'Collaboration Opportunities',
  'Event Support',
  'Other',
];

export const contactWithUsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('E-mail is required')
    .matches(
      /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid e-mail format. Please enter a valid e-mail address.'
    ),
  yourCenePlan: Yup.string()
    .oneOf(
      [
        YourCenePlan.CENE_USER,
        YourCenePlan.CENE_PLUS,
        YourCenePlan.CENE_PARTNERS,
      ],
      'Invalid subscription plan'
    )
    .required('Subscription plan is required'),
  reasonForReachingOut: Yup.string()
    .oneOf(reasonsForReachingOut, 'Invalid reason for reaching out')
    .required('Reason for reaching out is required'),
  message: Yup.string(),
  newsletterAgreement: Yup.boolean(),
});

export type ContactWithUsSchema = Yup.InferType<typeof contactWithUsSchema>;
