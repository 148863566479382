import FaviconManager from '../../../../components/faviconManager/faviconManager';
import Steps from '../../../../components/steps/steps';
import { ROUTE_AUTH } from '../../../../constants/routes';
import { usePreventUnload } from '../../../../hooks/usePreventUpload';

import {
  PasswordFormWithoutEmailSchema,
  passwordFormWithoutEmailSchema,
} from '../../../../schemas/registerSchema';

import { supabaseCenePlusClient } from '../../../../supabase/supabase';
import { GenrePreferencesForm } from '../../genrePreferencesStep/cenePlus/genrePreferences';
import { InterestPreferencesForm } from '../../interestPreferencesStep/cenePlus/interestPreferences';
import { Notifications } from '../../notificationStep/notificationForm';
import { PasswordForm } from '../../passwordStep/cenePlus/passwordForm';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RegisterFormPartTwoProps {
  onLoading: () => void;
}

export const RegisterFormPartTwo = ({
  onLoading,
}: RegisterFormPartTwoProps) => {
  const [activeStep, setStep] = useState(1);
  const navigate = useNavigate();
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePreventUnload(isFormDirty);

  const onBackStep = useCallback(() => {
    if (activeStep === 1) return navigate(ROUTE_AUTH);

    setStep(activeStep - 1);
  }, [activeStep]);

  const nextStep = useCallback(() => setStep(activeStep + 1), [activeStep]);

  const validationSchemas = [passwordFormWithoutEmailSchema];

  const isSkip = activeStep === 2 || activeStep === 3 || activeStep === 4;

  const onSubmit = async (values: PasswordFormWithoutEmailSchema) => {
    try {
      const { data: updatedUser } = await supabaseCenePlusClient.auth.updateUser({
        password: values.password,
      });

      const { error } = await supabaseCenePlusClient
        .from('customers')
        .update([
          {
            interests: values.interestGenres,
            genres: values.musicGenres,
            notifications: values.notifications,
            onboarding_finished: true,
          },
        ])
        .eq('user_id', updatedUser?.user?.id);

      if (error) return alert(error.message);

      onLoading();
    } catch (error) {
      //todo use Snackbar / Toast / tooltip
      console.log(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
          agreement: false,
          musicGenres: [],
          interestGenres: [],
          notifications: [],
        }}
        validationSchema={validationSchemas[activeStep - 1]}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, dirty }) => {
          useEffect(() => {
            setIsFormDirty(dirty);
          }, [dirty]);
          return (
            <Steps
              width={168}
              size="px"
              isPremium={true}
              activeStep={activeStep}
              onBack={onBackStep}
              onSkip={() => {
                if (activeStep === 2) setFieldValue('musicGenres', []);
                if (activeStep === 3) setFieldValue('interestGenres', []);
                if (activeStep === 4) {
                  return onSubmit({ ...values, notifications: [] });
                }

                nextStep();
              }}
              canSkip={isSkip}
              hideBackButton={activeStep === 1}
            >
              <PasswordForm onNext={nextStep} />
              <GenrePreferencesForm onNext={nextStep} />
              <InterestPreferencesForm onNext={nextStep} />
              <Notifications onNext={() => onSubmit(values)} isPremium={true} />
            </Steps>
          );
        }}
      </Formik>
      <FaviconManager isCenePlus />
    </>
  );
};
