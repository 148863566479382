import FaviconManager from '../../../../components/faviconManager/faviconManager';
import { ROUTE_AUTH } from '../../../../constants/routes';
import { RegisterForm } from '../../../../forms/signup/registerForm/cenePlus/registerForm';
import { ReactComponent as CloseSVG } from '../../../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../../../svg/logotype.svg';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const SignupPage = () => {
  const navigate = useNavigate();

  const gotoBack = useCallback(() => navigate(ROUTE_AUTH), []);

  return (
    <>
      <FaviconManager isCenePlus />
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between mb-[40px] px-[20px]',
        ])}
      >
        <LogoSVG className="w-[164px] h-[48px]" />
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center max-w-[422px]">
        <RegisterForm />
      </div>
    </>
  );
};
