import { Button } from '../../../../components/button/button';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import { Input } from '../../../../components/input/cenePlus/input';
import { Label } from '../../../../components/label/cene/label';
import PasswordRequirementsList from '../../../../components/passwordRequirementsList/passwordRequirementsList';
import translation from '../../../../constants/translations.json';
import {
  lowercaseRule,
  numberRule,
  specialCharacterRule,
  uppercaseRule,
} from '../../../../schemas/validationRules';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { SyntheticEvent, useEffect, useState } from 'react';

interface FormProps {
  onNext: () => void;
}

interface FormValues {
  password: string;
  confirmPassword: string;
  agreement: boolean;
}

export const PasswordForm = ({ onNext = () => null }: FormProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isValid,
    dirty,
    setFieldValue,
  } = useFormikContext<FormValues>();
  const [isChecked, setChecked] = useState(values.agreement);

  const [passwordValid, setPasswordValid] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  useEffect(() => {
    setFieldValue('agreement', isChecked);
  }, [isChecked]);

  const handleChangePassword = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setFieldValue('password', value);
    validatePassword(value);
  };

  const validatePassword = (newPassword: string) => {
    const isUppercase = uppercaseRule?.isValidSync(newPassword);
    const isLowercase = lowercaseRule?.isValidSync(newPassword);
    const isNumber = numberRule?.isValidSync(newPassword);
    const isSpecialCharacter = specialCharacterRule?.isValidSync(newPassword);

    setPasswordValid({
      uppercase: isUppercase,
      lowercase: isLowercase,
      number: isNumber,
      specialChar: isSpecialCharacter,
    });
  };

  const handleCheckboxChange = (checked: boolean) => {
    setChecked(checked);
  };

  return (
    <div className={classNames(['mt-[24px] pb-[20px]', 'sm:px-0 px-5'])}>
      <h1 className="text-[2rem] text-generic font-light mb-[12px]">
        {translation.steps.createPassword.titleCenePlus}
      </h1>
      <p className="text-[0.875rem] text-generic font-light">
        {translation.steps.createPassword.descriptionCenePlus}
      </p>
      <div className="flex flex-col">
        <div className="mt-[40px]">
          <Label classLabel="text-generic" id="password" isRequired={true}>
            {translation.steps.createPassword.password}
          </Label>
          <Input
            id="password"
            type="password"
            placeholder={translation.steps.createPassword.enterPassword}
            value={values.password}
            onChange={handleChangePassword}
            onBlur={handleBlur}
            error={errors.password}
            touched={Boolean(touched.password)}
          />
          {errors.password && touched.password && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {errors.password}
            </div>
          )}
        </div>
        <div className="mt-[40px]">
          {/* <label className="text-[0.875rem] text-generic font-light mb-[12px] block">
            {translation.steps.createPassword.passwordMustHave}
          </label> */}
          <PasswordRequirementsList
            isPremium={true}
            passwordValid={passwordValid}
          />
        </div>
        <div className="mt-[40px]">
          <Label id="confirmPassword" isRequired={true}>
            {translation.steps.createPassword.confirmPassword}
          </Label>
          <Input
            id="confirmPassword"
            type="password"
            placeholder={translation.steps.createPassword.enterConfirmPassword}
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.confirmPassword}
            touched={Boolean(touched.confirmPassword)}
          />
          {errors.confirmPassword && touched.confirmPassword && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {errors.confirmPassword}
            </div>
          )}
        </div>
        <Checkbox
          isPremium={true}
          className="mt-[40px]"
          checked={isChecked}
          onChange={handleCheckboxChange}
          label="I agree to Cene's Terms of Use and Conditions"
        />
        <Button
          color="generic"
          type="submit"
          className="mt-[24px]"
          onClick={onNext}
          disabled={!(isValid && dirty)}
        >
          {translation.next}
        </Button>
      </div>
    </div>
  );
};
