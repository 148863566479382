import { Button } from '../../components/button/button';
import { ROUTE_PARTNER_WITH_US, ROUTE_TICKETING } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Logo = '/pictures/banner.png';
const LogoSmall = '/pictures/bannerSmall.png';

export const BurstOntoDesc = () => {
  usePreventHorizontalScroll();
  const navigate = useNavigate();

  return (
    <div
      className={classNames([
        'relative w-full h-auto flex flex-col justify-center items-center lg:mt-[92px]',
        'lg:px-0',
      ])}
    >
      <div className="relative lg:hidden w-screen h-[578px]">
        <img
          src={LogoSmall}
          alt="Banner"
          className="object-cover w-full h-full"
        />

        <div className="absolute inset-0 flex flex-col mt-[142px] items-center z-[1] px-[20px]">
          <p className="text-white text-[1.0625rem] leading-[22px] mb-[16px] text-center font-light w-[335px]">
            {translations.burstDesc.titleTwo}
          </p>
          <h1 className="text-[2.25rem] leading-[44px] font-light text-white text-center mb-[16px]">
            {translations.burstDesc.title}
          </h1>
          <h3 className="text-[1.0625rem] font-normal text-white text-center  w-[335px] leading-[22px]">
            {translations.burstDesc.description}
          </h3>
          <div className="flex mt-[16px] w-full justify-center space-x-4">
            <Button
              color="content"
              type="button"
              className="w-[159.5px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px]"
              onClick={() => navigate(ROUTE_PARTNER_WITH_US)}
            >
              {translations.burstDesc.buttonOne}
            </Button>
            <Button
              color="ticket"
              type="button"
              className="w-[159.5px] h-[48px] bg-ticket rounded-none rounded-tl-[20px] rounded-br-[20px]"
              onClick={() => navigate(ROUTE_TICKETING)}
            >
              {translations.burstDesc.buttonTwo}
            </Button>
          </div>
        </div>
      </div>

      {/* Desktop View */}
     <div className=' hidden lg:flex flex-col justify-center items-center'>
      <p className="text-white text-[1.0625rem] leading-[22px] mb-[24px] text-center font-light">
        {translations.burstDesc.titleTwo}
      </p>
      <h1
        className={classNames([
          'text-[3.5rem] leading-[64px] font-light text-white text-center',
          'lg:text-[4.5rem] lg:leading-[80px]',
        ])}
      >
        {translations.burstDesc.title}
      </h1>
      <h3 className="text-[1.0625rem] font-normal text-white text-center mt-[24px] w-full max-w-[537.92px] leading-[22px] px-[20px]">
        {translations.burstDesc.description}
      </h3>
      <div
        className={classNames([
          'flex mt-[24px] w-full justify-center space-x-4 mb-[80px]',
        ])}
      >
        <Button
          color="content"
          type="button"
          className={classNames([
            'lg:w-[199px] lg:h-[46px]',
            'w-[159.5px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px] ',
          ])}
          onClick={() => navigate(ROUTE_PARTNER_WITH_US)}
        >
          {translations.burstDesc.buttonOne}
        </Button>
        <Button
          color="ticket"
          type="button"
          className={classNames([
            'lg:w-[199px] lg:h-[46px] bg-ticket rounded-none rounded-tl-[20px] rounded-br-[20px]',
            'w-[159.5px] h-[48px]',
          ])}
          onClick={() => navigate(ROUTE_TICKETING)}
        >
          {translations.burstDesc.buttonTwo}
        </Button>
      </div>

      <div className="hidden lg:block relative w-screen h-screen overflow-hidden">
        <img src={Logo} alt="Banner" className="object-contain w-full h-full" />
      </div>
      </div>
    </div>
  );
};
