import classNames from 'classnames';
import { ReactNode } from 'react';

export interface IButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  color?:
    | ''
    | 'content'
    | 'village'
    | 'event'
    | 'ticket'
    | 'generic'
    | 'outlined'
    | 'white'
    | 'text';
  children: ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isPopular?: boolean;
  isFooter?: boolean;
}

export const Button = ({
  disabled = false,
  onClick = () => null,
  children,
  color = '',
  className = '',
  isFooter = false,
  type = 'button',
  isPopular = false,
}: IButtonProps) => {
  const buttonClass = classNames(
    'rounded-none rounded-tl-[20px] rounded-br-[20px]',
    {
      'bg-white': color === 'white',
      'bg-content': color === 'content',
      'bg-village': color === 'village',
      'bg-event': color === 'event',
      'bg-ticket': color === 'ticket',
      'bg-generic': color === 'generic',
      'bg-outlined': color === 'outlined',
      'bg-text': color === 'text',
      'text-white flex gap-[8px] border border-gray-medium':
        color === 'outlined',
      'bg-gray-light cursor-not-allowed': disabled,
      'text-[1rem] font-medium duration-300 hover:shadow-button-shadow cursor-pointer flex items-center justify-center h-[48px] px-[12px] py-[24px] text-black':
        !isPopular, // Default height
      'text-[0.9375rem] font-normal duration-300 hover:shadow-button-shadow cursor-pointer flex items-center justify-center px-[12px] text-black':
        isPopular, // Adjusted height for popular button
      'rounded-big': !isFooter, // Default rounded corners
      'rounded-tl-[20px] rounded-br-[20px]': isFooter, // Rounded corners for footer
    },
    className
  );

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={buttonClass}
    >
      {children}
    </button>
  );
};
