import classNames from 'classnames';
import React from 'react';
import translations from '../../constants/translations.json';
import { SubscriptionCard } from './subscriptionCard';

type popularButtonColor = 'content' | 'ticket' | 'generic';

interface SubscriptionData {
    title: string;
    description: string;
    features: string[];
    buttonText: string;
    starterPlus:string
}

interface SubscriptionJSON {
    subscriptionCard: {
        feature: string;
        popularText: string;
        full: SubscriptionData[];
    };
}

const subscriptionDataTyped: SubscriptionJSON = translations as SubscriptionJSON;

export const SubscriptionPrice: React.FC = () => {
    const { full } = subscriptionDataTyped.subscriptionCard;

    return (
        <div className={classNames(["grid grid-cols-1 lg:grid-cols-3 gap-8 mt-[64px] container mx-auto"])}>
            {full.map((card, index) => {
                const popular = index === 0 || index === 1;
                const popularButtonColor: popularButtonColor = popular ? (index === 0 ? 'content' : 'ticket') : 'generic';
                const buttonColor: popularButtonColor = index === 0 ? 'content' : (index === 1 ? 'ticket' : 'generic');
                const titleColor = popular ? 'text-white' : 'text-generic';

                return (
                    <SubscriptionCard
                        key={index}
                        title={card.title}
                        starterPlus={card.starterPlus}
                        description={card.description}
                        features={card.features}
                        popular={popular}
                        popularButtonColor={popularButtonColor}
                        buttonColor={buttonColor}
                        buttonText={card.buttonText}
                        titleColor={titleColor}
                    />
                );
            })}
        </div>
    );
};
