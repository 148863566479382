import { ReactComponent as LeftArrowPlusSvg } from '../svg/arrowLeftCenePlus.svg';
import classNames from 'classnames';

interface IBackButton {
  onClick: () => void;
  label: string;
}

export const BackButton = ({ onClick, label }: IBackButton) => (
  <div className="flex items-center">
    <button
      className={classNames([
        'text-event mr-[16px] leading-[14px] text-[0.875rem] px-[17.75px] py-[16.5px] bg-gray-dark',
        'rounded-big',
      ])}
      onClick={onClick}
    >
      <LeftArrowPlusSvg />
    </button>
    <p className="text-white text-[0.875rem] leading-[14px]">{label}</p>
  </div>
);
