export interface IOption {
  label: string;
  value: string;
}

export const ceneUsersOptions: IOption[] = [
  { label: 'Technical Support', value: 'Technical Support' },
  { label: 'Event Inquiries', value: 'Event Inquiries' },
  { label: 'Payment Issues', value: 'Payment Issues' },
  { label: 'Account Management', value: 'Account Management' },
];

export const deleteUserOptions: IOption[] = [
  { label: 'Not Enough Events', value: 'Not Enough Events' },
  { label: 'Too Many Notifications', value: 'Too Many Notifications' },
  {
    label: 'Inconvenient User Experience',
    value: 'Inconvenient User Experience',
  },
  { label: 'Privacy Concerns', value: 'Privacy Concerns' },
];

export const cenePlusOptions: IOption[] = [
  { label: 'Account Management', value: 'Account Management' },
  { label: 'Membership Benefits', value: 'Membership Benefits' },
  { label: 'Member-only offers', value: 'Member-only offers' },
];

export const cenePartnersOptions: IOption[] = [
  {
    label: 'Collaboration Opportunities',
    value: 'Collaboration Opportunities',
  },
  { label: 'Technical Support', value: 'Technical Support' },
  { label: 'Event Support', value: 'Event Support' },
  { label: 'Account Management', value: 'Account Management' },
];
