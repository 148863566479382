import * as Yup from 'yup';

export const deleteAccountSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('E-mail is required')
    .matches(
      /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid e-mail format. Please enter a valid e-mail address.'
    ),
  message: Yup.string(),
  newsletterAgreement: Yup.boolean(),
});

export type PartnerWithUsSchema = Yup.InferType<typeof deleteAccountSchema>;
