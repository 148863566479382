import { CeneModeProps } from '../../../providers/ceneMode';

interface ISwitch {
  ceneMode: CeneModeProps['ceneMode'];
  onChange: (checked: CeneModeProps['ceneMode']) => void;
}

export const CeneSwitch = ({ ceneMode, onChange }: ISwitch) => {
  return (
    <div className="relative w-full max-md:w-full max-xl:w-1/3 flex items-center justify-between gap-2 mb-[32px]">
      <div className="absolute w-full flex justify-between text-center">
        <span
          className={`cursor-pointer block pointer-events-auto w-1/2 py-[12px] text-textSmall font-medium transition-all duration-300 ${
            ceneMode === 'cene' ? 'text-black' : 'text-white'
          } z-10`}
          onClick={() => onChange('cene')}
        >
          CENE
        </span>
        <span
          className={`cursor-pointer block pointer-events-auto w-1/2 py-[12px] text-textSmall font-medium transition-all duration-300 ${
            ceneMode === 'cene+' ? 'text-black' : 'text-generic'
          } z-10`}
          onClick={() => onChange('cene+')}
        >
          CENE+
        </span>
      </div>
      <label className="w-full flex items-center cursor-pointer">
        <div className="w-full p-1 bg-gray-medium rounded-tl-[20px] rounded-br-[20px]">
          <div
            className={`w-1/2 h-10 rounded-tl-[16px] rounded-br-[16px] transform transition-transform duration-300 ${
              ceneMode === 'cene+'
                ? 'translate-x-full bg-generic'
                : 'translate-x-0 bg-content'
            }`}
          />
        </div>
      </label>
    </div>
  );
};
