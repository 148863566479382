import {
  DateView,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  pickersLayoutClasses,
  usePickerLayout,
} from '@mui/x-date-pickers';

export const CustomLayout = (
  props: PickersLayoutProps<Date | null, Date, DateView>
) => {
  const { toolbar, content, actionBar } = usePickerLayout(props);

  return (
    <PickersLayoutRoot
      className={pickersLayoutClasses.root}
      ownerState={props}
      sx={{
        backgroundColor: '#000',
        color: 'white',
        border: '1px solid #222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        [`.${pickersLayoutClasses.toolbar}`]: {
          display: 'none',
          '& .MuiTypography-root': {
            color: 'white',
          },
        },
        [`.${pickersLayoutClasses.actionBar}`]: {
          '& .MuiButton-text': {
            color: '#4A7DFF',
          },
        },
        [`.${pickersLayoutClasses.contentWrapper}`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& .Mui-selected': {
            color: 'white',
            backgroundColor: '#4A7DFF',
            borderRadius: '16px 0',
          },
          '& .Mui-selected:hover': {
            color: 'white',
          },
          '& .Mui-selected:focus': {
            color: 'white',
          },
        },
        '.MuiTypography-root': {
          color: 'white',
          fontFamily: "'Roobert', sans-serif",
        },
        '.Mui-disabled': {
          color: 'rgba(255,255,255, 0.5) !important',
        },
        '.MuiSvgIcon-root': {
          color: 'white',
        },
        '.MuiPickersCalendarHeader-label': {
          fontFamily: "'Roobert', sans-serif",
        },
        '.MuiPickersYear-yearButton': {
          fontFamily: "'Roobert', sans-serif",
        },
        '.MuiPickersDay-root': {
          color: 'white',
          fontFamily: "'Roobert', sans-serif",
        },
      }}
    >
      {toolbar}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        {content}
      </PickersLayoutContentWrapper>
      {actionBar}
    </PickersLayoutRoot>
  );
};
