import { Button } from '../../components/button/button';
import { ROUTE_PARTNER_WITH_US } from '../../constants/routes';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Phone = '/pictures/notebookBanner.png';

export const MakeTicketSection = () => {
  const navigate = useNavigate();
  return (
    <div
      className={
        'w-full h-auto flex flex-col justify-center items-center lg:px-[113.5px] '
      }
    >
      <div
        className={classNames([
          'relative bg-village-gradient lg:h-[520px] h-[692px] rounded-tl-[30px] w-full flex flex-col',
          'lg:w-full lg:justify-between lg:flex-row',
        ])}
      >
        <div
          className={classNames([
            ' flex flex-col items-start mt-[28px]',
            ' lg:ml-[55px] lg:mr-[90px] mx-[24px] lg:mx-0 lg:items-start lg:mt-[83px]  lg:flex-row lg:w-full  lg:h-auto ',
          ])}
        >
          <div
            className={classNames([
              'text-left lg:w-[467px] w-auto h-[114px]',
              'lg:text-left lg:w-[467px] lg:mr-[90px]',
            ])}
          >
            <h2
              className={classNames([
                'text-white text-[2rem] leading-[40px] font-light',
                'lg:leading-[56px]  lg:text-[3rem] whitespace-pre-line',
              ])}
            >
              {translations.burstDesc.makeTicket}
            </h2>
            <p className="text-white lg:w-[414px] leading-[22px] font-normal text-[1.0625rem] lg:mt-[32px] mt-[24px]">
              {translations.burstDesc.desc}
            </p>
            <div className="flex mt-[24px] lg:mt-[32px] space-x-4  z-10 relative">
              <Button
                color="content"
                type="button"
                className="lg:w-[199px] lg:h-[46px] w-[159.5px] h-[48px] rounded-none rounded-tl-[20px] rounded-br-[20px]"
                onClick={() => navigate(ROUTE_PARTNER_WITH_US)}
              >
                {translations.burstDesc.buttonOne}
              </Button>
            </div>
          </div>
          <img
            src={Phone}
            alt="Phone"
            className={classNames([
              'w-auto h-[310px] absolute bottom-0 z-20  right-0 ',
              'lg:top-[0] lg:mt-0 mx-auto lg:right-0 lg:h-[480px] ',
            ])}
          />
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-[278px] bg-bottom-village"></div>
      </div>
    </div>
  );
};
