import translations from '../../constants/translations.json';
import { ReactComponent as RequestSVG } from '../../svg/ticketGeneric.svg';
import classNames from 'classnames';

interface NotFoundSectionProps {
  title: string;
  sessionType: boolean;
  ticketRequestsCount: number;
}

const NotFoundSection = ({
  sessionType,
  ticketRequestsCount,
  title,
}: NotFoundSectionProps) => {
  return (
    <div className="flex flex-col gap-[2.5rem] max-lg:gap-[1.5rem] items-center w-full">
      <div className="flex items-center justify-start w-full">
        <h2
          className={classNames(
            'text-[2rem]/[2.5rem] font-light',
            sessionType ? 'text-ticket' : 'text-generic'
          )}
        >
          {title}
        </h2>

        <p className="text-[0.9375rem] leading-[20px] text-event-light ml-[8px] self-start">
          {ticketRequestsCount}
        </p>
      </div>
      <div
        className={classNames(
          'max-w-[544px] text-center flex flex-col items-center',
          'max-lg:py-[2.5rem] max-lg:max-w-[280px]'
        )}
      >
        <RequestSVG
          style={{ stroke: '#b8cad9', width: '48px', height: '48px' }}
        />
        <h2
          className={classNames(
            'text-[3rem]/[3.5rem] font-light',
            'max-lg:text-[1.3125rem]/[150%]',
            sessionType ? 'text-ticket' : 'text-generic'
          )}
        >
          {translations.myBasket.noRequestsTitle}
        </h2>
        <p
          className={classNames(
            'text-[1.0625rem]/[1.375rem]  text-gray-light !font-light mt-[1rem]',
            'max-lg:text-[0.875rem]/[1.3125rem] max-lg:mt-[0.5rem]'
          )}
        >
          {translations.myBasket.emptyRequestsTitle}
        </p>
      </div>
    </div>
  );
};

export default NotFoundSection;
