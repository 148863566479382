const privacyPolicy = {
  label: 'Privacy Policy',
  content: (
    <div>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light">
        We acknowledge the importance of your privacy and personal data and have
        thus ensured that this Privacy Policy is in compliance with the Nigerian
        Data Protection Act 2023. In this Privacy Policy we explain the
        following in clear, concise and transparent words:
      </p>
      <ol className="text-generic list-decimal list-inside">
        <li>Personal data which we collect from you.</li>
        <li>
          Purposes for which your personal data is collected and processed.
        </li>
        <li>The Recipients of your personal data.</li>
        <li>Storage and Security of your data.</li>
        <li>
          The retention period within which we shall keep your personal data.
        </li>
        <li>Your lawful rights under this Privacy Policy.</li>
        <li>
          Our identity, place of business and means of communication or contact
          with you.
        </li>
      </ol>
      <ol className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white list-decimal list-inside space-y-[4rem] max-lg:space-y-[2.5rem] mt-[4rem] max-lg:mt-[2.5rem]">
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white inline font-light">
            Personal data which we collect from you
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            In the course of your interaction with the Website, our provision of
            services, or any other business relationship with you, we may
            collect and process some or all of the following types of data:
          </p>
          <ul
            className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] list-inside mt-[24px]"
            style={{ listStyleType: 'lower-roman' }}
          >
            <li>
              Personal information which you furnish by completing forms on the
              website and devices/apps owned by our third-party partners such as
              your name, address, phone number, date of birth, email address,
              your image(s) and recordings, billing details or any further
              information which you supply in the course of using the Website.
            </li>
            <li>
              Your correspondence with us in the event that you contact us.
            </li>
            <li>
              Information regarding your visits to the Website, which includes,
              but is not restricted to, traffic data, location data, weblogs,
              and other communication-related data, as well as the resources you
              access.
            </li>
          </ul>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white font-light inline">
            Purposes for which your personal data is collected and processed
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            Personal data which you supply to us is used in the process of your
            registration and/or subscription to the website and our app. When
            you provide information such as your name, email address, location
            and telephone number. Personal data provided to us from your
            interaction with the website and our app will also be used to reach
            and contact you for news, alerts or notifications about our services
            and our apps. Your personal data will also be used to grant you
            access and engage with the interactive features of our services and
            our apps, providing you with an opportunity to actively take part in
            various aspects of our services.
          </p>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white font-light inline">
            Recipients of your personal data
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            The Cene Team collects and utilizes the information you provide to
            us to facilitate your access to our services, as outlined in Clause
            2 of this Privacy Policy. This information is used to deliver the
            services you request and enhance your overall experience while using
            our platform. By leveraging the data you provide, we can effectively
            provide the services we offer and maintain a high level of user
            satisfaction. Our third-party event organizers and store owners may
            access and utilize your data to push out communications to you
            occasionally. Also, our partners like the payment gateways and cloud
            storage companies as well as all other third party partners may
            access and utilize your personal data in carrying out their
            obligations. Rest assured that we handle your information in
            accordance with our Privacy Policy and take measures to safeguard
            its security and confidentiality.
          </p>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white font-light inline">
            Storage and security of your data
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            To safeguard your personal data, we implement appropriate security
            measures to prevent accidental loss, unauthorized access, or
            improper use. Access to your personal data is restricted to
            individuals who genuinely require it for legitimate business
            purposes. Those individuals handling your information are bound by
            strict confidentiality obligations and will process it only in an
            authorized manner. We take data security seriously to ensure your
            information remains protected and confidential.
          </p>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            In the event of any suspected data security breach, we have
            established comprehensive procedures to address the situation
            promptly. If we determine that a breach has occurred and it could
            potentially impact your personal data, we will notify you and any
            relevant regulatory authorities as required by applicable laws and
            regulations. Our commitment to data security includes taking
            appropriate action to mitigate the impact of breaches and ensuring
            that you are informed in a timely and transparent manner if your
            data is affected. We take data security seriously and employ
            measures to protect your personal information, but we cannot
            guarantee the security of data transmitted to our Website. When
            visiting external websites linked from our Website, please review
            their privacy policies and terms of use, as we are not responsible
            for their practices.
          </p>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white font-light inline">
            Retention period within which we shall keep your personal data
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            We shall retain your personal data for as long as our platform
            exists and we continue to provide our services. Your data shall be
            deleted once the purpose for which it was collected ceases to exist.
          </p>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white font-light inline">
            Your lawful rights under this privacy policy
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            You have the right to access your personal data and supplementary
            information covered in this Notice. If you believe there are
            mistakes in your information, you can request correction. In certain
            situations, you may also request the erasure of your personal data.
            Additionally, you have the right to receive your provided personal
            data in a structured, commonly used, and machine-readable format
            and, in some cases, transmit it to a third party.
          </p>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            You can object to the processing of your personal data for direct
            marketing purposes, as well as to decisions based on automated
            processing that significantly affect you. In specific circumstances,
            you have the right to object to our continued processing of your
            personal data or to request a restriction on such processing.
          </p>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white font-light inline">
            Changes to our privacy policy
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            We retain the right to make changes to this Privacy at our
            discretion. In the event of any modifications, we will notify you
            and provide the updated Notice on the Website. By continuing to use
            our services and the Website after such changes, you are deemed to
            have accepted the revised Privacy Notice.
          </p>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white font-light inline">
            Our identity, place of business and means of communication or
            contact with you.
          </h2>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            For all questions, comments, and requests related to this Privacy
            Policy, except where specified above, please contact us at
            support@cene.xyz or write to us at the following address:
          </p>
          <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            Cene Entertainment Ltd,
          </p>
        </li>
      </ol>
    </div>
  ),
};

export default privacyPolicy;
