import classNames from 'classnames';
import React from 'react';

interface SubscriptionCardProps {
  title: string;
  description: string;
  titleSmall: string;
  stepLabel?: string;
}

export const StepsCard: React.FC<SubscriptionCardProps> = ({
  title,
  description,
  titleSmall,
  stepLabel,
}) => {
  return (
    <div className="flex flex-col justify-between rounded-tl-[30px] rounded-br-[30px] relative border font-normal border-gray-medium lg:h-[429px] h-[405px] ">
      <div>
        <div className="flex items-center justify-between lg:mb-[56px] mb-[32px] px-[40px] mt-[40px]">
          <h1
            className={classNames([
              `text-[1.5rem] leading-[36px]`,
              'lg:text-[3rem] lg:leading-[56px] font-light text-event',
            ])}
          >
            {title}
          </h1>
          {stepLabel && (
            <button className="bg-gray-medium text-event w-[75px] rounded-big leading-[20px] text-[0.9375rem]">
              {stepLabel}
            </button>
          )}
        </div>
        <div className="px-[40px] text-[1.3125rem] leading-[21px]">
          <p className="text-generic mb-[46px] ">{titleSmall}</p>

          <h2
            className={classNames([
              `font-light  text-white whitespace-pre-line`,
              'leading-[22px] text-[1.0625rem] lg:mb-[56px]',
            ])}
          >
            {description}
          </h2>
        </div>
      </div>
    </div>
  );
};
