import translations from '../../constants/translations.json';
import { FeatureList } from './featureList';
import classNames from 'classnames';

export const FeaturesMembership = () => {
  return (
    <div className="w-full h-auto flex flex-col justify-center items-center lg:py-[120px] py-[72px] max-xl:px-0 lg:px-[64px] px-[20px]  ">
      <div className="w-full md:w-[744px] mx-auto">
        <h1 className="text-generic text-[0.9375rem] leading-[20px] text-center font-normal mb-[8px] lg:mb-0">
          {translations.infoFeaturesMembership.feature}
        </h1>
        <p className="text-[2rem] leading-[40px] font-light text-generic text-center lg:text-[4rem] lg:leading-[72px]">
          {translations.infoFeaturesMembership.title}
        </p>
      </div>

      <p
        className={classNames([
          ' font-light text-[1rem] leading-[21px] text-white-light text-center mt-[24px] md:mx-0 lg:w-[644px] mb-[40px]',
          ' lg:text-[1.0625rem]  lg:leading-[22px] lg:mb-[64px]',
        ])}
      >
        {translations.infoFeaturesMembership.descriptionOne}
      </p>
      <div className=" lg:hidden w-full h-[1px] bg-gray-medium" />
      <FeatureList />
    </div>
  );
};
