import translations from '../../constants/translations.json';
import classNames from 'classnames';

export const DiversityBanner = () => {
  return (
    <div
      className={classNames([
        'w-full z-20 absolute flex flex-col justify-center items-center top-[45%] -translate-y-[50%]',
      ])}
    >
      <p className="text-white lg:text-[1.0625rem] lg:leading-[22px] lg:mb-[24px] text-[1rem] leading-[21px] mt-[145px] lg:mt-0">
        {translations.diversity.titleSmall}
      </p>
      <h1
        className={classNames([
          'font-light text-white text-center',
          'lg:text-[4.5rem] lg:leading-[80px] text-[2.25rem] leading-[44px] whitespace-pre-line mt-[16px] lg:mt-0',
        ])}
      >
        {translations.diversity.title}
      </h1>
      <h3
        className={classNames([
          'lg:text-[1.0625rem] font-light text-white lg:px-0 text-center lg:mt-[24px] mt-[16px] whitespace-pre-line lg:leading-[22px] lg:w-[600px]',
          'text-[1rem] leading-[21px] px-[20px]',
        ])}
      >
        {translations.diversity.desc}
      </h3>

      <div
        className={classNames([
          'flex mt-[24px] w-full justify-center space-x-4',
        ])}
      ></div>
    </div>
  );
};
